// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../_assets/img/icon_alerts.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".AlarmCard_AlarmCard__Hsnb9 {\n  width: 180px;\n  margin: 0 20px 20px; }\n  .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_icon__2jXRQ {\n    width: 70px;\n    position: relative;\n    top: 30px; }\n  .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_content__UYKZm {\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);\n    padding: 0 10px;\n    height: 90px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding-top: 15px;\n    margin-bottom: 0; }\n    .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_content__UYKZm.AlarmCard_alert__3TA4w::before {\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      background-size: contain; }\n    .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_content__UYKZm p {\n      margin: 0;\n      position: relative;\n      top: -20px; }\n  .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_date__1C0ZJ {\n    margin: 0 15px;\n    border: 4px solid #3d009e;\n    line-height: 18px;\n    color: #fff;\n    font-weight: 700; }\n    .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_date__1C0ZJ.AlarmCard_alert__3TA4w {\n      border: 4px solid #ff063c;\n      background-color: #ff063c; }\n    .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_date__1C0ZJ.AlarmCard_battery__wrddm {\n      border: 4px solid royalblue;\n      background-color: royalblue; }\n    .AlarmCard_AlarmCard__Hsnb9 .AlarmCard_date__1C0ZJ.AlarmCard_energyMeter__3aasR {\n      border: 4px solid #ff063c;\n      background-color: #ff063c; }\n", ""]);
// Exports
exports.locals = {
	"AlarmCard": "AlarmCard_AlarmCard__Hsnb9",
	"icon": "AlarmCard_icon__2jXRQ",
	"content": "AlarmCard_content__UYKZm",
	"alert": "AlarmCard_alert__3TA4w",
	"date": "AlarmCard_date__1C0ZJ",
	"battery": "AlarmCard_battery__wrddm",
	"energyMeter": "AlarmCard_energyMeter__3aasR"
};
module.exports = exports;
