import React,{ useEffect,useState } from 'react';
import { Link } from 'react-router-dom';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import RegisterSermatecForm from '../_components/Form/RegisterSermatecForm';

// import classes from './RegisterSermatecPage.sermatec.scss';

const RegisterSermatecPage = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const [params,setParams] = useState(props.match.params);
    const queryString = require('query-string');
    const paramId = props.location.search.length > 0 ? JSON.parse(queryString.parse(props.location.search).id) : null;
    // const newCustomerId = (typeof paramId !== 'undefined' && paramId !==  null) ? paramId : "";
    const [userIsClient,setUserIsClient] = useState(false);

    useEffect(() => {
        // if((user.roles.includes('installer') || user.roles.includes('super-admin') || user.roles.includes('admin')) && !user.roles.includes('client')) {
        if(user.roles.includes('installer') || user.roles.includes('super-admin') || user.roles.includes('admin')) {
            setUserIsClient(false);
        }
        if(!user.roles.includes('installer') && !user.roles.includes('super-admin') && !user.roles.includes('admin') && user.roles.includes('client')) {
            setUserIsClient(true);
        }
        console.log("user in register sermatec");
        console.log(user);
        console.log("userIsClient");
        console.log(userIsClient);
        // console.log("newCustomer");
        // console.log(newCustomer);
        console.log("paramId");
        console.log(paramId);
    },[]);

    return (
        <BatteryUpPage sidebar={true}>
            <RegisterSermatecForm idSelect={paramId !==  null ? paramId : user.id} />
        </BatteryUpPage>
    );
}

export default RegisterSermatecPage;
