import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './DataContainer.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import SocVisual from '../Data/SocVisual';
import AcrPGauge from '../Data/AcrPGauge';
import FlipCard from '../Data/Cards/FlipCard';
import DataCards from '../Data/Cards/DataCards';
import Legend from './Legend';
import AdditionalData from '../Data/AdditionalData';
import PylontechData from '../Data/Pylontech/PylontechData';
import PylontechAlarms from '../Data/Pylontech/PylontechAlarms';
import AcrelData from '../Data/Acrel/AcrelData';
import EastronData from '../Data/Eastron/EastronData';
import FirstNewChart from '../Data/NewCharts/FirstNewChart';
import SecondNewChart from '../Data/NewCharts/SecondNewChart';
import ThirdNewChart from '../Data/NewCharts/ThirdNewChart';
import FourthNewChart from '../Data/NewCharts/FourthNewChart';
import FirstNewChartDays from '../Data/NewCharts/FirstNewChartDays';
import HoursChart from '../Data/NewCharts/HoursChart';
import AcrelChartsWrapper from '../Data/NewCharts/AcrelChartsWrapper';
// import ChartContainer from '../Data/ChartContainer';
import ChartContainer from '../Data/ChartContainerMongo';
import { dataService } from '../../_services/dataService';
import TestComponent from '../Test/TestComponent';

const DevDataContainer = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    let initInstall = props.selectedInstallation;
    const [batteries,setBatteries] = useState([]);
    const [acrels,setAcrels] = useState([]);
    const [eastrons,setEastrons] = useState([]);
    const [acrAbsRel,setAcrAbsRel] = useState([]);

    useEffect(() => {
        console.log("props DevDataContainer");
        console.log(props);
        // console.log("init install in NDataContainer");
        // console.log(initInstall);
        let installBatteries = [];
        let installAcrels = [];
        let installEastrons = [];
        if (initInstall && initInstall.modules) {
            for (let i = 0; i < initInstall.modules.length; i++) {
                if (initInstall.modules[i].protocol_id === 1) {
                    installBatteries.push(initInstall.modules[i].mac_address);
                }
                if (initInstall.modules[i].protocol_id === 2 || initInstall.modules[i].protocol_id === 4) {
                    installAcrels.push(initInstall.modules[i].mac_address);
                }
                if (initInstall.modules[i].protocol_id === 3 || initInstall.modules[i].protocol_id === 5) {
                    installEastrons.push(initInstall.modules[i].mac_address);
                }
            }
            // console.log("installBatteries in NDataContainer");
            // console.log(installBatteries);
            // console.log("installAcrels in NDataContainer");
            // console.log(installAcrels);
            // console.log("installEastrons in NDataContainer");
            // console.log(installEastrons);
            if (installBatteries.length > 0) {
                setBatteries(installBatteries);
            } else {
                setBatteries([]);
            }
            if (installAcrels.length > 0) {
                setAcrels(installAcrels);
            } else {
                setAcrels([]);
            }
            if (installEastrons.length > 0) {
                setEastrons(installEastrons);
            } else {
                setEastrons([]);
            }
        }
        else {
            setBatteries([]);
            setAcrels([]);
            setEastrons([]);
        }
    },[initInstall]);

    return (
        <Aux>
            {/* <h4>Main chart MongoDB</h4> */}
            {(batteries.length > 0 || acrels.length > 0 || eastrons.length > 0) ? <ChartContainer arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} /> : ""}
            {/* {(batteries.length > 0 ? (
                batteries.map((battery,idx) => {
                    return (
                        <div key={idx} className={`col-sm-12 col-md-6 col-lg-4`}>
                            <SocVisual mac_address={battery} />
                        </div>
                    )
                })) : ""
            )}
            {(acrels.length > 0 ? (
                acrels.map((acrel,idx) => {
                    return (
                        <div key={idx} className={`col-sm-12 col-md-6 col-lg-4`}>
                            <AcrPGauge mac_address={acrel} />
                        </div>
                    )
                })) : ""
            )}
            {batteries.length === 0 && acrels.length === 0 && eastrons.length === 0 && (
                <p>{t('dashboard:DataContainer.no-device')}</p>
            )}
            <div className={`col-12`}>
                <DataCards arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
            </div>
            <div>
                <SingleValueCard title="Title" icon="power" type="battery" value={3.45} tooltip="Tooltip" />
            </div>
            <div>
                <CircleCard title="Title" icon="power" type="battery" value={3.45} tooltip="Tooltip" />
            </div>
            <div>
                <FlipCard title="Puissance max" icon="power" type="battery" value={3.45} unit=" kW" tooltip="La puissance maximale (en kilowatts) enregistrée depuis l'installation de votre module Battery UP." />
            </div> */}
            <Legend arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
            {/* <div className={`col-md-12`}>
                <AdditionalBatteriesData arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
            </div> */}
            {(batteries.length > 0 ? (
                batteries.map((battery,idxP) => {
                    return (
                        <Aux key={idxP}>
                            <p>test A</p>
                            <TestComponent macaddress={battery} />
                            {/* <div className={`col-12`}>
                                <PylontechData mac_address={battery} />
                            </div>
                            <div className={`col-12`}>
                                <PylontechAlarms mac_address={battery} />
                            </div> */}
                            {/* <div className="col-12">
                                <FirstNewChart />
                            </div> */}
                            {/* <div className="col-12">
                                <SecondNewChart />
                            </div> */}
                            {/* <div className="col-12">
                                <ThirdNewChart />
                            </div> */}
                            {/* <div className="col-12">
                                <FourthNewChart />
                            </div> */}
                            {/* <div className="col-12">
                                <FirstNewChartDays />
                            </div> */}
                            {/* <div className="col-12">
                                <HoursChart />
                            </div> */}
                        </Aux>
                    )
                })) : ""
            )}
            {(acrels.length > 0 ? (
                acrels.map((acrel,idxA) => {
                    return (
                        <Aux key={idxA}>
                            {/* <div className={`col-12`}>
                                <AcrelData mac_address={acrel} />
                            </div> */}
                            <div className="col-12">
                                {/* <AcrelChartsWrapper macAddress={acrel} /> */}
                            </div>
                        </Aux>
                    )
                })) : ""
            )}
            {/* {(eastrons.length > 0 ? (
                eastrons.map((eastron,idxE) => {
                    return (
                        <Aux key={idxE}>
                            <div className={`col-12`}>
                                <AcrelData mac_address={eastron} />
                            </div>
                        </Aux>
                    )
                })) : ""
            )} */}
            {/* {(batteries.length > 0 || acrels.length > 0 || eastrons.length > 0) && (
                <Aux>
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        <ChartContainer arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div>
                </Aux>
            )} */}
        </Aux>
    );
};

export default DevDataContainer;