import React,{ useMemo } from 'react';
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import Auxil from '../../_hoc/Auxil/Auxil';

const RecursiveContainer = ({ validationSchema,config,formik }) => {
    console.log(validationSchema);
    // const validationSchema = props.stepOneValidationSchema;
    const builder = (individualConfig) => {
        switch (individualConfig.type) {
            case 'text':
                return (
                    <>
                        <Formik validationSchema={validationSchema}>
                            <Auxil>
                                <label htmlFor={individualConfig.field}>{individualConfig.label}</label>
                                <Field type='text'
                                    id={individualConfig.field}
                                    name={individualConfig.field}
                                    onChange={formik.handleChange} style={{ ...individualConfig.style }} />
                                <ErrorMessage name={individualConfig.field} />
                            </Auxil>
                        </Formik>
                    </>
                );
            case 'number':
                return (
                    <>
                        <div>
                            <label htmlFor={individualConfig.field}>{individualConfig.label}</label>
                            <input type='number'
                                id={individualConfig.field}
                                name={individualConfig.field}
                                onChange={formik.handleChange} style={{ ...individualConfig.style }} />
                        </div>
                    </>
                )
            case 'array':
                return (
                    <RecursiveContainer config={individualConfig.children || []} formik={formik} />
                );
            default:
                return <div>Unsupported field</div>
        }
    }

    return (
        <>
            {config.map((c) => {
                return builder(c);
            })}
        </>
    );
};

export default RecursiveContainer;