import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';
import LoginForm from '../_components/Form/LoginForm';

import classes from './LoginPage.module.scss';

const LoginPage = (props) => {
    return (
        <GenericPage sidebar={false}>
            <LoginForm />
        </GenericPage>
    );
}

export default LoginPage;