import React,{ useState,useEffect } from 'react';

import { Trans,useTranslation } from 'react-i18next';

import { dataService } from '../../../_services/dataService';
import { moduleService } from '../../../_services/moduleService';
import { predictionService } from '../../../_services/predictionService';

import Aux from '../../../_hoc/Auxil/Auxil';

import classes from './PylontechVisualSummary.module.scss';

const PylontechVisualSummary = (props) => {
    const { t } = useTranslation(['common']);
    const mac_address = props.mac_address;
    const [currentSoc,setCurrentSoc] = useState(0);
    const [previousSoc,setPreviousSoc] = useState(0);
    const [diffSoc,setDiffSoc] = useState(0);
    const [valueGradient,setValueGradient] = useState("100%");
    const [display,setDisplay] = useState("none");
    const [device,setDevice] = useState("Pylontech");
    const [testMac,setTestMac] = useState("");
    const [socAdvice,setSocAdvice] = useState([]);

    useEffect(() => {
        // console.log("mac_address soc visual");
        // console.log(mac_address);
        moduleService.getNameByMacAddress(mac_address)
            .then(response => {
                setDevice(response);
            });
        dataService.socPastHour(mac_address)
        // dataService.socPastHourOld(mac_address)
            .then(response => {
                if (response.latest) {
                    // console.log("response.latest.soc");
                    // console.log(response.latest.soc);
                    setCurrentSoc(parseInt(response.latest.soc));
                } else {
                    setCurrentSoc("empty");
                }
                if (response.oldest) {
                    setPreviousSoc(parseInt(response.oldest.soc));
                    setDiffSoc(parseInt(response.latest.soc) - parseInt(response.oldest.soc));
                } else {
                    setPreviousSoc("empty");
                    setDiffSoc(0);
                }
                if (response.latest) {
                    setValueGradient("" + 100 - parseInt(response.latest.soc) + "%");
                } else {
                    setValueGradient("" + 100 + "%");
                }
            })
            .then(response => {
                setDisplay("block");
            });
        predictionService.socAdvice(mac_address)
            .then(response => {
                let test = [];
                // console.log("response SOC prediction");
                // console.log(response);
                if (response) {
                    for (const [key,value] of Object.entries(response)) {
                        test[key] = value;
                    }
                    setSocAdvice(test);
                } else {
                    setSocAdvice(response);
                }
            });
        // let testArray = [];
        // testArray['_id'] = "ee";
        // testArray['value'] = 23;
        // testArray['recommendation'] = true;
        // setSocAdvice(testArray);
    },[props.mac_address]);

    // console.log("socAdvice");
    // console.log(socAdvice["recommendation"]);
    // console.log("currentSoc");
    // console.log(currentSoc);
    // console.log("previousSoc");
    // console.log(previousSoc);

    return (
        <div className={classes.SocVisual}>
            <h3 className={classes.SocVisualTitle}>{device}</h3>
            {((currentSoc !== "empty" && previousSoc !== "empty") && (currentSoc !== 0 && previousSoc !== 0)) ? (
                <div className={classes.SocVisualContent}>
                    <div className={`${classes.socValues} ${classes.socValues__Visual}`}>
                        <div className={classes.imageBatteryWrapper}>
                            <div className={classes.imageBatteryAlt} style={{ "display": display }}>
                                <div className={classes.progressBarContainerAlt}>
                                    <div className={`${classes.progressBarChildAlt} ${classes.progressAlt}`}></div>
                                    <div className={`${classes.progressBarChildAlt} ${classes.shrinkerAlt} ${classes.timelapseAlt}`} style={{ "--to-height": valueGradient }}></div>
                                    <p className={classes.testSoc}>80%</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.socData}>
                            <h4>{t('common:datas.battery.soc')}</h4>
                            {/* {currentSoc !== "empty" ? <p>{currentSoc} %</p> : <p>{t('common:datas.not-communicated')}</p>} */}
                            {currentSoc !== "empty" ? <p>{currentSoc} %</p> : <p>{t('common:datas.no-data')}</p>}
                            {/* <p>{currentSoc} %</p> */}
                        </div>
                    </div>
                    <div className={classes.socValues}>
                        <div className={classes.socData}>
                            <h4>{t('common:datas.progression-last-hour')}</h4>

                            {diffSoc ? (
                                <p className={classes.socProgression}>{diffSoc > 0 ? (
                                    <Aux>
                                        <img className={classes.upOrDown} src={require("../../../_assets/img/icon_up.png")} alt="Créez des scénarios grâce à  IFTTT" /><span>+</span>
                                    </Aux>
                                ) : (diffSoc < 0 ? (
                                    <Aux>
                                        <img className={classes.upOrDown} src={require("../../../_assets/img/icon_down.png")} alt="Créez des scénarios grâce à  IFTTT" /><span></span>
                                    </Aux>
                                ) : (
                                    // <span className={classes.equals}>=</span>
                                    <span className={classes.noProgression}>{t('dashboard:SocVisual.no-progression')}</span>
                                ))}{diffSoc}%</p>
                                // ) : <p>"N/A"</p>}
                            ) : <p className={classes.socProgression}><span className={classes.noProgression}>{t('dashboard:SocVisual.no-progression')}</span></p>}
                        </div>
                        {/* {(socAdvice["_id"] && currentSoc !== "empty") ? ( */}
                        {(socAdvice && currentSoc !== "empty") ? (
                            <div className={`${classes.socData} ${classes.socData__predictions}`}>
                                <h4>Conseil Battery UP</h4>
                                <div>
                                    {(socAdvice["recommendation"] && socAdvice["value"] > 5 && currentSoc < 80) ? (
                                        // {(socAdvice["recommendation"] && socAdvice["value"] > 5) ? (
                                        <Aux>
                                            <p><img src={require("../../../_assets/img/icons/predictions/consumption_advice_yes_green.png")} alt="Yes" /></p>
                                            {/* <p>YES</p> */}
                                        </Aux>
                                    ) : (socAdvice["recommendation"] && currentSoc < 80) ? (
                                        // ) : (socAdvice["recommendation"]) ? (
                                        <Aux>
                                            <p><img src={require("../../../_assets/img/icons/predictions/consumption_advice_yes_purple.png")} alt="" /></p>
                                            {/* <p>yes</p> */}
                                        </Aux>
                                    ) : (
                                        <Aux>
                                            <p><img src={require("../../../_assets/img/icons/predictions/consumption_advice_no.png")} alt="No" /></p>
                                            {/* <p>no</p> */}
                                        </Aux>
                                    )}
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </div>
            ) : <p className={classes.emptyData}>Pas de données</p>}
            <div className={classes.colorBorder}></div>
        </div>
    )
};

export default PylontechVisualSummary;