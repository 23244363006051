// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StepOne_scenarioStep1__3_bJ4 .StepOne_scenarioStep1__list__2Xy6H .StepOne_scenarioStep1__item__2ni-K {\n  display: flex;\n  align-items: baseline; }\n  .StepOne_scenarioStep1__3_bJ4 .StepOne_scenarioStep1__list__2Xy6H .StepOne_scenarioStep1__item__2ni-K span {\n    margin-left: 10px;\n    font-weight: 600;\n    position: relative;\n    top: 1px; }\n\n.StepOne_scenarioStep1__3_bJ4 .StepOne_scenarioStep1__list__2Xy6H input[type=checkbox] {\n  height: 0;\n  width: 0;\n  visibility: hidden; }\n\n.StepOne_scenarioStep1__3_bJ4 .StepOne_scenarioStep1__list__2Xy6H .StepOne_switchLabel__392Eq {\n  cursor: pointer;\n  width: 54px;\n  height: 24px;\n  background: grey;\n  display: block;\n  border-radius: 100px;\n  position: relative;\n  color: white;\n  font-weight: 700;\n  font-size: 12px;\n  padding-top: 6px;\n  text-transform: uppercase;\n  text-align: right;\n  padding-right: 4px;\n  padding-left: 0; }\n  .StepOne_scenarioStep1__3_bJ4 .StepOne_scenarioStep1__list__2Xy6H .StepOne_switchLabel__392Eq.StepOne_on__1ZgIq {\n    text-align: left;\n    padding-left: 4px;\n    padding-right: 0; }\n\n.StepOne_scenarioStep1__3_bJ4 .StepOne_switchLabel__392Eq:after {\n  content: '';\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  width: 20px;\n  height: 20px;\n  background: #fff;\n  border-radius: 90px;\n  transition: 0.3s; }\n\n.StepOne_scenarioStep1__3_bJ4 input:checked + .StepOne_switchLabel__392Eq {\n  background: #bada55; }\n\n.StepOne_scenarioStep1__3_bJ4 input:checked + .StepOne_switchLabel__392Eq:after {\n  left: calc(100% - 2px);\n  transform: translateX(-100%); }\n\n.StepOne_scenarioStep1__3_bJ4 .StepOne_laswitchLabelbel__2dnsL:active:after {\n  width: 130px; }\n", ""]);
// Exports
exports.locals = {
	"scenarioStep1": "StepOne_scenarioStep1__3_bJ4",
	"scenarioStep1__list": "StepOne_scenarioStep1__list__2Xy6H",
	"scenarioStep1__item": "StepOne_scenarioStep1__item__2ni-K",
	"switchLabel": "StepOne_switchLabel__392Eq",
	"on": "StepOne_on__1ZgIq",
	"laswitchLabelbel": "StepOne_laswitchLabelbel__2dnsL"
};
module.exports = exports;
