import React,{ Component } from 'react';

import ReactWOW from 'react-wow';
import classes from './AppUsers.module.scss';

class AppUsers extends Component {
    render() {
        return (
            <section className={`app-users-section ${classes.AppUsers}`}>
                <ReactWOW delay='0.3s' animation='fadeInUp'>
                    <div className="container">
                        <div className="row">
                            <div className="col-2 offset-2 ">
                                <div className="single-user">
                                    <i className="icofont-wifi-alt"></i>
                                    {/* <h3>ios <br /> 205k</h3> */}
                                    <h3>WIFI</h3>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="single-user">
                                    <i className="icofont-brand-apple"></i>
                                    {/* <h3>ios <br /> 205k</h3> */}
                                    <h3>ios</h3>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="single-user">
                                    <i className="icofont-brand-android-robot"></i>
                                    <h3>Android</h3>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className="single-user">
                                    {/* <i className="icofont-cloud-download"></i> */}
                                    <h3>IFTTT</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </section>
        )
    }
}

export default AppUsers;