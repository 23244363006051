import React from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

function AlertsPage() {
    return (
        <BatteryUpPage>
        </BatteryUpPage>
    );
}

export default AlertsPage;
