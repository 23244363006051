// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SmallDataCard_SmallDataCard__5mbrD {\n  width: 180px;\n  margin: 20px;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  border-radius: 10px; }\n  .SmallDataCard_SmallDataCard__5mbrD .SmallDataCard_title__3WPUc {\n    margin: 0;\n    font-size: 14px;\n    color: #000;\n    font-weight: 400; }\n  .SmallDataCard_SmallDataCard__5mbrD .SmallDataCard_value__2jDaG {\n    margin: 0;\n    font-size: 16px;\n    color: royalblue;\n    font-weight: 700; }\n    .SmallDataCard_SmallDataCard__5mbrD .SmallDataCard_value__2jDaG.SmallDataCard_energyMeter__2fWLq {\n      color: #ff063c; }\n", ""]);
// Exports
exports.locals = {
	"SmallDataCard": "SmallDataCard_SmallDataCard__5mbrD",
	"title": "SmallDataCard_title__3WPUc",
	"value": "SmallDataCard_value__2jDaG",
	"energyMeter": "SmallDataCard_energyMeter__2fWLq"
};
module.exports = exports;
