import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../../_services/dataService';
import { moduleService } from '../../../_services/moduleService';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';

import AlarmCard from '../Cards/AlarmCard';
import SmallDataCard from '../Cards/SmallDataCard';

import classes from './AcrelData.module.scss';

const AcrelData = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [acrel,setAcrel] = useState([]);
    const macAddress = [props.mac_address];
    const [acrelName,setAcrelName] = useState("Acrel");

    // console.log("AcrelId");
    // console.log(macAddress);

    useEffect(() => {
        if (macAddress.length > 0) {
            moduleService.getNameByMacAddress(macAddress)
                .then(response => {
                    // if (response !== 'empty') {
                    // console.log("response getNameByMacAddress");
                    // console.log(response);
                    setAcrelName(response);
                    // }
                });
            dataService.acrelLatestData(macAddress)
                .then(response => {
                    // console.log("response pylontechLastData");
                    // console.log(response);
                    if (response !== 'empty') {
                        setAcrel(Object.values(response));
                    }
                });
        } else {
            setAcrel([]);
        }
        // },[macAddress]);
    },[]);

    // console.log("Acrel");
    // console.log(acrel);

    return (
        <Aux>
            {/* <div className={`col-md-12 ${classes.AdditionalBatteriesData__list}`}> */}
            {acrel.length > 0 ? (
                acrel.map((acrel,idx) =>
                    <div className={classes.dataWrapper} key={idx}>
                        {/* <h4>{t('common:datas.battery.battery')} - {pylontech.mac_address}</h4> */}
                        <h4 className={classes.title}>Les autres données Acrel - {acrelName}</h4>
                        {/* <p className={classes.date}>{t('common:datas.last-record')}: {pylontech.date}</p> */}
                        <div className={`${classes.data}`}>
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_abs_act_en')} value={(acrel.acr_abs_act_en / 1000).toFixed(2) + " kW"} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_rel_act_en')} value={(acrel.acr_rel_act_en / 1000).toFixed(2) + " kW"} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_react_en')} value={(acrel.acr_react_en / 1000).toFixed(2) + " kW"} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_capa_en')} value={acrel.acr_capa_en} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.frequency')} value={acrel.frequency + " Hz"} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_pf')} value={acrel.acr_pf} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_q')} value={acrel.acr_q} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.acr_s')} value={acrel.acr_s} />
                            <SmallDataCard type="energy_meter" name={t('common:datas.acrel.factor')} value={acrel.factor + "%"} />
                        </div>
                    </div>
                )) : macAddress.length > 0 ? (
                    <Spinner />
                ) : ""
            }
        </Aux>
    )
};

export default AcrelData;