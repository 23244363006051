import React,{ useState,useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

import AddressForm from '../_components/Form/AddressForm';

import { addressService } from '../_services/addressService';

const AddAddressPage = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const { id } = props.match.params;
    const isAddMode = !id;
    const [initAddress,setInitAddress] = useState({
        line1: '',
        line2: '',
        street_number: '',
        zipcode: '',
        locality: '',
        country: '',
        user_id: user.id
    });

    console.log("isAddMode init addAddress page");
    console.log(isAddMode);

    useEffect(() => {
        if (id) {
            addressService.getAddressById(id)
                .then(response => {
                    console.log('response getAddressById');
                    console.log(response);
                    // const fields = ['line1','line2','street_number','zipcode','locality','country'];
                    // fields.forEach(field => setFieldValue(field, response[field], false));
                    setInitAddress(response);
                });
        }
    },[]);

    return (
        <BatteryUpPage isAddMode={isAddMode} address={initAddress} >
            {/* <AddressForm isAddMode={isAddMode} address={initAddress} /> */}
        </BatteryUpPage>
    );
}

export default AddAddressPage;