import React,{ useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';
import RegisterForm from '../_components/Form/RegisterForm';
import AlreadyRegistered from '../_components/Admin/AlreadyRegistered';
import { useDispatch,useSelector } from 'react-redux';
import classes from './AlreadyRegisteredPage.module.scss';
import pageBg from '../_assets/img/bandeau-header-IMG-vector-CONTACT.png';

const AlreadyRegisteredPage = (props) => {
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    return (
        // <BatteryUpPage sidebar={true}>
        //     <div className="container" style={{background: `url(${pageBg}) center center no-repeat`,backgroundSize:"contain"}}>
        //             <div className="row" style={{minHeight: "600px", display:"flex", flexDirection:"column", justifyContent: "flex-end", paddingBottom: "50px"}}>
        //                 <div className="col-lg-12">
        //                     <button className="btn btn-primary">
        //                         {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
        //                         <Link to="/my-profile"><span style={{color:"#fff"}}>Profil</span></Link>
        //                     </button>
        //                     <p style={{padding:".5rem",display:"inline-block"}}>or</p>
        //                     <button className="btn btn-primary" onClick={()=>{setRegister(true)}}>
        //                         {loggingIn &&

        //                           <span className="spinner-border spinner-border-sm mr-1"></span>}
        //                           New inscription
        //                     </button>
        //                 </div>
        //             </div>
        //     </div>
        // </BatteryUpPage>
        <GenericPage>
            <AlreadyRegistered loggingIn={loggingIn} />
        </GenericPage>
    );
}

export default AlreadyRegisteredPage;
