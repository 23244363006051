import React,{ useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik,Field,Form,ErrorMessage,setFieldValue } from 'formik';
import * as Yup from 'yup';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { releaseNotesService } from '../../_services/releaseNotesService';
import { history } from '../../_helpers/history';

import classes from './ReleaseNoteForm.module.scss';

const ReleaseNoteForm = (props) => {
    const { t } = useTranslation(['common','releasenotespage']);
    // const auth = JSON.parse(localStorage.getItem('appState')).user;
    const id = props.id;
    // const [initReleaseNote,setInitReleaseNoteForm] = useState({
    //     title_fr: '',
    //     title_en: '',
    //     done_fr: '',
    //     done_en: '',
    //     to_do_fr: '',
    //     to_do_en: '',
    // });
    const [initReleaseNote,setInitReleaseNoteForm] = useState(props.initReleaseNote);

    useEffect(() => {
        setInitReleaseNoteForm(props.initReleaseNote);
    },[props.initReleaseNote]);

    console.log("props in ReleaseNoteForm");
    console.log(props);
    console.log("initReleaseNote");
    console.log(initReleaseNote);

    return (
        <section id="releaseNoteForm" className={` ${classes.ReleaseNoteForm}`}>
            {/* <div className="container">
                <div className="row"> */}
            <div className={` ${classes.ReleaseNoteForm__background}`}>
                {/* <div className="contact-form wow fadeInUp" data-wow-delay=".9s"> */}
                <div className={classes.ReleaseNoteForm__wrapper}>
                    <h2>{id ? t('releasenotespage:ReleaseNoteForm.edit-release-note') : t('releasenotespage:ReleaseNoteForm.add-release-note')}</h2>
                    <Formik
                        enableReinitialize
                        initialValues={initReleaseNote}
                        validationSchema={Yup.object({
                            id: Yup.number(),
                            title_fr: Yup.string()
                                .trim()
                                .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                            title_en: Yup.string()
                                .trim()
                                .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                            done_fr: Yup.string()
                                .trim()
                                .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                            done_en: Yup.string()
                                .trim()
                                .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                            to_do_fr: Yup.string()
                                .trim()
                                .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                            to_do_en: Yup.string()
                                .trim()
                                .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        })}
                        onSubmit={(values,{ setSubmitting }) => {
                            setTimeout(() => {
                                console.log('releaseNotes in form');
                                console.log(values);
                                if (values.id === 0) {
                                    releaseNotesService.addReleaseNote(values)
                                        .then(() => {
                                            history.push('/release-notes-all');
                                        });
                                } else {
                                    releaseNotesService.editReleaseNote(values)
                                        .then(() => {
                                            history.push('/release-notes-all');
                                        });
                                }
                            },400);
                        }}
                    >
                        <Form>
                            <Field type="hidden" name="id" value={initReleaseNote.id} />
                            <div className="form-group" style={{ margin: '10px' }}>
                                <label htmlFor="title_fr">Titre FR</label>
                                <Field name="title_fr" type="text" className="form-control" />
                                <ErrorMessage name="title_fr" />
                            </div>
                            <div className="form-group" style={{ margin: '10px' }}>
                                <label htmlFor="title_en">Title EN</label>
                                <Field name="title_en" type="text" className="form-control" />
                                <ErrorMessage name="title_en" />
                            </div>
                            <div className="form-group" style={{ margin: '10px' }}>
                                <label htmlFor="done_fr">Ce qui a été fait</label>
                                <Field as="textarea" name="done_fr" type="street_number" className="form-control" />
                                <ErrorMessage name="done_fr" />
                            </div>
                            <div className="form-group" style={{ margin: '10px' }}>
                                <label htmlFor="done_en">What has been done</label>
                                <Field as="textarea" name="done_en" type="text" className="form-control" />
                                <ErrorMessage name="done_en" />
                            </div>
                            <div className="form-group" style={{ margin: '10px' }}>
                                <label htmlFor="to_do_fr">Les prochaines étapes</label>
                                <Field as="textarea" name="to_do_fr" type="text" className="form-control" />
                                <ErrorMessage name="to_do_fr" />
                            </div>
                            <div className="form-group" style={{ margin: '10px' }}>
                                <label htmlFor="to_do_en">Next steps</label>
                                <Field as="textarea" name="to_do_en" type="text" className="form-control" />
                                <ErrorMessage name="to_do_en" />
                            </div>

                            <p class="text-center">
                                <Link to="/release-notes-all" className="btn btn-link">{t('common:forms.cancel')}</Link>
                                {i18n.language === "fr" ? (
                                    <button type="submit"><img style={{ cursor: "pointer",size: "30%" }} className="" src={require("../../_assets/img/bouton-enregistrer-battery-up.png")} alt={t('registerpage:RegisterForm.register-btn-alt')} /></button>
                                ) : (
                                    <button type="submit"><img style={{ cursor: "pointer",size: "30%" }} className="" src={require("../../_assets/img/bouton-enregistrer-battery-up-EN.png")} alt={t('registerpage:RegisterForm.register-btn-alt')} /></button>
                                )}
                            </p>
                        </Form>
                    </Formik>
                </div>
                <div className={classes.colorBorder}></div>
            </div>
            {/* </div>
            </div> */}
        </section>
    );
}

export default ReleaseNoteForm;