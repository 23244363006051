import React from 'react';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { Formik,Field,Form,ErrorMessage,getIn } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { userActions } from '../../_actions/userActions';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import classes from './RegisterForm.module.scss';

const RegisterForm = () => {
    const { t } = useTranslation(['common','registerpage']);
    const dispatch = useDispatch();

    function getStyles(errors,fieldName) {
        if (getIn(errors,fieldName)) {
            return {
                border: '1px solid red',
                color: 'red',
            }
        }
    }

    return (
        <section id="registerForm" className={`app-about-section angle-sp ${classes.RegisterForm}`} style={{ marginTop: "20px",paddingTop: '0' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <img src={require("../../_assets/img/IMG-vectoriel-site-battery-UP-register.png")} alt="App About Image" style={{ width: '500px',float: "center" }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{ background: `url(${imgfondlogin}) center center no-repeat`,backgroundSize: 'contain',padding: "220px",float: 'center',marginTop: "-70px" }}>
                        <div className="col-md-12 col-lg-12" style={{ background: `#ffffff none repeat scroll 0 0`,boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',padding: "30px" }}>
                            <p><span className="doubleLine-title" style={{ textAlign: "left",color: "#ff063c",fontWeight: "300",fontSize: "40px",paddingBottom: '30px' }}>{t('registerpage:RegisterForm.title')}</span><span style={{ clear: 'both' }}></span></p>
                            <Formik
                                initialValues={{ firstname: '',surname: '',email: '',password: '',password_confirmation: '' }}
                                validationSchema={Yup.object({
                                    firstname: Yup.string()
                                        .trim()
                                        // .matches(/^[A-Za-z- ]+$/, { message: <p className="errorMessage">Please only type in letters, white spaces and "-"</p> })
                                        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registerpage:RegisterForm.yup-firstname-match')}</p> })
                                        // .max(15, 'Must be 15 characters or less')
                                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                    surname: Yup.string()
                                        .trim()
                                        // .max(20, 'Must be 20 characters or less')
                                        // .matches(/^[A-Za-z- ]+$/,{ message: <p className="errorMessage">Please only type in letters, white spaces and "-"</p> })
                                        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registerpage:RegisterForm.yup-surname-match')}</p> })
                                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                    email: Yup.string()
                                        .trim()
                                        .email(<p className="errorMessage">{t('registerpage:RegisterForm.yup-invalid-email')}</p>)
                                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                    password: Yup.string()
                                        .trim()
                                        .min(8,<p className="errorMessage">{t('registerpage:RegisterForm.yup-password-min')}</p>)
                                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                    password_confirmation: Yup.string()
                                        // .trim()
                                        .oneOf([Yup.ref('password'),null],<p className="errorMessage">{t('registerpage:RegisterForm.yup-password-match')}</p>)
                                })}
                                onSubmit={(values,{ setSubmitting }) => {
                                    setTimeout(() => {
                                        // alert(JSON.stringify(values, null, 2));
                                        console.log("before dispatch");
                                        console.log(JSON.stringify(values,null,2));
                                        // dispatch(userActions.register(JSON.stringify(values, null, 2)));
                                        dispatch(userActions.register(values));
                                        // setSubmitting(false);
                                    },400);
                                }}
                            >
                                {(props) =>
                                    <Form>
                                        {/* <div className="col-lg-12"> */}
                                        <div className="form-group" style={{ margin: '10px',width: '45%',float: 'left',color: "#3d009e" }}>
                                            <label htmlFor="firstname">{t('registerpage:RegisterForm.input-firstname-label')}</label>
                                            <Field name="firstname" type="text" className="form-control" />
                                            <ErrorMessage name="firstname" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px',width: '45%',float: 'right',color: "#3d009e" }}>
                                            <label htmlFor="surname">{t('registerpage:RegisterForm.input-surname-label')}</label>
                                            <Field name="surname" type="text" className="form-control" />
                                            <ErrorMessage name="surname" />
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                        <div className="form-group" style={{ margin: '10px',color: "#3d009e" }}>
                                            <label htmlFor="email">{t('registerpage:RegisterForm.input-email-label')}</label>
                                            <Field name="email" type="email" className="form-control" />
                                            <ErrorMessage name="email" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px',color: "#3d009e" }}>
                                            <label htmlFor="password">{t('registerpage:RegisterForm.input-password-label')}</label>
                                            <Field name="password" type="password" className="form-control" />
                                            <ErrorMessage name="password" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px',color: "#3d009e" }}>
                                            <label htmlFor="password_confirmation">{t('registerpage:RegisterForm.input-confirm-password-label')}</label>
                                            <Field name="password_confirmation" type="password" className="form-control" />
                                            <ErrorMessage name="password_confirmation" />
                                        </div>
                                        <p className={classes.alreadyAccount}><span>{t('registerpage:RegisterForm.already-account')}</span><Link to="/login" className="btn btn-link">{t('common:buttons.connection')}</Link></p>
                                        {i18n.language === "fr" ? (
                                            <button type="submit"><img style={{ cursor: "pointer",size: "30%" }} className="" src={require("../../_assets/img/bouton-enregistrer-battery-up.png")} alt={t('registerpage:RegisterForm.register-btn-alt')} /></button>
                                        ) : (
                                                <button type="submit"><img style={{ cursor: "pointer",size: "30%" }} className="" src={require("../../_assets/img/bouton-signup-battery-up.png")} alt={t('registerpage:RegisterForm.register-btn-alt')} /></button>
                                            )}
                                        {/* </div> */}
                                    </Form>
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegisterForm;