import axios from '../axios';

export const countryService = {
    getAll,
};

function getAll(id) {
    console.log('getAll in country service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') {return}
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/countries',config)
        .then(response => {
            console.log(response);
            if (response.data) {
                console.log("Countries successfully retrieved !");
                console.log(response.data.countries);

                let countries = response.data.countries;

                return countries;
            }
        });
}