import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { releaseNotesService } from '../../_services/releaseNotesService';
import { history } from '../../_helpers/history';

import classes from './ReleaseNotesIndex.module.scss';

import Modal from '../UI/Modal/Modal';
import Data from '../Data/Data';

const ReleaseNotesIndex = (props) => {
    const { t } = useTranslation(['common','releasenotespage']);

    const [releaseNotes,setReleaseNotes] = useState([]);

    useEffect(() => {
        releaseNotesService.getReleaseNotes()
            .then(response => {
                setReleaseNotes(response);
            });
    },[]);

    console.log("ReleaseNotes list");
    console.log(releaseNotes);

    return (
        <div className={`col-12 ${classes.releaseNotesList}`}>
            {/* <p>ReleaseNotesIndex</p> */}
            <Data
                icon="icone-battery-UP-IMG-Users"
                title="Release Notes"
                type="userslist"
            >
                <div className={`row ${classes.titles}`}>
                    <div className={`${classes.titles__date}`}>{t('releasenotespage:ReleaseNotesIndex.date')}</div>
                    <div className={`${classes.titles__title}`}>{t('releasenotespage:ReleaseNotesIndex.title')}</div>
                    {/* <div className={`col-lg-2 ${classes.titles__actions}`}>Actions</div> */}
                </div>
                {releaseNotes.map((releaseNote,idx) => {
                    return (
                        <div className={`row ${classes.releaseNoteItem}`} key={idx} style={{ display: "flex" }}>
                            <Link className={classes.linkWrapper} to={`edit-release-note/` + releaseNote.id}>
                                {/* <div className={`col-5 col-lg-3 ${classes.releaseNoteId}`}>{releaseNote.dateDay}/{releaseNote.dateMonth}/{releaseNote.dateYear}</div> */}
                                <div className={`${classes.releaseNoteDate}`}>{releaseNote.dateDay}/{releaseNote.dateMonth}/{releaseNote.dateYear}</div>
                                {i18n.language === "fr" ? (
                                    // <div className={`col-7 col-lg-3 ${classes.releaseNoteTitle}`}>{releaseNote.title_fr}</div>
                                    <div className={`${classes.releaseNoteTitle}`}>{releaseNote.title_fr}</div>
                                ) : (
                                    <div className={`${classes.releaseNoteTitle}`}>{releaseNote.title_en}</div>
                                )}
                            </Link>
                        </div>
                    )
                })}
                <p className={classes.addButton}>
                    <Link to="add-release-note">
                        <button className="btn btn-primary">
                            <i class="icofont-ui-add"></i>
                            {t('releasenotespage:ReleaseNotesIndex.add-button')}
                        </button>
                    </Link>
                </p>
            </Data>
        </div>
    )
};

export default ReleaseNotesIndex;