import React from 'react';
import { Router,Route,Switch,Redirect } from 'react-router-dom';

import { history } from '../../_helpers/history';

const HardReset = (props) => {
    localStorage.removeItem("appState");
    localStorage.removeItem('mainInfo');
    return (
        <Redirect to="/login" />
    );
    // history.push('/home');
};

export default HardReset;