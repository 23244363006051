// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DataCards_DataCards__3Qqvy {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"DataCards": "DataCards_DataCards__3Qqvy"
};
module.exports = exports;
