import React,{ useState,useEffect } from 'react';
import { history } from '../_helpers/history';

import RegisterForm from '../_components/Form/RegisterForm';

const RegisterPage = (props) => {
    const user = props.user;
    const [isAdmin,setIsAdmin] = useState(false);

    useEffect(() => {
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('installer') || user.roles.includes('admin') || user.roles.includes('super-admin')) {
                setIsAdmin(true);
            }
            if (user.roles.includes('client') && !user.roles.includes('installer') && !user.roles.includes('admin') && !user.roles.includes('super-admin')) {
                setIsAdmin(false);
                history.push('/already-registered');
            }
        }
    },[]);

    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <RegisterForm />
        </div>
    );
}

export default RegisterPage;