import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './WeAreWe.module.scss';
import contactBg from '../../_assets/img/IMG-bacckground-site-battery-UP-CONTACT.png';
import { Map,InfoWindow,Marker,GoogleApiWrapper } from 'google-maps-react';
const mapStyles = {
    width: '100%',
    height: '100%',

    margin: "2em auto",
};
const containerStyle = {

    //background:"red",
    bottom: "2em",
    right: ".1em",

    width: "100%",


}
class WeAreWe extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="contactUs" className={classes.WeAreWe}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className={`col-md-6 col-xl-7 ${classes.mapContainer}`}>
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div>
                                    <Map
                                        google={this.props.google}
                                        zoom={14}
                                        style={mapStyles}
                                        containerStyle={containerStyle}
                                        initialCenter={{
                                            lat: 50.605285,
                                            lng: 5.888851
                                        }}
                                        zoom={18}>
                                        <Marker
                                            title={'WATTUNEED'}
                                            name={'WATTUNEED'}
                                            position={{ lat: 50.605285,lng: 5.888851 }} />
                                    </Map>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className={`col-md-6 col-xl-5 ${classes.infoContainer}`}>
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className="app-about-text" style={{ textAlign: "left" }}>
                                    <h3 className={classes.sectionTitle}>{t('contactpage:WeAreWe.contact-details')}</h3>
                                    <div className={classes.contactElement}>
                                        <img src={require("../../_assets/img/IMG-icone-battery-up-adresse.png")} alt={t('contactpage:WeAreWe.icon-address-alt')} />
                                        <p>
                                            <Trans i18nKey="contactpage:WeAreWe.contact-address">
                                                Rue Henripré 12<br />4821 Andrimont
                                            </Trans>
                                        </p>
                                    </div>
                                    <a className={classes.contactElement}>
                                        <img src={require("../../_assets/img/IMG-icone-battery-up-telephone-300.png")} alt={t('contactpage:WeAreWe.icon-phone-alt')} />
                                        <p>{t('contactpage:WeAreWe.contact-phone')}</p>
                                    </a>
                                    <a href="mailto:info@wattuneed.com" className={classes.contactElement}>
                                        <img src={require("../../_assets/img/IMG-icone-battery-up-email.png")} alt={t('contactpage:WeAreWe.icon-mail-alt')} />
                                        <p>{t('contactpage:WeAreWe.contact-mail')}</p>
                                    </a>
                                    <a href="https://www.wattuneed.com/fr/" className={classes.contactElement}>
                                        <img src={require("../../_assets/img/IMG-icone-battery-up-wattuneed.png")} alt={t('contactpage:WeAreWe.icon-website-alt')} />
                                        <p>{t('contactpage:WeAreWe.contact-website')}</p>
                                    </a>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBrzn7ssIP_lT-xKe7nprk6mmahXACmHQw'
})(withTranslation(['common','contactpage'])(WeAreWe));
