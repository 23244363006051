import { authHeader } from '../_helpers/auth-header';
import axios from '../axios';
import { userConstants } from '../_constants/userConstants';

export const sermatecService = {
    getAll,
    registerSermatec,
    getByUserId,
    getById,
    // update,
    // delete: _delete,
};

function getAll() {
    console.log('getAll in sermatec service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/sermatecs',config)
        .then(response => {
            console.log("Response getAll sermatecs");
            console.log(response);
            if (response.data) {
                console.log("List of sermatecs successfully retrieved !");

                let sermatecs = response.data.sermatecs;

                return sermatecs;
            }
        });
}

function registerSermatec(values) {
    console.log('is in sermatec service');
    console.log('values');
    // let address= `${values.line1},${values.street_number} ${values.zipcode} ${values.locality}, ${values.country}`;
    // console.log(address);
    // axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=wTvLLbdq_q1KYM_CcLVhB_iYDc1THksBWjvN5h8vAdU`)
    //     .then(response => {
    //         let country_coords=response.data.items[0].position;
    //         values.latitude = country_coords.lat;
    //         values.longitude = country_coords.lng;
    //     });
    // console.log(typeof user);
    console.log(values);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/register-sermatec',values,config)
        // return axios.post('/api/register-sermatec',{values:values,coords:window.coords},config)
        .then(response => {
            console.log("Response registerSermatec");
            console.log(response);
            if (response.data) {
                console.log("Sermatec successfully created !");
                console.log(response);

                let sermatec = response.data.sermatec;

                return sermatec;
            }
        }).catch((e) => {
            console.log(e + JSON.stringify(window.coords) + " :coords");
        });
}

function getById(sermatecId) {
    console.log('getById in sermatec service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    console.log("target");
    console.log(sermatecId);

    // return axios.get('/api/users',config)
    return axios.get('/api/sermatec?id=' + sermatecId,config)
        .then(response => {
            console.log("Response getAll");
            console.log(response);
            if (response.data) {
                console.log("Sermatec successfully retrieved !");
                console.log(response);

                let sermatec = response.data.sermatec;

                return sermatec;
            }
        });
}

function getByUserId(userId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/user-sermatecs?id=' + userId,config)
        .then(response => {
            console.log("Response sermatecs getByUserId");
            console.log(response);
            if (response.data) {
                console.log("User's sermatecs successfully retrieved !");
                console.log(response);

                let sermatecs = response.data.sermatecs;

                return sermatecs;
            }
        });
}

function update(userData) {
    console.log('update in service');
    console.log('userData');
    console.log(userData);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-user/',userData,config)
        .then(response => {
            console.log("Response update");
            console.log(response);
            if (response.data) {
                console.log("User successfully updated !");
                console.log(response);

                let user = response.data.user;

                return user;
            }
        });
}

function _delete(targetId) {
    console.log('delete in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/delete-user/?id=' + targetId,config)
        .then(response => {
            console.log("Response delete");
            console.log(response);
            if (response.data) {
                console.log("User successfully deleted !");
                console.log(response);

                let message = "User successfully deleted !";

                return message;
            }
        });
}
