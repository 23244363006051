// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Download_downloads__1Yt-6 .Download_links__154WA {\n  text-align: left; }\n  .Download_downloads__1Yt-6 .Download_links__154WA ul li {\n    margin: 10px 0; }\n    .Download_downloads__1Yt-6 .Download_links__154WA ul li a {\n      color: #3d009e;\n      font-weight: 700; }\n", ""]);
// Exports
exports.locals = {
	"downloads": "Download_downloads__1Yt-6",
	"links": "Download_links__154WA"
};
module.exports = exports;
