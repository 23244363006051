import React from 'react';

import Aux from '../../../_hoc/Auxil/Auxil';

import classes from './CircleCard.module.scss';

const CircleCard = (props) => {
    const icons = {
        // power: require('../../../_assets/img/icons/data/power_black.png'),
        power: require('../../../_assets/img/icons/data/power_form_blue.png'),
        test: require('../../../_assets/img/icons/data/production.png'),
    };
    const test = "../../../_assets/img/icons/data/power.png";
    console.log("icons['power']");
    console.log(icons['power']);

    return (
        <div className={classes.CircleCard}>
            <div className={classes.cardInner}>
                <div className={classes.cardFront}>
                    <div className={classes.cardBackground}>
                        <img className={classes.icon} src={icons['power']} alt="Créez des scénarios grâce à  IFTTT" />
                        <div className={classes.frontContent}>
                            <div className={classes.titleWrapper}>
                                <h4 className={classes.title}>Puissance max</h4>
                                {/* <div className={classes.info}> */}
                                {/* <p className={`${classes.tooltip}`} data-text="Thanks for hovering! I'm a tooltip"><img src={require("../../../_assets/img/icon_info.png")} alt="Créez des scénarios grâce à  IFTTT" /></p> */}
                                {/* <p className={`${classes.tooltip}`} data-text="Thanks for hovering! I'm a tooltip"><img src={require("../../../_assets/img/icons/data/info.png")} alt="Créez des scénarios grâce à  IFTTT" /></p> */}
                                {/* </div> */}
                            </div>
                            <div className={classes.contentWrapper}>
                                <p className={classes.value}>3.45 kW</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.cardBack}>
                    <div className={classes.cardBackground}>
                        <div className={classes.backContent}>
                            <div className={classes.titleWrapper}>
                                <h4 className={classes.title}>Puissance max</h4>
                                {/* <div className={classes.info}> */}
                                {/* <p className={`${classes.tooltip}`} data-text="Thanks for hovering! I'm a tooltip"><img src={require("../../../_assets/img/icon_info.png")} alt="Créez des scénarios grâce à  IFTTT" /></p> */}
                                {/* <p className={`${classes.tooltip}`} data-text="Thanks for hovering! I'm a tooltip"><img src={require("../../../_assets/img/icons/data/info.png")} alt="Créez des scénarios grâce à  IFTTT" /></p> */}
                                {/* </div> */}
                            </div>
                            <div className={classes.contentWrapper}>
                                <p className={classes.info}>La puissance maximale (en kilowatts) enregistrée depuis l'installation de votre module Battery UP.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CircleCard;