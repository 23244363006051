import React,{ useState,useEffect } from 'react';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { Formik,Field,Form,ErrorMessage,getIn } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import imgfondlogin from '../../_assets/img/IMG-forme-fond-login-battery-up.png';

import { userActions } from '../../_actions/userActions';
import classes from './LoginForm.module.scss';

const LoginForm = (props) => {
    const { t } = useTranslation(['common','loginpage']);
    // const [inputs,setInputs] = useState({
    //     email: '',
    //     password: '',
    //     checked:false
    // });
    // const [submitted,setSubmitted] = useState(false);
    // const { email,password } = inputs;
    const [isChecked,setIsChecked] = React.useState(false);
    // const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();

    // reset login status
    // useEffect(() => {
    //     dispatch(userActions.logout());
    // },[]);

    // function handleChange(e) {
    //     const { name,value} = e.target;

    //       setInputs(inputs => ({ ...inputs,[name]: value }));


    // }

    // function handleSubmit(e) {
    //     e.preventDefault();

    //     setSubmitted(true);
    //     if (email && password) {
    //         dispatch(userActions.login(email,password));
    //     }
    // }

    return (
        <section id="loginForm" className={`app-about-section angle-sp ${classes.LoginForm}`} style={{ marginTop: "20px",paddingTop: '0' }}>

            <div className="container">
                <div className="row" style={{ zIndex: "1" }}>
                    <div className="col-md-12 col-lg-12">
                        <img className={classes.mainImage} src={require("../../_assets/img/IMG-vector-personnage-login-battery-up.png")} alt={t('loginpage:LoginForm.img-main-alt')} />
                    </div>
                    <div className={`col-lg-12 ${classes.formWrapper}`}>
                        <div className={`col-md-12 col-lg-12 ${classes.formBackground}`}>
                            <h2 className="doubleLine-title" style={{ textAlign: "left",color: "#ff063c",fontWeight: "300",fontSize: "40px",marginBottom: "30px" }}>{t('loginpage:LoginForm.title')}</h2>
                            <Formik
                                initialValues={{ email: '',password: '' }}
                                validationSchema={Yup.object({
                                    email: Yup.string()
                                        .trim()
                                        .email(<p className="errorMessage">{t('loginpage:LoginForm.invalid-email')}</p>)
                                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                    password: Yup.string()
                                        .trim()
                                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                })}
                                onSubmit={(values,{ setSubmitting }) => {
                                    setTimeout(() => {
                                        dispatch(userActions.login(values.email,values.password));
                                        // setSubmitting(false);
                                    },400);
                                }}
                            >
                                {(props) =>
                                    <Form>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="email">{t('loginpage:LoginForm.input-email-label')}</label>
                                            <Field name="email" type="email" className="form-control" />
                                            <ErrorMessage name="email" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="password">{t('loginpage:LoginForm.input-password-label')}</label>
                                            <Field name="password" type="password" className="form-control" />
                                            <ErrorMessage name="password" />
                                        </div>
                                        {/* <p className={classes.forgottenPassword}><Link to="/forgotPassword" className="btn btn-link">{t('loginpage:LoginForm.forgotten-password')}</Link></p> */}
                                        <p className={classes.forgottenPassword}><Link to={{ pathname: "https://portal.wattuneed.com/forgot-password" }} target="_blank" className="btn btn-link">{t('loginpage:LoginForm.forgotten-password')}</Link></p>
                                        {i18n.language === "fr" ? (
                                            <button type="submit"><img style={{ cursor: "pointer",size: "30%" }} src={require("../../_assets/img/bouton-connexion-battery-up.png")} alt={t('loginpage:LoginForm.login-btn-alt')} /></button>
                                        ) : (
                                            <button type="submit"><img style={{ cursor: "pointer",size: "30%" }} src={require("../../_assets/img/bouton-login-battery-up-EN.png")} alt={t('loginpage:LoginForm.login-btn-alt')} /></button>
                                        )}

                                        <p className={classes.noAccount}><span>{t('loginpage:LoginForm.no-account')}</span><Link to="/register" className="btn btn-link">{t('common:buttons.sign-up')}</Link></p>
                                    </Form>
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ transform: "translateY(-40%)",zIndex: "0",pointerEvents: "none" }}>

                </div>
            </div>
        </section>
    );
}

export default LoginForm;
