// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../_assets/img/bandeau-header-IMG-vector-CONTACT.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".AlreadyRegistered_AlreadyRegistered__3sIXi {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: contain;\n  min-height: 600px; }\n", ""]);
// Exports
exports.locals = {
	"AlreadyRegistered": "AlreadyRegistered_AlreadyRegistered__3sIXi"
};
module.exports = exports;
