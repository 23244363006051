import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import ForgotPasswordForm from '../_components/Form/ForgotPasswordForm';

import classes from './ForgotPasswordPage.module.scss';

const ForgotPasswordPage = (props) => {
    return (
        <BatteryUpPage sidebar={false}>
            <ForgotPasswordForm />
        </BatteryUpPage>
    );
}

export default ForgotPasswordPage;
