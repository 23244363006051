import React,{ Component } from 'react';

import ReactWOW from 'react-wow';
import classes from './ControlConsumption.module.scss';

class ControlConsumption extends Component {
    render() {
        return (
            <section id="controlConsumption" className="app-about-section angle-sp">
                <div className={`container ${classes.container}`}>

                    <div className="row">
                        <div className="col-lg-6">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className={`app-about-text ${classes.reveal}`} style={{ textAlign: "left" }}>
                                    <h3 style={{ color: "#ff063c",fontWeight: "300",fontSize: "40px",textTransform: "none" }}>Flux d'énergie détaillés</h3>
                                    <p>Révélez toute la puissance de votre <strong><span style={{ color: "#3d009e" }}>Battery UP </span></strong>en ajoutant nos compteurs d’énergie. Ceux-ci s’installent en rail DIN directement dans votre tableau électrique.</p>
                                    <p>Visualisez l’évolution énergétique de votre installation au travers de votre mobile. Suivez votre consommation et optimisez votre gestion énergétique en toute simplicité.</p>
                                    <ul>
                                        <li><span style={{ color: "#3d009e" }}>• Visualisation des énergies totalisées</span></li>
                                        <li><span style={{ color: "#3d009e" }}>• Courant de production, batterie & réseau sur un seul graphique clair </span></li>
                                    </ul>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-6">
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className="text-center">
                                    <img src={require("../../_assets/img/IMG-smartphone-Flux-energie-detailles.jpg")} />
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ControlConsumption;