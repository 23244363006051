import React,{ useEffect,useState } from 'react';
// import { Link } from 'react-router-dom';
import { Formik,Field,Form,ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { systemRuleService } from '../../_services/systemRuleService';
import { Trans,useTranslation } from 'react-i18next';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

import classes from './AlertForm.module.scss';

import TemporaryPopup from '../UI/ValidationMessage/TemporaryPopup';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import btnRegister from '../../_assets/img/bouton-enregistrer-battery-up.png';
import { moduleService } from '../../_services/moduleService';
// import classes from '*.module.css';

const AlertForm = (props) => {
    const { t } = useTranslation(['common','registermodulepage']);
    // console.log("props Alert form");
    // console.log(props);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const ruleId = props.ruleId;
    const moduleId = props.moduleId;
    const isActive = props.active;
    const [modified,setModified] = useState(false);
    const [module,setModule] = useState([]);
    const [min,setMin] = useState(1);
    const [max,setMax] = useState(100);
    const [initRule,setInitRule] = useState({
        active: isActive,
        param1: 1,
        param2: 100,
        user_id: user.id,
        rule_id: ruleId,
        module_id: moduleId,
    });
    // console.log("module Alert form");
    // console.log(module);

    useEffect(() => {
        systemRuleService.getParams(ruleId,moduleId)
            .then(response => {
                // console.log('response getAll');
                console.log(response);
                let param1 = 1;
                let param2 = 100;
                response.forEach(param => {
                    if (param.parameter === 1) {
                        param1 = param.value;
                    }
                    if (param.parameter === 2) {
                        param2 = param.value;
                    }
                });
                setInitRule({
                    active: isActive,
                    param1: parseInt(param1),
                    param2: parseInt(param2),
                    user_id: user.id,
                    rule_id: ruleId,
                    module_id: moduleId,
                });
            });
        moduleService.getById(moduleId)
            .then(response => {
                // console.log("loaded module in alert form");
                // console.log(response);
                setModule(response);
                if (response.protocol.id === 1) {
                    setMin(-1);
                    setMax(120);
                } else if (response.protocol.id === 2) {
                    setMin(-50);
                    setMax(50);
                }
            });
    },[ruleId,moduleId]);

    return (
        <div id="alertForm" className={classes.AlertForm}>
            {/* {validation} */}
            <Formik
                enableReinitialize
                initialValues={initRule}
                validationSchema={Yup.object({
                    param1: Yup.number()
                        .min(min,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-must-select-country')}</p>)
                        .max(max,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-must-select-country')}</p>)
                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                    param2: Yup.number()
                        .min(min,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-must-select-country')}</p>)
                        .max(max,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-must-select-country')}</p>)
                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                    user_id: Yup.number(),
                    rule_id: Yup.number(),
                    module_id: Yup.number(),
                })}
                onSubmit={(values,{ setSubmitting }) => {
                    setTimeout(() => {
                        // values["user_id"] = 
                        console.log(values);

                        systemRuleService.updateRule(values)
                            .then(() => {
                                setModified(true);
                                // setTimeout(() => {
                                //     history.push('/my-profile');
                                // },2000);
                            });

                        // addressService.editAddress(values)
                        //     .then(() => {
                        //         setModified(true);
                        //         setTimeout(() => {
                        //             history.push('/my-profile');
                        //         },2000);
                        //     });
                    },400);
                }}
            >
                <Form style={{ textAlign: 'left' }}>
                    <Field type="hidden" name="user_id" value={user.id} />
                    <Field type="hidden" name="rule_id" value={ruleId} />
                    <Field type="hidden" name="module_id" value={moduleId} />
                    {module.protocol ? (
                        <Aux>
                            <label>
                                <Field type="checkbox" name="active" />
                                {/* {`${values.toggle}`} */}
                            </label>
                            <div className="form-group" style={{ margin: "0 20px" }}>
                                {/* <label htmlFor="param1">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                <label htmlFor="param1">{module.protocol.id === 1 ? "Etat de charge - seuil minimal (en %)" : "Puissance (en kW)"}</label>
                                <Field name="param1" type="number" className="form-control" placeholder={t('registermodulepage:RegisterForm.installationname-placeholder')} step={module.protocol.id === 1 ? "1" : ".01"} />
                                <ErrorMessage name="param1" />
                            </div>
                            <div className="form-group" style={{ margin: "0 20px" }}>
                                {/* <label htmlFor="param2">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                <label htmlFor="param2">{module.protocol.id === 1 ? "Etat de charge - seuil minimal (en %)" : "Puissance (en kW)"}</label>
                                <Field name="param2" type="number" className="form-control" placeholder={t('registermodulepage:RegisterForm.installationname-placeholder')} step={module.protocol.id === 1 ? "1" : ".01"} />
                                <ErrorMessage name="param2" />
                            </div>
                        </Aux>
                    ) : <Spinner />}

                    <button className="btn btn-primary" type="submit" style={{ background: `url(${btnRegister}) center center no-repeat`,backgroundSize: 'contain',width: "180px",height: '70px',border: 'none',padding: '0',display: 'block' }}></button>
                </Form>
            </Formik>
        </div>
    );
}

export default AlertForm;