// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.BatteryTemperatures_BatteryTemperatures__3eWLl {\n  display: flex;\n  justify-content: center; }\n  .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_current__2ytDE {\n    color: #fcaf0b; }\n  .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_min__1539E {\n    color: #8dbee6; }\n  .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_max__3O2gP {\n    color: #9b174e; }\n  .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_current__2ytDE, .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_min__1539E, .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_max__3O2gP {\n    flex: 1 1;\n    padding: 10px; }\n    .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_current__2ytDE h5, .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_min__1539E h5, .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_max__3O2gP h5 {\n      color: black;\n      text-align: center;\n      font-size: 14px;\n      font-weight: 400; }\n    .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_current__2ytDE p svg, .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_min__1539E p svg, .BatteryTemperatures_BatteryTemperatures__3eWLl .BatteryTemperatures_max__3O2gP p svg {\n      margin-right: 10px; }\n", ""]);
// Exports
exports.locals = {
	"BatteryTemperatures": "BatteryTemperatures_BatteryTemperatures__3eWLl",
	"current": "BatteryTemperatures_current__2ytDE",
	"min": "BatteryTemperatures_min__1539E",
	"max": "BatteryTemperatures_max__3O2gP"
};
module.exports = exports;
