import { combineReducers } from 'redux';

// import authenticatedReducer from './authenticated';
import { forgotPassword } from './forgotPasswordReducer';
import { authentication } from './authenticationReducer';
import { registration } from './registrationReducer';
import { users } from './usersReducer';
import { alert } from './alertReducer';


const allReducers = combineReducers({
    // authenticated: authenticatedReducer
    authentication,
    registration,
    users,
    alert
})

export default allReducers;
