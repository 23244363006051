import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './SolutionHome.module.scss';
import fondImgTexte1 from '../../_assets/img/fond-texte-solution-batteries-lihtium.png';

class SolutionHome extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="solutionHome" className={`app-about-section angle-sp ${classes.solutionHome}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className={`section-title angle-section-title ${classes.sectionTitleWrapper}`}>
                                    {/* <h2 className={classes.sectionTitle}>Monitorez et gérez vos batteries lithium</h2> */}
                                    <h2 className={classes.sectionTitle}>{t('homepage:SolutionHome.title')}</h2>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="row angle-slides-wrapper-bg">
                        <div className={`col-md-6 ${classes.textLeftColoumn}`} >
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className="app-about-text" style={{ marginTop: "0px" }}>
                                    {/* <h3 className={classes.sectionSubtitle} style={{ marginBottom: "40px" }}>La solution pour connecter vos batteries lithium</h3> */}
                                    <p>{t('homepage:SolutionHome.solution-p1')}</p>
                                    <p>{t('homepage:SolutionHome.solution-p2')}</p>
                                    <p>{t('homepage:SolutionHome.solution-p3')}</p>
                                    <br />
                                    <p style={{ fontStyle: "italic",color: "#3d009e",fontSize: "20px" }}>{t('homepage:SolutionHome.solution-p4')}</p>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className={`col-md-6 ${classes.imgRightColoumn}`}>
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className="text-center">
                                    <img src={require("../../_assets/img/schema-IMG-IOT_BATTERY_UP.png")} alt={t('homepage:SolutionHome.img-iot-alt')} style={{ marginTop: "20px" }} />
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','homepage'])(SolutionHome);