import React,{ useState,useEffect } from 'react';

import { dataService } from '../../_services/dataService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './BatteryTemperatures.module.scss';

import Data from './Data';

const BatteryTemperatures = (props) => {
    const [batteryTemps,setBatteryTemps] = useState({ current: 15.0,min: 10.0,max: 20.0 });
    const selectedId = typeof props.selectedClient.mac_address !== 'undefined' ? props.selectedClient.mac_address : props.selectedClient.serial_number;

    useEffect(() => {
        dataService.batteryTemperatures(selectedId)
            .then(response => {
                console.log("response batteryTemperatures");
                console.log(response);
                setBatteryTemps(response);
            });
    },[]);

    // console.log("Batt Temps in BattTemps");
    // console.log(typeof batteryTemps);

    return (
        <Data
            icon="icone-battery-UP-IMG-TEMPERATURE"
            title="Temperature"
            type="batteryTemps"
        >
            {(batteryTemps.current !== 0 || batteryTemps.min !== 0 || batteryTemps.max !== 0) ? (
                <div className={classes.BatteryTemperatures}>
                    <div className={classes.current}>
                        <h5>Current</h5>
                        <p><FontAwesomeIcon icon="thermometer-half" />{batteryTemps.current}</p>
                    </div>
                    <div className={classes.min}>
                        <h5>Min</h5>
                        <p><FontAwesomeIcon icon="thermometer-empty" />{batteryTemps.min}</p>
                    </div>
                    <div className={classes.max}>
                        <h5>Max</h5>
                        <p><FontAwesomeIcon icon="thermometer-full" />{batteryTemps.max}</p>
                    </div>
                </div>
            ) : (<p>No data</p>)}
        </Data>
    );
};

export default BatteryTemperatures;