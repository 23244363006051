// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WebhookForm_WebhookForm__1oKox {\n  flex: 1 1;\n  padding: 10px; }\n  .WebhookForm_WebhookForm__1oKox form {\n    display: flex;\n    align-items: center;\n    padding-bottom: 10px; }\n    .WebhookForm_WebhookForm__1oKox form div {\n      flex: 2 1;\n      margin-left: 20px; }\n    .WebhookForm_WebhookForm__1oKox form button {\n      flex: 1 1; }\n    .WebhookForm_WebhookForm__1oKox form input[type=\"checkbox\"] {\n      margin: 0 10px; }\n", ""]);
// Exports
exports.locals = {
	"WebhookForm": "WebhookForm_WebhookForm__1oKox"
};
module.exports = exports;
