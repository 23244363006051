import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { triggerService } from '../../_services/triggerService';
import { moduleService } from '../../_services/moduleService';

import classes from './Index.module.scss';

import Modal from '../UI/Modal/Modal';

const Index = (props) => {
    const { t } = useTranslation(['common','triggerspage']);
    const auth = JSON.parse(localStorage.getItem('appState')).user;
    const [triggers,setTriggers] = useState([]);
    const [deletingTriggerStatus,setDeletingTriggerStatus] = useState(false);
    const [deletingTriggerDone,setDeletingTriggerDone] = useState(false);
    const [deletingTriggerTargetId,setDeletingTriggerTargetId] = useState(0);
    const modules = props.modules;

    useEffect(() => {
        console.log("modules");
        console.log(modules);
        console.log("props.modules");
        console.log(props.modules);
        let test = [];
        triggerService.triggerByUserId(auth.id)
            .then(response => {
                // console.log("response triggers by user id");
                // console.log(response);
                // response[0].conditions[0].time_interval.from = new Date(response[0].conditions[0].time_interval.from);
                // console.log("new from");
                // console.log(response[0].conditions[0].time_interval.from);
                // let test = Date.parse("01 Jan 1970 " + response[0].conditions[0].time_interval.from);
                // let second = new Date(test);
                // console.log("second");
                // console.log(second);
                // console.log(second.getHours() + ":" + second.getMinutes());
                // console.log(String(second.getHours()).padStart(2,'0') + ":" + String(second.getMinutes()).padStart(2,'0'));
                // response[0].conditions[0].time_interval.from = transformTimeInterval(response[0].conditions[0].time_interval.from);
                // response[0].conditions[0].time_interval.to = transformTimeInterval(response[0].conditions[0].time_interval.to);
                // console.log("new from");
                // console.log(response[0].conditions[0].time_interval.from);
                // console.log("new to");
                // console.log(response[0].conditions[0].time_interval.to);
                response.forEach(trigger => {
                    trigger.actions[0].time_interval.from = transformTimeInterval(trigger.actions[0].time_interval.from);
                    trigger.actions[0].time_interval.to = transformTimeInterval(trigger.actions[0].time_interval.to);
                    console.log("props.modules");
                    console.log(props.modules);
                    console.log("trigger.mac_address");
                    console.log(trigger.mac_address);
                    // let indexDevice = auth.modules.map(e => e.mac_address).indexOf(trigger.mac_address);
                    // trigger.deviceName = auth.modules[indexDevice].module_name;
                    if (props.modules.length > 0) {
                        let indexDevice = props.modules.map(e => e.mac_address).indexOf(trigger.mac_address);
                        // console.log("indexDevice");
                        // console.log(indexDevice);
                        // let test = "";
                        // if (indexDevice === -1) {
                        //     // test = getDeviceName(trigger.mac_address);
                        //     moduleService.getNameByMacAddress(trigger.mac_address)
                        //         .then(result => {
                        //             test = result;
                        //         });
                        // }
                        // console.log("result getDeviceName in foreach");
                        // console.log(test);
                        trigger.deviceName = indexDevice !== -1 ? props.modules[indexDevice].module_name : "";
                    }
                })
                console.log("response triggers by user id after time interval modif");
                console.log(response);
                response.forEach(trigger => {
                    if (trigger.deviceName === "") {
                        // console.log("this should run after first deviceName and before setting triggers");
                        // moduleService.getNameByMacAddress(trigger.mac_address)
                        //     .then(deviceName => {
                        //         trigger.deviceName = deviceName;
                        //     });
                        moduleService.getClientDeviceInfo(trigger.mac_address)
                            .then(infoDevice => {
                                // console.log("infoDevice");
                                // console.log(infoDevice);
                                trigger.deviceName = infoDevice.name;
                                trigger.clientFirstname = infoDevice.clientFirstname;
                                trigger.clientSurname = infoDevice.clientSurname;
                            });
                    }
                })
                console.log("response after second attempt to retrieve device name");
                console.log(response);
                setTimeout(() => {
                    setTriggers(response);
                },600);
                // setTriggers(response);
                // })
                // .then(response => {
                //     console.log("can this be triggered ?");
                //     console.log(response);
                //     triggers.forEach(trigger => {
                //         console.log("trigger device name in second then");
                //         console.log(trigger.deviceName);
                //     })
            });
        // });
        moduleService.getDeviceConfig("BU-50-02-91-F7-11-8D")
            .then(response => {
                console.log("response device config");
                console.log(response);
            });
        moduleService.updateDeviceConfig("BU-50-02-91-F7-11-8D",5)
            .then(response => {
                console.log("response device update");
                console.log(response);
            });
    },[props.triggerAdded,props.modules]);

    // function handleTriggerChange(event) {
    //     // Here, we invoke the callback with the new value
    //     props.handleTriggerChange(event.target.value);
    // }

    function getDeviceName(macaddress) {
        let test = ""
        moduleService.getNameByMacAddress(macaddress)
            .then(response => {
                test = response;
            })
            .then(test => {
                return test;
            });
        // return test;
    }

    function transformTimeInterval(interval) {
        let parsedDate = Date.parse("01 Jan 1970 " + interval);
        let fakeDate = new Date(parsedDate);
        let formattedTimeInterval = String(fakeDate.getHours()).padStart(2,'0') + ":" + String(fakeDate.getMinutes()).padStart(2,'0');
        return formattedTimeInterval;
    }

    function handleTriggerDelete(triggerId) {
        setDeletingTriggerStatus(false);
        console.log("this was triggered somehow");
        triggerService.deleteTrigger(triggerId)
            .then(response => {
                console.log("response handleTriggerDelete");
                console.log(response);
                setDeletingTriggerDone(true);
                // window.location.reload(true);
            });
    }

    function deletionTriggerModalHandler(id) {
        console.log("this should just open the modal asking if I am sure");
        // let target = e.target.getAttribute('target');
        setDeletingTriggerTargetId(id);
        setDeletingTriggerStatus(true);
    }

    function deletionTriggerCancelHandler() {
        setDeletingTriggerStatus(false);
    }

    function deletedTriggerCloseHandler() {
        triggerService.triggerByUserId(auth.id)
            .then(response => {
                console.log("response triggers by user id after deletion");
                console.log(response);
                setTriggers(response);
            })
            .then(response => {
                props.handleTriggerDeletion();
                setDeletingTriggerDone(false);
            });
    }

    return (
        <div className={`row ${classes.triggersIndex}`}>
            <Modal show={deletingTriggerStatus} modalClosed={deletionTriggerCancelHandler}>
                <img src={require("../../_assets/img/icon_alert.png")} alt={t('common:icons.alert')} style={{ width: "50px" }} />
                {/* <p>{t('myprofilepage:UserProfile.sure-delete-module')}</p> */}
                <p>{t('triggerspage:TriggersIndex.sure-delete-module')}</p>
                <p>{t('common:user-interactions.please-confirm')}</p>
                <span onClick={deletionTriggerCancelHandler}><img src={require("../../_assets/img/bouton-annuler-battery-up-EN.png")} alt={t('common:user-interactions.button-cancel-alt')} style={{ height: "65px" }} /></span>
                <button onClick={() => { handleTriggerDelete(deletingTriggerTargetId) }} className={`btn btn-primary ${classes.submitButton}`}>
                    {/* <button className="btn btn-primary"> */}
                    {t('common:user-interactions.delete')}
                </button>
            </Modal>
            <Modal show={deletingTriggerDone} modalClosed={deletedTriggerCloseHandler}>
                <img src={require("../../_assets/img/icon_checked.png")} alt={t('common:icons.success')} style={{ width: "50px" }} />
                {/* <p>{t('myprofilepage:UserProfile.module-deleted')}</p> */}
                <p>{t('triggerspage:TriggersIndex.trigger-deleted')}</p>
                <button onClick={deletedTriggerCloseHandler} className={`btn btn-primary ${classes.submitButton}`}>
                    {t('common:user-interactions.done')}
                </button>
            </Modal>
            {triggers.length > 0 ? (
                triggers.map((trigger,idx) => {
                    return (
                        <div className={`${classes.triggerItem}`} key={idx}>
                            <div className={classes.triggerItem__wrapper}>
                                <div className={classes.triggerItem__idx}>
                                    <span>{idx + 1}</span>
                                </div>
                                <div className={classes.triggerItem__content}>
                                    <h4 className={classes.triggerItem__deviceName}>{trigger.deviceName}</h4>
                                    <p className={classes.triggerItem__macaddress}>{trigger.clientFirstname ? trigger.clientFirstname : ""} {trigger.clientSurname ? trigger.clientSurname : ""} ({trigger.mac_address})</p>
                                    <div className={classes.triggerItem__parameters}>
                                        <div className={`${classes.triggerItem__param} ${classes.triggerItem__paramConditions}`}>
                                            <h5>{t('triggerspage:TriggersIndex.condition')}</h5>
                                            <p>{trigger.conditions[0].metric} {trigger.conditions[0].compare} {trigger.conditions[0].value}</p>
                                        </div>
                                        <div className={`${classes.triggerItem__param} ${classes.triggerItem__paramTime}`}>
                                            <h5>{t('triggerspage:TriggersIndex.time')}</h5>
                                            {trigger.actions[0].time_interval ? (
                                                <p>{t('triggerspage:TriggersIndex.from')} {trigger.actions[0].time_interval.from} {t('triggerspage:TriggersIndex.to')} {trigger.actions[0].time_interval.to}</p>
                                            ) : ""}
                                        </div>
                                        <div className={`${classes.triggerItem__param} ${classes.triggerItem__paramActions}`}>
                                            <h5>{t('triggerspage:TriggersIndex.action')}</h5>
                                            <p>{trigger.actions[0].type} {"=>"} {trigger.actions[0].value} ({t('triggerspage:TriggersIndex.repeat')}: {trigger.actions[0].repeat})</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.triggerItem__actions}>
                                    <span onClick={() => props.handleTriggerChange(trigger.id)}><img src={require("../../_assets/img/icon_edit_btn.png")} /></span>
                                    <span onClick={() => deletionTriggerModalHandler(trigger.id)}><img src={require("../../_assets/img/icon_delete_btn.png")} /></span>
                                </div>
                            </div>
                            {/* <div className={``}> */}
                            {/* <div>
                            <p>{trigger.conditions[idx].metric} {trigger.conditions[idx].compare} {trigger.conditions[idx].value} {"=>"} {trigger.actions[idx].type} {trigger.actions[idx].value} {trigger.actions[idx].repeat}</p>
                        </div> */}
                            {/* {trigger.conditions.map((condition,idc) => {
                                return (
                                    <div>
                                        <p>{condition.metric} {condition.compare} {condition.value}  {"=>"} {trigger.actions[idc].type} {trigger.actions[idc].value} {trigger.actions[idc].repeat}</p>
                                    </div>
                                )
                            })} */}
                            {/* </div> */}
                            <div className={classes.colorBorder}></div>
                        </div>
                    )
                })) : <p className={classes.noTrigger}>{t('triggerspage:TriggersIndex.no-trigger')}</p>}
            <div className="col-12 text-right">
                <button className={classes.addTriggerBtn} onClick={() => props.handleTriggerChange('0')}>{t('triggerspage:TriggersIndex.btn-add-trigger')}</button>
            </div>
        </div>
    )
};

export default Index;