import { authHeader } from '../_helpers/auth-header';
import axios from '../axios';
import { userConstants } from '../_constants/userConstants';

export const moduleService = {
    getAll,
    registerModule,
    editModule,
    getByUserId,
    getByUserIdWithAlerts,
    getActiveSystemRules,
    getAllSystemRules,
    getById,
    getNameByMacAddress,
    // getWebhook,
    // update,
    delete: _delete,
    getDeviceConfig,
    updateDeviceConfig,
    getClientDeviceInfo,
    getClientDevices,
};

function getAll() {
    // console.log('getAll in protocol service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/protocols',config)
        .then(response => {
            // console.log("Response getAll protocols");
            // console.log(response);
            if (response.data) {
                // console.log("List of protocols successfully retrieved !");

                let protocols = response.data.protocols;

                return protocols;
            }
        });
}

function registerModule(values) {
    // console.log('is in module service');
    // console.log('values');
    // let address= `${values.line1},${values.street_number} ${values.zipcode} ${values.locality}, ${values.country}`;
    // console.log(address);
    // axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=wTvLLbdq_q1KYM_CcLVhB_iYDc1THksBWjvN5h8vAdU`)
    //     .then(response => {
    //         let country_coords=response.data.items[0].position;
    //         values.latitude = country_coords.lat;
    //         values.longitude = country_coords.lng;
    //     });
    // console.log(values);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/register-module',values,config)
        // return axios.post('/api/register-module',{values:values,coords:window.coords},config)
        .then(response => {
            // console.log("Response registerModule");
            // console.log(response);
            if (response.data) {
                // console.log("Module successfully created !");
                // console.log(response);

                let module = response.data.module;

                let userRoles = [];
                for (let i = 0; i < response.data.user.roles.length; i++) {
                    userRoles.push(response.data.user.roles[i].name);
                }

                let userModules = [];
                for (let i = 0; i < response.data.user.modules.length; i++) {
                    userModules.push(response.data.user.modules[i].mac_address);
                }

                let userData = {
                    firstname: response.data.user.firstname,
                    surname: response.data.user.surname,
                    id: response.data.user.id,
                    email: response.data.user.email,
                    auth_token: token,
                    // roles: userRoles,
                    // modules: userModules
                    // roles: response.data.user.roles,
                    roles: userRoles,
                    rolesObj: response.data.user.roles,
                    modules: response.data.user.modules,
                    sermatecs: response.data.user.sermatecs,
                    addresses: response.data.user.addresses,
                    clients: response.data.user.clients
                    // timestamp: new Date().toString()
                };

                let mainInfo = {
                    firstname: response.data.user.firstname,
                    id: response.data.user.id,
                    mainRole: userRoles.includes('super-admin') ? 'super-admin' : userRoles.includes('admin') ? 'admin' : 'client',
                };

                // dispatch(login());
                let appState = {
                    isLoggedIn: true,
                    user: userData,
                    // mainInfo: mainInfo,
                };
                localStorage.setItem("appState",JSON.stringify(appState));
                localStorage.setItem("mainInfo",JSON.stringify(mainInfo));

                return module;
            }
        }).catch((e) => {
            // console.log(e + JSON.stringify(window.coords) + " :coords");
        });
}

function editModule(values,selectedDevice) {
    values.device_id = selectedDevice;
    // console.log("values edit module");
    // console.log(values);
    // return values;
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-module',values,config)
        .then(response => {
            // console.log("Response editModule");
            // console.log(response);
            if (response.data) {
                let module = response.data.module;

                let userRoles = [];
                for (let i = 0; i < response.data.user.roles.length; i++) {
                    userRoles.push(response.data.user.roles[i].name);
                }

                let userModules = [];
                for (let i = 0; i < response.data.user.modules.length; i++) {
                    userModules.push(response.data.user.modules[i].mac_address);
                }

                let userData = {
                    firstname: response.data.user.firstname,
                    surname: response.data.user.surname,
                    id: response.data.user.id,
                    email: response.data.user.email,
                    auth_token: token,
                    roles: userRoles,
                    rolesObj: response.data.user.roles,
                    modules: response.data.user.modules,
                    sermatecs: response.data.user.sermatecs,
                    addresses: response.data.user.addresses,
                    clients: response.data.user.clients
                };

                let mainInfo = {
                    firstname: response.data.user.firstname,
                    id: response.data.user.id,
                    mainRole: userRoles.includes('super-admin') ? 'super-admin' : userRoles.includes('admin') ? 'admin' : 'client',
                };

                let appState = {
                    isLoggedIn: true,
                    user: userData,
                    // mainInfo: mainInfo,
                };
                localStorage.setItem("appState",JSON.stringify(appState));
                localStorage.setItem("mainInfo",JSON.stringify(mainInfo));

                return module;
            }
        }).catch((e) => {
            // console.log(e + JSON.stringify(window.coords) + " :coords");
        });
}

function getById(moduleId) {
    // console.log('getById in module service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    // console.log("target");
    // console.log(moduleId);

    // return axios.get('/api/users',config)
    return axios.get('/api/module?id=' + moduleId,config)
        .then(response => {
            // console.log("Response getById");
            // console.log(response);
            if (response.data) {
                // console.log("Module successfully retrieved !");
                // console.log(response);

                let module = response.data.module;

                return module;
            }
        });
}

function getByUserId(userId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/user-modules?id=' + userId,config)
        .then(response => {
            // console.log("Response getByUserId");
            // console.log(response);
            if (response.data) {
                // console.log("User's modules successfully retrieved !");
                // console.log(response);

                let modules = response.data.modules;

                return modules;
            }
        });
}

function getByUserIdWithAlerts(userId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/modules-alerts?id=' + userId,config)
        .then(response => {
            // console.log("Response getByUserIdWithAlerts");
            // console.log(response);
            if (response.data) {
                // console.log("User's modules successfully retrieved !");
                // console.log(response);

                let modules = response.data.modules;

                return modules;
            }
        });
}

function getActiveSystemRules(moduleId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/active-system-rules?module=' + moduleId,config)
        .then(response => {
            // console.log("Response getActiveSystemRules");
            // console.log(response);
            if (response.data) {
                // console.log("Module's active system rules successfully retrieved !");
                // console.log(response);

                let systemRules = response.data.systemRules;

                return systemRules;
            }
        });
}

function getAllSystemRules() {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/system-rules',config)
        .then(response => {
            // console.log("Response getAllSystemRules");
            // console.log(response);
            if (response.data) {
                // console.log("System Rules successfully retrieved !");
                // console.log(response);

                let systemRules = response.data.systemRules;

                return systemRules;
            }
        });
}

function getNameByMacAddress(macAddress) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/module-name-by-mac-address?macaddress=' + macAddress,config)
        .then(response => {
            // console.log("Response getNameByMacAddress");
            // console.log(response);
            if (response.data) {
                // console.log("Module name successfully retrieved !");
                // console.log(response);

                let name = response.data.module;

                return name;
            }
        });
}

function getClientDeviceInfo(macAddress) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/client-device-info?macaddress=' + macAddress,config)
        .then(response => {
            // console.log("Response getNameByMacAddress");
            // console.log(response);
            if (response.data) {
                // console.log("Device info successfully retrieved !");
                // console.log(response);

                let module = response.data.module;

                return module;
            }
        });
}

// function getWebhook(module_id) {

// }

function update(userData) {
    // console.log('update in service');
    // console.log('userData');
    // console.log(userData);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-user/',userData,config)
        .then(response => {
            // console.log("Response update");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully updated !");
                // console.log(response);

                let user = response.data.user;

                return user;
            }
        });
}

function _delete(targetId) {
    // console.log('delete in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/delete-module?id=' + targetId,config)
        .then(response => {
            // console.log("Response delete device");
            // console.log(response);
            if (response.data) {
                // console.log("Device successfully deleted !");
                // console.log(response);

                let message = "Device successfully deleted !";

                return message;
            }
        });
}

function getDeviceConfig(macaddress) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/devices/' + macaddress,config)
        .then(response => {
            // console.log("Response delete device");
            // console.log(response);
            if (response.data) {
                // console.log("Device config successfully retrieved !");
                // console.log(response);

                let message = "Device config successfully retrieved !";

                return message;
            }
        });
}

function updateDeviceConfig(macaddress,protocol) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    const pylonConfig = '{\n  \"mac_address\":\"' + macaddress + '\",\n  \"interface_type\":1,\n  \"protocol\":{\n    \"version\":1,\n    \"start_tag\":\"0359\",\n    \"tag_length\":4,\n    \"frame_length\":60,\n    \"data_refresh_rate\":15,\n    \"baud_rate\":500000\n   }\n}';
    // const acrelConfig = '{\n  \"mac_address\":\"' + macaddress + '\",\n  \"interface_type\":2,\n  \"protocol\":{\n    \"version\":2,\n    \"start_tag\":\"01030061\",\n    \"tag_length\":8,\n    \"frame_length\":88,\n    \"data_refresh_rate\":15,\n    \"baud_rate\":9600\n   }\n}';
    const acrelConfig = '{\n  \"mac_address\":\"' + macaddress + '\",\n  \"interface_type\":2,\n  \"protocol\":{\n    \"version\":2,\n    \"start_tag\":\"01030061\",\n    \"tag_length\":8,\n    \"frame_length\":88,\n    \"data_refresh_rate\":15,\n    \"baud_rate\":9600\n   }\n}';
    const acrelMasterConfig = '{\n  \"mac_address\":\"' + macaddress + '\",\n  \"interface_type\":4,\n  \"protocol\":{\n    \"version\":4,\n    \"start_tag\":\"010362\",\n    \"tag_length\":6,\n    \"frame_length\":100,\n    \"data_refresh_rate\":15,\n    \"baud_rate\":9600,\n    \"cde_len\":8,\n    \"cde\":010300470031340B\n   }\n}';
    const eastronConfig = '{\n  \"mac_address\":\"' + macaddress + '\",\n  \"interface_type\":2,\n  \"protocol\":{\n    \"version\":3,\n    \"start_tag\":\"01040000004C\",\n    \"tag_length\":12,\n    \"frame_length\":164,\n    \"data_refresh_rate\":15,\n    \"baud_rate\":9600\n   }\n}';
    const eastronMasterConfig = '{\n  \"mac_address\":\"' + macaddress + '\",\n  \"interface_type\":4,\n  \"protocol\":{\n    \"version\":5,\n    \"start_tag\":\"0104C0\",\n    \"tag_length\":6,\n    \"data_refresh_rate\":15,\n    \"baud_rate\":9600,\n    \"cde_len\":8,\n    \"cde\":010400000060F022,\n    \"frame_length\":194,\n    \"cde2_len\":8,\n    \"cde2\":01040102000851F0,\n    \"frame2_length\":21,\n    \"cde3_len\":8,\n    \"cde3\":0104015600081020,\n    \"frame3_length\":21\n   }\n}';
    let newDeviceConfig = "";
    switch (protocol) {
        case 1:
            newDeviceConfig = pylonConfig;
            break;
        case 2:
            newDeviceConfig = acrelConfig;
            break;
        case 3:
            newDeviceConfig = eastronConfig;
            break;
        case 4:
            newDeviceConfig = acrelMasterConfig;
            break;
        case 5:
            newDeviceConfig = eastronMasterConfig;
            break;
        default:
            newDeviceConfig = pylonConfig;
            break;
    }
    // var bodyFormData = new FormData();
    // bodyFormData.append('config',newDeviceConfig);
    // bodyFormData.append('config','newDeviceConfig');
    // console.log("bodyFormData");
    // console.log(bodyFormData.entries());
    let test = { config: newDeviceConfig };

    // return axios.put('/api/devices/' + macaddress,bodyFormData,config)
    return axios.put('/api/devices/' + macaddress,test,config)
        .then(response => {
            // console.log("Response delete device");
            // console.log(response);
            if (response.data) {
                // console.log("Device config successfully updated !");
                // console.log(response);

                let message = "Device config successfully updated !";

                return message;
            }
        });
}

function getClientDevices(installerId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/installer-available-modules?installer=' + installerId,config)
        .then(response => {
            // console.log("Response getAll protocols");
            // console.log(response);
            if (response.data) {
                // console.log("List of protocols successfully retrieved !");

                let modules = response.data.modules;

                return modules;
            }
        });
}