import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { Formik,Field,Form,ErrorMessage,getIn } from 'formik';
import * as Yup from 'yup';

import { userService } from '../../_services/userService';
import { userActions } from '../../_actions/userActions';
import { roleService } from '../../_services/roleService';
import { history } from '../../_helpers/history';

import TemporaryPopup from '../UI/ValidationMessage/TemporaryPopup';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import btnRegister from '../../_assets/img/bouton-enregistrer-battery-up.png';
import classes from './UserForm.module.scss';

const UserForm = (props) => {
    const { t } = useTranslation(['common','registerpage']);

    const auth = JSON.parse(localStorage.getItem('appState')).user;
    const [initForm,setInitForm] = useState({
        firstname: auth.firstname,
        surname: auth.surname,
        email: auth.email,
        // roles: []
    });

    // const [user,setUser] = useState({
    //     firstname: '',
    //     surname: '',
    //     email: '',
    //     roles: []
    // });
    // const [submitted,setSubmitted] = useState(false);
    // const modifying = useSelector(state => state.modifying);
    const [modified,setModified] = useState(false);
    // const dispatch = useDispatch();
    const [targetId,setTargetId] = useState(props.params.id);
    // const [roles,setRoles] = useState([]);
    // const [rolesId,setRolesId] = useState([]);
    // const [rolesSelected,setRolesSelected] = useState([]);
    // console.log('auth id and target id');
    // console.log(auth.id + " " + targetId);

    // reset login status
    // useEffect(() => {
    //     userService.getById(targetId)
    //         .then(response => {
    //             console.log('response in edit form');
    //             console.log(response);
    //             setUser(response);
    //             let rolesArr = [];
    //             let rolesSelected = [];
    //             response.roles.forEach(function (r) {
    //                 rolesArr.push(r.id);
    //                 rolesSelected.push(r.name);
    //             })
    //             console.log('rolesArr');
    //             console.log(rolesArr);
    //             setRolesId(rolesArr);
    //             setRolesSelected(rolesSelected);
    //         });
    //     roleService.getAll()
    //         .then(response => {
    //             console.log('roles response in edit form');
    //             console.log(response);
    //             setRoles(response);
    //         });
    // },[]);


    const validationMessage = (
        <TemporaryPopup redirect={props.prevPath} button={props.prevPath === "/users" ? "Liste des utilisateurs" : "Retour au profil"}>
            Les données de l'utilisateur ont bien été changées !
        </TemporaryPopup>
    )
    const validation = modified ? validationMessage : "";

    return (
        <div id="userForm" className="app-about-section angle-sp" style={{ marginTop: "0",paddingTop: '0',width: '100%' }}>
            {validation}
            <div className="container">
                <div className="row" style={{ background: `url(${imgfondlogin}) center center no-repeat`,backgroundSize: 'contain',padding: "100px",margin: '0 150px' }}>
                    <div className="col-12" style={{ background: `#ffffff none repeat scroll 0 0`,boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',padding: "60px 30px" }}>
                        <h2>{parseInt(auth.id) === parseInt(targetId) ? "Edit my profile" : "Edit user"}</h2>
                        <Formik
                            enableReinitialize
                            initialValues={initForm}
                            validationSchema={Yup.object({
                                firstname: Yup.string()
                                    .trim()
                                    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registerpage:RegisterForm.yup-firstname-match')}</p> })
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                surname: Yup.string()
                                    .trim()
                                    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registerpage:RegisterForm.yup-surname-match')}</p> })
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                email: Yup.string()
                                    .trim()
                                    .email(<p className="errorMessage">{t('registerpage:RegisterForm.yup-invalid-email')}</p>)
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>)
                            })}
                            onSubmit={(values,{ setSubmitting }) => {
                                setTimeout(() => {
                                    // alert(values);
                                    console.log("before dispatch");
                                    console.log(JSON.stringify(values,null,2));
                                    userService.update(values);
                                },400);
                            }}
                        >
                            <Form>
                                <div className="form-group" style={{ margin: '10px',width: '45%',float: 'left',color: "#3d009e" }}>
                                    <label htmlFor="firstname">{t('registerpage:RegisterForm.input-firstname-label')}</label>
                                    <Field name="firstname" type="text" className="form-control" />
                                    <ErrorMessage name="firstname" />
                                </div>
                                <div className="form-group" style={{ margin: '10px',width: '45%',float: 'right',color: "#3d009e" }}>
                                    <label htmlFor="surname">{t('registerpage:RegisterForm.input-surname-label')}</label>
                                    <Field name="surname" type="text" className="form-control" />
                                    <ErrorMessage name="surname" />
                                </div>
                                <div style={{ clear: 'both' }}></div>
                                <div className="form-group" style={{ margin: '10px',color: "#3d009e" }}>
                                    <label htmlFor="email">{t('registerpage:RegisterForm.input-email-label')}</label>
                                    <Field name="email" type="email" className="form-control" />
                                    <ErrorMessage name="email" />
                                </div>
                                <button className="btn btn-primary" type="submit" style={{ background: `url(${btnRegister}) center center no-repeat`,backgroundSize: 'contain',width: "180px",height: '70px',border: 'none',padding: '0',margin: '0 auto',display: 'block' }}></button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserForm;