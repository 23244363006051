// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TermsPage_HomePage__2TlX8 .TermsPage_container__YQ__7 {\n  max-width: 1500px !important; }\n", ""]);
// Exports
exports.locals = {
	"HomePage": "TermsPage_HomePage__2TlX8",
	"container": "TermsPage_container__YQ__7"
};
module.exports = exports;
