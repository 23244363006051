import React,{ Component } from 'react';
import { Link } from 'react-router-dom';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import '../../_assets/css/color/color-default.scss';
import classes from './Download.module.scss';

class Download extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="download" className={`app-about-section angle-sp ${classes.downloads}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className={`section-title angle-section-title ${classes.sectionTitleWrapper}`}>
                                    {/* <h2 className={classes.sectionTitle}>Monitorez et gérez vos batteries lithium</h2> */}
                                    <h2 className={classes.sectionTitle}>{t('downloads:Downloads.title')}</h2>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-12 ${classes.links}`}>
                            <ul>
                                <li><Link to="/files/Battery-UP-Manuel-utilisation-FR-A5.pdf" target="_blank" download>{t('downloads:Downloads.bu-device-fr')}</Link></li>
                                <li><Link to="/files/Battery-UP-Manuel-utilisation-FR-A5-EN.pdf" target="_blank" download>{t('downloads:Downloads.bu-device-en')}</Link></li>
                                <li><Link to="/files/battery-up_webhooks_guide_fr.pdf" target="_blank" download>{t('downloads:Downloads.bu-webhooks-fr')}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','downloads'])(Download);