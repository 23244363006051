import React,{ Component } from 'react';

import ReactWOW from 'react-wow';
// import classes from './IntroApp.module.scss';
// import imgIntro from '../../_assets/img/IMG-header-site-battery-UP-22.png';

class DiscoverApp extends Component {
    render() {
        return (
            <section id="discoverApp" className="app-about-section angle-sp" style={{paddingTop: '0',paddingBottom: '0'}}>
                <div className="container-fluid" style={{padding:"0"}}>
                    <div className="row" style={{minHeight:"500px"}}>
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                {/* <div className="jumbotron" style={{  background: `url(${imgIntro}) no-repeat center center`,height: '100%'  }}> */}
                                <img src={require("../../_assets/img/IMG-header-site-battery-UP-22.png")} alt="App About Image" style={{width: '1900px',float: "center"}} />
                                <div className="section-title angle-section-title">
                                    <h2 style={{color:"#3d009e", fontWeight:"300", fontSize:"40px", textTransform: "uppercase"}}>Application</h2>
                                </div>
                                {/* </div> */}
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DiscoverApp;