import React from 'react';
import { Route,Redirect } from 'react-router-dom';

// if (localStorage.getItem("appState") === null) {
//     let appState = {
//         isLoggedIn: false,
//         user: {}
//     }
//     localStorage.setItem("appState",JSON.stringify(appState))
// }
// console.log("RR");
// console.log(typeof localStorage.getItem('appState'));
// console.log(localStorage.getItem('appState'));
// console.log(JSON.parse(localStorage.getItem('appState')));
const user = JSON.parse(localStorage.getItem('appState')).user;
const userRoles = typeof user.roles !== 'undefined' ? user.roles : [];
// console.log("RR roles has");
// console.log(userRoles);
// console.log("RR roles needs");
// console.log(props.role);

export const RoleRoute = ({ component: Component,role,...rest }) => (
    <Route {...rest} render={props => (
        // localStorage.getItem('user')
        // JSON.parse(localStorage.getItem('appState')).user.auth_token
        // userRoles.includes(props.role)
        userRoles.some(r => role.indexOf(r) >= 0)
            // localStorage.getItem('appState')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/401',state: { from: props.location } }} />
    )} />
)