import axios from '../axios';

export const roleService = {
    getAll,
};

function getAll() {
    console.log('getAll in role service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') {return}
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/roles',config)
        .then(response => {
            console.log("Response getAll");
            console.log(response);
            if (response.data) {
                console.log("List of roles successfully retrieved !");

                let roles = response.data.roles;

                return roles;
            }
        });
}
