import React,{ Component } from 'react';

import ReactWOW from 'react-wow';
import classes from './ChartApp.module.scss';

class ChartApp extends Component {
    render() {
        return (
            <section id="chartApp" className="app-about-section angle-sp">
                <div className={`container ${classes.container}`}>
               

                    <div className="row">
                        <div className="col-lg-6">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className="app-about-text" style={{textAlign:"left", marginTop:"200px"}}>
                                <h3>Graphiques</h3>
                                    <p>Révélez toute la puissance de votre <strong><span>Battery UP </span></strong>en ajoutant nos compteurs d’énergie. Ceux-ci s’installent en rail DIN directement dans votre tableau électrique.</p>
                                    <p>Visualisez l’évolution énergétique de votre installation au travers de votre mobile. Suivez votre consommation et optimisez votre gestion énergétique en toute simplicité.</p>
                                <ul>
                                    <li><span>• Visualisation des énergies totalisées</span></li>
                                    <li><span>• Courant de production, batterie & réseau sur un seul graphique clair </span></li>
                                </ul>
                                
                                </div>
                            </ReactWOW>
                        </div>

                        <div className="col-lg-6">
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className="text-center">
                                <img src={require("../../_assets/img/IMG-smartphone-Flux-energie-detailles.jpg")} />
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChartApp;