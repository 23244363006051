import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import { userService } from '../../_services/userService';
import { moduleService } from '../../_services/moduleService';
import classes from './UserInfo.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../../_helpers/history';

import Modal from '../UI/Modal/Modal';
import Data from '../Data/Data';
import Spinner from '../UI/Spinner/Spinner';

const UserInfo = (props) => {
    const authUser = JSON.parse(localStorage.getItem('appState')).user;
    const authRoles = authUser.roles;
    const [user,setUser] = useState([]);
    const [hasModules,setHasModules] = useState(true);
    // const roles = user.roles;
    const [modules,setModules] = useState([]);
    const [targetId,setTargetId] = useState(props.params.id);
    const [isAdmin,setIsAdmin] = useState(false);
    const [deletingStatus,setDeletingStatus] = useState(false);
    const [deletingTarget,setDeletingTarget] = useState(0);
    const [deletingTargetId,setDeletingTargetId] = useState(0);
    const [deletingDone,setDeletingDone] = useState(false);

    useEffect(() => {
        userService.getById(targetId)
            .then(response => {
                setUser(response);
                if(response.modules.length === 0) {
                    setHasModules(false);
                }
            });
            // .then(user => {
            //     console.log('user 000');
            //     console.log(user);
            // })
        moduleService.getByUserId(targetId)
            .then(response => {
                console.log("response modules by user id");
                console.log(response);
                setModules(response);
            });
        if(authRoles.includes('installer') ||authRoles.includes('admin') || authRoles.includes('super-admin')) {
            setIsAdmin(true);
        }
    },[]);

//     useEffect(() => {
//         console.log("user bzfore setting modules");
//         console.log(user);
// setModules(user.modules);
//     })

    function deletionModalHandler(e) {
        let target = {
            id: e.target.getAttribute('target-id'),
            name: e.target.getAttribute('target')
        };
        setDeletingTarget(target.name);
        setDeletingTargetId(target.id);
        setDeletingStatus(true);
    }

    function deletionCancelHandler() {
        setDeletingStatus(false);
    }

    function deletionHandler() {
        userService.delete(deletingTargetId)
            .then(response => {
                setDeletingStatus(false);
                setDeletingDone(true);
            });
    }

    function deletedCloseHandler() {
        setDeletingDone(false);
        history.push('/users');
    }

    console.log('user in user details1');
    console.log(user);
    console.log('modules in user details1');
    console.log(modules);
    // console.log('modules in user details2');
    // console.log(modules[0]);

    return (
        <div id="userInfo" className={classes.UserInfo}>
            <Modal show={deletingStatus} modalClosed={deletionCancelHandler}>
                {/* <p>{deletingTargetId}</p> */}
                <i className="icofont-warning"></i>
                <p>You are about to delete {deletingTarget}</p>
                <p>Please confirm</p>
                <span onClick={deletionCancelHandler}><i className="icofont-close"></i>cancel</span>
                <button onClick={deletionHandler} className="btn btn-primary">
                    {/* {modifying && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                    Delete
                </button>
            </Modal>
            <Modal show={deletingDone} modalClosed={deletedCloseHandler}>
                <i className="icofont-verification-check"></i>
                <p>The user has been successfully deleted !</p>
                <button onClick={deletedCloseHandler} className="btn btn-primary">
                    Done
                </button>
            </Modal>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <Data
                            icon="icone-battery-UP-IMG-personne"
                            title={`${user.firstname} ${user.surname}`}
                            type="userInfo"
                        >
                            <div className="row mt-4">
                                <div className="col-lg-10 text-left">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4>Info</h4>
                                        </div>
                                        <div className="col-lg-2">
                                            <p>
                                                ID<br/>
                                                Nom<br/>
                                                Prénom
                                            </p>
                                        </div>
                                        <div className="col-lg-10">
                                            <p>
                                                {user.id}<br/>
                                                {user.surname}<br/>
                                                {user.firstname}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4>Modules</h4>
                                        </div>
                                        <div className={`col-lg-12 ${classes.modulesList}`}>
                                            {modules.length > 0 ? (
                                                modules.map((module,idx) => {
                                                    return (
                                                        <div className={`row ${classes.moduleItem}`} key={idx}>
                                                            <div className={`col-lg-2`}>{module.mac_address}</div>
                                                            <div className={`col-lg-10`}>{module.protocol.brand} - {module.protocol.product}</div>
                                                        </div>
                                                    )
                                            })) : hasModules ? <Spinner /> : (
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <p>This user only has access to our demo module.</p>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4>Addresses</h4>
                                        </div>
                                        <div className={`col-lg-12 ${classes.modulesList}`}>
                                            {typeof user.addresses !== 'undefined' ? (
                                                user.addresses.length > 0 ? (
                                                    user.addresses.map((address,idx) => {
                                                        return (
                                                            <div className={`row ${classes.moduleItem}`} key={idx}>
                                                                <div className={`col-lg-3`}>{address.street_number}, {address.line1}{address.line2 !== "" ? `, ${address.line2}` : ""}</div>
                                                                <div className={`col-lg-9`}>{address.zipcode} {address.locality}, {address.country.name_en}</div>
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <p>This user has no registered address.</p>
                                                    </div>
                                                </div>
                                                )
                                            ) : <Spinner />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <Link to={{pathname: `/edit-user/${user.id}`, state: { prevPath: history.location.pathname }}}><i className="icofont-ui-edit"></i></Link>
                                    {/* <Link to={`/edit-user/${user.id}`}><i className="icofont-ui-edit"></i></Link> */}
                                    {isAdmin ? (
                                    <i target-id={user.id} target={user.firstname + ' ' + user.surname} onClick={deletionModalHandler} className="icofont-ui-delete"></i>
                                    ) : "" }
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-2 offset-lg-10">
                                    <Link to="register">
                                        <button className="btn btn-primary">
                                            <i className="icofont-ui-add"></i>
                                            Add user
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </Data>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserInfo;