import axios from '../axios';
import axiosreal from 'axios';
import { predictionConstants } from '../_constants/predictionConstants';

export const predictionService = {
    // predictionsByDeviceInfoDate,
    socPredictionsByDeviceInfoDate,
    socAdvice,
};

/**
 * Retrieving the predictions for a device on a date
 */
// function socValue(userId) {
// function predictionsByDeviceInfoDate(userId,mac_address,date) {
//     return axios.get(predictionConstants.PREDICTION_SHORT_URL + "/" + mac_address + "?user_id=" + userId + "&date=" + date)
//         .then(response => {
//             console.log("response.data.predictions");
//             console.log(response.data.predictions);
//             let predictions = response.data.predictions;
//             return predictions;
//         });
// }

/**
 * Retrieving the predictions for a device on a date
 */
// function socValue(userId) {
function socPredictionsByDeviceInfoDate(userId,mac_address,date) {
    return axios.get(predictionConstants.PREDICTION_SHORT_URL + "/" + mac_address + "?user_id=" + userId + "&date=" + date)
        .then(response => {
            // console.log("response.data.predictions");
            // console.log(response.data.soc_predictions);
            let predictions = response.data.soc_predictions;
            return predictions;
        });
}

function socAdvice(mac_address) {
    let date = formatDate(new Date());
    // console.log("test date soc advice");
    // console.log(date);
    let time = formatTime();
    // console.log("test time soc advice");
    // console.log(time);
    // return test;
    return axios.get(predictionConstants.PREDICTION_SHORT_URL + "/" + mac_address + "/advice?date=" + date + "&time=" + time)
        .then(response => {
            // console.log("response socAdvice");
            // console.log(response.data.soc_advice);
            return response.data.soc_advice;
        });
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
}

function formatTime() {
    var coeff = 1000 * 60 * 15;
    var date = new Date();  //or use any other date
    var rounded = new Date(Math.floor(date.getTime() / coeff) * coeff);
    let hours = rounded.getHours().toString();
    let minutes = rounded.getMinutes().toString();

    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return [hours,minutes].join(':');
}
