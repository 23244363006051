// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AmChart_DatePicker__3JqTJ {\n  background-color: white;\n  padding: 5px;\n  border: 1px solid grey; }\n", ""]);
// Exports
exports.locals = {
	"DatePicker": "AmChart_DatePicker__3JqTJ"
};
module.exports = exports;
