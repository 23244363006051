import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../_services/dataService';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

import classes from './AdditionalBatteriesData.module.scss';

const AdditionalBatteriesData = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [batteries,setBatteries] = useState([]);
    const batteriesId = props.arrBatteries;
    const [acrels,setAcrels] = useState([]);
    const acrelsId = props.arrAcrel;
    const [eastrons,setEastrons] = useState([]);
    const eastronsId = props.arrEastron;
    // console.log("batteries for map");
    // console.log(batteries);
    // console.log("acrels for map in additional data");
    // console.log(acrels);
    // console.log("acrels id's in additional data");
    // console.log(acrelsId);
    // console.log("eastrons for map");
    // console.log(eastrons);

    useEffect(() => {
        // console.log("batteriesId in useeffect");
        // console.log(batteriesId);
        if (batteriesId.length > 0) {
            dataService.lastBatteriesAllValues(batteriesId)
                .then(response => {
                    // console.log("response lastBatteriesValues done");
                    // console.log(Object.values(response));
                    if (response !== 'empty') {
                        setBatteries(Object.values(response));
                    }
                });
        } else {
            setBatteries([]);
        }
        if (acrelsId.length > 0) {
            dataService.lastAcrelsAllValues(acrelsId)
                .then(response => {
                    // console.log("response lastAcrelsAllValues done");
                    // console.log(response);
                    if (response !== 'empty') {
                        setAcrels(Object.values(response));
                    }
                });
        } else {
            setAcrels([]);
        }
        if (eastronsId.length > 0) {
            dataService.lastEastronsAllValues(eastronsId)
                .then(response => {
                    // console.log("response lastEastronsValues done");
                    // console.log(response);
                    if (response === 'empty') {
                        setEastrons([]);
                    } else {
                        setEastrons(Object.values(response));
                    }
                });
        } else {
            setEastrons([]);
        }
    },[batteriesId,acrelsId,eastronsId]);

    return (
        <Aux>
            {/* <div className={`col-md-12 ${classes.AdditionalBatteriesData__list}`}> */}
            {batteries.length > 0 ? (
                batteries.map((battery,idx) => {
                    return (
                        <div className={`${classes.AdditionalBatteriesData}`}>
                            <div className="" key={idx}>
                                <h4>{t('common:datas.battery.battery')} - {battery.mac_address}</h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {battery.date}</p>
                                <div className={`${classes.data}`}>
                                    <p>{t('common:datas.battery.prot_disc_oi')} : <span>{battery.prot_disc_oi ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.prot_chg_oi')} : <span>{battery.prot_chg_oi ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.prot_cell_ut')} : <span>{battery.prot_cell_ut ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.prot_cell_ot')} : <span>{battery.prot_cell_ot ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.prot_cell_uv')} : <span>{battery.prot_cell_uv ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.prot_cell_ov')} : <span>{battery.prot_cell_ov ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.prot_sys_err')} : <span>{battery.prot_sys_err ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_chg_hi')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_chg_hi ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_dischg_hi')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_dischg_hi ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_cell_lt')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_cell_lt ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_cell_ht')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_cell_ht ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_cell_lv')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_cell_lv ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_cell_hv')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_cell_hv ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.alar_comm_fail')}: <span className={battery.alar_chg_hi ? classes.alarm : ""}>{battery.alar_comm_fail ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.mod_cnt')}: <span>{battery.mod_cnt}</span></p>
                                    <p>{t('common:datas.battery.batt_chg_v')}: <span>{battery.batt_chg_v}</span></p>
                                    <p>{t('common:datas.battery.chg_i_limit')}: <span>{battery.chg_i_limit}</span></p>
                                    <p>{t('common:datas.battery.dischg_i_limit')}: <span>{battery.dischg_i_limit}</span></p>
                                    <p>{t('common:datas.battery.soh')} : <span>{battery.soh}</span></p>
                                    <p>{t('common:datas.battery.v')} : <span>{battery.v}</span></p>
                                    <p>{t('common:datas.battery.itot')} : <span>{battery.itot}</span></p>
                                    <p>{t('common:datas.battery.avg_cell_t')}: <span>{battery.avg_cell_t}</span></p>
                                    <p>{t('common:datas.battery.req_charge_enable')}: <span>{battery.req_charge_enable ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.req_dischg_enable')}: <span>{battery.req_dischg_enable ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.req_forcechg_1')}: <span>{battery.req_forcechg_1 ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                    <p>{t('common:datas.battery.req_forcechg_2')}: <span>{battery.req_forcechg_2 ? t('common:terms.yes') : t('common:terms.no')}</span></p>
                                </div>
                            </div>
                        </div>
                    )
                })) : batteriesId.length > 0 ? (
                    // <Spinner />
                    <p>{t('common:datas.no-data')}</p>
                ) : ""
            }
            {/* </div> */}
            {acrels.length > 0 ? (
                acrels.map((acrel,idx) => {
                    return (
                        <div className={`${classes.AdditionalBatteriesData}`}>
                            <div className="" key={idx}>
                                <h4>{t('common:datas.acrel.acrel')} - {acrel.mac_address}</h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {acrel.date}</p>
                                <div className={`${classes.data}`}>
                                    <p>{t('common:datas.acrel.acr_abs_act_en')} : <span>{(acrel.acr_abs_act_en / 1000).toFixed(2)} kW</span></p>
                                    <p>{t('common:datas.acrel.acr_rel_act_en')} : <span>{(acrel.acr_rel_act_en / 1000).toFixed(2)} kW</span></p>
                                    <p>{t('common:datas.acrel.acr_react_en')} : <span>{(acrel.acr_react_en / 1000).toFixed(2)} kW</span></p>
                                    <p>{t('common:datas.acrel.acr_capa_en')} : <span>{acrel.acr_capa_en}</span></p>
                                    <p>{t('common:datas.acrel.frequency')} : <span>{acrel.frequency} Hz</span></p>
                                    <p>{t('common:datas.acrel.acr_pf')} : <span>{acrel.acr_pf}</span></p>
                                    <p>{t('common:datas.acrel.acr_q')} : <span>{acrel.acr_q}</span></p>
                                    <p>{t('common:datas.acrel.acr_s')} : <span>{acrel.acr_s}</span></p>
                                    <p>{t('common:datas.acrel.factor')} : <span>{acrel.factor} %</span></p>
                                </div>
                            </div>
                        </div>
                    )
                })) : acrelsId.length > 0 ? (
                    // <Spinner />
                    <p>{t('common:datas.no-data')}</p>
                ) : ""
            }
            {eastrons.length > 0 ? (
                eastrons.map((eastron,idx) => {
                    return (
                        <div className={`${classes.AdditionalBatteriesData}`}>
                            <div key={idx}>
                                <h4>{t('common:datas.eastron.eastron')} - {eastron.mac_address}</h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {eastron.date}</p>
                                <div className={classes.data}>
                                    {(eastron.voltage1 !== "0.00" && eastron.voltage2 === "0.00" && eastron.voltage3 === "0.00") ? (
                                        <p>{t('common:datas.eastron.voltage')} : <span>{eastron.voltage1} V</span></p>
                                    ) : (
                                        <Aux>
                                            <p>{t('common:datas.eastron.voltage1')} : <span>{eastron.voltage1} V</span></p>
                                            <p>{t('common:datas.eastron.voltage2')} : <span>{eastron.voltage2} V</span></p>
                                            <p>{t('common:datas.eastron.voltage3')} : <span>{eastron.voltage3} V</span></p>
                                        </Aux>
                                    )}
                                    {(eastron.current1 !== "0.00" && eastron.current2 === "0.00" && eastron.current3 === "0.00") ? (
                                        <p>{t('common:datas.eastron.current')} : <span>{eastron.current1} A</span></p>
                                    ) : (
                                        <Aux>
                                            <p>{t('common:datas.eastron.current1')} : <span>{eastron.current1} A</span></p>
                                            <p>{t('common:datas.eastron.current2')} : <span>{eastron.current2} A</span></p>
                                            <p>{t('common:datas.eastron.current3')} : <span>{eastron.current3} A</span></p>
                                        </Aux>
                                    )}
                                    {(eastron.act_pow1 !== "0.00" && eastron.act_pow2 === "0.00" && eastron.act_pow3 === "0.00") ? (
                                        <p>{t('common:datas.eastron.act_pow')} : <span>{eastron.act_pow1} W</span></p>
                                    ) : (
                                        <Aux>
                                            <p>{t('common:datas.eastron.act_pow1')} : <span>{eastron.act_pow1} W</span></p>
                                            <p>{t('common:datas.eastron.act_pow2')} : <span>{eastron.act_pow2} W</span></p>
                                            <p>{t('common:datas.eastron.act_pow3')} : <span>{eastron.act_pow3} W</span></p>
                                        </Aux>
                                    )}
                                    {(eastron.app_pow1 !== "0.00" && eastron.app_pow2 === "0.00" && eastron.app_pow3 === "0.00") ? (
                                        <p>{t('common:datas.eastron.app_pow')} : <span>{eastron.app_pow1} W</span></p>
                                    ) : (
                                        <Aux>
                                            <p>{t('common:datas.eastron.app_pow1')} : <span>{eastron.app_pow1} W</span></p>
                                            <p>{t('common:datas.eastron.app_pow2')} : <span>{eastron.app_pow2} W</span></p>
                                            <p>{t('common:datas.eastron.app_pow3')} : <span>{eastron.app_pow3} W</span></p>
                                        </Aux>
                                    )}
                                    {(eastron.react_pow1 !== "0.00" && eastron.react_pow2 === "0.00" && eastron.react_pow3 === "0.00") ? (
                                        <p>{t('common:datas.eastron.react_pow')} : <span>{eastron.react_pow1} W</span></p>
                                    ) : (
                                        <Aux>
                                            <p>{t('common:datas.eastron.react_pow1')} : <span>{eastron.react_pow1} W</span></p>
                                            <p>{t('common:datas.eastron.react_pow2')} : <span>{eastron.react_pow2} W</span></p>
                                            <p>{t('common:datas.eastron.react_pow3')} : <span>{eastron.react_pow3} W</span></p>
                                        </Aux>
                                    )}
                                    {(eastron.pow_fact1 !== "0.00" && eastron.pow_fact2 === "0.00" && eastron.pow_fact3 === "0.00") ? (
                                        <p>{t('common:datas.eastron.pow_fact')} : <span>{eastron.pow_fact1}</span></p>
                                    ) : (
                                        <Aux>
                                            <p>{t('common:datas.eastron.pow_fact1')} : <span>{eastron.pow_fact1}</span></p>
                                            <p>{t('common:datas.eastron.pow_fact2')} : <span>{eastron.pow_fact2}</span></p>
                                            <p>{t('common:datas.eastron.pow_fact3')} : <span>{eastron.pow_fact3}</span></p>
                                        </Aux>
                                    )}
                                    <p>{t('common:datas.eastron.freq')} : <span>{eastron.freq} Hz</span></p>
                                </div>
                            </div>
                        </div>
                    )
                })) : ""
            }
        </Aux>
    )
};

export default AdditionalBatteriesData;