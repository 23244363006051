import React,{ useEffect,useState } from 'react';
// import { Link } from 'react-router-dom';
import { Formik,Field,Form,ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { systemRuleService } from '../../_services/systemRuleService';
import { Trans,useTranslation } from 'react-i18next';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

import classes from './TriggerForm.module.scss';

import TemporaryPopup from '../UI/ValidationMessage/TemporaryPopup';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import btnRegister from '../../_assets/img/bouton-enregistrer-battery-up.png';
import { triggerService } from '../../_services/triggerService';
import { moduleService } from '../../_services/moduleService';

const TriggerForm = (props) => {
    const { t } = useTranslation(['common','triggerspage']);
    // console.log("props Trigger form");
    // console.log(props);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const ruleId = props.ruleId;
    const moduleId = props.moduleId;
    // const isActive = props.active;
    const [modified,setModified] = useState(false);
    const [module,setModule] = useState([]);
    const [modules,setModules] = useState([]);
    const [min,setMin] = useState(1);
    const [max,setMax] = useState(100);
    const [minMinutes,setMinMinutes] = useState(0);
    const [maxMinutes,setMaxMinutes] = useState(59);
    const [minHours,setMinHours] = useState(0);
    const [maxHours,setMaxHours] = useState(23);
    const [addIndexDevice,setAddIndexDevice] = useState(999999);
    const [initRule,setInitRule] = useState({
        // active: isActive,
        // device: 999999,
        metric: "soc",
        compare: "=",
        value_condition: 10,
        from_hours: 9,
        from_minutes: 0,
        to_hours: 17,
        to_minutes: 0,
        type: "email",
        value_action: "",
        repeat: 1,
        user_id: user.id,
        rule_id: ruleId,
        module_id: moduleId,
        id: "0",
    });
    const metricsNames = {
        pylontech: ["soc","soh"],
        acrel: ["acr_u","acr_i","acr_p"],
        eastron: ["voltage","current"],
    }
    const [metricsList,setMetricsList] = useState(["-"]);

    useEffect(() => {
        // console.log("modules in useeffect form");
        // console.log(props.modules);
        // console.log(modules);
        // setModules(props.modules);

        /* Change modules list if installer */
        if (user.roles.includes('installer')) {
            moduleService.getClientDevices(user.id)
                .then(response => {
                    setModules(response);
                });
        } else {
            setModules(props.modules);
        }

        // moduleService.getByUserId(user.id)
        //     .then(response => {
        //         setModules(response);
        //     });

        if (props.triggerTarget && props.triggerTarget !== "0") {
            triggerService.getTriggerById(props.triggerTarget)
                .then(response => {
                    let indexDevice = modules.map(e => e.mac_address).indexOf(response.mac_address);
                    let protocolId = modules[indexDevice].protocol_id;
                    let list = [];
                    if (protocolId === 1) {
                        list = metricsNames.pylontech;
                    } else if (protocolId === 2 || protocolId === 4) {
                        list = metricsNames.acrel;
                    } else if (protocolId === 3 || protocolId === 5) {
                        list = metricsNames.eastron;
                    }
                    setMetricsList(list);
                    setInitRule({
                        device: indexDevice,
                        metric: response.conditions[0].metric,
                        compare: response.conditions[0].compare,
                        value_condition: response.conditions[0].value,
                        from_hours: parseInt(response.actions[0].time_interval.from.split(':')[0]),
                        from_minutes: parseInt(response.actions[0].time_interval.from.split(':')[1]),
                        to_hours: parseInt(response.actions[0].time_interval.to.split(':')[0]),
                        to_minutes: parseInt(response.actions[0].time_interval.to.split(':')[1]),
                        type: response.actions[0].type,
                        value_action: response.actions[0].value,
                        repeat: parseInt(response.actions[0].repeat),
                        user_id: parseInt(response.user_id),
                        rule_id: ruleId,
                        module_id: moduleId,
                        id: response.id,
                    });
                });
        } else {
            setInitRule({
                device: addIndexDevice,
                metric: "soc",
                compare: "=",
                value_condition: 10,
                from_hours: 9,
                from_minutes: 0,
                to_hours: 17,
                to_minutes: 0,
                type: "email",
                value_action: "",
                repeat: 1,
                user_id: user.id,
                rule_id: ruleId,
                module_id: moduleId,
                id: "0",
            });
        }
    },[props.triggerTarget,addIndexDevice,props.modules]);

    const deviceSelectChange = (e,setFieldValue) => {
        const { name,value } = e.target;
        console.log("value in device select");
        console.log(value);
        setAddIndexDevice(value);
        if (parseInt(value) !== 999999) {
            let protocolId = modules[value].protocol_id;
            let list = [];
            if (protocolId === 1) {
                list = metricsNames.pylontech;
            } else if (protocolId === 2 || protocolId === 4) {
                list = metricsNames.acrel;
            } else if (protocolId === 3 || protocolId === 5) {
                list = metricsNames.eastron;
            }
            setMetricsList(list);
        } else {
            setMetricsList(["-"]);
        }
    }

    return (
        props.triggerTarget ? (
            <div id="triggerForm" className={` ${classes.TriggerForm}`}>
                <h3>{props.triggerTarget !== "0" ? t('triggerspage:TriggerForm.title-edit') : t('triggerspage:TriggerForm.title-add')}</h3>
                <Formik
                    enableReinitialize
                    setFieldValue
                    initialValues={initRule}
                    validationSchema={Yup.object({
                        device: Yup.number(),
                        metric: Yup.string()
                            .trim()
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        compare: Yup.string()
                            .trim()
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        value_condition: Yup.number()
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        from_hours: Yup.number()
                            .min(minHours,<p className="errorMessage">{t('common:forms.error-hours-min')}</p>)
                            .max(maxHours,<p className="errorMessage">{t('common:forms.error-hours-max')}</p>)
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        to_hours: Yup.number()
                            .min(minHours,<p className="errorMessage">{t('common:forms.error-hours-min')}</p>)
                            .max(maxHours,<p className="errorMessage">{t('common:forms.error-hours-max')}</p>)
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        from_minutes: Yup.number()
                            .min(minMinutes,<p className="errorMessage">{t('common:forms.error-minutes-min')}</p>)
                            .max(maxMinutes,<p className="errorMessage">{t('common:forms.error-minutes-max')}</p>)
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        to_minutes: Yup.number()
                            .min(minMinutes,<p className="errorMessage">{t('common:forms.error-minutes-min')}</p>)
                            .max(maxMinutes,<p className="errorMessage">{t('common:forms.error-minutes-max')}</p>)
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        type: Yup.string()
                            .trim()
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        value_action: Yup.string()
                            .trim()
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                        repeat: Yup.number()
                            .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                    })}
                    onSubmit={(values,{ setSubmitting }) => {
                        setTimeout(() => {
                            console.log("values submitted");
                            console.log(values);
                            console.log("modules");
                            console.log(modules);
                            let selectedDevice = modules[values.device];
                            console.log("selectedDevice");
                            console.log(selectedDevice);
                            values.user_id = values.user_id.toString();
                            values.mac_address = selectedDevice.mac_address;
                            values.protocol = selectedDevice.protocol_id;
                            values.protocol_version = selectedDevice.protocol.version;
                            values.timezone = selectedDevice.address.timezone;
                            console.log("values updated");
                            console.log(values);

                            if (values.id === "0") {
                                triggerService.registerTrigger(values)
                                    .then(() => {
                                        // setModified(true);
                                        console.log("trigger form did its job => ADD");
                                        props.handleTriggerAddition("add");
                                        // window.location.reload(true);
                                    });
                            } else {
                                triggerService.editTrigger(values)
                                    .then(() => {
                                        // setModified(true);
                                        console.log("trigger form did its job => EDIT");
                                        props.handleTriggerAddition("edit");
                                        // window.location.reload(true);
                                    });
                            }
                        },400);
                    }}
                >
                    <Form style={{ textAlign: 'left' }}>
                        <div className={`row ${classes.formSectionSeparator}`}>
                            <div className="col-12">
                                <h4>{t('triggerspage:TriggerForm.module')}</h4>
                                <p className={classes.formSectionSeparator__text}>
                                    <Trans i18nKey="triggerspage:TriggerForm.instructions-device">
                                        Sélectionnez un de vos modules.<span>Attention ! La liste des données disponibles pour la condition (voir ci-dessous) dépend de l'appareil auquel est branché votre module. En sélectionnant un autre module le champ "Donnée" sera donc réinitialisé.</span>
                                    </Trans>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6 col-lg-4">
                                {/* <h4>{t('triggerspage:TriggerForm.module')}</h4> */}
                                {/* <label htmlFor="module">{t('triggerspage:TriggerForm.module')}</label> */}
                                <Field as="select" name="device" className="form-control" onChange={deviceSelectChange}>
                                    {/* <Field as="select" name="device" className="form-control"> */}
                                    {/* <Field as="select" name="module" className="form-control" onChange={(e) => { handleChange(e); deviceSelectChange(value,setFieldValue); }}> */}
                                    <option value="999999">-</option>
                                    {modules.map((module,idx) => {
                                        return (<option
                                            value={idx}
                                            key={idx}
                                        >
                                            {module.module_name}{module.user ? ` (${module.user.firstname} ${module.user.surname})` : ""}
                                        </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage name="device" />
                            </div>
                        </div>
                        <div className={`row ${classes.formSectionSeparator}`}>
                            <div className="col-12">
                                <h4>{t('triggerspage:TriggerForm.condition')}</h4>
                                <p className={classes.formSectionSeparator__text}>
                                    <Trans i18nKey="triggerspage:TriggerForm.instructions-condition">
                                        Une condition est composée de trois éléments: une donnée récupérée par le module, un comparatif et une valeur.<span>Par exemple: SOC &gt; 70</span>
                                    </Trans>
                                </p>
                            </div>
                        </div>
                        {/* <div className="form-group col-12" style={{ margin: '10px',width: '30%',float: 'left',color: "#3d009e" }}> */}
                        <div className="row">
                            <div className="form-group col-4">
                                <label htmlFor="metric">{t('triggerspage:TriggerForm.input-metric-label')}</label>
                                {/* <Field name="metric" type="text" className="form-control" /> */}
                                <Field as="select" name="metric" className="form-control">
                                    {/* <option value="empty">empty</option> */}
                                    {/* <option value="soc">SOC</option>
                                    <option value="soh">SOH</option> */}
                                    {metricsList.map((metric,idx) => {
                                        return (<option
                                            value={metric}
                                            key={idx}
                                        >
                                            {metric}
                                        </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage name="metric" />
                                <p className={classes.fieldInfo}>{t('triggerspage:TriggerForm.input-metric-help')}</p>
                            </div>
                            <div className="form-group col-4">
                                <label htmlFor="compare">{t('triggerspage:TriggerForm.input-compare-label')}</label>
                                {/* <Field name="compare" type="text" className="form-control" /> */}
                                <Field as="select" name="compare" className="form-control">
                                    <option value=">">{">"}</option>
                                    <option value="=">{"="}</option>
                                    <option value="<">{"<"}</option>
                                </Field>
                                <ErrorMessage name="compare" />
                                <p className={classes.fieldInfo}>{t('triggerspage:TriggerForm.input-compare-help')}</p>
                            </div>
                            <div className="form-group col-4">
                                {/* <label htmlFor="value_condition">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                <label htmlFor="value_condition">{t('triggerspage:TriggerForm.input-value-condition-label')}</label>
                                <Field name="value_condition" type="number" className="form-control" />
                                <ErrorMessage name="value_condition" />
                                <p className={classes.fieldInfo}>{t('triggerspage:TriggerForm.input-value-condition-help')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>{t('triggerspage:TriggerForm.time')}</h4>
                                <p>{t('triggerspage:TriggerForm.instructions-time')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className={`col-12 ${classes.timeWindow}`}>
                                <div className={`form-group ${classes.timeWindow__field}`}>
                                    {/* <label htmlFor="value_condition">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                    <label htmlFor="from_hours">{t('triggerspage:TriggerForm.input-from-label')}</label>
                                    <Field name="from_hours" type="number" className="form-control" min="0" max="23" />
                                    <ErrorMessage name="from_hours" />
                                </div>
                                <span className={classes.timeWindow__hourSeparator}>{t('triggerspage:TriggerForm.input-time-separator')}</span>
                                <div className={`form-group ${classes.timeWindow__field}`}>
                                    {/* <label htmlFor="value_condition">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                    {/* <label htmlFor="from_minutes">{t('triggerspage:TriggerForm.input-value-condition-label')}</label> */}
                                    <Field name="from_minutes" type="number" className="form-control" min="0" max="59" />
                                    <ErrorMessage name="from_minutes" />
                                </div>
                                <div className={`form-group ${classes.timeWindow__field}`}>
                                    {/* <label htmlFor="value_condition">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                    <label htmlFor="to_hours">{t('triggerspage:TriggerForm.input-to-label')}</label>
                                    <Field name="to_hours" type="number" className="form-control" min="0" max="23" />
                                    <ErrorMessage name="to_hours" />
                                </div>
                                <span className={classes.timeWindow__hourSeparator}>{t('triggerspage:TriggerForm.input-time-separator')}</span>
                                <div className={`form-group ${classes.timeWindow__field}`}>
                                    {/* <label htmlFor="value_condition">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                    {/* <label htmlFor="to_minutes">{t('triggerspage:TriggerForm.input-value-condition-label')}</label> */}
                                    <Field name="to_minutes" type="number" className="form-control" min="0" max="59" />
                                    <ErrorMessage name="to_minutes" />
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>{t('triggerspage:TriggerForm.action')}</h4>
                                <p>{t('triggerspage:TriggerForm.instructions-action')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-xs-12 col-lg-4">
                                <label htmlFor="type">{t('triggerspage:TriggerForm.input-type-label')}</label>
                                {/* <Field name="type" type="text" className="form-control" /> */}
                                <Field as="select" name="type" className="form-control">
                                    <option value="email">{t('triggerspage:TriggerForm.input-type-option-email')}</option>
                                    {/* <option value="sms">{t('triggerspage:TriggerForm.input-type-option-sms')}</option> */}
                                    {/* <option value="notification">{t('triggerspage:TriggerForm.input-type-option-notification')}</option> */}
                                </Field>
                                <ErrorMessage name="type" />
                                <p className={classes.fieldInfo}>{t('triggerspage:TriggerForm.input-type-help')}</p>
                            </div>
                            <div className="form-group col-xs-12 col-lg-4">
                                <label htmlFor="value_action">{t('triggerspage:TriggerForm.input-value-action-label')}</label>
                                <Field name="value_action" type="text" className="form-control" placeholder="Email or tel nbr" />
                                <ErrorMessage name="value_action" />
                                <p className={classes.fieldInfo}>{t('triggerspage:TriggerForm.input-value-action-help')}</p>
                            </div>
                            <div className="form-group col-xs-12 col-lg-4">
                                {/* <label htmlFor="value_action">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                                <label htmlFor="repeat">{t('triggerspage:TriggerForm.input-repeat-label')}</label>
                                {/* <Field name="repeat" type="number" className="form-control" /> */}
                                <Field as="select" name="repeat" className="form-control">
                                    <option value="-1">{t('triggerspage:TriggerForm.input-repeat-option-unlimited')}</option>
                                    <option value="0">{t('triggerspage:TriggerForm.input-repeat-option-deactivated')}</option>
                                    <option value="1">{t('triggerspage:TriggerForm.input-repeat-option-once')}</option>
                                    <option value="2">{t('triggerspage:TriggerForm.input-repeat-option-twice')}</option>
                                    <option value="5">{t('triggerspage:TriggerForm.input-repeat-option-five')}</option>
                                    <option value="10">{t('triggerspage:TriggerForm.input-repeat-option-ten')}</option>
                                </Field>
                                <ErrorMessage name="repeat" />
                                <p className={classes.fieldInfo}>{t('triggerspage:TriggerForm.input-repeat-help')}</p>
                            </div>
                        </div>
                        <Field type="hidden" name="mac_address" />
                        <Field type="hidden" name="module_protocol" />
                        <Field type="hidden" name="module_timezone" />
                        <Field type="hidden" name="id" />
                        {/* </Aux>
                    ) : <Spinner />} */}

                        <button className={classes.submitButton} type="submit" style={{ background: `url(${btnRegister}) center center no-repeat`,backgroundSize: 'contain',width: "180px",height: '70px',border: 'none',padding: '0',display: 'block',float: 'right' }}></button>
                        <div style={{ clear: "both" }}></div>
                    </Form>
                </Formik>
            </div >
        ) : ""
    );
}

export default TriggerForm;