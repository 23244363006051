import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import { Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../_assets/css/color/color-default.scss';
import classes from './PoweredBy.module.scss';

class PoweredBy extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="poweredBy" className={`angle-color-bg ${classes.PoweredBy}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className="text-center">
                                    <h2 style={{ color: "#1a1a1a",marginBottom: "20px" }}>{t('homepage:PoweredBy.directed-by')}</h2>
                                    <img src={require("../../_assets/img/Logo-solar-tech-vector-bw.png")} alt={t('homepage:PoweredBy.img-logo-alt')} style={{ width: '300px',float: "center",marginBottom: "50px" }} />
                                    <p style={{ lineHeight: "20px" }}>{t('homepage:PoweredBy.powered-by-p1')}</p>
                                    <p style={{ lineHeight: "20px" }}>{t('homepage:PoweredBy.powered-by-p2')}</p>
                                    <p style={{ lineHeight: "20px" }}>{t('homepage:PoweredBy.powered-by-p3')}</p>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','homepage'])(PoweredBy);