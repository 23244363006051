import React,{ useEffect,useState,useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import { userActions } from '../../_actions/userActions';
import { userService } from '../../_services/userService';
// import { protocolService } from '../../_services/protocolService';
import { sermatecService } from '../../_services/sermatecService';
import { countryService } from '../../_services/countryService';
import { addressService } from '../../_services/addressService';
import { history } from '../../_helpers/history';

import Auxil from '../../_hoc/Auxil/Auxil';

const RegisterSermatecForm = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const [clients,setClients] = useState([]);
    // const [protocols,setProtocols] = useState([]);
    const [countries,setCountries] = useState([]);
    const [userIsClient,setUserIsClient] = useState(true);
    // const queryString = require('query-string');
    // const idSelect = props.idSelect;
    const [targetId,setTargetId] = useState(0);
    const [target,setTarget] = useState(false);
    const [targetHasAddress,setTargetHasAddress] = useState(false);
    const [showUserSelect,setShowUserSelect] = useState(false);
    const [selectedAddress,setSelectedAddress] = useState([]);
    const [showAddressForm,setShowAddressForm] = useState(false);
    const formRef = useRef();
    const [initForm,setInitForm] = useState({
        user: targetId,
        serial_number: '',
        // protocol: '',
        address_id: '',
        line1: '',
        line2: '',
        street_number: '',
        zipcode: '',
        locality: '',
        country_id: '',
        existing_id: 0
        // user_id: targetId
    });


    useEffect(() => {
        if(!user.roles.includes('installer') && !user.roles.includes('super-admin') && !user.roles.includes('admin') && user.roles.includes('client')) {
            setUserIsClient(true);
        }
        if(user.roles.includes('installer') && !user.roles.includes('super-admin') && !user.roles.includes('admin')) {
            console.log("c1 true");
            userService.getClients()
                .then(response => {
                    console.log('response getClients');
                    setClients(response);
                    setUserIsClient(false);
                    setShowUserSelect(true);
                });
        }
        if(user.roles.includes('super-admin') || user.roles.includes('admin')) {
            console.log("c2 true");
            userService.getAll()
                .then(response => {
                    console.log('response getAll');
                    setClients(response);
                    setUserIsClient(false);
                    setShowUserSelect(true);
                });
            // dispatch(userActions.getSermatecs());
        }
        // protocolService.getAll()
        //     .then(response => {
        //         console.log('response getAll');
        //         setProtocols(response);
        // });
        countryService.getAll()
            .then(response => {
                console.log('response getAll');
                setCountries(response);
            });
        if(userIsClient) {
            setShowUserSelect(false);
            setTargetId(user.id)
            userService.getById(user.id)
                .then(response=> {
                    setTarget(response);
                    if(response.addresses.length > 0) {
                        setTargetHasAddress(true);
                        setShowAddressForm(false);
                    }else {
                        setTargetHasAddress(false);
                        setShowAddressForm(true);
                    }
                });
        }else {
            setShowUserSelect(true);
        }
    },[]);
    console.log('test typeof user id');
    console.log(typeof user.id);
    console.log(user.id);
    console.log("countries in register sermatec");
    console.log(countries);

    function userSelectChange(e) {
        const { name,value } = e.target;
        console.log("value in user select");
        console.log(value);
        setTargetId(value);
        userService.getById(value)
            .then(response=> {
                setTarget(response);
                if(response.addresses.length > 0) {
                    setTargetHasAddress(true);
                }else {
                    setTargetHasAddress(false);
                }
            });
    }

    function addressSelectChange(e) {
        console.log("formRef select change");
        console.log(formRef.current.values);
        const serial_number = formRef.current.values.serial_number;
        // const protocol = formRef.current.values.protocol;
        const { name,value } = e.target;
        setSelectedAddress(value);
        let address = target.addresses.findIndex(item => item.id === parseInt(value));
        console.log("serial_number select change");
        console.log(serial_number);
        // console.log("protocol select change");
        // console.log(protocol);
        if(parseInt(value) === 99999) {
            setInitForm({
                user: targetId,
                serial_number: serial_number,
                // protocol: protocol,
                address_id: '',
                line1: '',
                line2: '',
                street_number: '',
                zipcode: '',
                locality: '',
                country_id: '',
                existing_id: 0
            });
            setShowAddressForm(true);
        }else {
            setInitForm({
                user: targetId,
                serial_number: serial_number,
                // protocol: protocol,
                address_id: '',
                line1: target.addresses[address].line1,
                line2: target.addresses[address].line2,
                street_number: target.addresses[address].street_number,
                zipcode: target.addresses[address].zipcode,
                locality: target.addresses[address].locality,
                country_id: target.addresses[address].country.id,
                existing_id: value
            });
            setShowAddressForm(false);
        }
    }

    const userSelect = (
        <div className="form-group" style={{ margin: '10px' }}>
            <label htmlFor="user">User</label>
            <select name="userSelect" onChange={userSelectChange} value={targetId}>
                <option value="0">-</option>
                {clients.map((client,idx) => {
                    return(<option
                        selected
                        value={client.id}
                        key={idx}
                    >
                        {client.firstname} {client.surname}
                    </option>)
                })}
            </select>
        </div>
    )

    const addressSelect = targetHasAddress ? (
        <div className="form-group" style={{ margin: '10px' }}>
            <label htmlFor="user">Address</label>
            <select name="addressSelect" onChange={addressSelectChange} value={selectedAddress}>
                <option value="0">-</option>
                <option value="99999">Add new address</option>
                {target.addresses.map((address,idx) => {
                    return(<option
                        selected
                        value={address.id}
                        key={idx}
                    >
                        {address.line1}, {address.street_number}, {address.zipcode} {address.locality}, {address.country.name_en}
                    </option>)
                })}
            </select>
        </div>
    ) : "";

    return (
        <section id="registerSermatecForm" className="app-about-section angle-sp" style={{marginTop:"100px", paddingTop: '0'}}>
            <div className="container">
                {/* <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <img src={require("../../_assets/img/IMG-vectoriel-site-battery-UP-register.png")} alt="App About Image" style={{width: '600px',float: "center"}} />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h2>Register a sermatec</h2>
                        {showUserSelect ? userSelect : ""}

                        <Formik
                            enableReinitialize
                            innerRef={formRef}
                            initialValues={initForm}
                            validationSchema={Yup.object({
                                user: Yup.number()
                                    .required('Required'),
                                serial_number: Yup.string()
                                    .trim()
                                    .length(12, 'Must be 12 characters')
                                    .matches(/^[A-Z0-9]+$/, { message: 'Only capital letters and numbers' })
                                    .required(<p className="errorMessage">Required</p>),
                                // protocol: Yup.number()
                                //     .required('Required'),
                                line1: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">This field is required</p>),
                                line2: Yup.string()
                                    .trim(),
                                street_number: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">This field is required</p>),
                                zipcode: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">This field is required</p>),
                                locality: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">This field is required</p>),
                                country_id: Yup.number()
                                    .min(1, <p className="errorMessage">You must select a country</p>)
                                    .required(<p className="errorMessage">This field is required</p>),
                                existing_id: Yup.number(),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    // alert(JSON.stringify(values, null, 2));
                                    // console.log("before dispatch");
                                    // console.log(JSON.stringify(values, null, 2));
                                    let countryIndex = countries.findIndex(item => item.id === parseInt(values.country_id));
                                    // console.log("countryObj in treatment");
                                    // console.log(countryIndex);
                                    let country = countries[countryIndex].name_en;
                                    // console.log("country in treatment");
                                    // console.log(country);
                                    values.country = country;
                                    // console.log("values in treatment");
                                    // console.log(values);
                                    addressService.getLatLng(values)
                                        .then(response => {
                                            console.log(response);
                                            values.latitude = response.lat;
                                            values.longitude = response.lng;
                                        })
                                        .then( () => {
                                            // alert(JSON.stringify(values, null, 2));
                                            console.log("before dispatch");
                                            console.log(JSON.stringify(values, null, 2));
                                            let countryIndex = countries.findIndex(item => item.id === parseInt(values.country_id));
                                            let country = countries[countryIndex].name_en;
                                            values.country = country;
                                            if(values.user === 0) {
                                                values.user = user.id;
                                            }
                                            sermatecService.registerSermatec(values)
                                                .then( () => {
                                                    if(!user.roles.includes('super-admin') && !user.roles.includes('admin') && !user.roles.includes('installer')) {
                                                        history.push('/my-profile');
                                                    } else {
                                                        history.push('/users');
                                                    }
                                                });
                                        });
                                }, 400);
                            }}
                        >
                            <Form>
                                <div className="form-group" style={{ margin: '10px' }}>
                                    <label htmlFor="serial_number">Serial Number</label>
                                    <Field name="serial_number" type="text" className="form-control" />
                                    <ErrorMessage name="serial_number" />
                                </div>
                                {/* <div className="form-group" style={{ margin: '10px' }}>
                                    <label htmlFor="protocol">Protocol</label>
                                    <Field as="select" name="protocol">
                                        <option value="0">-</option>
                                        {protocols.map((protocol,idx) => {
                                            return(<option
                                                selected
                                                value={protocol.id}
                                                key={idx}
                                            >
                                                {protocol.brand} - {protocol.product} - {protocol.version}
                                            </option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="protocol" />
                                </div> */}
                                {addressSelect}
                                {showAddressForm ? (
                                    <Auxil>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="line1">Address line 1</label>
                                            <Field name="line1" type="text" className="form-control" />
                                            <ErrorMessage name="line1" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="line2">Address line 2</label>
                                            <Field name="line2" type="text" className="form-control" />
                                            <ErrorMessage name="line2" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="street_number">Street number</label>
                                            <Field name="street_number" type="street_number" className="form-control" />
                                            <ErrorMessage name="street_number" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="zipcode">Zipcode</label>
                                            <Field name="zipcode" type="text" className="form-control" />
                                            <ErrorMessage name="zipcode" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="locality">Locality</label>
                                            <Field name="locality" type="text" className="form-control" />
                                            <ErrorMessage name="locality" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px' }}>
                                            <label htmlFor="country_id">Country</label>
                                            <Field as="select" name="country_id">
                                            <option value="0">-</option>
                                            {countries.map((country,idx) => {
                                                return(<option
                                                    selected
                                                    value={parseInt(country.id)}
                                                    key={idx}
                                                >
                                                    {country.name_en}
                                                </option>
                                                );
                                            })}
                                            </Field>
                                            <ErrorMessage name="country_id" />
                                        </div>
                                    </Auxil>
                                ) : (
                                    <Auxil>
                                        <Field type="hidden" name="line1" />
                                        <Field type="hidden" name="line2" />
                                        <Field type="hidden" name="street_number" />
                                        <Field type="hidden" name="zipcode" />
                                        <Field type="hidden" name="locality" />
                                        <Field type="hidden" name="country_id" />
                                    </Auxil>
                                )}
                                {/* <p>Already registered ? <Link to="/login" className="btn btn-link">Login</Link></p> */}
                                <button className="btn btn-primary" type="submit">Submit</button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
   );
};

export default RegisterSermatecForm;
