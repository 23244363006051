import React,{ useEffect,useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import classes from './StepMetrics.module.scss';

const StepMetrics = (props) => {
    // console.log("metricsFields");
    // console.log(metricsFields);
    // const fields = props.fields;
    // const test = metricsFields[0];
    const [fields,setFields] = useState([]);
    const [cannotBeSubmitted,setcannotBeSubmitted] = useState(true);
    const handleSubmit = (values) => {
        props.next(values);
    };
    const onTrigger = (values) => {
        props.parentCallback(values);
        // event.preventDefault();
    }
    const validYupSoc = fields.includes("soc") ? metricsFields[0]["soc"]["validation"] : "";
    const validYupCurrent = fields.includes("current") ? metricsFields[0]["current"]["validation"] : "";
    const [installFields,setInstallFields] = useState([]);
    const [testValidation,setTestValidation] = useState({
        soc: metricsFields[0]["soc"]["validation"],
        current: metricsFields[0]["current"]["validation"],
    })

    useEffect(() => {
        for (const [key,value] of Object.entries(metricsFields[0])) {
            // console.log("key, value");
            // console.log(key,value);
            if (props.pylontech.length !== 0) {
                if (value.linked === "pylontech" && !installFields.includes(key)) {
                    setInstallFields(installFields => [...installFields,key]);
                }
            }
            if (props.acrel.length !== 0) {
                if (value.linked === "acrel" && !installFields.includes(key)) {
                    setInstallFields(installFields => [...installFields,key]);
                }
            }
            if (props.eastron.length !== 0) {
                if (value.linked === "eastron" && !installFields.includes(key)) {
                    setInstallFields(installFields => [...installFields,key]);
                }
            }
        }
        console.log("props.data");
        console.log(props.data);
        console.log("current step");
        console.log(props.currentStep);
        // let arrayData = Object.entries(props.data);
        // if (props.currentStep === 0 && installFields.length > 0) {
        if (props.currentStep === 0) {
            let arrayData = [];
            for (const [key,value] of Object.entries(props.data)) {
                arrayData[key] = value;
            }
            // console.log("arrayData");
            // console.log(arrayData);
            // props.data.forEach(element => {
            //     if (element !== "") {
            //         console.log("element");
            //     }
            // });
            Object.entries(props.data).forEach(entry => {
                const [key,value] = entry;
                let targetCheckboxName = '';
                if (value !== "") {
                    targetCheckboxName = "activate_" + key;
                    let targetCheckbox = document.getElementById(targetCheckboxName);
                    if (targetCheckbox && !targetCheckbox.checked) {
                        targetCheckbox.click();
                    }
                }
            });
        }

    },[props.data,props.currentStep,props.pylontech,props.acrel,props.eastron]);

    const handleChange = (e) => {
        let changedMetric = e.target.name.split("_").pop();
        let targetLabel = "label_" + changedMetric;
        let myElement = document.getElementById(targetLabel);
        if (e.target.checked) {
            setcannotBeSubmitted(false);
            setFields(fields => [...fields,changedMetric]);
            myElement.innerHTML = "ON";
            myElement.style.textAlign = "left";
            myElement.style.paddingLeft = "7px";
            myElement.style.paddingRight = "0";
        } else {
            if (fields.length < 2) {
                setcannotBeSubmitted(true);
            }
            setFields(fields.filter(item => item !== changedMetric));
            myElement.innerHTML = "OFF";
            myElement.style.textAlign = "right";
            myElement.style.paddingLeft = "0";
            myElement.style.paddingRight = "4px";
        }
    }

    // console.log("metricsFields");
    // console.log(metricsFields);
    // console.log("metricsFields");
    // console.log(metricsFields[0]);
    console.log("installFields");
    console.log(installFields);
    console.log("{metricsFields[0].installField.label}");
    console.log(metricsFields[0].soc.label);
    // console.log("props.pylontech");
    // console.log(props.pylontech);
    // console.log("props.acrel");
    // console.log(props.acrel);
    // console.log("props.eastron");
    // console.log(props.eastron);

    return (
        <div className={`${classes.scenarioStep} ${classes.scenarioStepMetrics}`}>
            <h2 className={`${classes.scenarioStep__title} ${classes.scenarioStepMetrics__title}`}>Step weather</h2>
            <div className={classes.scenarioStep__list}>
                {/* <div className={classes.scenarioStepMetrics__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_soc" name="activate_soc" /><label className={`${classes.switchLabel}`} id="label_soc" for="activate_soc">OFF</label><span>State of charge</span></div>
                <div className={classes.scenarioStepMetrics__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_current" name="activate_current" /><label className={`${classes.switchLabel}`} id="label_current" for="activate_current">OFF</label><span>Current</span></div>
                <div className={classes.scenarioStepMetrics__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_third" name="activate_third" /><label className={`${classes.switchLabel}`} id="label_third" for="activate_third">OFF</label><span>Third metric</span></div> */}
                {installFields.map((installField,idx) => {
                    return (
                        <div className={classes.scenarioStep__item}><input type="checkbox" onChange={e => handleChange(e)} id={`activate_` + installField} name={`activate_` + installField} /><label className={`${classes.switchLabel}`} id={`label_` + installField} for={`activate_` + installField}>OFF</label><span>{metricsFields[0][installField].label}</span></div>
                    )
                })}
            </div>
            <Formik
                validationSchema={Yup.object({
                    soc: validYupSoc,
                    current: validYupCurrent,
                })}
                // validationSchema={Yup.object(testValidation)}
                initialValues={props.data}
                // onSubmit={handleSubmit}
                onSubmit={onTrigger}
            >
                {({ values }) => (
                    <Form>
                        {fields.map((field,idx) => {
                            return (
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor={field}>{metricsFields[0][field]["label"]}</label>
                                    <Field name={`${field}_comp`} as="select">
                                        <option value="lt">&lt;</option>
                                        <option value="equal">=</option>
                                        <option value="gt">&gt;</option>
                                    </Field>
                                    <Field name={field} type={metricsFields[0][field]["type"]} className="form-control" />
                                    <ErrorMessage name={field} />
                                </div>
                            )
                        })}
                        {/* <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                        <label htmlFor="soc">State of charge</label>
                        <Field name="soc" type="text" className="form-control" />
                        <ErrorMessage name="soc" />
                    </div> */}

                        <button type="button" onClick={() => props.prev(values)}>
                            Back
                        </button>
                        <button type="submit" disabled={cannotBeSubmitted}>Next</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default StepMetrics;