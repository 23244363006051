import React,{ Component } from 'react';

import ReactWOW from 'react-wow';
import classes from './IntroApp.module.scss';
import imgIntro from '../../_assets/img/IMG-header-site-battery-UP-22.png';

class IntroApp extends Component {
    render() {
        return (
            <section id="introApp" className="app-about-section angle-sp" style={{paddingTop: '0'}}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                
                        <div className="col-lg-8">
                            <img src={require("../../_assets/img/IMG-application-batterie-UP-monitoring-a-modifier.png")} alt="App About Image"/>
                        </div>

                        <div className="col-lg-4" >
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className="text-center">
                                    <h2>Le monitoring batterie simple et connecté WiFi</h2>
                                    <p> <strong>Une connexion Web et immédiate à votre parc batterie.</strong></p>
                                    <br></br>
                                    <ul className={classes.customUl}>
                                        <li><span>• Entièrement gratuite</span></li>
                                        <li><span>• Données stockées 30 jours</span></li>
                                        <li><span>• Enregistrement toutes les 5 minutes</span></li>
                                        <li><span>• Partage de compte familiale</span></li>
                                    </ul>
                                    </div>
                                    <br></br>
                                    <br></br>
                                <div>
                                    <img src={require("../../_assets/img/IMG-icone-batterie-UP-monitoring2.png")}/>
                                    <h3>Données temps réel</h3>
                                    <p classe="donnees">Via votre Wi-Fi, connectez-vous en direct à votre boitier Battery UP et visualisez l’ensemble des flux d’énergie instantanée.</p>
                                </div>
                                <br></br>
                                <div>
                                     <img src={require("../../_assets/img/IMG2-icone-batterie-UP-monitoring2.png")}/>
                                     <h3>Suivi `` Everywhere``</h3>
                                     <p classe="donnees">Accédez en tous lieux à vos données sur le Cloud via votre application Mobile.</p>
                                </div>
                                <br></br>
                                
                                <div>
                                <img src={require("../../_assets/img/IMG3-icone-batterie-UP-monitoring2.png")}/>
                                    <h3  style={{textAlign:"left"}}>Alertes sur votre mobile</h3>
                                    <p classe="donnees">Configurez et recevez vos différentes alertes IFTTT sur votre Mobile.</p>
                                </div>

                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default IntroApp;