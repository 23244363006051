import React from 'react';

import { useTranslation } from 'react-i18next';

import Aux from '../../../_hoc/Auxil/Auxil';

import classes from './SimpleCard.module.scss';

const SimpleCard = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    // const icons = {
    //     power: require('../../../_assets/img/icons/data/power_form_blue.png'),
    //     test: require('../../../_assets/img/icons/data/production.png'),
    // };
    const icons = {
        battery: {
            power: require('../../../_assets/img/icons/data/battery/power.png'),
            current: require('../../../_assets/img/icons/data/battery/current.png'),
            production: require('../../../_assets/img/icons/data/battery/production.png'),
            consumption: require('../../../_assets/img/icons/data/battery/consumption.png'),
            soc: require('../../../_assets/img/icons/data/battery/soc.png'),
            alert: require('../../../_assets/img/icons/data/battery/alert.png'),
            clock: require('../../../_assets/img/icons/data/battery/clock.png'),
        },
        energy_meter: {
            power: require('../../../_assets/img/icons/data/energy_meter/power.png'),
            current: require('../../../_assets/img/icons/data/energy_meter/current.png'),
            production: require('../../../_assets/img/icons/data/energy_meter/production.png'),
            consumption: require('../../../_assets/img/icons/data/energy_meter/consumption.png'),
            soc: require('../../../_assets/img/icons/data/energy_meter/soc.png'),
            alert: require('../../../_assets/img/icons/data/energy_meter/alert.png'),
            clock: require('../../../_assets/img/icons/data/energy_meter/clock.png'),
        },
    };
    const imagesAlt = {
        power: t('common:datas.icons.power'),
        current: t('common:datas.icons.current'),
        production: t('common:datas.icons.production'),
        consumption: t('common:datas.icons.consumption'),
        soc: t('common:datas.icons.soc'),
        alert: t('common:datas.icons.alert'),
        clock: t('common:datas.icons.clock'),
    };

    // const test = "../../../_assets/img/icons/data/power.png";
    // console.log("icons['power']");
    // console.log(icons['power']);
    const title = props.title;
    const icon = props.icon;
    const type = props.type;
    const value = props.value;
    const value2 = props.value2;
    const unit = props.unit;
    const unit2 = props.unit2;
    const tooltip = props.tooltip;

    return (
        <div className={type === "battery" ? `${classes.SimpleCard} ${classes.SimpleCardBattery}` : `${classes.SimpleCard} ${classes.SimpleCardEnergyMeter}`}>
            <img className={classes.icon} src={icons[type][icon]} alt={imagesAlt[icon]} />
            <h4 className={classes.cardTitle}>{title}</h4>
            <div className={classes.cardContent}>
                <div className={classes.cardValues}>
                    {/* <div className={type === "battery" ? `${classes.cardValues__item} ${classes.battery}` : `${classes.cardValues__first} ${classes.energyMeter}`}> */}
                    <div className={classes.cardValues__item}>
                        {/* <span>{value}</span> */}
                        {/* <p className={classes.value}>{value}</p>
                        <p className={classes.unit}>{unit}</p> */}
                        {(typeof (value) != "undefined" && value !== "null" && value !== null) ? (<p className={classes.value}>{value} {unit}</p>) : (<p className={classes.valueNotCommunicated}>{t('common:datas.not-communicated')}</p>)}
                        {/* {value !== "null" ? (<p className={classes.value}>{value} {unit}</p>) : (<p className={classes.valueNotCommunicated}>{t('common:datas.not-communicated')}</p>)} */}
                    </div>
                    {/* {value2 ? (<div className={type === "battery" ? `${classes.cardValues__item} ${classes.battery}` : `${classes.cardValues__first} ${classes.energyMeter}`}> */}
                    {value2 ? (<div className={classes.cardValues__item}>
                        {(typeof (value2) != "undefined" && value2 !== "null" && value2 !== null) ? (<p className={classes.value}>{value2} {unit2}</p>) : (<p className={classes.valueNotCommunicated}>{t('common:datas.not-communicated')}</p>)}
                    </div>) : ""}
                </div>
                <div className={classes.cardText}>{tooltip}</div>
            </div>
            <div className={classes.cardBorder}></div>
            {/* <div className={classes.cardInner}>
                <div className={type === "battery" ? `${classes.cardFront} ${classes.battery}` : `${classes.cardFront} ${classes.energyMeter}`}>
                    <img className={classes.icon} src={icons[type][icon]} alt={imagesAlt[icon]} />
                    <div className={classes.frontContent}>
                        <div className={classes.titleWrapper}>
                            <h4 className={classes.title}>{title}</h4>
                        </div>
                        <div className={classes.contentWrapper}>
                            <p className={classes.value}>{value}{value2 ? (<span><br />{value2}</span>) : ""}</p>
                        </div>
                    </div>
                </div>
                <div className={type === "battery" ? `${classes.cardBack} ${classes.battery}` : `${classes.cardBack} ${classes.energyMeter}`}>
                    <div className={classes.backContent}>
                        <div className={classes.titleWrapper}>
                            <h4 className={classes.title}>{title}</h4>
                        </div>
                        <div className={classes.contentWrapper}>
                            <p className={classes.info}>{tooltip}</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
};

export default SimpleCard;