// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserInfo_UserInfo__2NR-V .UserInfo_modulesList__2CMfU .UserInfo_moduleItem__2Hdei {\n  line-height: 40px; }\n  .UserInfo_UserInfo__2NR-V .UserInfo_modulesList__2CMfU .UserInfo_moduleItem__2Hdei:nth-child(odd) {\n    background-color: #fff; }\n  .UserInfo_UserInfo__2NR-V .UserInfo_modulesList__2CMfU .UserInfo_moduleItem__2Hdei:nth-child(even) {\n    background-color: #eee; }\n", ""]);
// Exports
exports.locals = {
	"UserInfo": "UserInfo_UserInfo__2NR-V",
	"modulesList": "UserInfo_modulesList__2CMfU",
	"moduleItem": "UserInfo_moduleItem__2Hdei"
};
module.exports = exports;
