import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './ContactUs.module.scss';

class ContactUs extends Component {
    render() {
        const { t } = this.props;

        return (
            <div id="contactUs" className="angle-sp">
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div>
                                    <img src={require("../../_assets/img/bandeau-header-IMG-vector-CONTACT.png")} alt="Créez des scénarios grâce à  IFTTT" />
                                    <p>
                                        <Trans i18nKey="contactpage:ContactUs.available">
                                            Les opérateurs de<strong> WATTUNEED</strong> sont disponibles au horaires suivants:
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="contactpage:ContactUs.monday-friday">
                                            Du lundi au vendredi <strong>de 8H30 à 17h00</strong>
                                        </Trans>
                                    </p>
                                    <a className={classes.contactBtn} href="mailto:info@wattuneed.com">{t('contactpage:ContactUs.contact-us')}</a>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation(['common','contactpage'])(ContactUs);