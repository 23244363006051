import React,{ useState,useEffect } from 'react';
import { Formik,Field,Form,ErrorMessage,getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch,} from 'react-redux';

import classes from './ContactForm.module.scss';
import formBg from '../../_assets/img/IMG2-bacckground-site-battery-UP-CONTACT.png';
import { contactService } from '../../_services/contactService';

const ContactForm = (props) => {
    return (
        <div id="contactForm" className={`contact-section gray-bg angle-contact-section ${classes.ContactForm}`}>
            <div className="container">
                <div className="row">
                    <div className={`col-md-12 col-lg-12 ${classes.formWrapper}`}>
                        <div className="contact-form wow fadeInUp" data-wow-delay=".9s">
                            <h2 className={`doubleLine-title ${classes.sectionTitle}`} style={{ marginBottom: "30px" }}>Contactez nous</h2>
                            <Formik
                                initialValues={{ firstname: '',surname: '',email: '',subject: '',message_text: '' }}
                                validationSchema={Yup.object({
                                    firstname: Yup.string()
                                        .trim()
                                        .matches(/^[A-Za-z- ]+$/,{ message: <p className="errorMessage">Veuillez ne taper que des lettres, des espaces blancs et le "-".</p> })
                                        .required(<p className="errorMessage">Ce champ est obligatoire</p>),
                                    surname: Yup.string()
                                        .trim()
                                        .matches(/^[A-Za-z- ]+$/,{ message: <p className="errorMessage">Veuillez ne taper que des lettres, des espaces blancs et le "-".</p> })
                                        .required(<p className="errorMessage">Obligatoire</p>),
                                    email: Yup.string()
                                        .trim()
                                        .email(<p className="errorMessage">Adresse électronique invalide</p>)
                                        .required(<p className="errorMessage">Obligatoire</p>),
                                    subject: Yup.string()
                                        .trim()
                                        .matches(/^[A-z0-9\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-_`~()]+$/,{ message: <p className="errorMessage">Please only type valid characters</p> })
                                        .max(80,<p className="errorMessage">Must be 80 characters or less</p>)
                                        .required(<p className="errorMessage">Ce champ est obligatoire</p>),
                                    message_text: Yup.string()
                                        .trim()
                                        .matches(/^[A-z0-9\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-_`~()]+$/,{ message: <p className="errorMessage">Please only type valid characters</p> })
                                        .required(<p className="errorMessage">Ce champ est obligatoire</p>),
                                })}
                                onSubmit={(values,{ setSubmitting }) => {
                                    setTimeout(() => {
                                        console.log('CONTACT');
                                        contactService.contact(values);
                                    },400);
                                }}
                            >
                                <Form>
                                    <div className="form-group" style={{ margin: '10px' }}>
                                        <label htmlFor="firstname">Prénom</label>
                                        <Field name="firstname" type="text" className="form-control" />
                                        <ErrorMessage name="firstname" />
                                    </div>
                                    <div className="form-group" style={{ margin: '10px' }}>
                                        <label htmlFor="surname">Nom</label>
                                        <Field name="surname" type="text" className="form-control" />
                                        <ErrorMessage name="surname" />
                                    </div>
                                    <div className="form-group" style={{ margin: '10px' }}>
                                        <label htmlFor="email">Adresse mail</label>
                                        <Field name="email" type="email" className="form-control" />
                                        <ErrorMessage name="email" />
                                    </div>
                                    <div className="form-group" style={{ margin: '10px' }}>
                                        <label htmlFor="subject">Sujet</label>
                                        <Field name="subject" type="text" className="form-control" />
                                        <ErrorMessage name="subject" />
                                    </div>
                                    <div className="form-group" style={{ margin: '10px' }}>
                                        <label htmlFor="message_text">Message</label>
                                        <Field name="message_text" component="textarea" class="form-control" />
                                        <ErrorMessage name="message_text" />
                                    </div>

                                    <button className="btn btn-primary" type="submit">Envoyer</button>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;