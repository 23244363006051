import { authHeader } from '../_helpers/auth-header';
import axios from '../axios';
import axiosreal from 'axios';
import { dataApiConstants } from '../_constants/dataApiConstants';

export const dataService = {
    socValue,
    batteryTemperatures,
    uvValue,
    uvForecast,
    uvForecastByCity,
    currentWeather,
    currentWeatherByCity,
    weatherForecast,
    weatherForecastByCity,
    socInPeriod,
    powerLogs,
    chartData,
    chartDataMongo,
    lastBatteriesValuesOld,
    lastBatteriesValues,
    lastBatteriesAllValues,
    lastAcrelsValues,
    lastAcrelsAllValues,
    lastEastronsValues,
    lastEastronsAllValues,
    socPastHourOld,
    socPastHour,
    acrPGaugeOld,
    acrPGauge,
    pylontechAlarmsOld,
    pylontechAlarms,
    pylontechLastDataOld,
    pylontechLastData,
    acrelLatestData,
    acrelAbsRelByHour,
    acrelAbsRelChartsData,
    eastronLatestData,
    lastRecord,
    checkDataValidity,
    // testNatacha,
};

let timezone_offset_minutes = new Date().getTimezoneOffset();
timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
let localOffset = timezone_offset_minutes * 60;
// console.log("localOffset dataService");
// console.log(localOffset);

/**
 * Retrieving the SOC (State Of Charge) value
 */
// function socValue(userId) {
function socValue(macAddress) {
    // console.log('macAddress in dataservice SOC');
    // console.log(macAddress);
    return axios.get(dataApiConstants.SOC_SHORT_URL + "?id=" + macAddress)
        .then(response => {
            // console.log(response.data.soc)
            // console.log(response)
            let soc = response.data.soc;
            return soc;
        });
    // fetch(dataApiConstants.SOC_FULL_URL)
    //     .then(response => response.json())
    //     .then(data => console.log(data));
}

/**
 * Retrieving the temperatures of the battery
 * Current temperature
 * Min & Max of the last 48h
 */
function batteryTemperatures(deviceId) {
    // console.log("userId battTemp service");
    // console.log(userId);
    return axios.get(dataApiConstants.BATTERY_TEMPS_SHORT_URL + "?id=" + deviceId)
        .then(response => {
            let batteryTemps = response.data;
            return batteryTemps;
        });
    // fetch(dataApiConstants.SOC_FULL_URL)
    //     .then(response => response.json())
    //     .then(data => console.log(data));
}

/**
 * Retrieving the weather data
 */
function currentWeather(coords) {
    // console.log("coords in service");
    // console.log(coords);
    // if (typeof coords.latitude !== 'undefined' && typeof coords.longitude !== 'undefined') {
    //     return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/weather?lat=" + coords.latitude + "&lon=" + coords.longitude + "&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
    //         .then(response => {
    //             console.log("response current weather");
    //             console.log(response);
    //             return response;
    //         });
    // } else {
    //     console.log("undefined coords");
    // }
}

function currentWeatherByCity(city,countryCode) {
    // console.log("city forecast in service");
    // console.log(city);
    // console.log("countryCode forecast in service");
    // console.log(countryCode);
    // // return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/weather?q=" + city + "," + countryCode + "&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
    // return axios.get("https://api.openweathermap.org/data/2.5/weather?q=" + city + "," + countryCode + "&lang=fr&units=metric&appid=f8fe7ca65044653e2440ac5f6f1a5d84")
    //     .then(response => {
    //         console.log("response current weather");
    //         console.log(response);
    //         return response;
    //     });

    let test = axiosreal.create({});

    // delete headers if existing
    delete test.defaults.headers.common['Accept'];
    delete test.defaults.headers.common['X-Requested-With'];
    delete test.defaults.headers.common['X-CSRF-TOKEN'];

    return test.get("https://api.openweathermap.org/data/2.5/weather?q=" + city + "," + countryCode + "&lang=fr&units=metric&appid=f8fe7ca65044653e2440ac5f6f1a5d84")
        .then(response => {
            // console.log("response dtaservice test openweathermap");
            // console.log(response);
            return response;
        });
}

/**
 * Retrieving the weather forecast
 */
function weatherForecast(coords) {
    return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/forecast/daily?lat=" + coords.latitude + "&lon=" + coords.longitude + "&cnt=8&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
        .then(response => {
            return response;
        });
}
function weatherForecastByCity(city,countryCode) {
    // return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/forecast/daily?q=" + city + "," + countryCode + "&cnt=8&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
    //     .then(response => {
    //         return response;
    //     });
    let test = axiosreal.create({});

    // delete headers if existing
    delete test.defaults.headers.common['Accept'];
    delete test.defaults.headers.common['X-Requested-With'];
    delete test.defaults.headers.common['X-CSRF-TOKEN'];

    return test.get("https://api.openweathermap.org/data/2.5/forecast/daily?q=" + city + "," + countryCode + "&cnt=8&lang=fr&units=metric&appid=f8fe7ca65044653e2440ac5f6f1a5d84")
        .then(response => {
            return response;
        });
}

/**
 * Retrieving the current UV value
 */
function uvValue(coords) {
    return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/uvi?lat=" + coords.latitude + "&lon=" + coords.longitude + "&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
        .then(response => {
            return response;
        });
}

/**
 * Retrieving the UV values for the next 7 days
 */
function uvForecast(coords) {
    return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/uvi/forecast?lat=" + coords.latitude + "&lon=" + coords.longitude + "&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
        .then(response => {
            return response;
        });
}
function uvForecastByCity(city,countryCode) {
    // return axios.get("https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/uvi/forecast?q=" + city + "," + countryCode + "&lang=fr&units=metric&appid=317299f6e614207c2cd2eb2665f3fab9")
    //     .then(response => {
    //         return response;
    //     });
    let test = axiosreal.create({});

    // delete headers if existing
    delete test.defaults.headers.common['Accept'];
    delete test.defaults.headers.common['X-Requested-With'];
    delete test.defaults.headers.common['X-CSRF-TOKEN'];

    return test.get("https://api.openweathermap.org/data/2.5/uvi/forecast?q=" + city + "," + countryCode + "&lang=fr&units=metric&appid=f8fe7ca65044653e2440ac5f6f1a5d84")
        .then(response => {
            return response;
        });
}

/**
 * Retrieving the SOC in the selected period
 * By default -> Today
 * TODO -> We can select a date via datepicker
 * TODO -> We can select a period
 */
let todayDate = new Date();
// let today = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
let today = todayDate.getFullYear().toString() + ('0' + (todayDate.getMonth() + 1)).slice(-2) + todayDate.getDate().toString();
// function socInPeriod($start = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString(),$end = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString()) {
function socInPeriod($start = parseInt(today),$end = parseInt(today)) {
    // console.log("today");
    // console.log(today);
    return axios.get(dataApiConstants.SOCINPERIOD_SHORT_URL + "?start=" + $start + "&end=" + $end)
        .then(response => {
            return response;
        });
}

/**
 * Retrieving the power logs in the selected period
 * By default -> Today
 * TODO -> We can select a date via datepicker
 * TODO -> We can select a period
 */
function powerLogs($start = parseInt(today),$end = parseInt(today),client = "5600") {
    // console.log("service start");
    // console.log($start);
    // console.log("service client id");
    // console.log(client);
    // console.log("service end");
    // console.log($end);
    return axios.get(dataApiConstants.POWERLOGS_SHORT_URL + "?start=" + $start + "&end=" + $end + "&client=" + client)
        .then(response => {
            // console.log("start");
            // console.log($start);
            // console.log("end");
            // console.log($end);
            // console.log("response");
            // console.log(response);
            return response;
        });
}

/**
 * Retrieving the data
 * By default -> Today
 * Use selected date
 */
function chartData($start = parseInt(today),$end = parseInt(today),arrBatteries,arrAcrel,arrEastron) {
    // console.log("service start");
    // console.log($start);
    // // console.log($start.toString());
    // console.log("service end");
    // console.log($end);
    // console.log("service arrBatteries");
    // console.log(arrBatteries);
    // console.log("service arrAcrel");
    // console.log(arrAcrel);
    // console.log("service arrEastron");
    // console.log(arrEastron);

    let paramBatteries = "";
    if (arrBatteries.length > 0) {
        // paramBatteries = "";
        for (let i = 0; i < arrBatteries.length; i++) {
            paramBatteries = paramBatteries + "&battery[]=" + arrBatteries[i];
        }
    }
    let paramAcrel = "";
    if (arrAcrel.length > 0) {
        // paramAcrel = "";
        for (let i = 0; i < arrAcrel.length; i++) {
            paramAcrel = paramAcrel + "&acrel[]=" + arrAcrel[i];
        }
    }
    let paramEastron = "";
    if (arrEastron.length > 0) {
        // paramAcrel = "";
        for (let i = 0; i < arrEastron.length; i++) {
            paramEastron = paramEastron + "&eastron[]=" + arrEastron[i];
        }
    }
    // console.log("service paramBatteries");
    // console.log(paramBatteries);
    // console.log("service paramAcrel");
    // console.log(paramAcrel);
    // console.log("service paramEastron");
    // console.log(paramEastron);
    // console.log("service selectedAcrel");
    // console.log(selectedAcrel);
    // return axios.get(dataApiConstants.CHARTDATA_SHORT_URL + "?start=" + $start + "&end=" + $end + "&battery=" + selectedBattery)
    return axios.get(dataApiConstants.CHARTDATA_SHORT_URL + "?start=" + $start + "&end=" + $end + paramBatteries + paramAcrel + paramEastron)
        .then(response => {
            // console.log("start");
            // console.log($start);
            // console.log("end");
            // console.log($end);
            // console.log("response battery dataservice");
            // console.log(response);
            return response;
        });
}
function chartDataMongo(start = parseInt(today),end = parseInt(today),arrBatteries,arrAcrel,arrEastron) {
    // console.log("chartDataMongo start date");
    // console.log(start);
    // console.log("chartDataMongo end date");
    // console.log(end);
    // var x = new Date();
    // var y = x.getFullYear().toString();
    // var m = (x.getMonth() + 1).toString();
    // var d = x.getDate().toString();
    // (d.length == 1) && (d = '0' + d);
    // (m.length == 1) && (m = '0' + m);
    // var yyyymmdd = y + m + d;

    // Calculate if start date is more than 7 days ago => short term vs long term DB
    let dateString  = start.toString();
    let year        = dateString.substring(0,4);
    let month       = dateString.substring(4,6);
    let day         = dateString.substring(6,8);
    let dateStart = new Date(year, month-1, day);
    let dateToday = new Date();
    let difference = dateToday.getTime() - dateStart.getTime();
    // let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
    // console.log("diff start date vs today");
    // console.log(TotalDays);


    let paramBatteries = "";
    if (arrBatteries.length > 0) {
        for (let i = 0; i < arrBatteries.length; i++) {
            paramBatteries = paramBatteries + "&battery[]=" + arrBatteries[i];
        }
    }
    let paramAcrel = "";
    if (arrAcrel.length > 0) {
        for (let i = 0; i < arrAcrel.length; i++) {
            paramAcrel = paramAcrel + "&acrel[]=" + arrAcrel[i];
        }
    }
    let paramEastron = "";
    if (arrEastron.length > 0) {
        for (let i = 0; i < arrEastron.length; i++) {
            paramEastron = paramEastron + "&eastron[]=" + arrEastron[i];
        }
    }

    if (start === end && TotalDays < 8) {
        return axios.get(dataApiConstants.MAINCHARTDATA_SHORT_UL + "?start=" + start + "&end=" + end + paramBatteries + paramAcrel + paramEastron + "&local_offset=" + localOffset)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error',error.message);
                }
                console.log(error.config);
                var testError = {
                    data: {
                        main_chart_data: "error"
                    }
                };
                return testError;
            })
            .then(response => {
                // console.log("MONGO => response battery dataservice");
                // console.log(response);
                return response;
            });
    } else {
        return axios.get(dataApiConstants.MAINCHARTDATALONGTERM_SHORT_UL + "?start=" + start + "&end=" + end + paramBatteries + paramAcrel + paramEastron)
            .then(response => {
                // console.log("MONGO => response battery dataservice");
                // console.log(response);
                return response;
            });
    }
}
function lastBatteriesValuesOld(batteriesId) {
    let paramBatteries = "";
    for (let i = 0; i < batteriesId.length; i++) {
        paramBatteries = paramBatteries + "&battery[]=" + batteriesId[i];
    }
    // console.log("url");
    // console.log(dataApiConstants.LASTBATTERIESVALUES_SHORT_URL + "?" + paramBatteries);
    // return axios.get(dataApiConstants.LASTBATTERIESVALUES_SHORT_URL + "?" + paramBatteries)
    return axios.get("/bq/pylontech-cards?" + paramBatteries)
        .then(response => {
            console.log("response cards pylon BQ in service");
            console.log(response.data.batteries);
            return response.data.batteries;
        });
    /* Add catch */
    // .catch(function (error) {
    //     if (error.response) {
    //         console.log(error.response.data);
    //         console.log(error.response.status);
    //         console.log(error.response.headers);
    //     } else if (error.request) {
    //         // The request was made but no response was received
    //         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //         // http.ClientRequest in node.js
    //         console.log(error.request);
    //     } else {
    //         // Something happened in setting up the request that triggered an Error
    //         console.log('Error',error.message);
    //     }
    //     console.log(error.config);
    //     var testError = {
    //         data: {
    //             main_chart_data: "empty"
    //         }
    //     };
    //     return testError;
    // })
    // .then(response => {
    //     // console.log("response lastBatteriesValues dataservice");
    //     // console.log(response.data.cards_values);
    //     return response.data.cards_values;
    // });
}
function lastBatteriesValues(batteriesId) {
    let pylon = batteriesId[0];
    return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + pylon + "/cards?local_offset=" + localOffset)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error',error.message);
            }
            console.log(error.config);
            var testError = {
                data: {
                    main_chart_data: "empty"
                }
            };
            return testError;
        })
        .then(response => {
            // console.log("response lastBatteriesValues dataservice");
            // console.log(response.data.cards_values);
            return response.data.cards_values;
        });
}

function lastBatteriesAllValues(batteriesId) {
    let paramBatteries = "";
    for (let i = 0; i < batteriesId.length; i++) {
        paramBatteries = paramBatteries + "&battery[]=" + batteriesId[i];
    }
    return axios.get(dataApiConstants.LASTBATTERIESALLVALUES_SHORT_URL + "?" + paramBatteries)
        .then(response => {
            // console.log("response lastBatteriesAllValues dataservice");
            // console.log(response.data.batteries);
            return response.data.batteries;
        });
}

function lastAcrelsValuesOld(acrelsId) {
    let paramAcrels = "";
    for (let i = 0; i < acrelsId.length; i++) {
        paramAcrels = paramAcrels + "&acrel[]=" + acrelsId[i];
    }
    return axios.get(dataApiConstants.LASTACRELSVALUES_SHORT_URL + "?" + paramAcrels)
        .then(response => {
            // console.log("response lastAcrelsValues dataservice");
            // console.log(response.data.acrels);
            return response.data.acrels;
        });
}
function lastAcrelsValues(acrelsId) {
    let acrel = acrelsId[0];
    return axios.get(dataApiConstants.QUERIES_ACREL_URL + "/" + acrel + "/cards?local_offset=" + localOffset)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error',error.message);
            }
            console.log(error.config);
            var testError = {
                data: {
                    main_chart_data: "empty"
                }
            };
            return testError;
        })
        .then(response => {
            // console.log("response cards_values dataservice");
            // console.log(response.data.cards_values);
            return response.data.cards_values;
        });
}

function lastAcrelsAllValues(acrelsId) {
    let paramAcrels = "";
    for (let i = 0; i < acrelsId.length; i++) {
        paramAcrels = paramAcrels + "&acrel[]=" + acrelsId[i];
    }
    return axios.get(dataApiConstants.LASTACRELSALLVALUES_SHORT_URL + "?" + paramAcrels)
        .then(response => {
            // console.log("response lastAcrelsAllValues dataservice");
            // console.log(response.data.acrels);
            return response.data.acrels;
        });
}

function lastEastronsValuesOld(eastronsId) {
    let paramEastrons = "";
    for (let i = 0; i < eastronsId.length; i++) {
        paramEastrons = paramEastrons + "&eastron[]=" + eastronsId[i];
    }
    return axios.get(dataApiConstants.LASTEASTRONSVALUES_SHORT_URL + "?" + paramEastrons)
        .then(response => {
            // console.log("response lastEastronsValues dataservice");
            // console.log(response.data.eastrons);
            return response.data.eastrons;
        });
}
function lastEastronsValues(eastronsId) {
    let eastron = eastronsId[0];
    return axios.get(dataApiConstants.QUERIES_EASTRON_URL + "/" + eastron + "/cards")
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error',error.message);
            }
            console.log(error.config);
            var testError = {
                data: {
                    cards_values: "empty"
                }
            };
            return testError;
        })
        .then(response => {
            // console.log("response lastEastronsValues dataservice");
            // console.log(response.data.cards_values);
            return response.data.cards_values;
        });
}

function lastEastronsAllValues(eastronsId) {
    let paramEastrons = "";
    for (let i = 0; i < eastronsId.length; i++) {
        paramEastrons = paramEastrons + "&eastron[]=" + eastronsId[i];
    }
    return axios.get(dataApiConstants.LASTEASTRONSALLVALUES_SHORT_URL + "?" + paramEastrons)
        .then(response => {
            // console.log("response lastEastronsAllValues dataservice");
            // console.log(response.data.eastrons);
            return response.data.eastrons;
        });
}

function socPastHourOld(macAddress) {
    // console.log("param macAddress");
    // console.log(macAddress);

    return axios.get(dataApiConstants.SOCLASTHOUR_SHORT_URL + "?mac_address=" + macAddress)
        .then(response => {
            console.log("response socPastHour dataservice");
            console.log(response.data);
            // return response.data;
            let soc = response.data.latest_oldest_timewindow;
            return soc;
        });
}
function socPastHour(macAddress) {
    return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + macAddress + "/metrics-latest-oldest?metrics[]=soc&duration=60")
        .then(response => {
            // console.log("socPastHour MONGO");
            // console.log(response);
            let soc = response.data.latest_oldest_timewindow;
            return soc;
        });
}

function acrPGaugeOld(macAddress) {
    // console.log("param macAddress");
    // console.log(macAddress);

    return axios.get(dataApiConstants.ACRPGAUGE_SHORT_URL + "?mac_address=" + macAddress)
        .then(response => {
            // console.log("response acrPGauge dataservice");
            // console.log(response.data);
            return response.data;
        });
}
function acrPGauge(macAddress) {
    // console.log("param macAddress");
    // console.log(macAddress);

    return axios.get(dataApiConstants.QUERIES_ACREL_URL + "/" + macAddress + "/gauge")
        .then(response => {
            // console.log("acrel acrPGauge MONGO");
            // console.log(response.data);
            return response.data.acrel_gauge_values;
        });
}

function pylontechAlarmsOld(pylontechIds) {
    let paramBatteries = "";
    for (let i = 0; i < pylontechIds.length; i++) {
        paramBatteries = paramBatteries + "&battery[]=" + pylontechIds[i];
    }
    return axios.get(dataApiConstants.PYLONTECHALARMS_SHORT_URL + "?" + paramBatteries)
        .then(response => {
            // console.log("response pylontechAlarms dataservice");
            // console.log(response.data.batteries);
            return response.data.batteries;
        });
}
function pylontechAlarmsTest(pylontechIds) {
    let pylon = pylontechIds[0];
    return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + pylon + "/alarms")
        .then(response => {
            // console.log("response pylontechAlarms dataservice");
            // console.log(response.data.batteries);
            return response.data.alarms;
        });
}
function pylontechAlarms(pylontechIds) {
    let pylon = pylontechIds[0];
    return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + pylon + "/alarms?local_offset=" + localOffset)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error',error.message);
            }
            console.log(error.config);
        })
        .then(response => {
            console.log("response pylontechAlarms dataservice");
            console.log(response.data.alarms);
            return response.data.alarms;
        });
}

function pylontechLastDataOld(pylontechIds) {
    let paramBatteries = "";
    let timezone_offset_minutes = new Date().getTimezoneOffset();
    timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
    let localOffset = timezone_offset_minutes * 60;
    for (let i = 0; i < pylontechIds.length; i++) {
        paramBatteries = paramBatteries + "&battery[]=" + pylontechIds[i];
    }
    return axios.get(dataApiConstants.PYLONTECHLASTDATA_SHORT_URL + "?" + paramBatteries + "&local_offset=" + localOffset + "metrics[]=all")
        .then(response => {
            // console.log("response pylontechLastData dataservice");
            // console.log(response.data.pylontech);
            return response.data.pylontech;
        });
}
function pylontechLastData(pylontechIds) {
    let pylon = pylontechIds[0];
    let timezone_offset_minutes = new Date().getTimezoneOffset();
    timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;

    // Timezone difference in minutes such as 330 or -360 or 0
    // console.log("New offset");
    // console.log(timezone_offset_minutes * 60);
    let localOffset = timezone_offset_minutes * 60;
    return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + pylon + "/metrics?metrics[]=all&local_offset=" + localOffset)
        // return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + pylon + "/test-query?metrics[]=all&local_offset=" + localOffset)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error',error.message);
            }
            console.log(error.config);
            var testError = {
                data: {
                    last_metrics: "error"
                }
            };
            return testError;
        })
        .then(response => {
            // console.log("response pylontechLastData dataservice");
            // console.log(response.data.last_metrics);
            return response.data.last_metrics;
        });
}

function acrelLatestDataOld(acrelIds) {
    let paramAcrels = "";
    for (let i = 0; i < acrelIds.length; i++) {
        paramAcrels = paramAcrels + "&acrel[]=" + acrelIds[i];
    }
    return axios.get(dataApiConstants.ACRELLATESTDATA_SHORT_URL + "?" + paramAcrels)
        .then(response => {
            // console.log("response acrelLatestData dataservice");
            // console.log(response.data.acrel);
            return response.data.acrel;
        });
}
function acrelLatestData(acrelIds) {
    let acrel = acrelIds[0];
    return axios.get(dataApiConstants.QUERIES_ACREL_URL + "/" + acrel + "/metrics?metrics[]=all")
        .then(response => {
            // console.log("response acrelLatestData dataservice");
            // console.log(response.data.last_metrics);
            return response.data.last_metrics;
        });
}

function acrelAbsRelByHour(macAddress) {
    // console.log("param macAddress");
    // console.log(macAddress);

    return axios.get(dataApiConstants.ACRABSRELBYHOUR_SHORT_URL + "?mac_address=" + macAddress + "&start=20210620")
        .then(response => {
            // console.log("response acrelAbsRelByHour dataservice");
            // console.log(response.data.test);
            return response.data.test;
        });
}

function acrelAbsRelChartsData(macAddress) {
    // console.log("param macAddress");
    // console.log(macAddress);

    return axios.get(dataApiConstants.ACRABSRELCHARTSDATA_SHORT_URL + "?mac_address=" + macAddress)
        .then(response => {
            // console.log("response acrelAbsRelChartsData dataservice");
            // console.log(response.data.fullData);
            return response.data.fullData;
        });
}

function eastronLatestDataOld(eastronIds) {
    let paramEastrons = "";
    for (let i = 0; i < eastronIds.length; i++) {
        paramEastrons = paramEastrons + "&eastron[]=" + eastronIds[i];
    }
    return axios.get(dataApiConstants.EASTRONLATESTDATA_SHORT_URL + "?" + paramEastrons)
        .then(response => {
            // console.log("response eastronLatestData dataservice");
            // console.log(response.data.eastron);
            return response.data.eastron;
        });
}
function eastronLatestData(eastronIds) {
    let eastron = eastronIds[0];
    return axios.get(dataApiConstants.QUERIES_EASTRON_URL + "/" + eastron + "/metrics?metrics[]=all")
        .then(response => {
            // console.log("response eastronLatestData dataservice");
            // console.log(response.data.last_metrics);
            return response.data.last_metrics;
        });
}

function lastRecord(mac_address,protocol) {
    // let mac_address = mac_address;
    switch (protocol) {
        case "pylontech":
            return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + mac_address + "/last-record?local_offset=" + localOffset)
                .then(response => {
                    // console.log("response lastRecord dataservice - Pylontech");
                    // console.log(response.data.last_record);
                    return response.data.last_record;
                });

        case "acrel":
            return axios.get(dataApiConstants.QUERIES_ACREL_URL + "/" + mac_address + "/last-record?local_offset=" + localOffset)
                .then(response => {
                    // console.log("response lastRecord dataservice - Acrel");
                    // console.log(response.data.last_record);
                    return response.data.last_record;
                });

        case "eastron":
            return axios.get(dataApiConstants.QUERIES_EASTRON_URL + "/" + mac_address + "/last-record?local_offset=" + localOffset)
                .then(response => {
                    // console.log("response lastRecord dataservice - Eastron");
                    // console.log(response.data.last_record);
                    return response.data.last_record;
                });

        default:
            break;
    }
}

function checkDataValidity(mac_address,protocol) {
    switch (protocol) {
        case "pylontech":
            return axios.get(dataApiConstants.QUERIES_PYLONTECH_URL + "/" + mac_address + "/check-data-validity")
                .then(response => {
                    // console.log("response lastRecord dataservice - Pylontech");
                    // console.log(response.data.last_record);
                    return response.data.data_validity;
                });

        case "acrel":
            return axios.get(dataApiConstants.QUERIES_ACREL_URL + "/" + mac_address + "/check-data-validity")
                .then(response => {
                    // console.log("response lastRecord dataservice - Acrel");
                    // console.log(response.data.last_record);
                    return response.data.data_validity;
                });

        case "eastron":
            return axios.get(dataApiConstants.QUERIES_EASTRON_URL + "/" + mac_address + "/check-data-validity")
                .then(response => {
                    // console.log("response lastRecord dataservice - Eastron");
                    // console.log(response.data.last_record);
                    return response.data.data_validity;
                });

        default:
            break;
    }
}

// function testNatacha() {
//     console.log("in test Natacha");
//     return axios.get("/api/consommateurs")
//         .then(response => {
//             console.log("response testNatacha");
//             console.log(response);
//             return response;
//         });
// }