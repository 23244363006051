import React from 'react';

import DeviceControl from './DeviceControl';

import classes from './DevicesControlWrapper.module.scss';

const DevicesControlWrapper = (props) => {
    let batteries = props.batteries;
    let acrels = props.acrels;
    let eastrons = props.eastrons;
    return (
        // <p>test</p>
        <div className={classes.DevicesControlWrapper}>
            {(batteries.length > 0 ? (
                batteries.map((battery,idx) => {
                    return (
                        <DeviceControl protocol="pylontech" mac_address={battery} />
                    )
                })) : ""
            )}
            {(acrels.length > 0 ? (
                acrels.map((acrel,idx) => {
                    return (
                        <DeviceControl protocol="acrel" mac_address={acrel} />
                    )
                })) : ""
            )}
            {(eastrons.length > 0 ? (
                eastrons.map((eastron,idx) => {
                    return (
                        <DeviceControl protocol="eastron" mac_address={eastron} />
                    )
                })) : ""
            )}
        </div>
    )
};

export default DevicesControlWrapper;