import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { Trans,useTranslation } from 'react-i18next';

import { userService } from '../../_services/userService';
import { moduleService } from '../../_services/moduleService';
import { sermatecService } from '../../_services/sermatecService';
import { addressService } from '../../_services/addressService';
import { history } from '../../_helpers/history';
import classes from './UserProfile.module.scss';

import Auxil from '../../_hoc/Auxil/Auxil';
import Modal from '../UI/Modal/Modal';
import Data from '../Data/Data';
import Spinner from '../UI/Spinner/Spinner';

const UserProfile = (props) => {
    const { t } = useTranslation(['common','myprofilepage']);
    const auth = JSON.parse(localStorage.getItem('appState')).user;
    const [user,setUser] = useState([]);
    const [hasModules,setHasModules] = useState(true);
    const [modules,setModules] = useState([]);
    const [hasInstaller,setHasInstaller] = useState(false);
    const [installersList,setInstallersList] = useState([]);
    const [installerTarget,setInstallerTarget] = useState(999999);
    // const [hasSermatecs,setHasSermatecs] = useState(true);
    // const [sermatecs,setSermatecs] = useState([]);
    const [deletingStatus,setDeletingStatus] = useState(false);
    const [deletingTargetId,setDeletingTargetId] = useState(0);
    const [deletingDone,setDeletingDone] = useState(false);
    const [deletingModuleStatus,setDeletingModuleStatus] = useState(false);
    const [deletingModuleTargetId,setDeletingModuleTargetId] = useState(0);
    const [deletingModuleDone,setDeletingModuleDone] = useState(false);
    const [addressHasModule,setAddressHasModule] = useState(false);

    useEffect(() => {
        userService.getById(auth.id)
            .then(response => {
                console.log("response user with full addresses");
                console.log(response);
                setUser(response);
                if (response.modules.length === 0) {
                    setHasModules(false);
                }
                // if (response.sermatecs.length === 0) {
                //     setHasSermatecs(false);
                // }
                if (response.installer && response.installer.length > 0) {
                    setHasInstaller(true);
                }
            });
        moduleService.getByUserId(auth.id)
            .then(response => {
                console.log("response modules by user id");
                console.log(response);
                setModules(response);
            });
        // sermatecService.getByUserId(auth.id)
        //     .then(response => {
        //         console.log("response sermatecs by user id");
        //         console.log(response);
        //         setSermatecs(response);
        //     });
        userService.getAllInstallers()
            .then(response => {
                // console.log('response get all installers');
                // console.log(response);
                setInstallersList(response);
            });
    },[]);

    /* Functions to delete a module */
    function deletionModuleModalHandler(e) {
        let target = e.target.getAttribute('target');
        setDeletingModuleTargetId(target);
        setDeletingModuleStatus(true);
    }

    function deletionModuleCancelHandler() {
        setDeletingModuleStatus(false);
    }

    function deletionModuleHandler() {
        moduleService.delete(deletingModuleTargetId)
            .then(response => {
                setDeletingModuleStatus(false);
            })
            .then(response => {
                userService.getById(auth.id)
                    .then(response => {
                        setUser(response);
                        if (response.modules.length === 0) {
                            setHasModules(false);
                        }
                        setDeletingModuleDone(true);
                    })
            });
    }

    function deletedModuleCloseHandler() {
        moduleService.getByUserId(auth.id)
            .then(response => {
                console.log("response modules by user id");
                console.log(response);
                setModules(response);
            })
            .then(() => {
                setDeletingModuleDone(false);
            });
    }

    /* Functions to delete an installation */
    function deletionModalHandler(e) {
        let target = e.target.getAttribute('target');
        addressService.getAddressById(target)
            .then(response => {
                console.log("response getAddressById modal");
                console.log(response);
                if (response.modules.length > 0) {
                    setAddressHasModule(true);
                } else {
                    setAddressHasModule(false);
                }
                setDeletingTargetId(target);
                setDeletingStatus(true);
            });
    }

    function deletionCancelHandler() {
        setDeletingStatus(false);
    }

    function deletionHandler() {
        addressService.delete(deletingTargetId)
            .then(response => {
                setDeletingStatus(false);
            })
            .then(response => {
                userService.getById(auth.id)
                    .then(response => {
                        setUser(response);
                        if (response.modules.length === 0) {
                            setHasModules(false);
                        }
                        setDeletingDone(true);
                    })
            });
    }

    function deletedCloseHandler() {
        setDeletingDone(false);
    }

    function installerChangeHandler(event) {
        let target = event.target.value;
        setInstallerTarget(target);
    }

    function attachInstaller() {
        userService.attachInstaller(installerTarget)
            .then(response => {
                console.log("response attach installer");
                console.log(response);
                setInstallerTarget(999999);
                setUser(response.data.user);
                setHasInstaller(true);
            });
    }

    return (
        <div className={classes.UserProfile}>
            <Modal show={deletingModuleStatus} modalClosed={deletionModuleCancelHandler}>
                {/* <Auxil> */}
                {/* <i className="icofont-warning"></i> */}
                <img src={require("../../_assets/img/icon_alert.png")} alt={t('common:icons.alert')} style={{ width: "50px" }} />
                <p>{t('myprofilepage:UserProfile.sure-delete-module')}</p>
                <p>{t('common:user-interactions.please-confirm')}</p>
                {/* </Auxil> */}
                <span onClick={deletionModuleCancelHandler}><img src={require("../../_assets/img/bouton-annuler-battery-up-EN.png")} alt={t('common:user-interactions.button-cancel-alt')} style={{ height: "65px" }} /></span>
                <button onClick={deletionModuleHandler} className="btn btn-primary">
                    {t('common:user-interactions.delete')}
                </button>
            </Modal>
            <Modal show={deletingModuleDone} modalClosed={deletedModuleCloseHandler}>
                <img src={require("../../_assets/img/icon_checked.png")} alt={t('common:icons.success')} style={{ width: "50px" }} />
                <p>{t('myprofilepage:UserProfile.module-deleted')}</p>
                <button onClick={deletedModuleCloseHandler} className="btn btn-primary">
                    {t('common:user-interactions.done')}
                </button>
            </Modal>
            <Modal show={deletingStatus} modalClosed={deletionCancelHandler}>
                {addressHasModule ? (
                    <Auxil>
                        <i className="icofont-warning"></i>
                        <p>
                            <Trans i18nKey="myprofilepage:UserProfile.cannot-delete-address">
                                This address is linked to at least 1 module.<br />In order to delete it, you must first delete the module.
                            </Trans>
                        </p>
                    </Auxil>
                ) : (
                    <Auxil>
                        <i className="icofont-warning"></i>
                        <p>{t('myprofilepage:UserProfile.sure-delete-address')}</p>
                        <p>{t('common:user-interactions.please-confirm')}</p>
                    </Auxil>
                )}
                {/* <span onClick={deletionCancelHandler}><i className="icofont-close"></i>cancel</span> */}
                <span onClick={deletionCancelHandler}><img src={require("../../_assets/img/bouton-annuler-battery-up-EN.png")} alt={t('common:user-interactions.button-cancel-alt')} style={{ width: "50px" }} /></span>
                <button onClick={deletionHandler} className="btn btn-primary" disabled={addressHasModule ? true : false}>
                    {t('common:user-interactions.delete')}
                </button>
            </Modal>
            <Modal show={deletingDone} modalClosed={deletedCloseHandler}>
                <i className="icofont-verification-check"></i>
                <p>{t('myprofilepage:UserProfile.address-deleted')}</p>
                <button onClick={deletedCloseHandler} className="btn btn-primary">
                    {t('common:user-interactions.done')}
                </button>
            </Modal>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <Data
                            icon={"icone-battery-UP-IMG-personne"}
                            title="Mon profil"
                            type={t('myprofilepage:UserProfile.title')}
                        >
                            <div className="row mt-4">
                                <div className={`col-lg-12 text-left ${classes.profileWrapper}`}>
                                    <div className={`row ${classes.profileSection}`}>
                                        <div className="col-lg-12">
                                            <img className={classes.profileSection__icon} src={require("../../_assets/img/icone-battery-UP-IMG-infos.png")} alt={t('common:icons.profile')} />
                                            <h4 className={classes.profileSection__title}>{t('myprofilepage:UserProfile.personal-data')}</h4>
                                            <Link to={{ pathname: `/edit-user/${auth.id}`,state: { prevPath: history.location.pathname } }}><i className="icofont-ui-edit"></i></Link>
                                        </div>
                                        <div className={`col-lg-12 ${classes.profileSection__data}`}>
                                            <p>
                                                {t('common:user-data.surname')}<br />
                                                {t('common:user-data.firstname')}<br />
                                                {t('common:user-data.email')}
                                            </p>
                                            <p className="ml-4">
                                                {user.surname}<br />
                                                {user.firstname}<br />
                                                {user.email}
                                            </p>
                                        </div>
                                        {hasInstaller ? ("") : (
                                            <div className="col-12">
                                                <p>
                                                    <Trans i18nKey="myprofilepage:UserProfile.select-installer">
                                                        Si vous le souhaitez, vous pouvez ajouter un de nos installateurs. Cela lui permettra d'avoir accès aux données de vos installations et de pouvoir vous assistez pour le monitoring.<br />Pour ce faire, sélectionnez un installateur dans la liste ci-dessous et validez.
                                                    </Trans>
                                                </p>
                                            </div>
                                        )}
                                        <div className="col-lg-2">
                                            <p>{t('common:user-data.installer')}</p>
                                        </div>
                                        <div className="col-lg-10">
                                            {hasInstaller ? (
                                                <p>{user.installer[0].firstname} {user.installer[0].surname}</p>
                                            ) : (
                                                installersList.length > 0 ? (
                                                    <Auxil>
                                                        <select onChange={installerChangeHandler} className={classes.selectInstaller}>
                                                            <option value="999999">-</option>
                                                            {installersList.map((installer,idx) => {
                                                                return (
                                                                    <option value={installer.id}>{installer.firstname} {installer.surname}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <button onClick={attachInstaller} className={`btn btn-primary ${classes.submitButton}`}>{t('common:buttons.select')}</button>
                                                        {/* <a href="#" onClick={attachInstaller}>Sélectionner</a> */}
                                                    </Auxil>
                                                ) : ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <img className={classes.profileSection__icon} src={require("../../_assets/img/icone-battery-UP-IMG-module.png")} alt={t('common:icons.device')} />
                                            <h4 className={classes.profileSection__title}>{t('common:devices.device_plural')}</h4>
                                            <Link to="/register-module"><i class="icofont-ui-add"></i></Link>
                                        </div>
                                        <div className={`col-lg-12 ${classes.modulesList}`}>
                                            <div className="row">
                                                {modules.length > 0 ? (
                                                    modules.map((module,idx) => {
                                                        return (
                                                            <div className={`${classes.moduleItem}`} key={idx}>
                                                                <h4>
                                                                    {module.protocol.id === 1 ? (
                                                                        <img className={classes.deviceIcon} src={require("../../_assets/img/icon_devices_battery.png")} alt={t('common:icons.battery')} />
                                                                    ) : module.protocol.id === 2 || module.protocol.id === 3 || module.protocol.id === 4 || module.protocol.id === 5 ? (
                                                                        <img className={classes.deviceIcon} src={require("../../_assets/img/icon_devices_em.png")} alt={t('common:icons.energy-meter')} />
                                                                    ) : <i className={`icofont-micro-chip`}></i>}
                                                                    {/* <i className={`icofont-micro-chip`}></i> */}
                                                                    {module.module_name}
                                                                </h4>
                                                                <div className={classes.moduleItem__description}>
                                                                    <div className={classes.moduleItem__info}>
                                                                        <p>{module.mac_address}</p>
                                                                        <p>{module.protocol.brand}</p>
                                                                        {/* <p>{module.protocol.product}</p> */}
                                                                    </div>
                                                                    <div className={classes.moduleItem__address}>
                                                                        <p>{module.address.installation_name}<br />{module.address.locality}, {module.address.country.name_fr}</p>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.moduleItem__buttons}>
                                                                    <div className={`${classes.actionButton} ${classes.editBtn}`}><Link to={{ pathname: `/edit-module/${module.id}`,state: { prevPath: history.location.pathname } }}></Link></div>
                                                                    <div target={module.id} onClick={deletionModuleModalHandler} className={`${classes.actionButton} ${classes.deleteBtn}`}></div>
                                                                    {/* <img target={module.id} onClick={deletionModuleModalHandler} src={require("../../_assets/img/icon_delete_btn.png")} alt="" /> */}
                                                                    {/* <img src={require("../../_assets/img/icon_delete_btn.png")} alt="" /> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })) : hasModules ? <Spinner /> : (
                                                        <div className="col-lg-12">
                                                            <p>
                                                                <Trans i18nKey="myprofilepage:UserProfile.no-registered-device">
                                                                    You do not have any registered module<br />You only have access to our demo module.
                                                                </Trans>
                                                            </p>
                                                            <Link to="/register-module">{t('myprofilepage:UserProfile.register-device')}</Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className={classes.profileSection__title}>Sermatec devices</h4>
                                            <Link to="/register-sermatec"><i class="icofont-ui-add"></i></Link>
                                        </div>
                                        <div className={`col-lg-12 ${classes.sermatecsList}`}>
                                            <div className="row">
                                                {sermatecs.length > 0 ? (
                                                    sermatecs.map((sermatec,idx) => {
                                                        return (
                                                            <div className={`${classes.sermatecItem}`} key={idx}>
                                                                <h4><i className={`icofont-micro-chip`}></i>{sermatec.serial_number}</h4>
                                                                <div className={classes.sermatecItem__description}>
                                                                    <div className={classes.sermatecItem__info}>
                                                                    </div>
                                                                    <div className={classes.sermatecItem__address}>
                                                                        {sermatec.address.locality}, {sermatec.address.country.name_en}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                })) : hasSermatecs ? <Spinner /> : (
                                                        <div className="col-lg-12">
                                                            <p>You do not have any registered sermatec device<br/>
                                                            You only have access to our demo module.</p>
                                                            <Link to="/register-sermatec">Register a sermatec device</Link>
                                                        </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <img className={classes.profileSection__icon} src={require("../../_assets/img/IMG-icone-battery-up-adresse.png")} alt={t('common:icons.address')} />
                                            <h4 className={classes.profileSection__title}>{t('common:contact.address_plural')}</h4>
                                            <Link to="/add-address"><i class="icofont-ui-add"></i></Link>
                                        </div>
                                        <div className={`col-lg-5 ${classes.addressesList}`}>
                                            {typeof user.addresses !== 'undefined' ? (
                                                user.addresses.length > 0 ? (
                                                    user.addresses.map((address,idx) => {
                                                        return (
                                                            <div className={`${classes.addressItem}`} key={idx}>
                                                                <h4 className={classes.addressItem__title}><i class="icofont-location-pin"></i>{address.installation_name}<span>{address.locality}, {address.country.name_fr}</span></h4>
                                                                {/* <Link to={`/edit-address/${address.id}`}><i className="icofont-ui-edit"></i></Link> */}
                                                                {/* <i target={address.id} onClick={deletionModalHandler} className="icofont-ui-delete"></i> */}
                                                                {/* <Link to={{pathname: `/edit-user/${auth.id}`, state: { prevPath: history.location.pathname }}}><i className="icofont-ui-delete"></i></Link> */}
                                                                <div className={classes.addressItem__content}>
                                                                    <p>
                                                                        {address.line1}, {address.street_number}{address.line2 !== "" ? (<br />) : ""}
                                                                        {address.line2 !== "" ? (address.line2) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {address.zipcode} {address.locality}
                                                                    </p>
                                                                    <p>
                                                                        {address.country.name_fr}
                                                                    </p>
                                                                </div>
                                                                <div className={classes.addressItem__buttons}>
                                                                    <div className={`${classes.actionButton} ${classes.editBtn}`}><Link to={`/edit-address/${address.id}`}></Link></div>
                                                                    <div target={address.id} onClick={deletionModalHandler} className={`${classes.actionButton} ${classes.deleteBtn}`}></div>
                                                                    {/* <img target={module.id} onClick={deletionModuleModalHandler} src={require("../../_assets/img/icon_delete_btn.png")} alt="" /> */}
                                                                    {/* <img src={require("../../_assets/img/icon_delete_btn.png")} alt="" /> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <p>{t('myprofilepage:UserProfile.no-registered-address')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            ) : <Spinner />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Data>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-lg-8">
                        <p>{user.email}</p>
                    </div>
                    <div className="col-lg-4">
                        <Link to="/change-password">Modifier mot de passe | </Link>
                        <Link to={`/edit-user/${user.id}`}>Modifier profil</Link>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default UserProfile;
