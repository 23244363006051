import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../../_services/dataService';
import { moduleService } from '../../../_services/moduleService';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';

import AlarmCard from '../Cards/AlarmCard';

import classes from './PylontechAlarms.module.scss';

// const PylontechAlarms = (props) => {
const PylontechAlarms = React.memo(props => {
    const { t } = useTranslation(['common','dashboard']);
    // const [pylontech,setPylontech] = useState([]);
    const [pylontechsAlarms,setPylontechsAlarms] = useState([]);
    const macAddress = [props.mac_address];
    const [pylontechName,setPylontechName] = useState("Pylontech");
    const [emptyReturn,setEmptyReturn] = useState(false);

    useEffect(() => {
        // if (macAddress) {
        moduleService.getNameByMacAddress(macAddress)
            .then(response => {
                // if (response !== 'empty') {
                // console.log("response getNameByMacAddress");
                // console.log(response);
                setPylontechName(response);
                // }
            });
        // dataService.pylontechAlarmsOld(macAddress)
        dataService.pylontechAlarms(macAddress)
            .then(response => {
                // if (response !== 'empty') {
                console.log("response pylontechAlarms");
                console.log(response);
                // setPylontechsAlarms(Object.values(response));
                setPylontechsAlarms(response);
                // }
                // if (Object.values(response).length === 0) {
                if (response.length === 0) {
                    setEmptyReturn(true);
                }
            });
        // } else {
        // setPylontech([]);
        // setPylontechsAlarms([]);
        // }
        // },[macAddress]);
    },[props.mac_address]);

    // console.log("pylontechsAlarms");
    // console.log(pylontechsAlarms);
    return (
        <Aux>
            {/* <div className={`col-md-12 ${classes.AdditionalBatteriesData__list}`}> */}
            {pylontechsAlarms.length > 0 ? (
                // pylontechsAlarms.map((pylontech,idx) =>
                pylontechsAlarms.map((alarms,idx) =>
                    <div className={classes.alarmsWrapper} key={idx}>
                        {/* <h4>{t('common:datas.battery.battery')} - {pylontech.mac_address}</h4> */}
                        <h4 className={classes.title}>Alertes 7 derniers jours - {pylontechName}</h4>
                        {!alarms.prot_disc_oi && !alarms.prot_chg_oi && !alarms.prot_cell_ut && !alarms.prot_cell_ot && !alarms.prot_cell_uv && !alarms.prot_cell_ov && !alarms.prot_sys_err && !alarms.alar_chg_hi && !alarms.alar_dischg_hi && !alarms.alar_cell_lt && !alarms.alar_cell_ht && !alarms.alar_cell_lv && !alarms.alar_cell_hv && !alarms.alar_comm_fail && !alarms.req_charge_enable && !alarms.req_dischg_enable && !alarms.req_forcechg_1 && !alarms.req_forcechg_2 && <p>No alarms</p>}
                        {/* <p className={classes.date}>{t('common:datas.last-record')}: {pylontech.date}</p> */}
                        <div className={`${classes.alarms}`}>
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_disc_oi')} date={alarms.prot_disc_oi} />
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_chg_oi')} date={alarms.prot_chg_oi} />
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_cell_ut')} date={alarms.prot_cell_ut} />
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_cell_ot')} date={alarms.prot_cell_ot} />
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_cell_uv')} date={alarms.prot_cell_uv} />
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_cell_ov')} date={alarms.prot_cell_ov} />
                            <AlarmCard type="battery" name={t('common:datas.battery.prot_sys_err')} date={alarms.prot_sys_err} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_chg_hi')} date={alarms.alar_chg_hi} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_dischg_hi')} date={alarms.alar_dischg_hi} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_cell_lt')} date={alarms.alar_cell_lt} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_cell_ht')} date={alarms.alar_cell_ht} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_cell_lv')} date={alarms.alar_cell_lv} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_cell_hv')} date={alarms.alar_cell_hv} />
                            <AlarmCard type="battery" name={t('common:datas.battery.alar_comm_fail')} date={alarms.alar_comm_fail} />
                            <AlarmCard type="battery" name={t('common:datas.battery.req_charge_enable')} date={alarms.req_charge_enable} />
                            <AlarmCard type="battery" name={t('common:datas.battery.req_dischg_enable')} date={alarms.req_dischg_enable} />
                            <AlarmCard type="battery" name={t('common:datas.battery.req_forcechg_1')} date={alarms.req_forcechg_1} />
                            <AlarmCard type="battery" name={t('common:datas.battery.req_forcechg_2')} date={alarms.req_forcechg_2} />
                        </div>
                    </div>
                    // )) : emptyReturn ? (
                    //     <p>{t('common:datas.battery.no-alarm')}</p>
                    // ) : macAddress.length > 0 ? (
                )) : !emptyReturn && macAddress.length > 0 ? (
                    <Spinner />
                ) : ""
            }
        </Aux>
    )
});

export default PylontechAlarms;