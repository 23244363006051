import React,{ Component,useEffect } from 'react';
import { Router,Route,Switch,Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components/Routes/PrivateRoute';

import i18n from "i18next";
import { withTranslation,Trans } from "react-i18next";

import classes from './BatteryUpPage.module.scss';

import Navigation from './Navigation/Navigation';
import Sidebar from './Sidebar/Sidebar';
import MainFooter from './Footer/MainFooter';
import GoTop from '../../_components/UI/GoTop/GoTop';
import Modal from '../UI/Modal/Modal';
import { history } from '../../_helpers/history';

import ClientChoice from '../Admin/ClientChoice';
import DeviceChoice from '../Dashboard/DeviceChoice';
import ConfigInstallations from '../Layout/ConfigInstallations';
import InstallationChoice from '../Layout/InstallationChoice';

import DataContainer from '../Dashboard/DataContainer';
import NDataContainer from '../Dashboard/NDataContainer';
import DevDataContainer from '../Dashboard/DevDataContainer';
import AlreadyRegistered from '../Admin/AlreadyRegistered';
import RegisterModule from '../Admin/RegisterModule';
import UserProfile from '../../_components/MyProfilePage/UserProfile';
import AddressForm from '../../_components/Form/AddressForm';
import UsersList from '../../_components/UsersPage/UsersList';
import UserInfo from '../../_components/Profile/UserInfo';
import UserForm from '../../_components/Form/UserForm';
import UserAlerts from '../../_components/Alerts/UserAlerts';
import Triggers from '../../_components/Triggers/Triggers';
import ReleaseNotesIndex from '../../_components/ReleaseNotes/ReleaseNotesIndex';
import ReleaseNoteForm from '../../_components/ReleaseNotes/ReleaseNoteForm';
import AddScenarioContainer from '../../_components/Scenarios/AddScenarioContainer';
import NewScenarioWrapper from '../../_components/Scenarios/NewScenarioWrapper';
import Predictions from '../../_components/Predictions/Predictions';

import { userService } from '../../_services/userService';
import { addressService } from '../../_services/addressService';
import { moduleService } from '../../_services/moduleService';
import { sermatecService } from '../../_services/sermatecService';
import ReleaseNotes from '../ReleaseNotes/ReleaseNotes';

// const BatteryUpPage = (props) => {
//     const user = localStorage.getItem("appState") !== null ? JSON.parse(localStorage.getItem('appState')).user : null;
//     const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
//     const sidebar = props.sidebar ? <Sidebar /> : "";
//     console.log("window.location.pathname");
//     console.log(window.location.pathname);
//     return (
//         <div className={`${classes.BatteryUpPage} ${props.sidebar ? classes.greyBg : ""}`} data-spy="scroll" data-offset="70">
//             <Navigation user={user} isLoggedIn={isLoggedIn} />
//             <div className={`container-fluid ${classes.BatteryUpContent}`}>
//                 <div className="row">
//                     <div className="col-md-3 col-lg-2 m-0 p-0">
//                         {sidebar}
//                     </div>
//                     <div className={props.sidebar ? "col-md-9 col-lg-10" : "col-lg-12 m-0 p-0"}>
//                         {props.children}
//                     </div>
//                 </div>
//             </div>
//             <MainFooter />
//         </div>
//     );
// };

/*class BatteryUpPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // selectedClient: JSON.parse(localStorage.getItem('appState')).user.id,
            // selectedDevice: 0,
            // selectedDeviceType: "module",
            selectedClient: this.props.selectedClient,
            selectedDevice: this.props.selectedDevice,
            selectedDeviceType: this.props.selectedDeviceType,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDeviceChoice = this.handleDeviceChoice.bind(this);
    }

    handleSelectChange(event) {
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        userService.getById(event.target.value)
            .then(response => {
                this.setState({ selectedClient: response });
                if (response.modules.length > 0) {
                    this.setState({ selectedDevice: response.modules[0] });
                    this.setState({ selectedDeviceType: "module" });
                } else if (response.sermatecs.length > 0) {
                    this.setState({ selectedDevice: response.sermatecs[0] });
                    this.setState({ selectedDeviceType: "sermatec" });
                } else {
                    this.setState({ selectedDevice: JSON.parse(localStorage.getItem('demo')) });
                    console.log("selected device changed => should rerender chart container !!!");
                    console.log(JSON.parse(localStorage.getItem('demo')));
                    this.setState({ selectedDeviceType: "module" });
                }
            });
        // this.setState({ selectedClient: event.target.value });
        // this.setState({ selectedDevice: 0 });
        // this.setState({ selectedDeviceType: "module" });
        // console.log("select new state");
        // console.log(this.state);
        // console.log("select new value");
        // console.log(this.state.selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(this.state.selectedClient));
    }

    handleDeviceChoice(event) {
        console.log("event.target");
        console.log(event.target.dataset.type);
        console.log(event.target.dataset.index);
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        if (event.target.dataset.type === "module") {
            moduleService.getById(event.target.dataset.index)
                .then(response => {
                    console.log("dashboard response module by id");
                    console.log(response);
                    this.setState({ selectedDevice: response });
                })
        }
        if (event.target.dataset.type === "sermatec") {
            sermatecService.getById(event.target.dataset.index)
                .then(response => {
                    this.setState({ selectedDevice: response });
                })
        }
        // this.setState({ selectedDevice: event.target.dataset.index });
        this.setState({ selectedDeviceType: event.target.dataset.type });
        console.log("select new state");
        console.log(this.state);
        // console.log("select new value");
        // console.log(this.state.selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(this.state.selectedClient));
    }

    render() {
        console.log("BUPage new !! state beginning of render function => selectedUser");
        console.log(this.state);
        const user = JSON.parse(localStorage.getItem('appState')).user;
        const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
        let clientChoice = "";
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
                clientChoice = (<div className="row">
                    <div className={`col-md-6 ${classes.clientChoice}`}>
                        <ClientChoice selectedClient={this.state.selectedClient} changed={this.handleSelectChange} />
                    </div>
                </div>);
            }
        }
        return (
            <div className={`${classes.BatteryUpPage}`} data-spy="scroll" data-offset="70">
                <Navigation user={user} isLoggedIn={isLoggedIn} />
                <div className={`container-fluid ${classes.BatteryUpContent}`}>
                    <div className="row">
                        <div className="col-md-3 col-lg-2 m-0 p-0">
                            <Sidebar />
                        </div>
                        <div className="col-md-9 col-lg-10 m-0 p-0">
                            <div className={classes.Dashboard}>
                                <div className="container-fluid">
                                    <div className="row">
                                        {clientChoice}
                                        <div className={`col-md-6 ${classes.clientChoice}`}>
                                            <DeviceChoice selectedClient={this.state.selectedClient} selectedDevice={this.state.selectedDevice} changed={this.handleDeviceChoice} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MainFooter />
            </div>
        );
    }
}*/

/*const BatteryUpPage = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
    console.log("user in BU Page");
    console.log(user);
    const selectedClient = user;
    // const selectedDevice = user.modules.length > 0 ? user.modules[0] : user.sermatecs.length > 0 ? user.sermatecs[0] : JSON.parse(localStorage.getItem('demo'));
    // const selectedDeviceType = user.modules.length > 0 ? "module" : user.sermatecs.length > 0 ? "sermatec" : "module";
    const selectedInstallation = user.addresses[0].id;
    console.log("selectedInstallation in BU Page");
    console.log(selectedInstallation);

    useEffect(() => {
        if (localStorage.getItem("demo") === null) {
            moduleService.getById(5600)
                .then(response => {
                    localStorage.setItem("demo",JSON.stringify(response));
                });
        }
    },[]);

    return (
        <div className={`${classes.BatteryUpPage}`} data-spy="scroll" data-offset="70">
            <Navigation user={user} isLoggedIn={isLoggedIn} />
            <div className={`container-fluid ${classes.BatteryUpContent}`}>
                <div className="row">
                    <div className="col-md-3 col-lg-2 m-0 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9 col-lg-10 m-0 p-0">
                        <div className={classes.centralPanel}>
                            <div className="container-fluid">
                                <div className="row">
                                    <ConfigInstallations selectedClient={selectedClient} selectedInstallation={selectedInstallation} />
                                </div>
                                <div className="row">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MainFooter />
        </div>
    );
}*/
class BatteryUpPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // selectedClient: this.props.selectedClient,
            selectedClient: JSON.parse(localStorage.getItem('appState')).user,
            // selectedInstallation: this.props.selectedInstallation,
            selectedInstallation: JSON.parse(localStorage.getItem('appState')).user.addresses.length > 0 ? JSON.parse(localStorage.getItem('appState')).user.addresses[0] : "",
            // mustReset: localStorage.getItem('mustReset') === 'no' ? false : true,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleInstallationChoice = this.handleInstallationChoice.bind(this);
    }

    handleSelectChange(event) {
        userService.getById(event.target.value)
            .then(response => {
                // this.setState({ selectedClient: response });
                // console.log('selected in handle');
                // console.log(response);
                if (response.addresses.length > 0) {
                    // this.setState({ selectedClient: response });
                    // this.setState({ selectedInstallation: response.addresses[0] });
                    this.setState({
                        selectedClient: response,
                        selectedInstallation: response.addresses[0]
                    });
                } else {
                    // this.setState({ selectedClient: response });
                    // this.setState({ selectedInstallation: JSON.parse(localStorage.getItem('demo')) });
                    this.setState({
                        selectedClient: response,
                        selectedInstallation: JSON.parse(localStorage.getItem('demo'))
                    });
                    // this.setState({ selectedInstallation: "" });
                }
            });
    }

    handleInstallationChoice(event) {
        addressService.getAddressById(event.target.value)
            .then(response => {
                this.setState({ selectedInstallation: response });
            });
    }

    // resetHandler() {
    //     localStorage.setItem("mustReset","no");
    //     history.push('/hardreset');
    // }

    componentDidMount() {
        console.log("culprit");
        // if(typeof this.state.selectedInstallation === "undefined") {
        // console.log("BU Page => props");
        // console.log(this.props);
        // console.log("it happens now => demo address !");
        console.log("BatteryUpPage");
        console.log(this.state.selectedClient);
        if (this.state.selectedClient.addresses.length === 0) {
            userService.getById(this.state.selectedClient.id)
                .then(response => {
                    // this.setState({
                    //     selectedClient: response,
                    //     selectedInstallation: response.addresses[0],
                    // });
                    console.log("get user by id if no address");
                    console.log(response);
                    console.log("user in local storage");
                    console.log(JSON.parse(localStorage.getItem('appState')).user);
                    let user = JSON.parse(localStorage.getItem('appState')).user;
                    if (response.modules.length > 0 && response.addresses.length > 0) {
                        user.modules = response.modules;
                        user.addresses = response.addresses;
                        let appState = {
                            isLoggedIn: true,
                            user: user
                        };
                        localStorage.setItem('appState',JSON.stringify(appState));
                        this.setState({
                            selectedClient: JSON.parse(localStorage.getItem('appState')).user,
                            selectedInstallation: JSON.parse(localStorage.getItem('appState')).user.addresses[0],
                        });
                        console.log("end of if");
                    } else {
                        addressService.getAddressById(29)
                            .then(response => {
                                this.setState({ selectedInstallation: response });
                            });
                        console.log("end of else");
                    }
                    console.log("end of both");
                });
            // console.log("In if");
            // addressService.getAddressById(29)
            //     .then(response => {
            //         this.setState({ selectedInstallation: response });
            //     });
        }
    }

    render() {
        const { t } = this.props;

        const user = JSON.parse(localStorage.getItem('appState')).user;
        const mainInfo = JSON.parse(localStorage.getItem('mainInfo'));
        // console.log('User before cleitn choice');
        // console.log(user);
        // console.log(mainInfo);
        const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
        let clientChoice = "";
        // console.log('Start check to display client choice');
        if (typeof user.roles !== 'undefined') {
            // console.log('First condition display client choice');
            if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
                // console.log('Second condition display client choice');
                // clientChoice = (<div className={`col-md-6 ${classes.clientChoice}`}>
                clientChoice = (<div className={`col-md-6`}>
                    {/* <ClientChoice handleSelectChange={this.handleSelectChange} changeClient={this.handleClient} /> */}
                    <ClientChoice selectedClient={this.state.selectedClient} changed={this.handleSelectChange} />
                </div>);
            }
        }
        let installChoice = "";
        if (this.state.selectedClient.addresses.length > 0) {
            installChoice = (<div className={`col-md-6`}>
                <InstallationChoice selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} changed={this.handleInstallationChoice} />
            </div>);
        }
        // console.log("this.state BU page");
        // console.log(this.state);
        return (
            <div className={`${classes.BatteryUpPage}`} data-spy="scroll" data-offset="70">
                {/* <Modal show={this.state.mustReset} modalClosed={resetCloseHandler}> */}
                {/* <Modal show={this.state.mustReset}>
                    <p>{t('common:reset.dear-customers')}</p>
                    <p>{t('common:reset.explanation')}</p>
                    <button onClick={this.resetHandler} className="">
                        {i18n.language === "fr" ? (
                            <img src={require("../../_assets/img/bouton-sign-out-battery-up-FR.png")} alt={t('common:user-interactions.sign-out')} style={{ width: "200px" }} />
                        ) : (
                            <img src={require("../../_assets/img/bouton-sign-out-battery-up-EN.png")} alt={t('common:user-interactions.sign-out')} style={{ width: "200px" }} />
                        )}
                    </button>
                </Modal> */}
                <Navigation user={user} isLoggedIn={isLoggedIn} />
                <div className={`container-fluid ${classes.BatteryUpContent}`}>
                    <div className="row">
                        <div className="col-md-3 col-lg-2 m-0 p-0">
                            <Sidebar />
                        </div>
                        <div className="col-md-9 col-lg-10 m-0 p-0">
                            <div className={classes.centralPanel}>
                                <div className="container-fluid">
                                    <div className="row">
                                        {clientChoice}
                                        {installChoice}
                                    </div>
                                    <div className="row">
                                        {/* {this.props.children} */}
                                        {/* <Route
                                            path='/dashboard'
                                            render={(props) => (
                                                <DataContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                            )}
                                        /> */}
                                        <Route
                                            path='/dashboard'
                                            render={(props) => (
                                                // <NDataContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} initBatteries={[]} />
                                                <NDataContainer selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} initBatteries={[]} />
                                            )}
                                        />
                                        <Route
                                            path='/already-registered'
                                            render={(props) => (
                                                <AlreadyRegistered />
                                            )}
                                        />
                                        <Route
                                            path='/register-module'
                                            render={(props) => (
                                                <RegisterModule {...props} />
                                            )}
                                        />
                                        <Route
                                            path='/edit-module/:id'
                                            render={(props) => (
                                                <RegisterModule {...props} />
                                            )}
                                        />
                                        <Route
                                            path='/my-profile'
                                            render={(props) => (
                                                <UserProfile />
                                            )}
                                        />
                                        <Route
                                            path='/add-address'
                                            render={(props) => (
                                                <AddressForm {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/edit-address/:id'
                                            render={(props) => (
                                                <AddressForm {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/edit-user/:id'
                                            render={(props) => (
                                                <UserForm {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/users'
                                            render={(props) => (
                                                <UsersList />
                                            )}
                                        />
                                        <Route
                                            path='/user-details/:id'
                                            render={(props) => (
                                                <UserInfo {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/my-alerts/'
                                            render={(props) => (
                                                <UserAlerts {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/release-notes-all/'
                                            render={(props) => (
                                                <ReleaseNotesIndex {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/add-release-note/'
                                            render={(props) => (
                                                <ReleaseNoteForm {...this.props} />
                                            )}
                                        />
                                        <Route
                                            path='/edit-release-note/:id'
                                            render={(props) => (
                                                <ReleaseNoteForm {...this.props} />
                                            )}
                                        />
                                        {/* <Route
                                            path='/edit-user/:id'
                                            render={(props) => (
                                                <UserForm {...this.props} />
                                            )}
                                        /> */}
                                        <Route
                                            path='/rgqdssvksefqsc'
                                            render={(props) => (
                                                // <AddScenarioContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                                <NewScenarioWrapper {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                            )}
                                        />
                                        <Route
                                            path='/aoiuaiuaoi'
                                            render={(props) => (
                                                // <AddScenarioContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                                <Predictions {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                            )}
                                        />
                                        <Route
                                            path='/rokvmwsevkmmoejvwse'
                                            render={(props) => (
                                                <DevDataContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                            )}
                                        />
                                        <Route
                                            path='/triggers/'
                                            render={(props) => (
                                                <Triggers {...this.props} selectedClient={this.state.selectedClient} />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MainFooter />
            </div>
        );
    }
}

export default (withTranslation(['common','contactpage'])(BatteryUpPage));
