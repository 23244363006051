// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StepMetrics_scenarioStep__title__M5GGK {\n  color: aqua; }\n\n.StepMetrics_scenarioStep__list__3MznO {\n  display: flex;\n  flex-wrap: wrap; }\n\n.StepMetrics_scenarioStep__item__3d30e {\n  display: flex;\n  align-items: baseline;\n  margin: 0 10px;\n  flex: 1 1 20%; }\n  .StepMetrics_scenarioStep__item__3d30e span {\n    margin-left: 5px;\n    font-weight: 600;\n    position: relative;\n    top: 1px; }\n  .StepMetrics_scenarioStep__item__3d30e input[type=checkbox] {\n    height: 0;\n    width: 0;\n    visibility: hidden; }\n  .StepMetrics_scenarioStep__item__3d30e .StepMetrics_switchLabel__TQaNL {\n    cursor: pointer;\n    width: 54px;\n    height: 24px;\n    background: grey;\n    display: block;\n    border-radius: 100px;\n    position: relative;\n    color: white;\n    font-weight: 700;\n    font-size: 12px;\n    padding-top: 6px;\n    text-transform: uppercase;\n    text-align: right;\n    padding-right: 4px;\n    padding-left: 0; }\n    .StepMetrics_scenarioStep__item__3d30e .StepMetrics_switchLabel__TQaNL.StepMetrics_on__2OKu3 {\n      text-align: left;\n      padding-left: 4px;\n      padding-right: 0; }\n    .StepMetrics_scenarioStep__item__3d30e .StepMetrics_switchLabel__TQaNL:after {\n      content: '';\n      position: absolute;\n      top: 2px;\n      left: 2px;\n      width: 20px;\n      height: 20px;\n      background: #fff;\n      border-radius: 90px;\n      transition: 0.3s; }\n  .StepMetrics_scenarioStep__item__3d30e input:checked + .StepMetrics_switchLabel__TQaNL {\n    background: #bada55; }\n  .StepMetrics_scenarioStep__item__3d30e input:checked + .StepMetrics_switchLabel__TQaNL:after {\n    left: calc(100% - 2px);\n    transform: translateX(-100%); }\n  .StepMetrics_scenarioStep__item__3d30e .StepMetrics_laswitchLabelbel__2T3hG:active:after {\n    width: 130px; }\n", ""]);
// Exports
exports.locals = {
	"scenarioStep__title": "StepMetrics_scenarioStep__title__M5GGK",
	"scenarioStep__list": "StepMetrics_scenarioStep__list__3MznO",
	"scenarioStep__item": "StepMetrics_scenarioStep__item__3d30e",
	"switchLabel": "StepMetrics_switchLabel__TQaNL",
	"on": "StepMetrics_on__2OKu3",
	"laswitchLabelbel": "StepMetrics_laswitchLabelbel__2T3hG"
};
module.exports = exports;
