import React,{ useState,useEffect } from 'react';

import SocVisual from './SocVisual';

const SocVisualWrapper = (props) => {
    console.log("props with batteries");
    console.log(props);
    const [batteries,setBatteries] = useState([]);

    useEffect(() => {
        setBatteries(props.batteries);
    },[props.batteries]);


    return (
        batteries.length > 0 ? (
            batteries.map((battery,idx) => {
                return (
                    <div key={idx} className={`col-sm-12 col-md-6 col-lg-4`}>
                        <SocVisual mac_address={battery} />
                    </div>
                )
            })
        ) : ""
    )
};

export default SocVisualWrapper;