// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.Data_Data__wq83t {\n  background-color: #fff;\n  margin: 15px 0;\n  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);\n  flex: 1 1; }\n  .Data_Data__wq83t h5 {\n    text-align: left;\n    font-size: 18px;\n    font-weight: 700; }\n    .Data_Data__wq83t h5.Data_dataTitle__3bK69 {\n      background-color: #2f3133;\n      color: #fff;\n      padding: 15px;\n      font-size: 22px; }\n    .Data_Data__wq83t h5 .Data_dataIcon__3-ylP {\n      height: 50px;\n      margin-right: 15px; }\n    .Data_Data__wq83t h5 svg {\n      margin-right: 10px; }\n", ""]);
// Exports
exports.locals = {
	"Data": "Data_Data__wq83t",
	"dataTitle": "Data_dataTitle__3bK69",
	"dataIcon": "Data_dataIcon__3-ylP"
};
module.exports = exports;
