import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';

// import Navigation from '../_components/UI/Navigation/Navigation';
import DiscoverApp from '../_components/AppPage/DiscoverApp';
import IntroApp from '../_components/AppPage/IntroApp';
import ChartApp from '../_components/AppPage/ChartApp';
import TakeControl from '../_components/AppPage/TakeControl';
// import MainFooter from '../_components/Layout/Footer/MainFooter';
// import GoTop from '../_components/UI/GoTop/GoTop';

// import classes from './AppPage.module.scss';

const AppPage = (props) => {
    return (
        // <BatteryUpPage sidebar={false}>
        <GenericPage>
            <DiscoverApp />
            <IntroApp />
            <ChartApp />
            <TakeControl />
            {/* </BatteryUpPage> */}
        </GenericPage>
        // <div data-spy="scroll" data-offset="70">
        //     <Navigation />
        //     <DiscoverApp />
        //     <IntroApp />
        //     <ChartApp />
        //     <TakeControl />
        //     <MainFooter />
        //     <GoTop scrollStepInPx="50" delayInMs="16.66" />
        // </div>
    );
}

export default AppPage;