import React,{ useState,useEffect } from 'react';

import i18n from "i18next";

import { weatherService } from '../../_services/weatherService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './FullWeather.module.scss';

import Data from '../Data/Data';
import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

const FullWeather = (props) => {
    const install = props.install;
    // console.log("props.install");
    // console.log(props.install);

    const [fullWeather,setFullWeather] = useState([]);
    const [forecast,setForecast] = useState([]);
    const weatherZone = (install && install.weather_zone) ? "weather-" + install.country.alpha2_code.toLowerCase() + "-" + install.weather_zone.toLowerCase() : "";
    const offset = install ? install.timezone_offset : 0;

    useEffect(() => {
        // weatherService.getFullWeather(weatherZone,offset)
        //     .then(response => {
        //         console.log("response.weather");
        //         console.log(response.weather);
        //         console.log("response.forecast");
        //         console.log(response.forecast);
        //     });
        weatherService.getFullWeatherMongo(weatherZone,offset)
            .then(response => {
                // console.log("response.weather MONGO");
                // console.log(response.weather);
                // console.log("response.forecast MONGO");
                // console.log(response.forecast);
                setFullWeather(response.weather);
                setForecast(response.forecast);
            });

    },[weatherZone]);

    return (
        <Data
            icon="icone-battery-UP-IMG-meteo"
            title="Weather"
            type="weatherForecast"
        >
            <div className={classes.WeatherWithForecast}>
                {install ? (
                    i18n.language === "fr" ? (
                        <h4>{install.locality}, {install.country.name_fr}</h4>
                    ) : (
                        <h4>{install.locality}, {install.country.name_en}</h4>
                    )
                ) : ""}

                {fullWeather.length > 0 ? (
                    <Aux>
                        <p className={classes.subtitle}>Today</p>
                        <div className={classes.weatherToday}>
                            <div className={classes.current}>
                                <p className={classes.columnTitle}>Right now</p>
                                <div className={classes.todayIcon}>
                                    <p className={classes.currentTemperatures}><img src={`http://openweathermap.org/img/wn/${fullWeather[0].weather[0].icon}@4x.png`} alt="" /></p>
                                </div>
                                <div className={classes.todayData}>
                                    <div>
                                        <h5>Temperature</h5>
                                        <p className={classes.weatherDataItem}>{fullWeather[0].temp} °C</p>
                                        <h5>Sunrise</h5>
                                        <p className={classes.weatherDataItem}>{fullWeather[0].sunrise}</p>
                                        <h5>Sunset</h5>
                                        <p className={classes.weatherDataItem}>{fullWeather[0].sunset}</p>
                                    </div>
                                    <div>
                                        <h5>UVI</h5>
                                        <p className={classes.weatherDataItem}>{fullWeather[0].uvi}</p>
                                        <h5>Clouds</h5>
                                        <p className={classes.weatherDataItem}>{fullWeather[0].clouds}</p>
                                        <h5>Visibility</h5>
                                        <p className={classes.weatherDataItem}>{fullWeather[0].visibility}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.nextHours}>
                                <p className={classes.columnTitle}>+1H</p>
                                <div>
                                    <p className={classes.currentTemperatures}><img src={`http://openweathermap.org/img/wn/${fullWeather[1].weather[0].icon}@2x.png`} alt="" /></p>
                                </div>
                                <div>
                                    <h5>Temperature</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[1].temp} °C</p>
                                    <h5>UVI</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[1].uvi}</p>
                                    <h5>Clouds</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[1].clouds}</p>
                                    <h5>Visibility</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[1].visibility}</p>
                                </div>
                            </div>
                            <div className={classes.nextHours}>
                                <p className={classes.columnTitle}>+2H</p>
                                <div>
                                    <p className={classes.currentTemperatures}><img src={`http://openweathermap.org/img/wn/${fullWeather[2].weather[0].icon}@2x.png`} alt="" /></p>
                                </div>
                                <div>
                                    <h5>Temperature</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[2].temp} °C</p>
                                    <h5>UVI</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[2].uvi}</p>
                                    <h5>Clouds</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[2].clouds}</p>
                                    <h5>Visibility</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[2].visibility}</p>
                                </div>
                            </div>
                            <div className={classes.nextHours}>
                                <p className={classes.columnTitle}>+3H</p>
                                <div>
                                    <p className={classes.currentTemperatures}><img src={`http://openweathermap.org/img/wn/${fullWeather[3].weather[0].icon}@2x.png`} alt="" /></p>
                                </div>
                                <div>
                                    <h5>Temperature</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[3].temp} °C</p>
                                    <h5>UVI</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[3].uvi}</p>
                                    <h5>Clouds</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[3].clouds}</p>
                                    <h5>Visibility</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[3].visibility}</p>
                                </div>
                            </div>
                            <div className={classes.nextHours}>
                                <p className={classes.columnTitle}>+4H</p>
                                <div>
                                    <p className={classes.currentTemperatures}><img src={`http://openweathermap.org/img/wn/${fullWeather[4].weather[0].icon}@2x.png`} alt="" /></p>
                                </div>
                                <div>
                                    <h5>Temperature</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[4].temp} °C</p>
                                    <h5>UVI</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[4].uvi}</p>
                                    <h5>Clouds</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[4].clouds}</p>
                                    <h5>Visibility</h5>
                                    <p className={classes.weatherDataItem}>{fullWeather[4].visibility}</p>
                                </div>
                            </div>
                        </div>
                    </Aux>

                ) : <Spinner />}
                {forecast.length > 0 ? (
                    <Aux>
                        <p className={classes.subtitle}>Next 7 days</p>
                        <div className={classes.forecast}>
                            {forecast.map((day,i) => (
                                <div className={classes.forecastDay} key={day.dt}>
                                    <p className={classes.columnTitle}>{day.date}</p>
                                    {/* <p>{("0" + (new Date(day.sunrise * 1000)).getDate()).slice(-2) + "/" + ("0" + (new Date(day.sunrise * 1000)).getMonth() + 1).slice(-2)}</p> */}
                                    <img src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`} alt="" />
                                    <h5>Temperature</h5>
                                    <p className={classes.weatherDataItem}>{Math.floor(day.temp.day)} °C</p>
                                    <h5>Sunrise</h5>
                                    <p className={classes.weatherDataItem}>{day.sunrise}</p>
                                    <h5>Sunset</h5>
                                    <p className={classes.weatherDataItem}>{day.sunset}</p>
                                    <h5>UVI</h5>
                                    <p className={classes.weatherDataItem}>{day.uvi}</p>
                                    <h5>Clouds</h5>
                                    <p className={classes.weatherDataItem}>{day.clouds} %</p>
                                    {/* {uvForecast.length > 0 ? <p className={classes.forecast__uv}>UV: {uvForecast[i].value}</p> : ""} */}
                                </div>
                            ))}
                        </div>
                    </Aux>
                ) : <Spinner />}
            </div>
        </Data >
    );
};

export default FullWeather;