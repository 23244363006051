import React,{ useState,useEffect } from 'react';
import { AreaChart,linearGradient,XAxis,YAxis,Tooltip,Area,CartesianGrid } from 'recharts';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import classes from './ActivityChart.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import Data from './Data';
import Button from '../Form/Inputs/Button';
import Spinner from '../UI/Spinner/Spinner';
import { dataService } from '../../_services/dataService';

const ActivityChart = (props) => {
    // const [soc,setSoc] = useState([]);
    const [powerLogs,setPowerLogs] = useState([]);
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(null);

    useEffect(() => {
        // dataService.socInPeriod()
        //     .then(response => {
        //         setSoc(response.data.soc);
        //     });
        dataService.powerLogs()
            .then(response => {
                console.log("response");
                console.log(response);
                setPowerLogs(response.data.power);
            });
    },[]);

    const dayPickerHandler = date => {
        setStartDate(date);
        setEndDate(date);
    };

    const datePickerHandler = dates => {
        const [start,end] = dates;
        setStartDate(start);
        setEndDate(end);
        // if (endDate === null) {
        //     setEndDate(start);
        // }
    };

    const dateChangeHandler = () => {
        console.log("go");
        // console.log("endDate");
        // console.log(endDate);
        let from = startDate.getFullYear().toString() + ('0' + (startDate.getMonth() + 1)).slice(-2) + ('0' + startDate.getDate()).slice(-2);
        let to = endDate.getFullYear().toString() + ('0' + (endDate.getMonth() + 1)).slice(-2) + ('0' + endDate.getDate()).slice(-2);
        console.log(from.toString());
        console.log(to.toString());
        dataService.powerLogs(from,to)
            .then(response => {
                setPowerLogs(response.data.power);
            });
    }

    console.log("Power Logs");
    console.log(powerLogs);

    const gradientOffset = () => {
        const dataMax = Math.max(...powerLogs.map((i) => i.power));
        const dataMin = Math.min(...powerLogs.map((i) => i.power));

        if (dataMax <= 0) {
            return 0
        }
        else if (dataMin >= 0) {
            return 1
        }
        else {
            return dataMax / (dataMax - dataMin);
        }
    }
    const off = gradientOffset();
    // if ((soc.data.soc).length === 0) return (<Spinner />);
    // if ((soc.data)) return (<Spinner />);
    // if (Object.keys(soc).length === 0 || Object.keys(powerLogs).length === 0) return (<Spinner />);
    if (Object.keys(powerLogs).length === 0) return (<Spinner />);

    return (
        <Data
            icon="icone-battery-UP-IMG-Graph"
            title="Graph"
            type="activityChart"
        >
            <div>
                Choisir une date
                <DatePicker
                    className={classes.DatePicker}
                    selected={startDate}
                    onChange={dayPickerHandler} />
            </div>
            <div>
                Chhoisir une période
                <DatePicker
                    className={classes.DatePicker}
                    selected={startDate}
                    onChange={datePickerHandler}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    shouldCloseOnSelect={false}
                // showDisabledMonthNavigation
                />
            </div>

            { /* Change date(s) for the activity chart */}
            {/* <Button
                className="submitButton"
                label={'Apply'}
                action={dateChangeHandler} /> */}

            <AreaChart
                width={450}
                height={250}
                data={powerLogs}
                margin={{
                    top: 20,right: 20,bottom: 20,left: 20,
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor="green" stopOpacity={1} />
                        <stop offset={off} stopColor="red" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="day" />
                <YAxis yAxisId="right" orientation="right" dataKey="soc" domain={[0,100]} unit="%" />
                <YAxis yAxisId="left" dataKey="power" />
                {/* <YAxis yAxisId="left" dataKey="power" domain={['dataMin - 300','dataMax + 300']} /> */}
                <Area yAxisId="right" dataKey="soc" stroke="#8884d8" fill="#8884d8" />
                {/* <Area yAxisId="left" dataKey="power" stroke="#8884d8" fill="#8884d8" /> */}
                <Area yAxisId="left" dataKey="power" stroke="#8884d8" fill="url(#splitColor)" />
                <Tooltip />
            </AreaChart>
        </Data>
    );

};

export default ActivityChart;