import React from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

function AddScenarioPage() {
    return (
        <BatteryUpPage>
        </BatteryUpPage>
        // <p>test</p>
    );
}

export default AddScenarioPage;
