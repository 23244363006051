import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const BasicGauge = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const average = parseFloat(props.average);
    const max = parseFloat(props.max);

    am4core.useTheme(am4themes_animated);

    useLayoutEffect(() => {
        console.log("average acrel GAUGE");
        console.log(props.average);
        console.log("power acrel GAUGE");
        console.log(props.power);
        // create chart
        var chart = am4core.create("gaugediv",am4charts.GaugeChart);
        chart.innerRadius = am4core.percent(82);

        /**
         * Normal axis
         */

        var axis = chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        // axis.min = max * -1;
        axis.max = max;
        axis.strictMinMax = true;
        axis.renderer.radius = am4core.percent(80);
        axis.renderer.inside = true;
        axis.renderer.line.strokeOpacity = 1;
        axis.renderer.ticks.template.disabled = false
        // axis.renderer.ticks.template.disabled = true
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.length = 10;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = 32;
        axis.renderer.labels.template.adapter.add("text",function (text) {
            // return text + "kW";
            return text;
        })

        /**
         * Axis for ranges
         */

        var colorSet = new am4core.ColorSet();

        var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
        axis2.min = 0;
        // axis2.min = max * -1;
        axis2.max = max;
        axis2.strictMinMax = true;
        axis2.renderer.labels.template.disabled = true;
        axis2.renderer.ticks.template.disabled = true;
        axis2.renderer.grid.template.disabled = true;

        var range = axis2.axisRanges.create();
        // range.value = 0;
        range.value = max * -1;
        range.endValue = average >= 0 ? 0 : average;
        range.axisFill.fillOpacity = 1;
        // range.axisFill.fill = colorSet.getIndex(0);
        // range.axisFill.fill = "#3d009e";
        range.axisFill.fill = "#000";
        // range.axisFill.fill = "#ff063c";

        var range2 = axis2.axisRanges.create();
        // range2.value = average;
        range2.value = average >= 0 ? 0 : average;
        range2.endValue = average >= 0 ? average : 0;
        range2.axisFill.fillOpacity = 1;
        // range2.axisFill.fill = colorSet.getIndex(2);
        // range2.axisFill.fill = "#000";
        range2.axisFill.fill = average >= 0 ? "#3d009e" : "#ff063c";

        var range3 = axis2.axisRanges.create();
        // range2.value = average;
        range3.value = average >= 0 ? average : 0;
        range3.endValue = max;
        range3.axisFill.fillOpacity = 1;
        range3.axisFill.fill = "#000";

        /**
         * Label
         */

        // var label = chart.radarContainer.createChild(am4core.Label);
        // label.isMeasured = false;
        // label.fontSize = 30;
        // label.x = am4core.percent(50);
        // label.y = am4core.percent(50);
        // label.horizontalCenter = "middle";
        // label.verticalCenter = "top";
        // label.text = average + " kW";


        /**
         * Hand
         */

        var hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(0);
        hand.startWidth = 5;
        hand.pin.disabled = true;
        hand.value = average;

        // hand.events.on("propertychanged",function (ev) {
        //     range0.endValue = ev.target.value;
        //     range1.value = ev.target.value;
        //     label.text = axis2.positionToValue(hand.currentPosition).toFixed(1);
        //     axis2.invalidate();
        // });

        // setInterval(function () {
        //     var value = average;
        //     var animation = new am4core.Animation(hand,{
        //         property: "value",
        //         to: value
        //     },1000,am4core.ease.cubicOut).start();
        // },2000);

    },[props.average,max]);

    return (
        // <div id="gaugediv" style={{ width: "100%",height: "270px",transform: "translateY(-70px)",marginBottom: "-70px" }}></div>
        <div id="gaugediv" style={{ width: "100%",height: "198px",position: "relative",top: "-5px",marginBottom: "-10px" }}></div>
        // </Aux>
    );
}

export default BasicGauge;