import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
// import classes from './MainDataChart.module.scss';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';
import { dataService } from '../../../_services/dataService';

am4core.useTheme(am4themes_animated);

const SecondNewChart = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    console.log("props all devices chart");
    console.log(props);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        var chart = am4core.create('chartdivsec',am4charts.XYChart)

        // Create axes
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.opposite = true;

        // Create series
        function createSeries(field,name) {
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = field;
            series.dataFields.categoryY = "category";
            series.name = name;
            series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
            series.columns.template.height = am4core.percent(100);
            series.sequencedInterpolation = true;

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueX}";
            valueLabel.label.horizontalCenter = "left";
            valueLabel.label.dx = 10;
            valueLabel.label.hideOversized = false;
            valueLabel.label.truncate = false;

            var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
            categoryLabel.label.text = "{name}";
            categoryLabel.label.horizontalCenter = "right";
            categoryLabel.label.dx = -10;
            categoryLabel.label.fill = am4core.color("#fff");
            categoryLabel.label.hideOversized = false;
            categoryLabel.label.truncate = false;
        }

        chart.data = [
            {
                category: 'January',
                abs: 50000,
                rel: 50000,
            },
            {
                category: 'February',
                abs: 272760,
                rel: 232080,
            },
            {
                category: 'March',
                abs: 160920,
                rel: 43200,
            },
            {
                category: 'April',
                abs: 141360,
                rel: 6240,
            },
            {
                category: 'May',
                abs: 126600,
                rel: 143280,
            },
            {
                category: 'June',
                abs: 104880,
                rel: 73800,
            },
            {
                category: 'July',
                abs: 50000,
                rel: 50000,
            },
            {
                category: 'August',
                abs: 50000,
                rel: 50000,
            },
            {
                category: 'September',
                abs: 50000,
                rel: 50000,
            },
            {
                category: 'October',
                abs: 50000,
                rel: 50000,
            },
            {
                category: 'November',
                abs: 50000,
                rel: 50000,
            },
            {
                category: 'December',
                abs: 50000,
                rel: 50000,
            }
        ]


        createSeries('abs','Absorbed');
        createSeries('rel','Released');

    },[]);
    // }

    return (
        <div id="chartdivsec" style={{ width: "calc(100% - 40px)",height: "600px" }}></div>
    );
}

export default SecondNewChart;