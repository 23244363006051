import React,{ Component } from 'react';

import ReactWOW from 'react-wow';
import {Accordion, AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../_assets/css/color/color-default.scss';
import classes from './TakeControl.module.scss';

class TakeControl extends Component {
    render() {
        return (
            <section id="takeControl" className="faqs-section angle-gray-bg">
                <div className={`container ${classes.container}`}>
                

                    <div className="row">

                    <div className="col-lg-6">
                            <div className="text-center wow fadeInUp" data-wow-delay=".4s" >
                                <img src={require("../../_assets/img/IMG-smartphone-representation-graphique-detailles.jpg")} alt="Faq Image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div style={{textAlign:"left", marginTop:"30%"}}>
                            <h2>Prenez le contrôle</h2>
                            <p> <strong> Visualisez en un coup d’œil l’évolution de vos énergies..</strong> </p>
                            <ul>
                                <li>• Energie stockée dans la batterie</li>
                                <li>• Energie prélevé de la batterie</li>
                                <li>• Production des panneaux solaires</li>
                                <li>• Energie prélevée sur le réseau (ou source alternative)</li>
                                <li>• Energie injectée sur le réseau (si possible)</li>
                                <li>• Consommation totale de l'habitation</li>
                            </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        );
    }
}

export default TakeControl;