import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';
// import Navigation from '../_components/UI/Navigation/Navigation';
// import MainFooter from '../_components/Layout/Footer/MainFooter';
import ContactUs from '../_components/ContactPage/ContactUs';
import ContactForm from '../_components/Form/ContactForm';
import WhereAreWe from '../_components/ContactPage/WhereAreWe';
// import GoTop from '../_components/UI/GoTop/GoTop';

// import classes from './AppPage.module.scss';

const ContactPage = (props) => {
    return (
        // <BatteryUpPage sidebar={false}>
        <GenericPage>
            <ContactUs />
            {/* <ContactForm /> */}
            <WhereAreWe />
            {/* </BatteryUpPage> */}
        </GenericPage>
    );
}

export default ContactPage;