import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { dataService } from '../../../_services/dataService';

import Aux from '../../../_hoc/Auxil/Auxil';

import HoursChart from './HoursChart';
import FourthNewChart from './FourthNewChart';
import ThirdNewChart from './ThirdNewChart';

const AcrelChartsWrapper = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const macAddress = props.macAddress;
    const [acrAbsRel,setAcrAbsRel] = useState([]);
    const [acrAbsRelFull,setAcrAbsRelFull] = useState([]);
    const [acrAbsRelMonths,setAcrAbsRelMonths] = useState([]);
    const [acrAbsRelDays,setAcrAbsRelDays] = useState([]);
    console.log("props in AcrelChartsWrapper");
    console.log(props);

    useEffect(() => {
        dataService.acrelAbsRelByHour(macAddress)
            .then(response => {
                console.log("response acrelAbsRelByHour");
                console.log(response);
                setAcrAbsRel(response);
            });
        dataService.acrelAbsRelChartsData(macAddress)
            .then(response => {
                console.log("response acrelAbsRelChartsData");
                console.log(response);
                let newMonths = [];
                let monthsConversion = [
                    t('common:month.january-abbv'),
                    t('common:month.february-abbv'),
                    t('common:month.march-abbv'),
                    t('common:month.april-abbv'),
                    t('common:month.may-abbv'),
                    t('common:month.june-abbv'),
                    t('common:month.july-abbv'),
                    t('common:month.august-abbv'),
                    t('common:month.september-abbv'),
                    t('common:month.october-abbv'),
                    t('common:month.november-abbv'),
                    t('common:month.december-abbv')
                ]
                for (let index = 1; index < 13; index++) {
                    if (response.months[index]) {
                        let key = monthsConversion[index - 1]
                        // console.log("response.months[index].abs-end");
                        // console.log(response.months[index].abs_end);
                        // newMonths[key] = response.months[index];
                        let newEntry = {
                            month: key,
                            abs: (response.months[index].abs_end - response.months[index].abs_start) * -1,
                            rel: response.months[index].rel_end - response.months[index].rel_start,
                            // abs: response.months[index]["abs-end"] - response.months[index]["abs-start"],
                            // rel: response.months[index]["rel-end"] - response.months[index]["rel-start"],
                        }
                        newMonths.push(newEntry);
                    }
                }
                let newDays = [];
                for (let index = 1; index < 32; index++) {
                    if (response.days[index]) {
                        let newEntry = {
                            category: index,
                            abs: response.days[index].abs_end - response.days[index].abs_start,
                            rel: response.days[index].rel_end - response.days[index].rel_start,
                            // abs: response.months[index]["abs-end"] - response.months[index]["abs-start"],
                            // rel: response.months[index]["rel-end"] - response.months[index]["rel-start"],
                        }
                        newDays.push(newEntry);
                    }
                }
                console.log("newMonths after conversion");
                console.log(newMonths);
                setAcrAbsRelDays(newDays);
                setAcrAbsRelMonths(newMonths);
                setAcrAbsRelFull(response);
            });
    },[]);

    return (
        <Aux>
            <ThirdNewChart data={acrAbsRelMonths} />
            <FourthNewChart data={acrAbsRelDays} />
            <HoursChart data={acrAbsRel} />
        </Aux>
    )
};

export default AcrelChartsWrapper;