import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

function ReleaseNotesAdminPage() {
    return (
        <BatteryUpPage>
        </BatteryUpPage>
    );
}

export default ReleaseNotesAdminPage;
