import React,{ useState,useEffect } from 'react';

import { moduleService } from '../../_services/moduleService';

const TestComponent = (props) => {
    // let macaddress = props.macaddress;
    // let macaddress = "2832376589399412";
    let macaddress = "BU-50-02-91";
    const [device,setDevice] = useState([]);
    useEffect(() => {
        moduleService.getDeviceConfig(macaddress)
            .then(response => {
                console.log("response config of device in IoT ?");
                console.log(response);
            });
    },[macaddress]);

    return (
        <p>TestComponent</p>
    )
}

export default TestComponent;