import { authHeader } from '../_helpers/auth-header';
import axios from '../axios';
import axiosreal from 'axios';
import { triggerConstants } from '../_constants/triggerConstants';

export const triggerService = {
    triggerByUserId,
    registerTrigger,
    editTrigger,
    deleteTrigger,
    getTriggerById,
};

/**
 * Retrieving the SOC (State Of Charge) value
 */
// function socValue(userId) {
function triggerByUserId(userId) {
    return axios.get(triggerConstants.TRIGGER_SHORT_URL + "/" + userId)
        .then(response => {
            console.log("response.data.triggers");
            console.log(response.data.triggers);
            let triggers = response.data.triggers;
            return triggers;
        });
}

function registerTrigger(triggerData) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    console.log("triggerData");
    console.log(triggerData);
    // return axios.post(triggerConstants.TRIGGER_ADD_SHORT_URL,config)
    return axios.post('/api/testmongo/triggerstore',triggerData,config)
        .then(response => {
            console.log("response.data.message");
            console.log(response.data.message);
            // console.log("response");
            // console.log(response);
            let message = response.data.message;
            return message;
        });
}

function editTrigger(triggerData) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    console.log("triggerData");
    console.log(triggerData);
    // return axios.post(triggerConstants.TRIGGER_ADD_SHORT_URL,config)
    return axios.post(triggerConstants.TRIGGER_SHOW_SHORT_URL + "/" + triggerData.id,triggerData,config)
        .then(response => {
            console.log("response.data.trigger");
            console.log(response.data.trigger);
            let trigger = response.data.trigger;
            return trigger;
        });
}

function deleteTrigger(triggerId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    console.log("triggerId");
    console.log(triggerId);
    // return axios.post(triggerConstants.TRIGGER_ADD_SHORT_URL,config)
    return axios.get(triggerConstants.TRIGGER_DELETE_SHORT_URL + "/" + triggerId,config)
        .then(response => {
            console.log("response.data.message");
            console.log(response.data.message);
            // let message = response.data.message;
            return response.data.message;
        });
}

function getTriggerById(triggerId) {
    return axios.get(triggerConstants.TRIGGER_SHOW_SHORT_URL + "/" + triggerId)
        .then(response => {
            console.log("response.data.trigger");
            console.log(response.data.trigger);
            let trigger = response.data.trigger;
            return trigger;
        });
}