// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.Spinner_Spinner__2QaIn,\n.Spinner_Spinner__2QaIn:before,\n.Spinner_Spinner__2QaIn:after {\n  border-radius: 50%;\n  width: 2.5em;\n  height: 2.5em;\n  animation-fill-mode: both;\n  animation: Spinner_load7__1yzFy 1.8s infinite ease-in-out; }\n\n.Spinner_Spinner__2QaIn {\n  color: #9b174e;\n  font-size: 10px;\n  margin: 80px auto;\n  position: relative;\n  text-indent: -9999em;\n  transform: translateZ(0);\n  animation-delay: -0.16s; }\n\n.Spinner_Spinner__2QaIn:before,\n.Spinner_Spinner__2QaIn:after {\n  content: '';\n  position: absolute;\n  top: 0; }\n\n.Spinner_Spinner__2QaIn:before {\n  left: -3.5em;\n  animation-delay: -0.32s; }\n\n.Spinner_Spinner__2QaIn:after {\n  left: 3.5em; }\n\n@keyframes Spinner_load7__1yzFy {\n  0%,\n  80%,\n  100% {\n    box-shadow: 0 2.5em 0 -1.3em; }\n  40% {\n    box-shadow: 0 2.5em 0 0; } }\n", ""]);
// Exports
exports.locals = {
	"Spinner": "Spinner_Spinner__2QaIn",
	"load7": "Spinner_load7__1yzFy"
};
module.exports = exports;
