import React from 'react';

import classes from './Button.module.scss';
// import classes from '*.module.css';

const Button = (props) => {
    console.log("props button");
    console.log(props);
    return (
        <button onClick={props.action} disabled={props.disabled} className={props.customClass}>
            {props.label}
        </button>
    )
};

export default Button;