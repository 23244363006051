import React,{ useState,useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

import UserProfile from '../_components/MyProfilePage/UserProfile';

const MyProfilePage = (props) => {
    const [params,setParams] = useState(props.match.params);
    const queryString = require('query-string');
    const parsed = queryString.parse(props.location.search);
    const parsedPwd = queryString.parse(props.location.search).password;
    console.log("parsed");
    console.log(parsedPwd);
    return (
        <BatteryUpPage>
            {/* <UserProfile /> */}
        </BatteryUpPage>
    );
}

export { MyProfilePage };
