import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import classes from './MainDataChart.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';
import { dataService } from '../../_services/dataService';

am4core.useTheme(am4themes_animated);

const AllDevicesChart = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const chartRef = useRef(null);

    console.log("props.powerLogs AllDevicesChart");
    console.log(props.powerLogs);

    function getMaxValue(target,data) {
        let maxValue = 0;
        for (let i = 0; i < target.length; i++) {
            let item = target[i];
            if (Math.abs(item[data]) > maxValue) {
                maxValue = Math.abs(item[data]);
            }
        }
        return maxValue;
    }

    function getMinMax(target,data) {
        // console.log("Start minMax function");
        // console.log("target");
        // console.log(target);
        // console.log("data");
        // console.log(data);
        let maxDefault = 0;
        let maxItem = null;
        let minDefault = 0;
        let minItem = null;
        let result = [];
        result['min'] = 0;
        result['max'] = 0;
        // console.log("result min max");
        // console.log(result);
        for (let i = 0; i < target.length; i++) {
            let item = target[i];
            // console.log("item.data");
            // console.log(item[data]);
            if (item[data] < minDefault) {
                minDefault = item[data];
                result['min'] = item[data];
            }
            if (item[data] > maxDefault) {
                maxDefault = item[data];
                result['max'] = item[data];
            }
        }
        // console.log("End minMax function");
        return result;
    }

    // function renderChart(data) {
    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv",am4charts.XYChart);

        // Increase contrast by taking evey second color
        chart.colors.step = 2;

        chart.paddingRight = 1;
        chart.paddingTop = 1;
        // chart.marginTop = 50;

        chart.dateFormatter.inputDateFormat = "dd-MM-yyyy H:m:s";
        chart.dateFormatter.dateFormat = "d-M H:m:s";

        // chart.data = props.powerLogs;
        // chart.data = generateChartData();
        // chart.data = [
        //     {
        //         date: new Date(2020,12,10),
        //         visits: 8000,
        //         hits: 2000,
        //         views: 2500
        //     },
        //     {
        //         date: new Date(2020,12,11),
        //         visits: 7000,
        //         hits: 2500,
        //         views: 4000
        //     },
        //     {
        //         date: new Date(2020,12,12),
        //         hits: 2500,
        //     },
        //     {
        //         date: new Date(2020,12,13),
        //         visits: 4000,
        //         views: 2000
        //     },
        //     {
        //         date: new Date(2020,12,14),
        //         visits: 7500,
        //         hits: 3000,
        //         views: 6000
        //     },
        //     {
        //         date: new Date(2020,12,15),
        //         visits: 5500,
        //         hits: 3500,
        //         views: 2000
        //     }
        // ]
        chart.data = props.powerLogs;

        // console.log("chart.data data");
        // console.log(chart.data);
        // console.log("chart.data powerLogs");
        // console.log(data);

        /*
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.baseInterval = {
            "timeUnit": "minutes",
            "count": 5
        }

        let loop = 1;
        dateAxis.renderer.labels.template.adapter.add("text",(label,target,key) => {
            let totalLabel = dateAxis.renderer.labels._values.length;
            if (target.dataItem._text === "00:00") {
                let newDate = new Date(target.dataItem.value);
                let day = newDate.getDate();
                let month = newDate.getMonth() + 1;
                if (month.length === 1) month = "0" + month;
                let year = newDate.getFullYear();
                return day + "/" + month;
            } else {
                return label;
            }
            loop++;
        })
        */
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        // dateAxis.renderer.minGridDistance = 50;

        if (chart.data.length > 289) dateAxis.groupData = true;

        /*
        let color = [];
        color[0] = "#008000";
        color['greenlight'] = "#7bca7b";
        color[1] = "#ff0000";
        color[2] = "#ffeca0";
        color['orange'] = "#d76000";

        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.background.fillOpacity = 0;
        chart.legend.width = "100%";
        var marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12,12,12,12);
        marker.stroke = "#333";
        marker.strokeDasharray = "1";
        marker.strokeWidth = 2;
        marker.strokeOpacity = 1;
        marker.fillOpacity = .3;
        marker.adapter.add("strokeDasharray",function (strokeDasharray,target) {
            if (typeof target.dataItem.dataContext.fill !== 'undefined') {
                if (target.dataItem.dataContext.fill === color[3]) {
                    return "3";
                }
            }
        });
        marker.adapter.add("stroke",function (strokeColor,target) {
            if (!target.dataItem) {
                return strokeColor;
            } else if (typeof target.dataItem._marker.fill !== 'undefined') {
                if (target.dataItem._marker.fill.hex === color[0]) { return color[0]; }
                if (target.dataItem._marker.fill.hex === color[1]) { return color[1]; }
                if (target.dataItem._marker.fill.hex === color[2]) { return color[2]; }
            } else {
                return strokeColor;
            }

        });
        */

        /**
         * Define min and max values to divide chart in 2 parts of same heights
         */
        let chartMinMax = getMinMax(props.powerLogs,'power');
        let solarMinMax = getMinMax(props.powerLogs,'solar_prod');
        let gridMinMax = getMinMax(props.powerLogs,'powerImport');
        chartMinMax = 2000;
        solarMinMax = 2000;
        gridMinMax = 2000;
        // console.log("chartMinMax");
        // console.log(chartMinMax);
        // console.log("solarMinMax");
        // console.log(solarMinMax);
        // console.log("gridMinMax");
        // console.log(gridMinMax);

        let minMaxArray = [Math.abs(chartMinMax['min']),Math.abs(chartMinMax['max']),Math.abs(solarMinMax['min']),Math.abs(solarMinMax['max']),Math.abs(gridMinMax['min']),Math.abs(gridMinMax['max'])];
        minMaxArray.sort(function (a,b) { return b - a });
        // console.log("minMaxArray[0]");
        // console.log(minMaxArray[0]);
        // let minItem = 0;
        // let maxItem = 0;
        let minItem = minMaxArray[0] * -1;
        let maxItem = minMaxArray[0];
        /*if (solarMinMax["max"] > chartMinMax["max"] || solarMinMax["max"] > Math.abs(chartMinMax["min"])) {
            chartMinMax["max"] = solarMinMax["max"];
            chartMinMax["min"] = solarMinMax["min"];
        }
        if (Math.abs(chartMinMax["min"]) < Math.abs(gridMinMax["max"]) || Math.abs(solarMinMax["max"]) < Math.abs(gridMinMax["min"])) {
            chartMinMax["max"] = gridMinMax["max"];
            chartMinMax["min"] = gridMinMax["min"];
        }
        if (chartMinMax["max"] < 50 && chartMinMax["min"] > -100) {
            minItem = -100;
            maxItem = 100;
        } else {
            if (Math.abs(chartMinMax["min"]) > Math.abs(chartMinMax["max"]) && chartMinMax["min"] <= 0 && chartMinMax["max"] >= 0) {
                minItem = Math.abs(chartMinMax["min"]) * -1;
                maxItem = Math.abs(chartMinMax["min"]);
            } else if (Math.abs(chartMinMax["min"]) < Math.abs(chartMinMax["max"]) && chartMinMax["min"] <= 0 && chartMinMax["max"] > 0) {
                minItem = Math.abs(chartMinMax["max"]) * -1;
                maxItem = chartMinMax["max"];
            } else if (Math.abs(chartMinMax["min"]) > Math.abs(chartMinMax["max"]) && chartMinMax["min"] <= 0 && chartMinMax["max"] < 0) {
                minItem = Math.abs(chartMinMax["min"]) * -1;
                maxItem = Math.abs(chartMinMax["min"]);
            } else if (Math.abs(chartMinMax["min"]) > Math.abs(chartMinMax["max"]) && chartMinMax["min"] >= 0 && chartMinMax["max"] > 0) {
                minItem = Math.abs(chartMinMax["max"]) * -1;
                maxItem = Math.abs(chartMinMax["max"]);
            }
        }
        console.log("minItem");
        console.log(minItem);
        console.log("maxItem");
        console.log(maxItem);*/

        /**
         * Creation of soc y axis
         */
        let socValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        socValueAxis.renderer.opposite = true;
        socValueAxis.baseValue = 0;
        socValueAxis.min = -100;
        socValueAxis.max = 100;
        socValueAxis.strictMinMax = false;
        socValueAxis.numberFormatter = new am4core.NumberFormatter();
        socValueAxis.minWidth = 60;
        socValueAxis.numberFormatter.numberFormat = "#' %'";
        // socValueAxis.layout = "absolute";
        socValueAxis.title.text = t('common:datas.battery.soc');
        socValueAxis.title.align = "left";
        socValueAxis.title.valign = "top";
        socValueAxis.title.rotation = 90;
        socValueAxis.title.dy = 50;
        socValueAxis.title.fontWeight = 600;

        /**
         * Creation of acr_p axis
         */
        let pylontechMax = getMaxValue(props.powerLogs,'maxPower');
        let acrelMax = getMaxValue(props.powerLogs,'acr_p');
        let chartMax = 0.00;
        if (pylontechMax > acrelMax) {
            chartMax = pylontechMax
        } else {
            chartMax = acrelMax
        }
        if (chartMax === 0) {
            chartMax = 2000;
        }
        // console.log("acrel Max Value");
        // console.log(acrelMax);
        let acrPAxis = chart.yAxes.push(new am4charts.ValueAxis());
        acrPAxis.renderer.opposite = false;
        // acrPAxis.baseValue = 0;
        acrPAxis.min = -1 * Math.abs(chartMax);
        acrPAxis.max = Math.abs(chartMax);
        // acrPAxis.numberFormatter.numberFormat = "#' kW'";
        acrPAxis.title.text = t('common:datas.power') + " (in kW)";
        acrPAxis.stroke = 'black';
        // acrPAxis.strokeWidth = 4;
        // console.log("acrel Min Value axis");
        // console.log(-1 * Math.abs(acrelMax));
        // console.log("acrel Max Value axis");
        // console.log(Math.abs(acrelMax));
        // acrPAxis.strictMinMax = false;
        // acrPAxis.numberFormatter = new am4core.NumberFormatter();
        // acrPAxis.minWidth = 60;
        // acrPAxis.numberFormatter.numberFormat = "#' W'";

        // Create series
        function createAxisAndSeries(field,name,opposite,bullet) {
            // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            // if (chart.yAxes.indexOf(valueAxis) != 0) {
            //     valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
            // }

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.strokeWidth = 2;
            // series.yAxis = valueAxis;
            // series.yAxis = socValueAxis;
            series.yAxis = acrPAxis;
            series.name = name;
            // series.tooltipText = "{name}: [bold]{valueY}%[/]";
            series.tooltipText = name + ": [bold]{valueY}%[/]";
            series.tensionX = 0.8;
            series.showOnInit = true;

            var interfaceColors = new am4core.InterfaceColorSet();

            switch (bullet) {
                case "triangle":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 12;
                    bullet.height = 12;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var triangle = bullet.createChild(am4core.Triangle);
                    triangle.stroke = interfaceColors.getFor("background");
                    triangle.strokeWidth = 2;
                    triangle.direction = "top";
                    triangle.width = 12;
                    triangle.height = 12;
                    break;
                case "rectangle":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var rectangle = bullet.createChild(am4core.Rectangle);
                    rectangle.stroke = interfaceColors.getFor("background");
                    rectangle.strokeWidth = 2;
                    rectangle.width = 6;
                    rectangle.height = 12;
                    break;
                case "square":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var rectangle = bullet.createChild(am4core.Rectangle);
                    rectangle.stroke = interfaceColors.getFor("background");
                    rectangle.strokeWidth = 2;
                    rectangle.width = 10;
                    rectangle.height = 10;
                    break;
                case "trapezoid":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var trapezoid = bullet.createChild(am4core.Trapezoid);
                    trapezoid.stroke = interfaceColors.getFor("background");
                    trapezoid.strokeWidth = 2;
                    trapezoid.width = 12;
                    trapezoid.height = 10;
                    trapezoid.topSide = 6;
                    break;
                case "diamond":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 12;
                    bullet.height = 12;
                    bullet.horizontalCenter = "left";
                    bullet.verticalCenter = "middle";

                    var diamond = bullet.createChild(am4core.Rectangle);
                    diamond.stroke = interfaceColors.getFor("background");
                    diamond.strokeWidth = 1;
                    diamond.width = 8;
                    diamond.height = 8;
                    diamond.rotation = 45;
                    break;
                default:
                    var bullet = series.bullets.push(new am4charts.CircleBullet());
                    bullet.circle.stroke = interfaceColors.getFor("background");
                    bullet.circle.strokeWidth = 2;
                    break;
            }

            // valueAxis.renderer.line.strokeOpacity = 1;
            // valueAxis.renderer.line.strokeWidth = 2;
            // valueAxis.renderer.line.stroke = series.stroke;
            // valueAxis.renderer.labels.template.fill = series.stroke;
            // valueAxis.renderer.opposite = opposite;
            // socValueAxis.renderer.labels.template.fill = series.stroke;
            /*socValueAxis.renderer.line.strokeOpacity = 1;
            socValueAxis.renderer.line.strokeWidth = 2;
            socValueAxis.renderer.line.stroke = series.stroke;
            socValueAxis.renderer.opposite = opposite;*/
            // series.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
        }

        // Create Acrel series
        function createAcrelSeries(field,name,opposite,bullet) {
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (chart.yAxes.indexOf(valueAxis) != 0) {
                valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
            }

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.strokeWidth = 2;
            // series.yAxis = valueAxis;
            series.yAxis = acrPAxis;
            series.name = name;
            // series.tooltipText = "{name}: [bold]{valueY}%[/]";
            series.tooltipText = name + ": [bold]{valueY}[/] kW";
            series.tensionX = 0.8;
            series.showOnInit = true;

            var interfaceColors = new am4core.InterfaceColorSet();

            /*switch (bullet) {
                case "triangle":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 12;
                    bullet.height = 12;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var triangle = bullet.createChild(am4core.Triangle);
                    triangle.stroke = interfaceColors.getFor("background");
                    triangle.strokeWidth = 2;
                    triangle.direction = "top";
                    triangle.width = 12;
                    triangle.height = 12;
                    break;
                case "rectangle":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var rectangle = bullet.createChild(am4core.Rectangle);
                    rectangle.stroke = interfaceColors.getFor("background");
                    rectangle.strokeWidth = 2;
                    rectangle.width = 6;
                    rectangle.height = 12;
                    break;
                case "square":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var rectangle = bullet.createChild(am4core.Rectangle);
                    rectangle.stroke = interfaceColors.getFor("background");
                    rectangle.strokeWidth = 2;
                    rectangle.width = 10;
                    rectangle.height = 10;
                    break;
                case "trapezoid":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var trapezoid = bullet.createChild(am4core.Trapezoid);
                    trapezoid.stroke = interfaceColors.getFor("background");
                    trapezoid.strokeWidth = 2;
                    trapezoid.width = 12;
                    trapezoid.height = 10;
                    trapezoid.topSide = 6;
                    break;
                case "diamond":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 12;
                    bullet.height = 12;
                    bullet.horizontalCenter = "left";
                    bullet.verticalCenter = "middle";

                    var diamond = bullet.createChild(am4core.Rectangle);
                    diamond.stroke = interfaceColors.getFor("background");
                    diamond.strokeWidth = 1;
                    diamond.width = 8;
                    diamond.height = 8;
                    diamond.rotation = 45;
                    break;
                default:
                    var bullet = series.bullets.push(new am4charts.CircleBullet());
                    bullet.circle.stroke = interfaceColors.getFor("background");
                    bullet.circle.strokeWidth = 2;
                    break;
            }*/

            // valueAxis.baseValue = 0;
            // valueAxis.numberFormatter.numberFormat = "#' W'";
            // valueAxis.renderer.line.strokeOpacity = 1;
            // valueAxis.renderer.line.strokeWidth = 2;
            // valueAxis.renderer.line.stroke = series.stroke;
            // valueAxis.renderer.labels.template.fill = series.stroke;
            // valueAxis.renderer.opposite = opposite;
        }

        // Create Pylontech series
        function createPylontechSeries(field,name,opposite,bullet) {
            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.strokeWidth = 2;
            // series.yAxis = valueAxis;
            // series.yAxis = socValueAxis;
            series.yAxis = acrPAxis;
            series.name = name;
            series.tooltipText = "{name}: [bold]{valueY}[/] kW";
            // series.tooltipText = name + ": [bold]{valueY}%[/]";
            series.tensionX = 0.8;
            series.showOnInit = true;

            var interfaceColors = new am4core.InterfaceColorSet();

            /*switch (bullet) {
                case "triangle":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 12;
                    bullet.height = 12;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var triangle = bullet.createChild(am4core.Triangle);
                    triangle.stroke = interfaceColors.getFor("background");
                    triangle.strokeWidth = 2;
                    triangle.direction = "top";
                    triangle.width = 12;
                    triangle.height = 12;
                    break;
                case "rectangle":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var rectangle = bullet.createChild(am4core.Rectangle);
                    rectangle.stroke = interfaceColors.getFor("background");
                    rectangle.strokeWidth = 2;
                    rectangle.width = 6;
                    rectangle.height = 12;
                    break;
                case "square":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var rectangle = bullet.createChild(am4core.Rectangle);
                    rectangle.stroke = interfaceColors.getFor("background");
                    rectangle.strokeWidth = 2;
                    rectangle.width = 10;
                    rectangle.height = 10;
                    break;
                case "trapezoid":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 10;
                    bullet.height = 10;
                    bullet.horizontalCenter = "middle";
                    bullet.verticalCenter = "middle";

                    var trapezoid = bullet.createChild(am4core.Trapezoid);
                    trapezoid.stroke = interfaceColors.getFor("background");
                    trapezoid.strokeWidth = 2;
                    trapezoid.width = 12;
                    trapezoid.height = 10;
                    trapezoid.topSide = 6;
                    break;
                case "diamond":
                    var bullet = series.bullets.push(new am4charts.Bullet());
                    bullet.width = 12;
                    bullet.height = 12;
                    bullet.horizontalCenter = "left";
                    bullet.verticalCenter = "middle";

                    var diamond = bullet.createChild(am4core.Rectangle);
                    diamond.stroke = interfaceColors.getFor("background");
                    diamond.strokeWidth = 1;
                    diamond.width = 8;
                    diamond.height = 8;
                    diamond.rotation = 45;
                    break;
                default:
                    var bullet = series.bullets.push(new am4charts.CircleBullet());
                    bullet.circle.stroke = interfaceColors.getFor("background");
                    bullet.circle.strokeWidth = 2;
                    break;
            }*/
            // socValueAxis.renderer.line.strokeOpacity = 1;
            // socValueAxis.renderer.line.strokeWidth = 2;
            // socValueAxis.renderer.line.stroke = series.stroke;
            /*socValueAxis.renderer.labels.template.fill = series.stroke;*/
            acrPAxis.renderer.labels.template.fill = series.stroke;
            // socValueAxis.renderer.opposite = opposite;
        }

        // Create Eastron series
        function createEastronSeries(field,name,opposite,bullet) {
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (chart.yAxes.indexOf(valueAxis) != 0) {
                valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
            }

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.strokeWidth = 2;
            // series.yAxis = valueAxis;
            series.yAxis = acrPAxis;
            series.name = name;
            // series.tooltipText = "{name}: [bold]{valueY}%[/]";
            series.tooltipText = name + ": [bold]{valueY}[/] W";
            series.tensionX = 0.8;
            series.showOnInit = true;

            var interfaceColors = new am4core.InterfaceColorSet();
        }

        if (props.arrBatteries.length > 0) {
            for (let i = 0; i < props.arrBatteries.length; i++) {
                let icon = "circle";
                // switch (i) {
                //     case 0: icon = "circle";
                //     case 1: icon = "triangle";
                //     case 2: icon = "rectangle";
                // }
                if (i === 1) {
                    icon = "triangle";
                } else if (i === 2) {
                    icon = "square";
                } else if (i === 3) {
                    icon = "rectangle";
                }
                // console.log("new series");
                // console.log("#" + i + ": " + "itot_" + props.arrBatteries[i] + " => Courant de charge - " + props.arrBatteries[i]);
                createPylontechSeries("maxPower_" + props.arrBatteries[i],t('common:datas.power') + ' ' + t('common:datas.battery.battery') + " - " + props.arrBatteries[i],true,icon);
            }
        }

        if (props.arrAcrel.length > 0) {
            for (let i = 0; i < props.arrAcrel.length; i++) {
                let icon = "circle";
                // switch (i) {
                //     case 0: icon = "circle";
                //     case 1: icon = "triangle";
                //     case 2: icon = "rectangle";
                // }
                if (i === 1) {
                    icon = "triangle";
                } else if (i === 2) {
                    icon = "square";
                } else if (i === 3) {
                    icon = "rectangle";
                }
                // console.log("new series");
                // console.log("#" + i + ": " + "acr_p_" + props.arrAcrel[i] + " => ACREL P - " + props.arrAcrel[i]);
                createAcrelSeries("acr_p_" + props.arrAcrel[i],t('common:datas.power') + ' ' + t('common:datas.acrel.acrel') + " - " + props.arrAcrel[i],false,icon);
            }
        }

        if (props.arrEastron.length > 0) {
            for (let i = 0; i < props.arrEastron.length; i++) {
                let icon = "circle";
                // switch (i) {
                //     case 0: icon = "circle";
                //     case 1: icon = "triangle";
                //     case 2: icon = "rectangle";
                // }
                if (i === 1) {
                    icon = "triangle";
                } else if (i === 2) {
                    icon = "square";
                } else if (i === 3) {
                    icon = "rectangle";
                }
                // console.log("new series");
                // console.log("#" + i + ": " + "acr_p_" + props.arrAcrel[i] + " => Eastron P - " + props.arrEastron[i]);
                createEastronSeries("act_power_" + props.arrEastron[i],t('common:datas.power') + ' ' + t('common:datas.eastron.eastron') + " - " + props.arrEastron[i],false,icon);
            }
        }

        // createAxisAndSeries("soc_BU-80-7D-3A-5E-D1-BA","Visits",false,"circle");
        // createAxisAndSeries("chg_i_limit_BU-80-7D-3A-5E-D1-BA","Views",true,"triangle");
        // createAxisAndSeries("batt_chg_v_BU-80-7D-3A-5E-D1-BA","Hits",true,"rectangle");

        // generate some random data, quite different range
        function generateChartData() {
            var chartData = [];
            var firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 100);
            firstDate.setHours(0,0,0,0);

            var visits = 1600;
            var hits = 2900;
            var views = 8700;

            for (var i = 0; i < 15; i++) {
                // we create date objects here. In your data, you can have date strings
                // and then set format of your dates using chart.dataDateFormat property,
                // however when possible, use date objects, as this will speed up chart rendering.
                var newDate = new Date(firstDate);
                newDate.setDate(newDate.getDate() + i);

                visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
                hits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
                views += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);

                chartData.push({
                    date: newDate,
                    visits: visits,
                    hits: hits,
                    views: views
                });
            }
            return chartData;
        }

        /**
         * Creation of left y axis
         */
        // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.baseValue = 0;
        // valueAxis.min = minItem;
        // valueAxis.max = maxItem;
        // valueAxis.strictMinMax = false;
        // valueAxis.numberFormatter = new am4core.NumberFormatter();
        // valueAxis.minWidth = 80;
        // valueAxis.numberFormatter.numberFormat = "#' W'";

        /**
         * Creation of series power
         */
        // let series = chart.series.push(new am4charts.LineSeries());
        // series.name = "[bold]Load/Unload[bold]";
        // series.dataFields.valueY = "power";
        // series.dataFields.dateX = "datetime";
        // series.strokeWidth = 2;
        // series.fillOpacity = 1;
        // series.color = color[1];
        // series.zIndex = 10;

        /**
         * Creation of series solar production
         */
        // let seriesSolarProd = chart.series.push(new am4charts.LineSeries());
        // seriesSolarProd.name = "[bold]Solar production[bold]";
        // seriesSolarProd.dataFields.valueY = "solar_prod";
        // seriesSolarProd.dataFields.dateX = "datetime";
        // seriesSolarProd.strokeWidth = 2;
        // let gradientSolar = new am4core.LinearGradient();
        // gradientSolar.addColor(color['orange'],1);
        // gradientSolar.addColor(color['orange'],0);
        // gradientSolar.rotation = 90;
        // seriesSolarProd.fill = gradientSolar;
        // seriesSolarProd.fillOpacity = 1;
        // seriesSolarProd.stroke = color['orange'];
        // if (chart.data.length < 350) { seriesSolarProd.tensionX = 0.75; }
        // seriesSolarProd.zIndex = 1;

        /**
         * Define gradient positive or negative
         */
        // let gradientPos = new am4core.LinearGradient();
        // gradientPos.addColor(color['greenlight'],1);
        // gradientPos.addColor(color['greenlight'],0);
        // gradientPos.rotation = 90;

        // let gradientNeg = new am4core.LinearGradient();
        // gradientNeg.addColor(color[1],0.3);
        // gradientNeg.addColor(color[1],0);
        // gradientNeg.rotation = -90;

        // Red color for negative values
        // let rangeRed = valueAxis.createSeriesRange(series);
        // rangeRed.value = 0;
        // rangeRed.endValue = -100000;
        // rangeRed.contents.stroke = color[1];
        // rangeRed.contents.fill = gradientNeg;
        // rangeRed.contents.strokeOpacity = 0.7;
        // rangeRed.contents.fillOpacity = .8;

        // Green color for positive values
        // let rangeGreen = valueAxis.createSeriesRange(series);
        // rangeGreen.value = 0;
        // rangeGreen.endValue = 100000;
        // rangeGreen.contents.stroke = color[0];
        // rangeGreen.contents.fill = gradientPos;
        // rangeGreen.contents.strokeOpacity = 0.7;
        // rangeGreen.contents.fillOpacity = .8;

        // if (chart.data.length < 350) { seriesSolarProd.tensionX = 0.95; seriesSolarProd.tensionY = 0.95; }
        // series.stroke = am4core.color('#333');
        // series.zIndex = 10;

        /**
         * Creation of soc y axis
         */
        // let socValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // socValueAxis.renderer.opposite = true;
        // socValueAxis.baseValue = 0;
        // socValueAxis.min = -100;
        // socValueAxis.max = 100;
        // socValueAxis.strictMinMax = false;
        // socValueAxis.numberFormatter = new am4core.NumberFormatter();
        // socValueAxis.minWidth = 60;
        // socValueAxis.numberFormatter.numberFormat = "#' %'";

        /**
         * Hide negative values of soc y axis
         */
        socValueAxis.renderer.labels.template.fill = "#f1c705";
        socValueAxis.renderer.labels.template.fontWeight = 600;
        socValueAxis.renderer.labels.template.adapter.add("text",(label,target,key) => {
            if (target.dataItem && target.dataItem.value < 0) {
                return "";
            } else {
                return label;
            }
        });

        socValueAxis.cursorTooltipEnabled = false;

        if (props.arrBatteries.length > 0) {
            for (let i = 0; i < props.arrBatteries.length; i++) {
                var seriesSoc = chart.series.push(new am4charts.LineSeries());
                seriesSoc.dataFields.dateX = "date";
                seriesSoc.dataFields.valueY = "soc_" + props.arrBatteries[i];
                seriesSoc.yAxis = socValueAxis;
                seriesSoc.name = t('common:datas.battery.soc') + ' ' + props.arrBatteries[i];
                seriesSoc.tooltipText = t('common:datas.battery.soc') + ": [bold]{valueY}[/] %";
                seriesSoc.fillOpacity = 0.1;
                seriesSoc.strokeWidth = 2;
            }
        }

        /**
         * Creation of series soc
         */
        // let socSeries = chart.series.push(new am4charts.LineSeries());
        // socSeries.name = "[bold]Battery State Of Charge[bold]";
        // socSeries.dataFields.valueY = "soc";
        // if (chart.data.length < 289) { socSeries.tensionX = 0.75; socSeries.tensionY = 0.75; }
        // socSeries.dataFields.dateX = "date";
        // socSeries.yAxis = socValueAxis;

        // let gradientSoc = new am4core.LinearGradient();
        // gradientSoc.addColor(color[2],0.85);
        // gradientSoc.addColor(color[2],0.25);
        // gradientSoc.rotation = 90;
        // socSeries.fill = gradientSoc;
        // socSeries.strokeWidth = 2;
        // socSeries.color = "#ffca2a";
        // socSeries.stroke = "#ffca2a";
        // socSeries.strokeOpacity = 1;
        // socSeries.fillOpacity = 1;
        // let gradientStroke = new am4core.LinearGradient();
        // gradientStroke.addColor(color[2],1,.8);
        // gradientStroke.rotation = 0;
        // gradientStroke.strokeDasharray = "0";
        // gradientStroke.zIndex = 8;
        // gradientStroke.fillOpacity = 1;

        /**
         * Creation of series energy grid
         */
        // let energyGridSeries = chart.series.push(new am4charts.LineSeries());
        // energyGridSeries.name = "[bold]Network[bold]";
        // energyGridSeries.dataFields.valueY = "energrid";
        // energyGridSeries.dataFields.dateX = "datetime";
        // energyGridSeries.strokeOpacity = 0;
        // energyGridSeries.strokeWidth = 1;
        // energyGridSeries.fillOpacity = .81;
        // energyGridSeries.stroke = color[1];
        // energyGridSeries.zIndex = 21;
        // energyGridSeries.fill = color[1];

        /**
         * Creation of series energy inv
         */
        // let energyInvSeries = chart.series.push(new am4charts.LineSeries());
        // energyInvSeries.name = "[bold]Consumption[bold]";
        // energyInvSeries.dataFields.valueY = "enerinv";
        // energyInvSeries.dataFields.dateX = "datetime";
        // energyInvSeries.strokeWidth = 2;
        // energyInvSeries.fillOpacity = .85;
        // energyInvSeries.stroke = color[1];
        // energyInvSeries.zIndex = 20;
        // energyInvSeries.fill = am4core.color('#fff');
        // // energyInvSeries.rotation = -90;
        // let gradientConsumption = new am4core.LinearGradient();
        // gradientConsumption.addColor(color[1],0.3);
        // gradientConsumption.addColor(color[1],0);

        /**
         * Grid and Consumption diplayed/hidden at the same time
         */
        // energyGridSeries.events.on("hidden",function () {
        //     energyInvSeries.hide();
        // });
        // energyGridSeries.events.on("shown",function () {
        //     energyInvSeries.show();
        // });
        // energyInvSeries.events.on("hidden",function () {
        //     energyGridSeries.hide();
        // });
        // energyInvSeries.events.on("shown",function () {
        //     energyGridSeries.show();
        // });
        // energyGridSeries.hidden = true;
        // energyInvSeries.hidden = true;

        // socSeries.tooltipText = "{datetime} \n SOC : {soc.formatNumber('#')}% \n Solar : {solar_prod} Wh \n Battery : {power} W \n Consumption : {enerinv} W[/]";
        // socSeries.tooltip.getFillFromObject = false;
        // socSeries.tooltip.background.fill = am4core.color("#000");

        chart.cursor = new am4charts.XYCursor();
        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.itemContainers.template.paddingBottom = 15;
        // chart.scrollbarX = new am4core.Scrollbar();

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    },[props.powerLogs]);
    // }

    // When the paddingRight prop changes it will update the chart
    // useLayoutEffect(() => {
    //     chartRef.current.paddingRight = props.paddingRight;
    // },[props.paddingRight]);

    return (
        // <Aux>
        //     <div>
        //         Pick a date
        //         <DatePicker
        //             className={classes.DatePicker}
        //             selected={startDate}
        //             onChange={dayPickerHandler} />
        //     </div>
        //     <div>
        //         Pick a period
        //         <DatePicker
        //             className={classes.DatePicker}
        //             selected={startDate}
        //             onChange={datePickerHandler}
        //             startDate={startDate}
        //             endDate={endDate}
        //             selectsRange
        //             shouldCloseOnSelect={false}
        //         // showDisabledMonthNavigation
        //         />
        //     </div>

        <div id="chartdiv" style={{ width: "calc(100% - 40px)",height: "600px" }}></div>
        // </Aux>
    );
}

export default AllDevicesChart;