import React,{ useEffect,useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import StepOne from "./StepOne";
import ScenarioSummary from "./ScenarioSummary";
import StepMetrics from "./StepMetrics";
import StepWeather from "./StepWeather";
import StepTiming from "./StepTiming";
import StepStopMetrics from "./StepStopMetrics";
import StepAdditional from "./StepAdditional";
import RecursiveContainer from './RecursiveContainer';
import classes from './NewScenarioWrapper.module.scss';

const NewScenarioWrapper = (props) => {
    // const [fields,setFields] = useState(["soc","current"]);
    const [isStepMetricsDone,setIsStepMetricsDone] = useState(false);
    const [isStepWeatherDone,setIsStepWeatherDone] = useState(false);
    const [isStepTimingDone,setIsStepTimingDone] = useState(false);
    const [isStepStopMetricsDone,setIsStepStopMetricsDone] = useState(false);
    const [isStepAdditionalDone,setIsStepAdditionalDone] = useState(false);
    const [data,setData] = useState({
        // first_name: "",
        // last_name: "",
        // email: "",
        // password: ""
        soc: "",
        // current: ""
    });
    const [metricsValues,setMetricsValues] = useState([]);
    const [weatherValues,setWeatherValues] = useState([]);
    const [timingValues,setTimingValues] = useState([]);
    const [stopMetricsValues,setStopMetricsValues] = useState([]);
    const [additionalValues,setAdditionalValues] = useState([]);
    // const testValidation = fields.map((field,idx) => {
    //     field: metricsFields[0][field]["validation"]
    //     // testValidation.field = metricsFields[0][field]["validation"];
    // });
    // const testValidation = fields.forEach(field => {
    //     testValidation.field = metricsFields[0][field]["validation"];
    // })
    const [pylontech,setPylontech] = useState([]);
    const [acrel,setAcrel] = useState([]);
    const [eastron,setEastron] = useState([]);

    const [currentStep,setCurrentStep] = useState(0);

    useEffect(() => {
        console.log("NewScenarioWrapper props.selectedInstallation.modules");
        console.log(props.selectedInstallation.modules);
        if (props.selectedInstallation && props.selectedInstallation.modules) {
            let modules = props.selectedInstallation.modules;
            for (let device of modules) {
                if (device.protocol_id === 1) {
                    setPylontech(device.mac_address);
                }
                if (device.protocol_id === 2 || device.protocol_id === 4) {
                    setAcrel(device.mac_address);
                }
                if (device.protocol_id === 3 || device.protocol_id === 5) {
                    setEastron(device.mac_address);
                }
            }
        }
    },[props.selectedInstallation]);

    const makeRequest = (formData) => {
        console.log("Form Submitted",formData);
    };

    const handleNextStep = (newData,final = false) => {
        setData((prev) => ({ ...prev,...newData }));

        if (final) {
            makeRequest(newData);
            return;
        }

        setCurrentStep((prev) => prev + 1);
    };

    const handlePrevStep = (newData) => {
        setData((prev) => ({ ...prev,...newData }));
        setCurrentStep((prev) => prev - 1);
    };

    const handleMetricsValues = (values) => {
        console.log("values step metrics in parents");
        console.log(values);
        setIsStepMetricsDone(true);
        setMetricsValues(values);
        handleNextStep(values);
    }

    const handleWeatherValues = (values) => {
        console.log("values step weather in parents");
        console.log(values);
        setIsStepWeatherDone(true);
        setWeatherValues(values);
        handleNextStep(values);
    }

    const handleTimingValues = (values) => {
        console.log("values step timing in parents");
        console.log(values);
        setIsStepTimingDone(true);
        setTimingValues(values);
        handleNextStep(values);
    }

    const handleStopMetricsValues = (values) => {
        console.log("values step stop metrics in parents");
        console.log(values);
        setIsStepStopMetricsDone(true);
        setStopMetricsValues(values);
        handleNextStep(values);
    }

    const handleAdditionalValues = (values) => {
        console.log("values step additional in parents");
        console.log(values);
        setIsStepAdditionalDone(true);
        setAdditionalValues(values);
        handleNextStep(values);
    }

    const steps = [
        // <StepOne next={handleNextStep} data={data} fields={fields} />,
        // <StepMetrics next={handleNextStep} data={data} fields={fields} parentCallback={handleMetricsValue} currentStep={currentStep} />,
        <StepMetrics next={handleNextStep} data={data} parentCallback={handleMetricsValues} currentStep={currentStep} pylontech={pylontech} acrel={acrel} eastron={eastron} />,
        <StepWeather next={handleNextStep} prev={handlePrevStep} data={data} parentCallback={handleWeatherValues} />,
        <StepTiming next={handleNextStep} prev={handlePrevStep} data={data} parentCallback={handleTimingValues} />,
        <StepStopMetrics next={handleNextStep} prev={handlePrevStep} data={data} parentCallback={handleStopMetricsValues} pylontech={pylontech} acrel={acrel} eastron={eastron} />,
        <StepAdditional next={handleNextStep} prev={handlePrevStep} data={data} parentCallback={handleAdditionalValues} />,
    ];

    console.log("metricsValues",metricsValues);
    console.log("weatherValues",weatherValues);
    console.log("timingValues",timingValues);
    console.log("stopMetricsValues",stopMetricsValues);
    console.log("additionalValues",additionalValues);

    // return <div className="App">{steps[currentStep]}</div>;
    return (
        <div className={classes.NewScenarioWrapper}>
            <ScenarioSummary metricsValues={metricsValues} weatherValues={weatherValues} timingValues={timingValues} stopMetricsValues={stopMetricsValues} additionalValues={additionalValues} />
            <div>{steps[currentStep]}</div>
        </div>
    )
}

// const stepOneValidationSchema = Yup.object({
//     // first_name: Yup.string().required().label("First name"),
//     // last_name: Yup.string().required().label("Last name")
//     // soc: Yup.string().required(<p className="errorMessage">soc validation text</p>)
// });

// const StepOne = (props) => {
//     // const fields = props.fields;
//     const [fields,setFields] = useState([]);
//     const [cannotBeSubmitted,setcannotBeSubmitted] = useState(true);
//     const handleSubmit = (values) => {
//         props.next(values);
//     };
//     // console.log("metricsFields");
//     // console.log(metricsFields[0]);
//     // fields.map((field,idx) => {

//     // })}
//     // const validYupSoc = fields.includes("soc") ? Yup.string().required(<p className="errorMessage">soc validation text</p>) : "";
//     const validYupSoc = fields.includes("soc") ? metricsFields[0]["soc"]["validation"] : "";
//     const validYupCurrent = fields.includes("current") ? metricsFields[0]["current"]["validation"] : "";

//     const handleActivateChange = () => {
//         // const { name,value } = e.target;
//         // console.log('The checkbox was toggled');
//         // console.log('e');
//     }
//     const handleChange = (e) => {
//         // console.log("fields");
//         // console.log(fields);
//         // console.log(e.target.name);
//         // console.log(e.target.checked);
//         // console.log(e.target.name.split("_").pop());
//         let changedMetric = e.target.name.split("_").pop();
//         let targetLabel = "label_" + changedMetric;
//         let myElement = document.getElementById(targetLabel);
//         if (e.target.checked) {
//             setcannotBeSubmitted(false);
//             setFields(fields => [...fields,changedMetric]);
//             // let myElement = document.getElementById(targetLabel);
//             // myElement.classList.add("on");
//             // myElement.classList.remove('off');
//             myElement.innerHTML = "ON";
//             myElement.style.textAlign = "left";
//             myElement.style.paddingLeft = "7px";
//             myElement.style.paddingRight = "0";
//         } else {
//             if (fields.length < 2) {
//                 setcannotBeSubmitted(true);
//             }
//             setFields(fields.filter(item => item !== changedMetric));
//             // myElement.classList.add("off");
//             // myElement.classList.remove('on');
//             myElement.innerHTML = "OFF";
//             myElement.style.textAlign = "right";
//             myElement.style.paddingLeft = "0";
//             myElement.style.paddingRight = "4px";
//         }
//     }

//     return (
//         <div className={classes.scenarioStep1}>
//             <div className={classes.scenarioStep1__list}>
//                 {/* <input type="checkbox" onChange={handleActivateChange} id="activate_soc" name="activate_soc" /><label className={`${classes.switchLabel} ${classes.off}`} for="activate_soc">OFF</label> */}
//                 <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_soc" name="activate_soc" /><label className={`${classes.switchLabel}`} id="label_soc" for="activate_soc">OFF</label><span>State of charge</span></div>
//                 <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_current" name="activate_current" /><label className={`${classes.switchLabel}`} id="label_current" for="activate_current">OFF</label><span>Current</span></div>
//                 <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_third" name="activate_third" /><label className={`${classes.switchLabel}`} id="label_third" for="activate_third">OFF</label><span>Third metric</span></div>
//             </div>
//             <Formik
//                 // validationSchema={stepOneValidationSchema}
//                 validationSchema={Yup.object({
//                     // soc: Yup.string().required(<p className="errorMessage">soc validation text</p>)
//                     soc: validYupSoc,
//                     current: validYupCurrent
//                 })}
//                 initialValues={props.data}
//                 onSubmit={handleSubmit}
//             // onSubmit={(values) => {
//             //     console.log(values);
//             // }}
//             >
//                 {({ values }) => (
//                     <Form>
//                         {fields.map((field,idx) => {
//                             return (
//                                 <div className="form-group" style={{ margin: '10px 10px 20px' }}>
//                                     <label htmlFor={field}>{metricsFields[0][field]["label"]}</label>
//                                     <Field name={`${field}comp`} as="select">
//                                         <option value="lt">&lt;</option>
//                                         <option value="equal">=</option>
//                                         <option value="gt">&gt;</option>
//                                     </Field>
//                                     <Field name={field} type={metricsFields[0][field]["type"]} className="form-control" />
//                                     <ErrorMessage name={field} />
//                                 </div>
//                             )
//                         })}
//                         {/* <div className="form-group" style={{ margin: '10px 10px 20px' }}>
//                         <label htmlFor="soc">State of charge</label>
//                         <Field name="soc" type="text" className="form-control" />
//                         <ErrorMessage name="soc" />
//                     </div> */}

//                         <button type="button" onClick={() => props.prev(values)}>
//                             Back
//                         </button>
//                         <button type="submit" disabled={cannotBeSubmitted}>Submit</button>
//                     </Form>
//                 )}
//             </Formik>
//         </div>
//     );
// };

const stepTwoValidationSchema = Yup.object({
    // first_name: Yup.string().required().label("First name"),
    // last_name: Yup.string().required().label("Last name")
    test: Yup.string().required(<p className="errorMessage">test validation text</p>)
});

const StepTwo = (props) => {
    const handleSubmit = (values) => {
        props.next(values,true);
    };

    return (
        <Formik
            validationSchema={stepTwoValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <p>Test</p>
                    <Field name="test" />
                    <ErrorMessage name="test" />

                    <button type="button" onClick={() => props.prev(values)}>
                        Back
                    </button>
                    <button type="submit">Submit</button>
                </Form>
            )}
        </Formik>
    );
};

export default NewScenarioWrapper;