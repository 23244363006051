import React from 'react';

import GenericPage from '../_components/Layout/GenericPage';

const Page401 = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    console.log("user in dashboard");
    console.log(user);

    return (
        <GenericPage sidebar={false}>
            <img src={require("../_assets/img/401-pas-autorise-site-battery-UP-IMG.png")} alt="Erreur 401 - Pas autorisé" style={{ width: '70%',margin: '50px auto' }} />
        </GenericPage>
    );
}

export default Page401;