import React from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

function TriggersPage() {
    return (
        <BatteryUpPage>
        </BatteryUpPage>
        // <p>test</p>
    );
}

export default TriggersPage;
