import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import { Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../_assets/css/color/color-default.scss';
import classes from './Glossary.module.scss';
import fondImgTexte1 from '../../_assets/img/fond-texte-solution-batteries-lihtium.png';

class Glossary extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="Glossary" className={`app-about-section angle-sp ${classes.Glossary}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className="section-title angle-section-title">
                                    <img className={classes.sectionImage} src={require("../../_assets/img/IMG-vector-glossary.png")} alt="Glossaire" />
                                    <h2 className={classes.sectionTitle1}>{t('glossarypage:Glossary.title')}</h2>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-md-12 ${classes.qAndA}`}>
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.battery-up-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.battery-up-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.bms-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.bms-text1')}</p>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.bms-text2')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.home-automation-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.home-automation-text')}</p>

                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.energy-meter-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.energy-meter-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.ifttt-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.ifttt-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.interoperability-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.interoperability-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.iot-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.iot-text1')}</p>
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.iot-text2')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>



                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ color: "#3d009e" }} className={`accordion__button ${classes.QATitle}`}>{t('glossarypage:Glossary.inverter-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            {/* <p style={{ textAlign: "left" }}>Non, un utilisateur sans trop de connaissances techniques peut installer notre solution Battery UP en suivant la procédure d’installation du manuel d’installation disponible en ligne.Si cela vous semble compliqué la FAQ et notre SUPPORT en ligne vous aiderons à atteindre avec peu de difficulté votre installation personnalisée.</p> */}
                                            <p style={{ textAlign: "left" }}>{t('glossarypage:Glossary.inverter-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','glossarypage'])(Glossary);