import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import classes from './ChartContainer.module.scss';
import { dataService } from '../../_services/dataService';
import AmChart from './AmChart';
import MainDataChart from './MainDataChart';
import AllDevicesChart from './AllDevicesChartMongo';
import Data from './Data';
import Aux from '../../_hoc/Auxil/Auxil';
import Button from '../Form/Inputs/Button';
import Spinner from '../UI/Spinner/Spinner';

class ChartContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            powerLogs: [],
            startDate: new Date(),
            endDate: new Date(),
            // selectedDevice: this.props.selectedDevice,
            // selectedId: typeof this.props.selectedDevice.mac_address !== 'undefined' ? this.props.selectedDevice.mac_address : this.props.selectedDevice.serial_number,
            // client: "5600"
            // selectedBattery: this.props.selectedBattery,
            // selectedAcrel: this.props.selectedAcrel,
            arrBatteries: this.props.arrBatteries,
            arrAcrel: this.props.arrAcrel,
            arrEastron: this.props.arrEastron,
        };
    }

    // localStorageUpdated(){
    //     this.setState({ client: JSON.parse(localStorage.getItem('selectedClient')) })
    // }

    dayPickerHandler = date => {
        // setStartDate(date);
        // setEndDate(date);
        this.setState({ startDate: date,endDate: date })
    };

    datePickerHandler = dates => {
        const [start,end] = dates;
        // setStartDate(start);
        // setEndDate(end);
        this.setState({ startDate: start,endDate: end })
        // if (endDate === null) {
        //     setEndDate(start);
        // }
    };

    dateChangeHandler = () => {
        /* Commented when changed DataContainer for users roles */
        // this.setState({ selectedBattery: this.props.selectedBattery,selectedAcrel: this.props.selectedAcrel })
        /* End comment users roles */
        let from = this.state.startDate.getFullYear().toString() + ('0' + (this.state.startDate.getMonth() + 1)).slice(-2) + ('0' + this.state.startDate.getDate()).slice(-2);
        let to = this.state.endDate.getFullYear().toString() + ('0' + (this.state.endDate.getMonth() + 1)).slice(-2) + ('0' + this.state.endDate.getDate()).slice(-2);
        // dataService.powerLogs(from,to,this.props.selectedClient)
        /*dataService.powerLogs(from,to,selectedBattery,selectedAcrel)
            .then(response => {
                // setPowerLogs(response.data.power);
                this.setState({ powerLogs: response.data.power })
            });*/
        // dataService.chartDataMongo(from,to,this.state.selectedBattery,this.state.selectedAcrel)
        // dataService.chartDataMongo(from,to,this.props.selectedBattery,this.props.selectedAcrel)
        dataService.chartDataMongo(from,to,this.props.arrBatteries,this.props.arrAcrel,this.props.arrEastron)
            .then(response => {
                // setPowerLogs(response.data.power);
                this.setState({ powerLogs: response.data.main_chart_data })
                // renderChart(powerLogs);
            });
    }

    componentDidMount() {
        // console.log("MONGO => arrays batteries acrels eastrons");
        // console.log(this.props.arrBatteries);
        // console.log(this.props.arrAcrel);
        // console.log(this.props.arrEastron);
        // this.setState({client: this.props.selectedClient})
        let from = this.state.startDate.getFullYear().toString() + ('0' + (this.state.startDate.getMonth() + 1)).slice(-2) + ('0' + this.state.startDate.getDate()).slice(-2);
        let to = this.state.endDate.getFullYear().toString() + ('0' + (this.state.endDate.getMonth() + 1)).slice(-2) + ('0' + this.state.endDate.getDate()).slice(-2);
        // let from = this.state.startDate;
        // let to = this.state.endDate;
        // console.log("this.state chart container mac addresses ?");
        // console.log(this.state);
        dataService.chartDataMongo(from,to,this.state.arrBatteries,this.state.arrAcrel,this.state.arrEastron)
            // .then(() => { console.log('is this triggered ???????') })
            .then(response => {
                // console.log("response.data.main_chart_data ?");
                // console.log(response.data.main_chart_data);
                // setPowerLogs(response.data.power);
                this.setState({ powerLogs: response.data.main_chart_data });
                // renderChart(powerLogs);
            });
        /*dataService.powerLogs(from,to,this.props.selectedClient)
            .then(response => {
                // setPowerLogs(response.data.power);
                this.setState({ powerLogs: response.data.power })
                // renderChart(powerLogs);
            });*/
    }

    componentDidUpdate(prevProps) {
        /*
        if (prevProps.selectedDevice !== this.props.selectedDevice) {
            console.log("chart container should rerender because props changed");
            this.setState({
                selectedId: typeof this.props.selectedDevice.mac_address !== 'undefined' ? this.props.selectedDevice.mac_address : this.props.selectedDevice.serial_number
            });
            // this.setState({ selectedId: 62726 });
        }*/
        // console.log("prevProps.arrBatteries");
        // console.log(prevProps.arrBatteries);
        // console.log("this.props.arrBatteries");
        // console.log(this.props.arrBatteries);
        // console.log("prevProps.arrAcrel");
        // console.log(prevProps.arrAcrel);
        // console.log("this.props.arrAcrel");
        // console.log(this.props.arrAcrel);
        // console.log("prevProps.arrEastron");
        // console.log(prevProps.arrEastron);
        // console.log("this.props.arrEastron");
        // console.log(this.props.arrEastron);
        if (prevProps.arrBatteries !== this.props.arrBatteries || prevProps.arrAcrel !== this.props.arrAcrel || prevProps.arrEastron !== this.props.arrEastron) {
            /* Update arrays of devices */
            // console.log("chart container should rerender because props changed");
            this.setState({
                arrBatteries: typeof this.props.arrBatteries !== 'undefined' ? this.props.arrBatteries : [],
                arrAcrel: typeof this.props.arrAcrel !== 'undefined' ? this.props.arrAcrel : [],
                arrEastron: typeof this.props.arrEastron !== 'undefined' ? this.props.arrEastron : []
            },() => {
                /* Update data for chart */
                let from = this.state.startDate.getFullYear().toString() + ('0' + (this.state.startDate.getMonth() + 1)).slice(-2) + ('0' + this.state.startDate.getDate()).slice(-2);
                let to = this.state.endDate.getFullYear().toString() + ('0' + (this.state.endDate.getMonth() + 1)).slice(-2) + ('0' + this.state.endDate.getDate()).slice(-2);
                dataService.chartDataMongo(from,to,this.state.arrBatteries,this.state.arrAcrel,this.state.arrEastron)
                    .then(response => {
                        this.setState({ powerLogs: response.data.main_chart_data })
                    });
            });
            // this.setState({ selectedId: 62726 });

        }
    }

    previousDayHandler = () => {
        // console.log("test new date prev day");
        // console.log(new Date());
        // console.log("this.state.startDate prev day");
        // console.log(this.state.startDate);
        // console.log("this.state.EndDate prev day");
        // console.log(this.state.EndDate);
        // let first = this.state.endDate.setHours(12,0,0,0).toString();
        // console.log("first prev day");
        // console.log(first);
        // let second = first / 1000;
        // console.log("second prev day");
        // console.log(second);
        // let third = new Date(1000 * second);
        // console.log("third prev day");
        // console.log(third);
        // let fourth = third.getDate() - 1;
        // let fifth = new Date(third.setDate(third.getDate() - 1));
        // console.log("fifth prev day");
        // console.log(fifth);
        // let sixth = fifth.getDate();
        // console.log("sixth prev day");
        // console.log(sixth);
        // let seventh = fifth.getFullYear().toString() + ('0' + (fifth.getMonth() + 1)).slice(-2) + ('0' + fifth.getDate()).slice(-2);
        // console.log("fourth prev day");
        // console.log(fourth);
        // console.log("seventh prev day");
        // console.log(seventh);
        // let currentDateString = this.state.startDate.setHours(12,0,0,0).toString();
        let currentDateString = this.state.startDate.setUTCHours(12,0,0,0).toString();
        let secondAlt = currentDateString / 1000;
        let currentDateNoon = new Date(1000 * secondAlt);
        // console.log("currentDateNoon prev day");
        // console.log(currentDateNoon);
        let newDate = new Date(currentDateNoon.setDate(currentDateNoon.getDate() - 1));
        // console.log("newDate prev day");
        // console.log(newDate);
        this.setState({ startDate: newDate,endDate: newDate });
        let newFrom = newDate.getFullYear().toString() + ('0' + (newDate.getMonth() + 1)).slice(-2) + ('0' + newDate.getDate()).slice(-2);
        // console.log("newFrom prev day");
        // console.log(newFrom);
        // console.log("this.state.startDate prev day");
        // console.log(this.state.endDate);
        // console.log("this.state.startDate prev day");
        // console.log(this.state.endDate);
        // console.log("get date prev day");
        // console.log(this.state.startDate.getDate());
        // console.log("get date -1 prev day");
        // console.log(this.state.startDate.getDate() - 1);
        // console.log("calc new date prev day");
        // console.log(new Date(this.state.startDate.setDate(this.state.startDate.getDate() - 1)).setHours(12));
        // console.log("second calc new date prev day");
        // console.log(new Date(this.state.startDate.setDate(this.state.startDate.getDate() - 1)).setHours(12,0,0,0));
        // let newStartDate = new Date(this.state.startDate.setDate(this.state.startDate.getDate() - 1));
        // let newStartDate = new Date(1000 * second);
        // this.setState({ startDate: new Date(this.state.startDate.setDate(this.state.startDate.getDate() - 1)),endDate: new Date(this.state.startDate.setDate(this.state.startDate.getDate() - 1)) });
        // this.setState({ startDate: newStartDate,endDate: newStartDate });
        // this.setState({ startDate: newDate,endDate: newDate });
        // let from = this.state.startDate.getFullYear().toString() + ('0' + (this.state.startDate.getMonth() + 1)).slice(-2) + ('0' + this.state.startDate.getDate()).slice(-2);
        // let from = newStartDate.getFullYear().toString() + ('0' + (newStartDate.getMonth() + 1)).slice(-2) + ('0' + newStartDate.getDate()).slice(-2);
        let from = newFrom;
        let to = from;
        // console.log("from prev day");
        // console.log(from);
        dataService.chartDataMongo(from,to,this.props.arrBatteries,this.props.arrAcrel,this.props.arrEastron)
            .then(response => {
                this.setState({ powerLogs: response.data.main_chart_data })
            });
    }

    nextDayHandler = () => {
        // console.log("this.state.startDate next day");
        // console.log(this.state.endDate);
        // console.log("this.state.startDate next day");
        // console.log(this.state.endDate);
        let currentDateString = this.state.startDate.setUTCHours(12,0,0,0).toString();
        let secondAlt = currentDateString / 1000;
        let currentDateNoon = new Date(1000 * secondAlt);
        // console.log("currentDateNoon next day");
        // console.log(currentDateNoon);
        let newDate = new Date(currentDateNoon.setDate(currentDateNoon.getDate() + 1));
        // console.log("newDate next day");
        // console.log(newDate);
        this.setState({ startDate: newDate,endDate: newDate });
        let from = newDate.getFullYear().toString() + ('0' + (newDate.getMonth() + 1)).slice(-2) + ('0' + newDate.getDate()).slice(-2);
        // console.log("from next day");
        // console.log(from);
        // this.setState({ startDate: new Date(this.state.startDate.setDate(this.state.startDate.getDate() + 1)),endDate: new Date(this.state.startDate.setDate(this.state.startDate.getDate() + 1)) });
        // let from = this.state.startDate.getFullYear().toString() + ('0' + (this.state.startDate.getMonth() + 1)).slice(-2) + ('0' + this.state.startDate.getDate()).slice(-2);
        let to = from;
        dataService.chartDataMongo(from,to,this.props.arrBatteries,this.props.arrAcrel,this.props.arrEastron)
            .then(response => {
                this.setState({ powerLogs: response.data.main_chart_data })
            });
    }
    // https://devapi.wattuneed.com/chart-data?start=20210721&end=20210721&battery[]=BU-50-02-91-F7-74-E3&acrel[]=BU-98-F4-AB-EE-D1-6E
    // https://devapi.wattuneed.com/chart-data?start=20210721&end=20210721&battery[]=BU-50-02-91-F7-74-E3&acrel[]=BU-98-F4-AB-EE-D1-6E
    // https://devapi.wattuneed.com/chart-data?start=20210722&end=20210722&battery[]=BU-50-02-91-F7-74-E3&acrel[]=BU-98-F4-AB-EE-D1-6E

    render() {
        const { t } = this.props;

        if (this.state.powerLogs.length < 1) { return (<Spinner />) };
        let chart = "";
        // if (this.state.powerLogs === 'empty') {
        if (this.state.powerLogs === 'empty' || this.state.powerLogs === 'error') {
            chart = (<p>{t('common:datas.no-data')}</p>);
        } else {
            // chart = (<AmChart powerLogs={this.state.powerLogs} from={this.startDate} to={this.endDate} />);
            // chart = (<MainDataChart powerLogs={this.state.powerLogs} from={this.startDate} to={this.endDate} />);
            // chart = (<AllDevicesChart powerLogs={this.state.powerLogs} from={this.startDate} to={this.endDate} />);
            chart = (<AllDevicesChart powerLogs={this.state.powerLogs} arrBatteries={this.state.arrBatteries} arrAcrel={this.state.arrAcrel} arrEastron={this.state.arrEastron} from={this.state.startDate} to={this.state.endDate} />);
        }
        return (
            <Data
                icon="icone-battery-UP-IMG-Graph"
                title={t('dashboard:ChartContainer.chart-title')}
                type="activityChart"
            >
                <div className={classes.ChartContainer}>
                    <p>
                        <Trans i18nKey="dashboard:ChartContainer.select-day-period">
                            Pour le graphique ci-dessous, vous pouvez sélectionner soit une date, un jour précis (<span className={classes.aboveBelow}>above</span><span className={classes.leftRight}>à gauche</span>) soit une période de plusieurs jours (<span className={classes.aboveBelow}>below</span><span className={classes.leftRight}>à droite</span>).<br />Après avoir fait votre choix, appuyer sur le bouton "appliquer" pour mettre le graphique à jour.
                        </Trans>
                    </p>
                    <div className={classes.datesChoice}>
                        {/* <img src={require("../../_assets/img/IMG-vector-calendrier-date-Jour-J.png")} alt="Calendrier-J" style={{ position: "absolue",width: "180px" }} /> */}
                        <div className={classes.datesOption}>
                            <img src={require("../../_assets/img/IMG-vector-calendrier-date-Jour-J.png")} alt="Calendrier-J" style={{ position: "absolue",width: "100px" }} />
                            <p>{t('dashboard:ChartContainer.pick-date')}</p>
                            <p className={classes.help}>{t('dashboard:ChartContainer.pick-specific-day')}</p>
                            <DatePicker
                                className={classes.DatePicker}
                                selected={this.state.startDate}
                                maxDate={new Date()}
                                onChange={this.dayPickerHandler} />
                        </div>

                        { /* Change date(s) for the activity chart */}
                        {/* <Button
                            customClass={'submitButton'}
                            label={'Apply change'}
                            action={this.dateChangeHandler} /> */}
                        {this.props.i18n.language === "fr" ? (
                            <img onClick={this.dateChangeHandler} className={classes.applyBtn} src={require("../../_assets/img/Bouton-appliquer-battery-up-header.png")} alt="Apply button" />
                        ) : (
                            <img onClick={this.dateChangeHandler} className={classes.applyBtn} src={require("../../_assets/img/Bouton-apply-battery-up.png")} alt="Apply button" />
                        )}


                        <div className={classes.datesOption}>
                            <img src={require("../../_assets/img/IMG-vector-calendrier-date-periode.png")} alt="Calendrier-J" style={{ position: "absolue",width: "100px" }} />
                            <p>{t('dashboard:ChartContainer.pick-period')}</p>
                            {/* <p className={classes.help}>{t('dashboard:ChartContainer.pick-several-days')}</p> */}
                            <p className={classes.help}>
                                <Trans i18nKey="dashboard:ChartContainer.pick-start-end">
                                    Sélectionnez un jour de début<br />et un jour de fin
                                </Trans>
                            </p>
                            <DatePicker
                                className={classes.DatePicker}
                                selected={this.state.startDate}
                                maxDate={new Date()}
                                onChange={this.datePickerHandler}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                selectsRange
                                shouldCloseOnSelect={false}
                            // showDisabledMonthNavigation
                            />
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <AmChart powerLogs={this.state.powerLogs} from={this.startDate} to={this.endDate} /> */}
                    {chart}
                    {/* <AllDevicesChart powerLogs={this.state.powerLogs} arrBatteries={this.state.arrBatteries} arrAcrel={this.state.arrAcrel} from={this.startDate} to={this.endDate} /> */}
                    <div className={classes.controls}>
                        <div onClick={this.previousDayHandler} className={`${classes.controls__day} ${classes.controls__prev}`} id="prevDay">
                            <img src={require("../../_assets/img/icons/icon_previous_day.png")} alt="Previous day" />
                            <span>{t('dashboard:ChartContainer.previous-day')}</span>
                        </div>
                        <div onClick={this.nextDayHandler} className={`${classes.controls__day} ${classes.controls__next}`} id="nextDay">
                            <span>{t('dashboard:ChartContainer.next-day')}</span>
                            <img src={require("../../_assets/img/icons/icon_next_day.png")} alt="Previous day" />
                        </div>
                    </div>
                </div>
            </Data>
        );
    }
    // }
}

export default withTranslation(['common','dashboard'])(ChartContainer);