import React from 'react';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import classes from './TriggersInfo.module.scss';

const TriggersInfo = (props) => {
    const { t } = useTranslation(['common','triggerspage']);

    return (
        <div className={`row ${classes.TriggersInfo}`}>
            {/* <div className="section-title angle-section-title">
                <img className={classes.sectionBanner} src={require("../../_assets/img/IMG-alert-system.png")} alt={t('monitoringpage:IntroMonitoring.banner-alt')} />
                <h2 className={classes.sectionTitle}>{t('triggerspage:TriggersInfo.title')}</h2>
            </div> */}
            <div className={`col-md-5 ${classes.leftColumn}`}>
                <img className={classes.monitoringImg} src={require("../../_assets/img/IMG-alert-system.png")} alt={t('monitoringpage:IntroMonitoring.img-batteries-alt')} />
            </div>

            <div className={`col-md-7 ${classes.rightColumn}`}>
                {/* <ReactWOW delay='0.9s' animation='fadeInUp'> */}
                <div className={`text-left ${classes.wifi}`}>
                    <h3 className={`smallLine-title ${classes.sectionSubtitle}`}>{t('triggerspage:TriggersInfo.title')}</h3>
                    <p>
                        <Trans i18nKey="triggerspage:TriggersInfo.alert-system">
                            <em className={classes.batteryUp}>Battery UP</em> vous permet de configurer des alertes qui correspondent à vos besoins. Définissez la condition ainsi que l'action qui doit être déclenchée lorsque la condition est remplie.
                        </Trans>
                    </p>
                </div>
                {/* </ReactWOW> */}
            </div>

            {/* <div className={`section-title ${classes.sectionTitleWrapper}`}>
                <h2 className={classes.sectionTitle}>{t('triggerspage:TriggersInfo.title')}</h2>
            </div>
            <div className={`text-left ${classes.TriggersInfo__content}`}>
                <p>
                    <Trans i18nKey="triggerspage:TriggersInfo.alert-system">
                        <em className={classes.batteryUp}>Battery UP</em> vous permet de configurer des alertes qui correspondent à vos besoins. Définissez la condition ainsi que l'action qui doit être déclenchée lorsque la condition est remplie.
                    </Trans>
                </p>
                <p>{t('triggerspage:TriggersInfo.choices')}</p>
            </div> */}
        </div>
    )
};

export default TriggersInfo;