// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal_Modal__3gWBV {\n  position: fixed;\n  z-index: 500;\n  background-color: white;\n  width: 70%;\n  border: 1px solid #ccc;\n  box-shadow: 1px 1px 1px black;\n  padding: 20px;\n  left: 15%;\n  top: 30%;\n  box-sizing: border-box;\n  transition: all 0.3s ease-out; }\n", ""]);
// Exports
exports.locals = {
	"Modal": "Modal_Modal__3gWBV"
};
module.exports = exports;
