import React,{ useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { userActions } from '../../_actions/userActions';
import { userService } from '../../_services/userService';

import Data from '../Data/Data';
import AdminBlock from '../Layout/Elements/AdminBlock';

const ClientChoice = (props) => {
    const { t } = useTranslation('admin');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const [clients,setClients] = useState([]);
    // const [selectedClient,setSelectedClient] = useState(user.roles.includes('client') ? user.modules[0] : "5600");
    // const [selectedClient,setSelectedClient] = useState(JSON.parse(localStorage.getItem('selectedClient')));
    // const dispatch = useDispatch();
    // console.log("localStorage");
    // console.log(JSON.parse(localStorage.getItem('appState')));
    // console.log("user id in client choice");
    // console.log(user.id);

    useEffect(() => {
        /*if(user.roles.includes('installer')) {
            // dispatch(userActions.getClientsModules());
            userService.getClients()
                .then(response => {
                    setClients(response);
                });
        }
        if(user.roles.includes('super-admin') || user.roles.includes('admin')) {
            userService.getAll()
                .then(response => {
                    setClients(response);
                });
            // dispatch(userActions.getModules());
        }*/
        if (user.roles.includes('installer')) {
            setClients(user.clients);
        }
        if (user.roles.includes('super-admin') || user.roles.includes('admin')) {
            userService.getAll()
                .then(response => {
                    setClients(response);
                });
        }
    },[]);

    /*useEffect(() => {
        // console.log("Value of Selected client in State is: ", selectedClient);
        localStorage.setItem("selectedClient",JSON.stringify(selectedClient));
    }, [selectedClient]);    

    function handleSelectChange(event) {
        // console.log("select old value");
        // console.log(selectedClient);
        setSelectedClient(event.target.value);
        // console.log("select new value");
        // console.log(selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(selectedClient));
    }*/

    // console.log("clients in client choice in the middle of everything");
    // console.log(clients);

    // let options = "";
    // if(user.roles.includes('client')) {
    //     options = modules.map((module,idx) => (
    //         <option
    //             selected={user.id === module.user.id ? "selected" : ""}
    //             value={module.mac_address}
    //             key={module.mac_address}
    //         >
    //             {module.user.firstname} {module.user.surname}
    //         </option>
    //     ))
    // } else {
    //     options = modules.map((module,idx) => (
    //         <option
    //             value={module.mac_address}
    //             key={module.mac_address}
    //         >
    //             {module.user.firstname} {module.user.surname}
    //         </option>
    //     ))
    // }

    return (
        // <Data
        //     icon="icone-battery-UP-IMG-CLIENT"
        //     title="Client"
        //     type="select"
        // >
        <AdminBlock
            icon="icone-battery-UP-IMG-CLIENT"
            title={t('client-choice')}
        // type="select"
        >
            <select value={props.selectedClient.id} onChange={props.changed}>
                <option value="demo">Demo</option>
                {clients.map((client,idx) => {
                    if (user.roles.includes('client')) {
                        if (user.id === client.id) {
                            return (
                                <option
                                    selected
                                    value={client.id}
                                    key={idx}
                                >
                                    {client.firstname} {client.surname}
                                </option>
                            )
                        } else {
                            return (
                                <option
                                    value={client.id}
                                    key={idx}
                                >
                                    {client.firstname} {client.surname}
                                </option>
                            )
                        }
                    } else {
                        return (
                            <option
                                value={client.id}
                                key={idx}
                            >
                                {client.firstname} {client.surname}
                            </option>
                        )
                    }
                })}
                {/* {options} */}
            </select>
        </AdminBlock>
        // </Data>
    );
};

export default ClientChoice;