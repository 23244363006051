import React,{ useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './AlreadyRegistered.module.scss';

const AlreadyRegistered = (props) => {
    const [register,setRegister] = useState(false);
    return (
        <div className={`col-md-12 ${classes.AlreadyRegistered}`}>
            <button className="btn btn-primary">
                {props.loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                <Link to="/my-profile"><span style={{ color: "#fff" }}>Profil</span></Link>
            </button>
            <p style={{ padding: ".5rem",display: "inline-block" }}>or</p>
            <button className="btn btn-primary" onClick={() => { setRegister(true) }}>
                {props.loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    New inscription
            </button>
        </div>
    );
};

export default AlreadyRegistered;