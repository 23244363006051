import React,{ Component } from 'react';

import i18n from "i18next";
import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './ControlBatteries.module.scss';
import fondImgTexte1 from '../../_assets/img/fond-texte-solution-batteries-lihtium.png';

// const ControlBatteries = (props) => {
//     return (
//         <section id="about" className={`app-about-section angle-sp ${classes.ctrlBatteries}`}>
//             <div className={`container ${classes.container}`}>
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <ReactWOW delay='0.3s' animation='fadeInUp'>
//                             <div className="section-title angle-section-title">
//                                 <h2 className={classes.sectionTitle}>Monitorez et gérez votre installation solaire</h2>
//                             </div>
//                         </ReactWOW>
//                     </div>
//                 </div>

//                 <div className="row angle-slides-wrapper-bg">
//                     <div className="col-md-7 col-lg-8 col-xl-9">
//                         <ReactWOW delay='0.6s' animation='fadeInUp'>
//                             <div className="app-about-text">
//                                 <h3 className={classes.sectionSubtitle}>La solution pour connecter vos batteries lithium</h3>
//                                 <p>Une fois connecté, votre boîtier, permet via IFTTT de déclencher et de couper automatiquement vos appareils en fonction du niveau de charge de votre batterie Pylontech. La mise en veille de vos appareils permet de préserver votre batterie et maximiser l’utilisation de l’énergie stockée.</p>
//                                 <p><span className="application">L’Application Battery UP</span> affiche les valeurs instantanées lorsque vous êtes connectés à votre compte. Vos données sont enregistrées par intervalle de 15 secondes et restent accessibles peu importe où vous vous trouvez via notre Cloud.</p>

//                                 <ul>
//                                     <li><i className="icofont-verification-check"></i> Connexion WiFi</li>
//                                     <li><i className="icofont-verification-check"></i> Alertes IFTT</li>
//                                     <li><i className="icofont-verification-check"></i> Application mobile</li>
//                                 </ul>

//                                 <a href="#app-download" className="default-button">
//                                     <i className="icofont-cloud-download"></i>
//                                     Découvrir
//                                 </a>
//                             </div>
//                         </ReactWOW>
//                     </div>

//                     <div className={`col-md-5 col-lg-4 col-xl-3 ${classes.imgSummary}`}>
//                         <ReactWOW delay='0.9s' animation='fadeInUp'>
//                             <div className="text-center">
//                                 <img className="IMG1" src={require("../../_assets/img/Solution-connexion-illu-IMG.png")} alt="App About Image" />
//                                 <img className="IMG2" src={require("../../_assets/img/Solution-connexion-illu-IMG2.png")} alt="App About Image" />
//                                 <img className="IMG3" src={require("../../_assets/img/Solution-connexion-illu-IMG3.png")} alt="App About Image" />
//                             </div>
//                         </ReactWOW>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default ControlBatteries;

class ControlBatteries extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="about" className={`app-about-section angle-sp ${classes.ctrlBatteries}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className="section-title angle-section-title">
                                    <h2 className={classes.sectionTitle}>{t('homepage:ControlBatteries.title')}</h2>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="row angle-slides-wrapper-bg">
                        <div className="col-md-7 col-xl-8">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className="app-about-text">
                                    <h3 className={classes.sectionSubtitle}>{t('homepage:ControlBatteries.solution-connect-battery')}</h3>
                                    <p>{t('homepage:ControlBatteries.ifttt-connection')}</p>
                                    <p>
                                        <Trans i18nKey="homepage:ControlBatteries.data">
                                            <span className="application">L’Application Battery UP</span> affiche les valeurs instantanées lorsque vous êtes connectés à votre compte. Vos données sont enregistrées par intervalle de 15 secondes et restent accessibles peu importe où vous vous trouvez via notre Cloud.
                                        </Trans>
                                    </p>
                                    {/* <p><span className="application">L’Application Battery UP</span> affiche les valeurs instantanées lorsque vous êtes connectés à votre compte. Vos données sont enregistrées par intervalle de 15 secondes et restent accessibles peu importe où vous vous trouvez via notre Cloud.</p> */}

                                    <ul>
                                        <li><i className="icofont-verification-check"></i> {t('homepage:ControlBatteries.wifi-connection')}</li>
                                        <li><i className="icofont-verification-check"></i> {t('homepage:ControlBatteries.ifttt-alerts')}</li>
                                        <li><i className="icofont-verification-check"></i> {t('homepage:ControlBatteries.mobile-application')}</li>
                                    </ul>

                                    <a href="#app-download" className="default-button">
                                        <i className="icofont-cloud-download"></i>
                                        {t('common:buttons.discover')}
                                    </a>
                                </div>
                            </ReactWOW>
                        </div>

                        <div className={`col-md-5 col-xl-4 ${classes.imgSummary}`}>
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                {this.props.i18n.language === "fr" ? (
                                    <div className="text-center">
                                        <img className="IMG1" src={require("../../_assets/img/Solution-connecter-batterie-illu-IMG.png")} alt="App About Image" />
                                        <img className="IMG2" src={require("../../_assets/img/Solution-connecter-batterie-illu-IMG2.png")} alt="App About Image" />
                                        <img className="IMG3" src={require("../../_assets/img/Solution-connexion-illu-IMG3.png")} alt="App About Image" />
                                    </div>
                                ) : (
                                        <div className="text-center">
                                            <img className="IMG1" src={require("../../_assets/img/Solution-connecter-batterie-illu-IMG-EN.png")} alt="App About Image" />
                                            <img className="IMG2" src={require("../../_assets/img/Solution-connecter-batterie-illu-IMG2-en.png")} alt="App About Image" />
                                            <img className="IMG3" src={require("../../_assets/img/Solution-connecter-batterie-illu-IMG3-EN.png")} alt="App About Image" />
                                        </div>
                                    )}
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','homepage'])(ControlBatteries);
// export default withTranslation()(ControlBatteries);