import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { moduleService } from '../../_services/moduleService';
import { dataService } from '../../_services/dataService';

import Notification from '../UI/Notifications/Notification';

import classes from './DeviceControl.module.scss';

const DeviceControl = (props) => {
    const { t } = useTranslation(['notifications']);

    const mac_address = props.mac_address;
    const protocol = props.protocol;
    const [moduleName,setModuleName] = useState("");
    const [lastRecord,setLastRecord] = useState("");
    const [amountMinutes,setAmountMinutes] = useState(0);
    const [isOldRecord,setIsOldRecord] = useState(false);
    const [hasNoRecord,setHasNoRecord] = useState(false);
    const [moduleDisplay,setModuleDisplay] = useState("");

    useEffect(() => {
        let moduleCtrlId = "control".mac_address;
        let moduleCtrlNotif = "display".mac_address;
        setModuleDisplay(moduleCtrlNotif);
        if (localStorage.getItem(moduleCtrlId)) {
            // console.log("localStorage.getItem(moduleCtrlId)");
            // console.log(localStorage.getItem(moduleCtrlId));
            let lastCheck = JSON.parse(localStorage.getItem(moduleCtrlId));
            let nowIntValue = Math.floor(new Date().getTime() / 1000);
            // console.log("lastCheck");
            // console.log(lastCheck);
            // console.log("nowIntValue");
            // console.log(nowIntValue);
            console.log("localStorage.getItem(moduleCtrlNotif)");
            console.log(localStorage.getItem(moduleCtrlNotif));
            if (nowIntValue - lastCheck > 1200 || localStorage.getItem(moduleCtrlNotif) === "true") {
                localStorage.setItem(moduleCtrlNotif,"true");
                console.log("nowIntValue - lastCheck > 10 || localStorage.getItem(moduleCtrlNotif) === true");
                moduleService.getNameByMacAddress(mac_address)
                    .then(response => {
                        setModuleName(response);
                    });
                dataService.lastRecord(mac_address,protocol)
                    .then(response => {
                        console.log("module last record - DeviceControl");
                        console.log(response);
                        // let test = new Date(response).getTime() / 1000;
                        // console.log("module last record INT - DeviceControl");
                        // console.log(test);
                        // let testNow = Math.floor(new Date().getTime() / 1000);
                        // console.log("now() INT - DeviceControl");
                        // console.log(testNow);
                        // console.log("DIFF last record and now - DeviceControl");
                        // console.log(testNow - test);
                        // if (testNow - test > 36) {
                        //     console.log("should be true");
                        // } else {
                        //     console.log("should stay false");
                        // }
                        setLastRecord(response);
                        let nowInt = Math.floor(new Date().getTime() / 1000);
                        if (response === "empty") {
                            setHasNoRecord(true);
                        } else {
                            let lastRecordInt = new Date(response).getTime() / 1000;
                            let diff = nowInt - lastRecordInt;
                            if (diff > 1200) {
                                setIsOldRecord(true);
                                setAmountMinutes(Math.floor(diff / 60));
                            }
                        }
                        localStorage.setItem(moduleCtrlId,nowInt);
                        // setHasNoRecord(true);
                        // setIsOldRecord(true);
                        // setLastRecord(response);
                    });
            }
        } else {
            // console.log("localStorage.getItem(moduleCtrlId) doest not exist");
            // console.log(localStorage.getItem(moduleCtrlId));
            moduleService.getNameByMacAddress(mac_address)
                .then(response => {
                    setModuleName(response);
                });
            dataService.lastRecord(mac_address,protocol)
                .then(response => {
                    setLastRecord(response);
                    let nowInt = Math.floor(new Date().getTime() / 1000);
                    if (response === "empty") {
                        setHasNoRecord(true);
                    } else {
                        let lastRecordInt = new Date(response).getTime() / 1000;
                        let diff = nowInt - lastRecordInt;
                        if (diff > 1200) {
                            setIsOldRecord(true);
                            setAmountMinutes(Math.floor(diff / 60));
                        }
                    }
                    localStorage.setItem(moduleCtrlId,nowInt);
                    // setHasNoRecord(true);
                    // setIsOldRecord(true);
                    // setLastRecord(response);
                });
        }
    },[props.mac_address,props.protocol]);

    function checkDataValidity(mac_address,protocol) {
        dataService.checkDataValidity(mac_address,protocol)

    }

    return (
        hasNoRecord ? (
            <Notification title={moduleName} type="warning" message={t('notifications:DeviceControl.no-data')} info="" ctrlId={moduleDisplay} />
        ) : isOldRecord ? (
            <Notification title={moduleName} type="warning" message={t('notifications:DeviceControl.no-data-n-minutes',{ minutes: amountMinutes })} info={t('notifications:DeviceControl.last-record',{ datetime: lastRecord })} ctrlId={moduleDisplay} />
        ) : ""
        // <p className={classes.DeviceControl}>{props.protocol} {props.mac_address}</p>
    )
};

export default DeviceControl;