import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../_services/dataService';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

import classes from './TopData.module.scss';

const TopData = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [batteries,setBatteries] = useState([]);
    const [acrels,setAcrels] = useState([]);
    const [eastrons,setEastrons] = useState([]);
    const batteriesId = props.arrBatteries;
    const acrelsId = props.arrAcrel;
    const eastronsId = props.arrEastron;
    // console.log("batteries for map");
    // console.log(batteries);
    // console.log("acrels for map");
    // console.log(acrels);
    // console.log("eastrons for map");
    // console.log(eastrons);

    useEffect(() => {
        // console.log("batteriesId in useeffect");
        // console.log(batteriesId);
        // console.log("acrelsId in useeffect");
        // console.log(acrelsId);
        // console.log("eastronsId in useeffect");
        // console.log(eastronsId);
        if (batteriesId.length > 0) {
            dataService.lastBatteriesValues(batteriesId)
                .then(response => {
                    // console.log('non formatted response');
                    // console.log(response);
                    // console.log("response lastBatteriesValues done");
                    // console.log(Object.values(response));
                    if (response !== 'empty') {
                        setBatteries(Object.values(response));
                    }
                });
        } else {
            setBatteries([]);
        }
        if (acrelsId.length > 0) {
            dataService.lastAcrelsValues(acrelsId)
                .then(response => {
                    // console.log("response lastAcrelsValues done");
                    // console.log(response);
                    if (response === 'empty') {
                        setAcrels([]);
                    } else {
                        setAcrels(Object.values(response));
                    }
                });
        } else {
            setAcrels([]);
        }
        if (eastronsId.length > 0) {
            dataService.lastEastronsValues(eastronsId)
                .then(response => {
                    // console.log("response lastEastronsValues done");
                    // console.log(response);
                    if (response === 'empty') {
                        setEastrons([]);
                    } else {
                        setEastrons(Object.values(response));
                    }
                });
        } else {
            setEastrons([]);
        }
    },[batteriesId,acrelsId,eastronsId]);

    return (
        // <div className={`col-lg-12 ${classes.TopData}`}>
        <div className={`row ${classes.TopData}`}>
            {batteries.length > 0 ? (
                batteries.map((battery,idx) => {
                    return (
                        <div className={`col-md-7 ${classes.TopData__list}`}>
                            <div className={`${classes.battery} ${classes.listItem}`} key={idx}>
                                <h4>{battery.module_name}<span>({battery.mac_address})</span></h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {battery.date}</p>
                                <div className={classes.dataWrapper}>
                                    <div className={`${classes.data}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div>
                                    {/* <div className={`${classes.data} ${classes.dataBg2}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div>
                                    <div className={`${classes.data} ${classes.dataBg3}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div>
                                    <div className={`${classes.data} ${classes.dataBg4}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div>
                                    <div className={`${classes.data} ${classes.dataBg5}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div>
                                    <div className={`${classes.data} ${classes.dataBg6}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div>
                                    <div className={`${classes.data} ${classes.dataBg7}`}>
                                        <h5>{t('common:datas.battery.soc')}</h5>
                                        <p>{battery.soc}%</p>
                                    </div> */}
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.battery.itot')}</h5>
                                        <p>{battery.itot} A</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.battery.max-power')}</h5>
                                        <p>{battery.itotMax} kW</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.battery.max-current-week')}</h5>
                                        <p>{battery.week_itot_min} A</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.battery.min-current-week')}</h5>
                                        <p>{battery.week_itot_max} A</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.battery.average-current-hour')}</h5>
                                        <p>{battery.hour_avg_itot} A et {battery.hour_avg_itot_v} W/h</p>
                                    </div>
                                    <div className={`${classes.data} ${classes.longData}`}>
                                        <h5>{t('common:datas.battery.last-req-force-chg')}</h5>
                                        <p>{battery.LastForcedCharge ? battery.LastForcedCharge : t('common:datas.n-a')}</p>
                                    </div>
                                </div>
                                {/* <div className={classes.dataWrapper}>
                                    <div className={`${classes.data} ${classes.additionalData}`}>
                                        <p>Décharge surtension : <span>{battery.itot}</span></p>
                                        <p>Charge surtension : <span>{battery.itot}</span></p>
                                        <p>Cellule en sous-température : <span>{battery.itot}</span></p>
                                        <p>Cellule en sur-température : <span>{battery.itot}</span></p>
                                        <p>Cellule en sous-voltage : <span>{battery.itot}</span></p>
                                        <p>Cellule en sur-voltage : <span>{battery.itot}</span></p>
                                        <p>Erreur du système : <span>{battery.itot}</span></p>
                                        <p>Alarme charge profonde: <span>{battery.itot}</span></p>
                                        <p>Alarme décharge profonde: <span>{battery.itot}</span></p>
                                        <p>Alarme cellule température basse: <span>{battery.itot}</span></p>
                                        <p>Alarme cellule température haute: <span>{battery.itot}</span></p>
                                        <p>Alarme cellule bas voltage: <span>{battery.itot}</span></p>
                                        <p>Alarme cellule haut voltage: <span>{battery.itot}</span></p>
                                        <p>Alarme erreur de communication: <span>{battery.itot}</span></p>
                                        <p>Nombre de modules: <span>{battery.itot}</span></p>
                                        <p>Voltage charge de la batterie: <span>{battery.itot}</span></p>
                                        <p>Limite courant de charge: <span>{battery.itot}</span></p>
                                        <p>Limite courant de décharge: <span>{battery.itot}</span></p>
                                        <p>Etat de santé de la batterie : <span>{battery.itot}</span></p>
                                        <p>Voltage : <span>{battery.itot}</span></p>
                                        <p>Courant total : <span>{battery.itot}</span></p>
                                        <p>Température moyenne des cellules: <span>{battery.itot}</span></p>
                                        <p>Demande de charge autorisée: <span>{battery.itot}</span></p>
                                        <p>Demande de décharge autorisée: <span>{battery.itot}</span></p>
                                        <p>Demande de charge forcée 1: <span>{battery.itot}</span></p>
                                        <p>Demande de charge forcée 2: <span>{battery.itot}</span></p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )
                    // })) : acrelsId.length > 0 ? (
                    //     <p>Pas de </p>
                    // ) : ""
                })) : ""
            }
            {acrels.length > 0 ? (
                acrels.map((acrel,idx) => {
                    return (
                        <div className={`col-md-5 ${classes.TopData__list}`}>
                            <div className={`${classes.acrel} ${classes.listItem}`} key={idx}>
                                <h4>{acrel.module_name}<span>({acrel.mac_address})</span></h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {acrel.date}</p>
                                <div className={classes.dataWrapper}>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.acrel.acr-p')}</h5>
                                        {Math.abs(acrel.acr_p) >= 1 ? (
                                            <p>{acrel.acr_p} kW</p>
                                        ) : (
                                            <p>{acrel.acr_p * 1000} W</p>
                                        )}
                                    </div>
                                    {/* <div className={classes.data}>
                                        <h5>Puissance<br />Max</h5>
                                        <p>{acrel.maxPower} W</p>
                                    </div> */}
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.acrel.max-consum')}</h5>
                                        <p>{acrel.maxConsum} W</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.acrel.max-prod')}</h5>
                                        <p>{acrel.maxProd} W</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    // })) : acrelsId.length > 0 ? (
                    //     <p>Pas de </p>
                    // ) : ""
                })) : ""
            }
            {eastrons.length > 0 ? (
                eastrons.map((eastron,idx) => {
                    return (
                        <div className={`col-md-6 ${classes.TopData__list}`}>
                            <div className={`${classes.eastron} ${classes.listItem}`} key={idx}>
                                <h4>{eastron.module_name}<span>({eastron.mac_address})</span></h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {eastron.date}</p>
                                <div className={classes.dataWrapper}>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.eastron.power')}</h5>
                                        <p>{eastron.power} W</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.eastron.max-consum')}</h5>
                                        <p>{eastron.maxConsum} W</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.eastron.max-prod')}</h5>
                                        <p>{eastron.maxProd < 0 ? (eastron.maxProd + "W") : t('common:datas.n-a')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })) : ""
            }
        </div>
    )
};

export default TopData;