// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RegisterForm_RegisterForm__hhi5i button img {\n  height: 80px; }\n\n.RegisterForm_RegisterForm__hhi5i .RegisterForm_alreadyAccount__2f0ZB {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 20px 0; }\n  .RegisterForm_RegisterForm__hhi5i .RegisterForm_alreadyAccount__2f0ZB span, .RegisterForm_RegisterForm__hhi5i .RegisterForm_alreadyAccount__2f0ZB a {\n    padding: 0;\n    margin: 0;\n    border: none;\n    line-height: 1em;\n    font-size: 16px; }\n  .RegisterForm_RegisterForm__hhi5i .RegisterForm_alreadyAccount__2f0ZB a {\n    margin-left: 10px; }\n    .RegisterForm_RegisterForm__hhi5i .RegisterForm_alreadyAccount__2f0ZB a::first-letter {\n      text-transform: uppercase; }\n", ""]);
// Exports
exports.locals = {
	"RegisterForm": "RegisterForm_RegisterForm__hhi5i",
	"alreadyAccount": "RegisterForm_alreadyAccount__2f0ZB"
};
module.exports = exports;
