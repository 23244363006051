import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { userService } from '../../_services/userService';
import { userActions } from '../../_actions/userActions';
import { roleService } from '../../_services/roleService';
import { history } from '../../_helpers/history';

import TemporaryPopup from '../UI/ValidationMessage/TemporaryPopup';

const ChangePasswordForm = (props) => {
    const authUser = JSON.parse(localStorage.getItem('appState')).user;
    const [user,setUser] = useState({
        password: '',
        password_confirmation: ''
    });
    const [submitted,setSubmitted] = useState(false);
    const [modified,setModified] = useState(false);
    // const { firstname,surname,email,password,password_confirmation } = inputs;
    const modifying = useSelector(state => state.modifying);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name,value } = e.target;
        setUser(user => ({ ...user,[name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (user.password && user.password_confirmation) {
            user.id = authUser.id;
            userService.updatePassword(user)
                .then(response => {
                    setModified(true);
                    setTimeout(() => {
                        history.push('/my-profile');
                    },2000);
                    // history.push('/my-profile?password=changed');
                    // setModified(true);
                });
        }
    }
    const validationMessage = (
        <TemporaryPopup redirect="/my-profile" button="Mon profil">
            Votre mot de passe a bien été changé !
        </TemporaryPopup>
    )
    const validation = modified ? validationMessage : "";

    return (
        <div className="col-lg-8 offset-lg-2">
            {validation}
            <h2>Modify your password</h2>
            <form name="form" onSubmit={handleSubmit}>
                {/* <input type="hidden" value={user.id} name="id" /> */}
                <div className="form-group" style={{ margin: '10px' }}>
                    <label>Mot de passe</label>
                    <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !user.password ? ' is-invalid' : '')} />
                    {submitted && !user.password &&
                        <div className="invalid-feedback">Password is required</div>
                    }
                </div>
                <div className="form-group" style={{ margin: '10px' }}>
                    <label>Confirm your password </label>
                    <input type="password" name="password_confirmation" value={user.password_confirmation} onChange={handleChange} className={'form-control' + (submitted && !user.password_confirmation ? ' is-invalid' : '')} />
                    {submitted && !user.password_confirmation &&
                        <div className="invalid-feedback">Password is required</div>
                    }
                </div>
                <div className="form-group">
                    <Link to="/users">
                        <i className="icofont-close"></i>
                        <span>cancel</span>
                    </Link>
                    <button className="btn btn-primary" style={{marginLeft:".5rem"}}>
                        {modifying && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Modify
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ChangePasswordForm;
