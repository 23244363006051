// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Predictions_Predictions__1f1rK {\n  width: 100%;\n  margin: 20px;\n  padding: 0 40px 20px; }\n  .Predictions_Predictions__1f1rK .Predictions_Header__P_ELx {\n    display: flex; }\n    .Predictions_Predictions__1f1rK .Predictions_Header__P_ELx p {\n      flex: 1 1; }\n  .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9 {\n    display: flex;\n    border: 1px solid black;\n    border-bottom: none; }\n    .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9:nth-child(even) {\n      background-color: lightgrey; }\n    .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9:last-child {\n      border: 1px solid black; }\n    .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9 .Predictions_Prediction__property__2EYpn {\n      flex: 1 1;\n      padding: 5px 0; }\n    .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9 .Predictions_Prediction__recommendation__3kg-p {\n      font-weight: 700; }\n      .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9 .Predictions_Prediction__recommendation__3kg-p .Predictions_recommendYesGreen__1NkBP {\n        color: green; }\n      .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9 .Predictions_Prediction__recommendation__3kg-p .Predictions_recommendYesBlue__1FEpk {\n        color: blue; }\n      .Predictions_Predictions__1f1rK .Predictions_Prediction__Q3Ki9 .Predictions_Prediction__recommendation__3kg-p .Predictions_recommendNo__2Hf5M {\n        color: red; }\n", ""]);
// Exports
exports.locals = {
	"Predictions": "Predictions_Predictions__1f1rK",
	"Header": "Predictions_Header__P_ELx",
	"Prediction": "Predictions_Prediction__Q3Ki9",
	"Prediction__property": "Predictions_Prediction__property__2EYpn",
	"Prediction__recommendation": "Predictions_Prediction__recommendation__3kg-p",
	"recommendYesGreen": "Predictions_recommendYesGreen__1NkBP",
	"recommendYesBlue": "Predictions_recommendYesBlue__1FEpk",
	"recommendNo": "Predictions_recommendNo__2Hf5M"
};
module.exports = exports;
