import React,{ useEffect } from 'react';

import { history } from '../_helpers/history';

import AppUsers from '../_components/Homepage/AppUsers';
import ControlBatteries from '../_components/Homepage/ControlBatteries';
import HowItWorks from '../_components/Homepage/HowItWorks';
import Features from '../_components/Homepage/Features';
import Needs from '../_components/Homepage/Needs';
import SmartHome from '../_components/Homepage/SmartHome';
import PoweredBy from '../_components/Homepage/PoweredBy';

import Aux from '../_hoc/Auxil/Auxil';

import '../_assets/css/bootstrap.min.css';
import '../_assets/css/animate.css';
// import '../_assets/css/icofont.css';
// import '../_assets/css/owl.carousel.min.css';
import '../_assets/css/owl.theme.default.css';
import '../_assets/css/magnific-popup.css';
import '../_assets/css/style.css';
import '../_assets/css/responsive.css';
import '../_assets/css/color/color-default.css';

import classes from './HomePage.module.scss';

function NHomePage() {
    if (window.location.pathname === "/") {
        history.push('/home');
    }

    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <AppUsers />
            <ControlBatteries />
            <Features />
            <Needs />
            <SmartHome />
            <HowItWorks />
            <PoweredBy />
        </div>
    );
}

export default NHomePage;
