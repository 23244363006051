// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroApp_container__1hBQ6 {\n  max-width: 1500px !important; }\n  .IntroApp_container__1hBQ6 h2 {\n    color: #ff063c;\n    font-weight: 300;\n    font-size: 35px;\n    text-align: left; }\n  .IntroApp_container__1hBQ6 span {\n    color: #3d009e;\n    font-size: 20px; }\n  .IntroApp_container__1hBQ6 img {\n    float: left;\n    margin-right: 20px; }\n  .IntroApp_container__1hBQ6 h3 {\n    text-align: left; }\n  .IntroApp_container__1hBQ6 .IntroApp_donnees__1CTCw {\n    text-align: left;\n    line-height: 150%; }\n  .IntroApp_container__1hBQ6 .IntroApp_text-center__jnRHh p {\n    text-align: left;\n    font-size: 20px; }\n  .IntroApp_container__1hBQ6 .IntroApp_col-lg-8__HTKYl img {\n    width: 1000px;\n    float: left;\n    margin-top: 200px; }\n  .IntroApp_container__1hBQ6 .IntroApp_col-lg-4__23qw3 div {\n    margin-top: 50%; }\n\n.IntroApp_customUl__2-akw li {\n  display: flex !important;\n  align-items: center; }\n  .IntroApp_customUl__2-akw li:before {\n    content: \"\";\n    display: inline-block;\n    width: 15px;\n    height: 15px;\n    background-size: contain;\n    margin-right: 5px; }\n", ""]);
// Exports
exports.locals = {
	"container": "IntroApp_container__1hBQ6",
	"donnees": "IntroApp_donnees__1CTCw",
	"text-center": "IntroApp_text-center__jnRHh",
	"col-lg-8": "IntroApp_col-lg-8__HTKYl",
	"col-lg-4": "IntroApp_col-lg-4__23qw3",
	"customUl": "IntroApp_customUl__2-akw"
};
module.exports = exports;
