import React from 'react';

import classes from './NGenericLayout.module.scss';

import { history } from '../../_helpers/history';
import { Router,Route,Switch,Redirect } from 'react-router-dom';
import { PrivateRoute } from '../Routes/PrivateRoute';
import { RoleRoute } from '../Routes/RoleRoute';

import N401 from '../../_pages/N401';
import N404 from '../../_pages/N404';
import NAppPage from '../../_pages/NAppPage';
import NContactPage from '../../_pages/NContactPage';
import NCookiePage from '../../_pages/NCookiePage';
import NHomePage from '../../_pages/NHomePage';
import NLoginPage from '../../_pages/NLoginPage';
import NMonitoringPage from '../../_pages/NMonitoringPage';
import NPrivacyPolicyPage from '../../_pages/NPrivacyPolicyPage';
import NRegisterPage from '../../_pages/NRegisterPage';
import NTermsPage from '../../_pages/NTermsPage';
import NAdminLayout from './NAdminLayout';

import Navigation from './Navigation/Navigation';
import MainFooter from './Footer/MainFooter';


const NGenericLayout = (props) => {
    const user = localStorage.getItem("mainInfo") !== null ? JSON.parse(localStorage.getItem('mainInfo')) : null;
    const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
    // console.log(" generic page localStorage");
    // console.log(localStorage.getItem("mainInfo"));
    // console.log(" generic page user");
    // console.log(user);
    // console.log("generic page isLoggedIn");
    // console.log(isLoggedIn);
    return (
        <div className={`${classes.GenericLayout}`} data-spy="scroll" data-offset="70">
            <Navigation user={user} isLoggedIn={isLoggedIn} />
            <div className={`container-fluid ${classes.GenericLayoutContent}`}>
                <div className="row">
                    {/* <div className={"col-lg-12 m-0 p-0"}> */}
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/" component={NHomePage} />
                            <Route exact path="/home" component={NHomePage} />
                            <Route exact path="/le-concept" component={NMonitoringPage} />
                            {/* <Route exact path="/application" component={AppPage} /> */}
                            <Route exact path="/contact" component={NContactPage} />
                            <Route exact path="/politique-cookies" component={NCookiePage} />
                            <Route exact path="/terms-of-use" component={NTermsPage} />
                            <Route exact path="/privacy-policy" component={NPrivacyPolicyPage} />
                            <Route exact path="/404" component={N404} />
                            <Route exact path="/401" component={N401} />
                            <Route path="/login" component={NLoginPage} />
                            {/* <Route path="/register" component={NRegisterPage} /> */}
                            <Route
                                path='/register'
                                render={(props) => (
                                    <NRegisterPage {...props} user={user} />
                                )}
                            />

                            <PrivateRoute
                                path='/admin'
                                render={(props) => (
                                    <NAdminLayout {...props} user={user} />
                                )}
                            />
                            {/* <PrivateRoute path="/admin" component={NAdminLayout} /> */}
                            {/* <PrivateRoute path="/dashboard" component={NAdminLayout} /> */}
                            <PrivateRoute path="/monitoring" component={NAdminLayout} />

                            <Redirect from="*" to="/404" />
                        </Switch>
                    </Router>
                    {/* </div> */}
                </div>
            </div>
            <MainFooter />
        </div>
    );
};

export default NGenericLayout;