import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../_services/dataService';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

import AlarmCard from './Cards/AlarmCard';

import classes from './AdditionalData.module.scss';

const AdditionalData = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [pylontechs,setPylontechs] = useState([]);
    const [pylontechsAlarms,setPylontechsAlarms] = useState([]);
    const pylontechIds = props.arrPylontech;
    const [acrels,setAcrels] = useState([]);
    const acrelsId = props.arrAcrel;
    const [eastrons,setEastrons] = useState([]);
    const eastronsId = props.arrEastron;

    useEffect(() => {
        if (pylontechIds.length > 0) {
            dataService.lastBatteriesAllValues(pylontechIds)
                .then(response => {
                    if (response !== 'empty') {
                        setPylontechs(Object.values(response));
                    }
                });
            dataService.pylontechAlarms(pylontechIds)
                .then(response => {
                    if (response !== 'empty') {
                        setPylontechsAlarms(Object.values(response));
                    }
                });
        } else {
            setPylontechs([]);
            setPylontechsAlarms([]);
        }
        if (acrelsId.length > 0) {
            dataService.lastAcrelsAllValues(acrelsId)
                .then(response => {
                    if (response !== 'empty') {
                        setAcrels(Object.values(response));
                    }
                });
        } else {
            setAcrels([]);
        }
        if (eastronsId.length > 0) {
            dataService.lastEastronsAllValues(eastronsId)
                .then(response => {
                    if (response === 'empty') {
                        setEastrons([]);
                    } else {
                        setEastrons(Object.values(response));
                    }
                });
        } else {
            setEastrons([]);
        }
    },[pylontechIds,acrelsId,eastronsId]);

    if (pylontechsAlarms.length > 0) {
        console.log("pylontechsAlarms[0][alarms]");
        console.log(pylontechsAlarms[0]['alarms']);
    }

    return (
        <Aux>
            {/* <div className={`col-md-12 ${classes.AdditionalBatteriesData__list}`}> */}
            {pylontechsAlarms.length > 0 ? (
                pylontechsAlarms.map((pylontech,idx) =>
                    <div className={classes.alarmsWrapper} key={idx}>
                        <h4>{t('common:datas.battery.battery')} - {pylontech.mac_address}</h4>
                        <p className={classes.date}>{t('common:datas.last-record')}: {pylontech.date}</p>
                        <div className={`${classes.alarms}`}>
                            <AlarmCard name={t('common:datas.battery.prot_disc_oi')} date={pylontech.alarms.prot_disc_oi} />
                            <AlarmCard name={t('common:datas.battery.prot_chg_oi')} date={pylontech.alarms.prot_chg_oi} />
                            <AlarmCard name={t('common:datas.battery.prot_cell_ut')} date={pylontech.alarms.prot_cell_ut} />
                            <AlarmCard name={t('common:datas.battery.prot_cell_ot')} date={pylontech.alarms.prot_cell_ot} />
                            <AlarmCard name={t('common:datas.battery.prot_cell_uv')} date={pylontech.alarms.prot_cell_uv} />
                            <AlarmCard name={t('common:datas.battery.prot_cell_ov')} date={pylontech.alarms.prot_cell_ov} />
                            <AlarmCard name={t('common:datas.battery.prot_sys_err')} date={pylontech.alarms.prot_sys_err} />
                            <AlarmCard name={t('common:datas.battery.alar_chg_hi')} date={pylontech.alarms.alar_chg_hi} />
                            <AlarmCard name={t('common:datas.battery.alar_dischg_hi')} date={pylontech.alarms.alar_dischg_hi} />
                            <AlarmCard name={t('common:datas.battery.alar_cell_lt')} date={pylontech.alarms.alar_cell_lt} />
                            <AlarmCard name={t('common:datas.battery.alar_cell_ht')} date={pylontech.alarms.alar_cell_ht} />
                            <AlarmCard name={t('common:datas.battery.alar_cell_lv')} date={pylontech.alarms.alar_cell_lv} />
                            <AlarmCard name={t('common:datas.battery.alar_cell_hv')} date={pylontech.alarms.alar_cell_hv} />
                            <AlarmCard name={t('common:datas.battery.alar_comm_fail')} date={pylontech.alarms.alar_comm_fail} />
                            <AlarmCard name={t('common:datas.battery.req_charge_enable')} date={pylontech.alarms.req_charge_enable} />
                            <AlarmCard name={t('common:datas.battery.req_dischg_enable')} date={pylontech.alarms.req_dischg_enable} />
                            <AlarmCard name={t('common:datas.battery.req_forcechg_1')} date={pylontech.alarms.req_forcechg_1} />
                            <AlarmCard name={t('common:datas.battery.req_forcechg_2')} date={pylontech.alarms.req_forcechg_2} />
                        </div>
                    </div>
                )) : pylontechIds.length > 0 ? (
                    <Spinner />
                    // <p>{t('common:datas.no-data')}</p>
                ) : ""
            }
        </Aux>
    )
};

export default AdditionalData;