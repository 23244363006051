import React,{ useEffect,useState,useRef } from 'react';
import { Formik,Field,Form,ErrorMessage } from 'formik';
import * as Yup from 'yup';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { userActions } from '../../_actions/userActions';
import { userService } from '../../_services/userService';
import { protocolService } from '../../_services/protocolService';
import { moduleService } from '../../_services/moduleService';
import { countryService } from '../../_services/countryService';
import { addressService } from '../../_services/addressService';
import { history } from '../../_helpers/history';

import Auxil from '../../_hoc/Auxil/Auxil';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import btnRegister from '../../_assets/img/bouton-enregistrer-battery-up.png';
import classes from './RegisterModuleForm.module.scss';

const RegisterModuleForm = (props) => {
    const { t } = useTranslation(['common','registermodulepage']);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const [clients,setClients] = useState([]);
    const [protocols,setProtocols] = useState([]);
    const [countries,setCountries] = useState([]);
    const [userIsClient,setUserIsClient] = useState(true);
    const queryString = require('query-string');
    const idSelect = props.idSelect;
    const [targetId,setTargetId] = useState(0);
    const [target,setTarget] = useState(false);
    const [targetHasAddress,setTargetHasAddress] = useState(false);
    const [showUserSelect,setShowUserSelect] = useState(false);
    const [selectedAddress,setSelectedAddress] = useState([]);
    const [showAddressForm,setShowAddressForm] = useState(false);
    const formRef = useRef();
    const selectedDevice = props.selectedDevice;
    const [selectedDeviceAddress,setSelectedDeviceAddress] = useState(0);
    const isEditMode = props.selectedDevice && props.selectedDevice !== 0 ? true : false;
    const [initForm,setInitForm] = useState({
        user: targetId,
        module_name: '',
        macaddress: '',
        protocol: '',
        address_id: '',
        installation_name: '',
        line1: '',
        line2: '',
        street_number: '',
        zipcode: '',
        locality: '',
        country_id: '',
        existing_id: 0
        // user_id: targetId
    });
    // console.log("selectedDevice");
    // console.log(selectedDevice);


    useEffect(() => {
        if (!user.roles.includes('installer') && !user.roles.includes('super-admin') && !user.roles.includes('admin') && user.roles.includes('client')) {
            setUserIsClient(true);
        }
        if (user.roles.includes('installer') && !user.roles.includes('super-admin') && !user.roles.includes('admin')) {
            console.log("c1 true");
            userService.getClients()
                .then(response => {
                    console.log('response getClients');
                    setClients(response);
                    setUserIsClient(false);
                    setShowUserSelect(true);
                });
        }
        if (user.roles.includes('super-admin') || user.roles.includes('admin')) {
            console.log("c2 true");
            userService.getAll()
                .then(response => {
                    console.log('response getAll');
                    setClients(response);
                    setUserIsClient(false);
                    setShowUserSelect(true);
                });
            // dispatch(userActions.getModules());
        }
        protocolService.getAll()
            .then(response => {
                console.log('response getAll');
                setProtocols(response);
            });
        countryService.getAll()
            .then(response => {
                console.log('response getAll');
                setCountries(response);
            });
        if (userIsClient) {
            setShowUserSelect(false);
            setTargetId(user.id)
            userService.getById(user.id)
                .then(response => {
                    setTarget(response);
                    if (response.addresses.length > 0) {
                        setTargetHasAddress(true);
                        setShowAddressForm(false);
                    } else {
                        setTargetHasAddress(false);
                        setShowAddressForm(true);
                    }
                });
        } else {
            setShowUserSelect(true);
        }
        if (selectedDevice) {
            console.log("fill form");
            moduleService.getById(selectedDevice)
                .then(response => {
                    console.log("response address_id get module by id");
                    console.log(typeof response.address_id);
                    console.log(response.address_id);
                    console.log("fill form data retrieved");
                    setTimeout(() => {
                        console.log("target in timeout");
                        console.log(target);
                        setInitForm({
                            user: targetId,
                            module_name: response.module_name,
                            macaddress: response.mac_address,
                            protocol: response.protocol_id,
                            address_id: response.address_id,
                            // installation_name: '',
                            // line1: '',
                            // line2: '',
                            // street_number: '',
                            // zipcode: '',
                            // locality: '',
                            // country_id: '',
                            // existing_id: 0
                            installation_name: response.address.installation_name,
                            line1: response.address.line1,
                            line2: response.address.line2,
                            street_number: response.address.street_number,
                            zipcode: response.address.zipcode,
                            locality: response.address.locality,
                            country_id: response.address.country_id,
                            existing_id: response.address_id
                        });
                        setSelectedDeviceAddress(response.address_id);
                    },4000);
                });
        }
    },[props.selectedDevice]);
    // console.log('test typeof user id');
    // console.log(typeof user.id);
    // console.log(user.id);

    function userSelectChange(e) {
        const { name,value } = e.target;
        console.log("value in user select");
        console.log(value);
        setTargetId(value);
        userService.getById(value)
            .then(response => {
                setTarget(response);
                if (response.addresses.length > 0) {
                    setTargetHasAddress(true);
                } else {
                    setTargetHasAddress(false);
                }
            });
    }

    function addressSelectChange(e) {
        // reinitialize selectedDeviceAddress to 0
        setSelectedDeviceAddress(0);
        // console.log("formRef select change");
        // console.log(formRef.current.values);
        // console.log("select change target");
        // console.log(e.target.value);
        const module_name = formRef.current.values.module_name;
        const macaddress = formRef.current.values.macaddress;
        const protocol = formRef.current.values.protocol;
        const { name,value } = e.target;
        console.log("value change target");
        console.log(value);
        setSelectedAddress(value);
        let address = target.addresses.findIndex(item => item.id === parseInt(value));
        console.log("address index select change");
        console.log(address);
        console.log("full address select change");
        console.log(target.addresses[address]);
        console.log("macaddress select change");
        console.log(macaddress);
        console.log("protocol select change");
        console.log(protocol);
        if (parseInt(value) === 99999 || parseInt(value) === 0) {
            setInitForm({
                user: targetId,
                module_name: module_name,
                macaddress: macaddress,
                protocol: protocol,
                address_id: '',
                installation_name: '',
                line1: '',
                line2: '',
                street_number: '',
                zipcode: '',
                locality: '',
                country_id: '',
                existing_id: 0
            });
            if (parseInt(value) === 99999) {
                setShowAddressForm(true);
            } else {
                setShowAddressForm(false);
            }
        } else {
            setInitForm({
                user: targetId,
                module_name: module_name,
                macaddress: macaddress,
                protocol: protocol,
                address_id: '',
                installation_name: target.addresses[address].installation_name,
                line1: target.addresses[address].line1,
                line2: target.addresses[address].line2,
                street_number: target.addresses[address].street_number,
                zipcode: target.addresses[address].zipcode,
                locality: target.addresses[address].locality,
                country_id: target.addresses[address].country.id,
                existing_id: value
            });
            setShowAddressForm(false);
        }
        // console.log(initForm);
        // console.log("initForm after address change");
    }

    const userSelect = (
        <div className="form-group" style={{ margin: '10px' }}>
            <label htmlFor="user">User</label>
            <select name="userSelect" onChange={userSelectChange} value={targetId}>
                <option value="0">-</option>
                {clients.map((client,idx) => {
                    return (<option
                        selected
                        value={client.id}
                        key={idx}
                    >
                        {client.firstname} {client.surname}
                    </option>)
                })}
            </select>
        </div>
    )

    const test = () => {
        if (typeof formRef.current !== 'undefined') {
            setInitForm({
                user: targetId,
                macaddress: formRef.protocol,
                protocol: formRef.protocol,
                address_id: formRef.address_id,
                installation_name: formRef.installation_name,
                line1: formRef.line1,
                line2: formRef.line2,
                street_number: formRef.street_number,
                zipcode: formRef.zipcode,
                locality: formRef.locality,
                country_id: formRef.country_id,
                existing_id: formRef.existing_id
            });
        }
    }

    const addressSelect = targetHasAddress ? (
        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
            {/* <p>{initForm.address_id ? initForm.address_id : "no"}</p> */}
            <label htmlFor="user">{t('registermodulepage:RegisterForm.address-label')}</label><br />
            <select name="addressSelect" className="form-control" onChange={addressSelectChange} value={selectedDeviceAddress !== 0 ? selectedDeviceAddress : selectedAddress}>
                <option value="0">-</option>
                {/* {selectedDeviceAddress === 0 ? <option value="0">-</option> : ""} */}
                <option value="99999">{t('registermodulepage:RegisterForm.address-add-new')}</option>
                {target.addresses.map((address,idx) => {
                    return (<option
                        // selected
                        selected={selectedDeviceAddress !== 0 ? true : false}
                        value={address.id}
                        key={idx}
                    >
                        {address.line1}, {address.street_number}, {address.zipcode} {address.locality}, {address.country.name_en}
                    </option>)
                })}
            </select>
        </div>
    ) : "";

    return (
        <section id="registerModuleForm" className="app-about-section angle-sp" style={{ marginTop: "0",paddingTop: '0',width: '100%' }}>
            <div className="container">
                {/* <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <img src={require("../../_assets/img/IMG-vectoriel-site-battery-UP-register.png")} alt="App About Image" style={{width: '600px',float: "center"}} />
                    </div>
                </div> */}
                <div className={`row ${classes.formBackground}`}>
                    <div className={`col-12 ${classes.formWrapper}`}>
                        <h2>{t('registermodulepage:RegisterForm.title')}</h2>
                        {showUserSelect ? userSelect : ""}

                        <Formik
                            enableReinitialize
                            innerRef={formRef}
                            initialValues={initForm}
                            validationSchema={Yup.object({
                                user: Yup.number()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                module_name: Yup.string()
                                    .trim()
                                    .matches(/^[a-zA-Z0-9À-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registermodulepage:RegisterForm.yup-modulename-match')}</p> })
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                macaddress: Yup.string()
                                    .trim()
                                    .min(17,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-macaddress-min')}</p>)
                                    .max(20,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-macaddress-max')}</p>)
                                    // .length(20,<p className="errorMessage">Ce champ doit contenir 20 caractères</p>)
                                    .matches(/^[A-Za-z0-9:-]+$/,{ message: <p className="errorMessage">{t('registermodulepage:RegisterForm.yup-macaddress-match')}</p> })
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                protocol: Yup.number()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                installation_name: Yup.string()
                                    .trim()
                                    .matches(/^[A-Za-z0-9À-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registermodulepage:RegisterForm.yup-installationname-match')}</p> })
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                line1: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                line2: Yup.string()
                                    .trim()
                                    .nullable(),
                                street_number: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                zipcode: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                locality: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                country_id: Yup.number()
                                    .min(1,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-must-select-country')}</p>)
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                existing_id: Yup.number(),
                            })}
                            onSubmit={(values,{ setSubmitting }) => {
                                setTimeout(() => {
                                    console.log('test is submitted');
                                    let countryIndex = countries.findIndex(item => item.id === parseInt(values.country_id));
                                    let country = countries[countryIndex].name_en;
                                    values.country = country;
                                    addressService.getLatLng(values)
                                        .then(response => {
                                            console.log(response);
                                            values.latitude = response.lat;
                                            values.longitude = response.lng;
                                            values.weather_zone = response.weather_zone;
                                        })
                                        .then(() => {
                                            /* PART TO DELETE AFTER API Geocoding*/
                                            // values.latitude = 0.00;
                                            // values.longitude = 0.00;
                                            /* END PART TO DELETE AFTER API Geocoding*/
                                            /* PART TO ADD AFTER API Geocoding*/
                                            // alert(JSON.stringify(values,null,2));
                                            console.log("before dispatch");
                                            console.log(JSON.stringify(values,null,2));
                                            let countryIndex = countries.findIndex(item => item.id === parseInt(values.country_id));
                                            let country = countries[countryIndex].name_en;
                                            values.country = country;
                                            /* END PART TO ADD AFTER API Geocoding*/
                                            if (values.user === 0) {
                                                values.user = user.id;
                                            }
                                            if (isEditMode) {
                                                moduleService.editModule(values,selectedDevice)
                                                    .then(response => {
                                                        console.log("response editModule");
                                                        console.log(response);
                                                        //     console.log("values.protocol after registration before config update");
                                                        //     console.log(typeof values.protocol);
                                                        //     console.log(values.protocol);
                                                        //     console.log("values.macaddress after registration before config update");
                                                        //     console.log(values.macaddress);
                                                        moduleService.updateDeviceConfig(values.macaddress,values.protocol)
                                                            .then(() => {
                                                                console.log("config update after module update");
                                                            });
                                                    })
                                                    .then(() => {
                                                        if (!user.roles.includes('super-admin') && !user.roles.includes('admin') && !user.roles.includes('installer')) {
                                                            history.push('/my-profile');
                                                        } else {
                                                            history.push('/users');
                                                        }
                                                    });
                                            } else {
                                                moduleService.registerModule(values)
                                                    .then(() => {
                                                        console.log("values.protocol after registration before config update");
                                                        console.log(typeof values.protocol);
                                                        console.log(values.protocol);
                                                        console.log("values.macaddress after registration before config update");
                                                        console.log(values.macaddress);
                                                        moduleService.updateDeviceConfig(values.macaddress,values.protocol)
                                                            .then(() => {
                                                                console.log("config update after module registration");
                                                            });
                                                    })
                                                    .then(() => {
                                                        if (!user.roles.includes('super-admin') && !user.roles.includes('admin') && !user.roles.includes('installer')) {
                                                            history.push('/my-profile');
                                                        } else {
                                                            history.push('/users');
                                                        }
                                                    });
                                            }
                                        });
                                    // dispatch(userActions.register(JSON.stringify(values, null, 2)));
                                    // dispatch(userActions.register(values));
                                    // setSubmitting(false);
                                },400);
                            }}
                        >
                            <Form style={{ textAlign: 'left' }}>
                                <div className="form-group" style={{ margin: '30px 10px 20px' }}>
                                    <label htmlFor="module_name">{t('registermodulepage:RegisterForm.modulename-label')}</label>
                                    <Field name="module_name" type="text" className="form-control" placeholder={t('registermodulepage:RegisterForm.modulename-placeholder')} />
                                    <ErrorMessage name="module_name" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="macaddress">{t('registermodulepage:RegisterForm.macaddress-label')}</label>
                                    <Field name="macaddress" type="text" className="form-control" />
                                    <ErrorMessage name="macaddress" />
                                    <p className={classes.fieldHelp}>
                                        <Trans i18nKey="registermodulepage:RegisterForm.macaddress-help">
                                            Text<br />Text
                                        </Trans>
                                    </p>
                                    {/* <p className={classes.fieldHelp}>{t('registermodulepage:RegisterForm.macaddress-help')}</p> */}
                                    <p className={`${classes.fieldHelp} ${classes.fieldExample}`}>{t('registermodulepage:RegisterForm.macaddress-example')}</p>
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="protocol">{t('registermodulepage:RegisterForm.protocol-label')}</label><br />
                                    <Field as="select" name="protocol" className="form-control">
                                        <option value="0">-</option>
                                        {protocols.map((protocol,idx) => {
                                            return (<option
                                                selected
                                                value={protocol.id}
                                                key={idx}
                                            >
                                                {protocol.product} - {protocol.brand}{protocol.id === 4 || protocol.id === 4 ? " - Master" : ""}
                                            </option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="protocol" />
                                </div>
                                {addressSelect}
                                {showAddressForm ? (
                                    <Auxil>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="installation_name">{t('registermodulepage:RegisterForm.installationname-label')}</label>
                                            <Field name="installation_name" type="text" className="form-control" placeholder={t('registermodulepage:RegisterForm.installationname-placeholder')} />
                                            <ErrorMessage name="installation_name" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="line1">{t('registermodulepage:RegisterForm.address-line1-label')}</label>
                                            <Field name="line1" type="text" className="form-control" />
                                            <ErrorMessage name="line1" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="line2">{t('registermodulepage:RegisterForm.address-line2-label')}</label>
                                            <Field name="line2" type="text" className="form-control" />
                                            <ErrorMessage name="line2" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="street_number">{t('registermodulepage:RegisterForm.address-number-label')}</label>
                                            <Field name="street_number" type="street_number" className="form-control" />
                                            <ErrorMessage name="street_number" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="zipcode">{t('registermodulepage:RegisterForm.address-zipcode-label')}</label>
                                            <Field name="zipcode" type="text" className="form-control" />
                                            <ErrorMessage name="zipcode" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="locality">{t('registermodulepage:RegisterForm.address-city-label')}</label>
                                            <Field name="locality" type="text" className="form-control" />
                                            <ErrorMessage name="locality" />
                                        </div>
                                        <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                            <label htmlFor="country_id">{t('registermodulepage:RegisterForm.address-country-label')}</label>
                                            <Field as="select" name="country_id" className="form-control">
                                                <option value="0">-</option>
                                                {countries.map((country,idx) => {
                                                    return (<option
                                                        selected
                                                        value={parseInt(country.id)}
                                                        key={idx}
                                                    >
                                                        {country.name_en}
                                                    </option>
                                                    );
                                                })}
                                            </Field>
                                            <ErrorMessage name="country_id" />
                                        </div>
                                    </Auxil>
                                ) : (
                                    <Auxil>
                                        <Field type="hidden" name="installation_name" />
                                        <Field type="hidden" name="line1" />
                                        <Field type="hidden" name="line2" />
                                        <Field type="hidden" name="street_number" />
                                        <Field type="hidden" name="zipcode" />
                                        <Field type="hidden" name="locality" />
                                        <Field type="hidden" name="country_id" />
                                    </Auxil>
                                )}
                                {/* <p>Already registered ? <Link to="/login" className="btn btn-link">Login</Link></p> */}
                                <button className="btn btn-primary" type="submit" style={{ background: `url(${btnRegister}) center center no-repeat`,backgroundSize: 'contain',width: "180px",height: '70px',border: 'none',padding: '0',margin: '0 auto',display: 'block' }}></button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegisterModuleForm;
