import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './AlarmCard.module.scss';

// const AlarmCard = (props) => {
const AlarmCard = React.memo(props => {
    const { t } = useTranslation(['common','dashboard']);
    const icons = {
        check: require('../../../_assets/img/icon_checked.png'),
        alert: require('../../../_assets/img/icon_alert.png'),
        battery: require('../../../_assets/img/icons/data/battery/alarm_bg.png'),
        energyMeter: require('../../../_assets/img/icons/data/energy_meter/alarm_bg.png'),
    };
    const imagesAlt = {
        power: t('common:datas.icons.power'),
        current: t('common:datas.icons.current'),
        production: t('common:datas.icons.production'),
        consumption: t('common:datas.icons.consumption'),
        soc: t('common:datas.icons.soc'),
        alert: t('common:datas.icons.alert'),
        clock: t('common:datas.icons.clock'),
    };
    const date = props.date;
    const type = props.type;

    // console.log("props.date");
    // console.log(props.date);

    return (
        date ? (
            <div className={classes.AlarmCard}>
                <img className={classes.icon} src={type === "battery" ? icons['battery'] : icons['energyMeter']} alt={imagesAlt['alert']} />
                <p className={classes.content}>{props.name}</p>
                <p className={type === "battery" ? `${classes.date} ${classes.battery}` : `${classes.date} ${classes.energyMeter}`}>{date}</p>
            </div>
        ) : ""
    )
    // };
});

export default AlarmCard;