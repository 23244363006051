// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeviceControl_DeviceControl__1oYZU {\n  padding: 30px;\n  background-color: red; }\n", ""]);
// Exports
exports.locals = {
	"DeviceControl": "DeviceControl_DeviceControl__1oYZU"
};
module.exports = exports;
