import React,{ useState,useEffect,Component } from 'react';

import classes from './WeatherForecast.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dataService } from '../../../_services/dataService';

const WeatherForecast = (props) => {
    const sunriseFull = new Date(props.data[0].sys.sunrise * 1000);
    const sunsetFull = new Date(props.data[0].sys.sunset * 1000);
    const sunrise = sunriseFull.getHours() + ":" + sunriseFull.getMinutes();
    const sunset = sunsetFull.getHours() + ":" + sunsetFull.getMinutes();
    // const [currentUv,setCurrentUv] = useState([]);
    // const [uvForecast,setUvForecast] = useState([]);

    // useEffect(() => {
    //     dataService.uvValue()
    //         .then(response => {
    //             setCurrentUv(response.data.value);
    //         });
    // },[]);
    // useEffect(() => {
    //     dataService.uvForecast()
    //         .then(response => {
    //             setUvForecast(response.data);
    //         });
    // },[]);

    console.log('in forecast');
    // let test = props.data[0].data;
    // console.log(JSON.stringify(props.data));
    // console.log(props.data);
    console.log(props.data[0]);
    return (
        <div className={classes.WeatherForecast}>
            <h4>{props.data[0].name}</h4>
            <p>Today</p>
            <p><img src={`http://openweathermap.org/img/wn/${props.data[0].weather[0].icon}@2x.png`} alt="" />{props.data[0].main.temp}°</p>
            {/* <p>Icon : {props.data[0].weather[0].icon}</p> */}
            <div className={classes.current}>
                <h5>Current UV</h5>
                <p>{props.data[1]}</p>
                <h5>Sunrise</h5>
                <p>{sunrise}</p>
                <h5>Sunset</h5>
                <p>{sunset}</p>
            </div>
            <div className={classes.min}>
                <h5>Min</h5>
                <p><FontAwesomeIcon icon="thermometer-empty" />{props.data[0].main.temp_min}</p>
            </div>
            <div className={classes.max}>
                <h5>Max</h5>
                <p><FontAwesomeIcon icon="thermometer-full" />{props.data[0].main.temp_max}</p>
            </div>
        </div>
    )
};
/*
class WeatherForecast extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weather: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        console.log("this.props");
        console.log(this.props);
        this.setState({
            data: this.props.data[0]
        })
    }

    render() {
        if (!this.state.weather) {
            return <div />
        }
        return (
            <div className={classes.WeatherForecast}>
                <h4>{this.props.data[0].name}</h4>
                <p>Today</p>
                <div className={classes.current}>
                    <h5>Current UV</h5>
                    <p>{this.props.data[1]}</p>
                </div>
                <div className={classes.min}>
                    <h5>Min</h5>
                    <p><FontAwesomeIcon icon="thermometer-empty" /></p>
                </div>
                <div className={classes.max}>
                    <h5>Max</h5>
                    <p><FontAwesomeIcon icon="thermometer-full" /></p>
                </div>
            </div>
        )
    }
}
*/
export default WeatherForecast;