import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './Needs.module.scss';
import fondImgTexte2 from '../../_assets/img/fond-img-creez-scenarios-besoins.png';

class Needs extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="needs" className="app-about-section angle-sp">
                <div className={`container ${classes.container}`}>

                    <div className="row">
                        <div className={`col-md-7 ${classes.iftttWrapper}`}>
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className={`app-about-text ${classes.ifttt}`}>
                                    <h2 className={classes.sectionTitle}>{t('homepage:Needs.create-scenarios')}</h2>
                                    <p>
                                        <Trans i18nKey="homepage:Needs.ifttt-concept">
                                            <span style={{ color: "#3d009e",fontWeight: "700" }}>IFTTT</span> va permettre le déclenchement d’une action si une condition est remplie. On peut, par exemple, démarrer une machine à laver si le pourcentage batterie dépasse 90%. Ou encore, démarrer votre chauffe eau si les prévisions d’ensoleillement pour la journée sont bonnes.
                                        </Trans>
                                    </p>
                                    <p>{t('homepage:Needs.infinity-scenarios')}</p>
                                    <img src={require("../../_assets/img/IMG-battery-UP-vectorisation-scenario.jpg")} alt={t('homepage:Needs.img-ifttt-scenarios-alt')} style={{ width: "600px",position: "center" }} />
                                </div>
                            </ReactWOW>
                        </div>

                        <div className={`col-md-5 ${classes.imgRightColoumn}`}>
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className="text-center">
                                    <img src={require("../../_assets/img/IMG5-battery-up-application-smartphone.png")} alt={t('homepage:Needs.img-ifttt-access-alt')} />
                                </div>
                            </ReactWOW>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','homepage'])(Needs);