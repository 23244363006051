// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PylontechData_dataWrapper__2AR4V {\n  margin: 15px 0; }\n  .PylontechData_dataWrapper__2AR4V .PylontechData_title__1nk_A {\n    text-align: left;\n    font-size: 20px;\n    color: royalblue; }\n  .PylontechData_dataWrapper__2AR4V .PylontechData_data__1KBl_ {\n    display: flex;\n    flex-wrap: wrap; }\n", ""]);
// Exports
exports.locals = {
	"dataWrapper": "PylontechData_dataWrapper__2AR4V",
	"title": "PylontechData_title__1nk_A",
	"data": "PylontechData_data__1KBl_"
};
module.exports = exports;
