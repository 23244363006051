import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
// import classes from './MainDataChart.module.scss';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';
import { dataService } from '../../../_services/dataService';

am4core.useTheme(am4themes_animated);

const ThirdNewChart = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    console.log("props all devices chart");
    console.log(props);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        var chart = am4core.create('chartdivthird',am4charts.XYChart)

        // Create axes
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "month";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.extraMin = 0.1;
        valueAxis.extraMax = 0.1;
        valueAxis.renderer.minGridDistance = 40;
        valueAxis.renderer.ticks.template.length = 5;
        valueAxis.renderer.ticks.template.disabled = false;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
        // valueAxis.renderer.labels.template.adapter.add("text",function (text) {
        //     return text == t('common:charts.imported-energy') || text == t('common:charts.exported-energy') ? text : text + " (in W)";
        // })

        // Create series
        var male = chart.series.push(new am4charts.ColumnSeries());
        male.dataFields.valueX = "abs";
        male.dataFields.categoryY = "month";
        male.clustered = false;

        var maleLabel = male.bullets.push(new am4charts.LabelBullet());
        maleLabel.label.text = "{valueX} W";
        maleLabel.label.hideOversized = false;
        maleLabel.label.truncate = false;
        maleLabel.label.horizontalCenter = "right";
        maleLabel.label.dx = -10;

        var female = chart.series.push(new am4charts.ColumnSeries());
        female.dataFields.valueX = "rel";
        female.dataFields.categoryY = "month";
        female.clustered = false;

        var femaleLabel = female.bullets.push(new am4charts.LabelBullet());
        femaleLabel.label.text = "{valueX} W";
        femaleLabel.label.hideOversized = false;
        femaleLabel.label.truncate = false;
        femaleLabel.label.horizontalCenter = "left";
        femaleLabel.label.dx = 10;

        var maleRange = valueAxis.axisRanges.create();
        maleRange.value = -100000;
        maleRange.endValue = 0;
        maleRange.label.text = t('common:charts.imported-energy');
        maleRange.label.fill = chart.colors.list[0];
        maleRange.label.dy = 20;
        maleRange.label.fontWeight = '600';
        maleRange.grid.strokeOpacity = 1;
        maleRange.grid.stroke = male.stroke;

        var femaleRange = valueAxis.axisRanges.create();
        femaleRange.value = 0;
        femaleRange.endValue = 100000;
        femaleRange.label.text = t('common:charts.exported-energy');
        femaleRange.label.fill = chart.colors.list[1];
        femaleRange.label.dy = 20;
        femaleRange.label.fontWeight = '600';
        femaleRange.grid.strokeOpacity = 1;
        femaleRange.grid.stroke = female.stroke;

        const test = props.data;
        chart.data = test;
        // chart.data = [
        //     {
        //         category: 'January',
        //         abs: -50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'February',
        //         abs: -272760,
        //         rel: 232080,
        //     },
        //     {
        //         category: 'March',
        //         abs: -160920,
        //         rel: 43200,
        //     },
        //     {
        //         category: 'April',
        //         abs: -141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: 'May',
        //         abs: -126600,
        //         rel: 143280,
        //     },
        //     {
        //         category: 'June',
        //         abs: -104880,
        //         rel: 73800,
        //     },
        //     {
        //         category: 'July',
        //         abs: -50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'August',
        //         abs: -50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'September',
        //         abs: -50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'October',
        //         abs: -50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'November',
        //         abs: -50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'December',
        //         abs: -50000,
        //         rel: 50000,
        //     }
        // ]

    },[props.data]);
    // }

    return (
        <div id="chartdivthird" style={{ width: "calc(100% - 40px)",height: "600px" }}></div>
    );
}

export default ThirdNewChart;