// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.Dashboard_Dashboard__35Ek2 {\n  display: flex;\n  flex-direction: column; }\n  .Dashboard_Dashboard__35Ek2 .Dashboard_secondaryData__2XUZf {\n    display: flex;\n    width: 100%; }\n", ""]);
// Exports
exports.locals = {
	"Dashboard": "Dashboard_Dashboard__35Ek2",
	"secondaryData": "Dashboard_secondaryData__2XUZf"
};
module.exports = exports;
