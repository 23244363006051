// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AlertForm_AlertForm__2V9Ze {\n  flex: 1 1; }\n  .AlertForm_AlertForm__2V9Ze form {\n    display: flex;\n    align-items: center;\n    padding-bottom: 10px; }\n    .AlertForm_AlertForm__2V9Ze form div, .AlertForm_AlertForm__2V9Ze form button {\n      flex: 1 1; }\n    .AlertForm_AlertForm__2V9Ze form input[type=\"checkbox\"] {\n      margin: 0 10px; }\n", ""]);
// Exports
exports.locals = {
	"AlertForm": "AlertForm_AlertForm__2V9Ze"
};
module.exports = exports;
