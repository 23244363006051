// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HomePage_HomePage__LY2jj .HomePage_container__3lv7h {\n  max-width: 1500px !important; }\n", ""]);
// Exports
exports.locals = {
	"HomePage": "HomePage_HomePage__LY2jj",
	"container": "HomePage_container__3lv7h"
};
module.exports = exports;
