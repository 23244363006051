import React,{ useEffect } from 'react';

import ContactUs from '../_components/ContactPage/ContactUs';
import ContactForm from '../_components/Form/ContactForm';
import WhereAreWe from '../_components/ContactPage/WhereAreWe';

import Aux from '../_hoc/Auxil/Auxil';

// import classes from './AppPage.module.scss';

const ContactPage = (props) => {
    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <ContactUs />
            {/* <ContactForm /> */}
            <WhereAreWe />
        </div>
    );
}

export default ContactPage;