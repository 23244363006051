import React from 'react';
import { NavLink as Link,useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Trans,useTranslation } from 'react-i18next';

import classes from './Sidebar.module.scss';

const Sidebar = (props) => {
    const { t } = useTranslation(['common']);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const { pathname } = useLocation();
    // if (user.roles.includes("admin") || user.roles.includes("super-admin") || user.roles.includes("installer")) { console.log('isAdmin sidebar'); }
    return (
        <nav className={classes.Sidebar}>
            <ul>
                <li >
                    <Link to="/dashboard" activeClassName={classes.active}    >
                        <FontAwesomeIcon icon="tachometer-alt" />
                        <span>{t('common:nav.dashboard')}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/my-profile" activeClassName={classes.active} isActive={() => ['/','/my-profile'].includes(pathname)}>
                        <FontAwesomeIcon icon="user" />
                        <span>{t('common:nav.profile')}</span>
                    </Link>
                </li>
                <li>
                    {/* <Link to="/my-alerts" activeClassName={classes.active} isActive={() => ['/','/my-alerts'].includes(pathname)}> */}
                    <Link to="/triggers" activeClassName={classes.active} isActive={() => ['/','/triggers'].includes(pathname)}>
                        <FontAwesomeIcon icon="volume-up" />
                        <span>{t('common:nav.alerts')}</span>
                    </Link>
                </li>
                {/* {JSON.parse(localStorage.getItem('appState')).isLoggedIn==true?( ):"" */}
                {(user.roles.includes("admin") || user.roles.includes("super-admin") || user.roles.includes("installer")) &&
                    <li>
                        <Link to="/users" activeClassName={classes.active}>
                            <FontAwesomeIcon icon="users" />
                            <span>{t('common:nav.users')}</span>
                        </Link>
                    </li>}
                {/*JSON.parse(localStorage.getItem('appState')).isLoggedIn==true?(JSON.parse(localStorage.getItem('appState')).user.roles.includes("admin") ||
                JSON.parse(localStorage.getItem('appState')).user.roles.includes("super-admin")
                || JSON.parse(localStorage.getItem('appState')).user.roles.includes("installer")):""
            &&<li>
                    <Link to="/"  activeClassName={classes.active}>
                        <FontAwesomeIcon icon="cogs" />
                        <span>Settings</span>
                    </Link>
                </li>*/}
                {(user.roles.includes("admin") || user.roles.includes("super-admin")) &&
                    <li>
                        <Link to="/release-notes-all" activeClassName={classes.active} isActive={() => ['/','/release-notes-all'].includes(pathname)}>
                            <FontAwesomeIcon icon="scroll" />
                            <span>{t('common:nav.release-notes')}</span>
                        </Link>
                    </li>}
                {(user.roles.includes("admin") || user.roles.includes("super-admin")) &&
                    <li>
                        <Link to="/rokvmwsevkmmoejvwse" activeClassName={classes.active} isActive={() => ['/','/rokvmwsevkmmoejvwse'].includes(pathname)}>
                            <FontAwesomeIcon icon="tachometer-alt" />
                            <span>DEV</span>
                        </Link>
                    </li>}
            </ul>
        </nav>
    )
};

export default Sidebar;
