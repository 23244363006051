import React from 'react';
import { Route,Redirect } from 'react-router-dom';

if (localStorage.getItem("appState") === null) {
    let appState = {
        isLoggedIn: false,
        user: {}
    }
    localStorage.setItem("appState",JSON.stringify(appState))
}
// console.log("PR");
// console.log(JSON.parse(localStorage.getItem('appState')));
// console.log(typeof localStorage.getItem('appState'));
// console.log(localStorage.getItem('appState'));
// console.log(JSON.parse(localStorage.getItem('appState')));
export const PrivateRoute = ({ component: Component,...rest }) => (
    <Route {...rest} render={props => (
        // localStorage.getItem('user')
        JSON.parse(localStorage.getItem('appState')).user.auth_token
            // localStorage.getItem('appState')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login',state: { from: props.location } }} />
        // : <Redirect to={{ pathname: 'https://portaldev.wattuneed.com/login?origin=batteryup' }} />
    )} />
)