import React from 'react';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import ReactWOW from 'react-wow';

import classes from './ReleaseNoteItem.module.scss';

const ReleaseNoteItem = (props) => {
    const { t } = useTranslation(['common','releasenotespage']);
    // console.log("props.releaseNote in RN item");
    // console.log(props.releaseNote);
    const done_fr = props.releaseNote.done_fr.split("\n");
    const done_en = props.releaseNote.done_en.split("\n");
    const to_do_fr = props.releaseNote.to_do_fr.split("\n");
    const to_do_en = props.releaseNote.to_do_en.split("\n");
    // console.log("test line break li in RN item");
    // console.log(done_fr);

    return (
        <ReactWOW delay='0.3s' animation='fadeInUp'>
            <div className={classes.ReleaseNoteItem}>
                <div className={classes.ReleaseNoteHeader}>
                    <div className={classes.date}>
                        <p className={classes.dateDay}>{props.releaseNote.dateDay}/{props.releaseNote.dateMonth}</p>
                        <p className={classes.dateYear}>{props.releaseNote.dateYear}</p>
                    </div>
                    {i18n.language === "fr" ? (
                        <h3 className={classes.ReleaseNoteTitle}>{props.releaseNote.title_fr}</h3>
                    ) : (
                        <h3 className={classes.ReleaseNoteTitle}>{props.releaseNote.title_en}</h3>
                    )}
                </div>
                <div className={classes.ReleaseNoteContent}>
                    <h4>{t('releasenotespage:ReleaseNoteItem.done-title')}</h4>
                    <ul>
                        {i18n.language === "fr" ? (
                            done_fr.map((doneFrItem,idx) => {
                                return (
                                    <li key={idx} ><span>{doneFrItem}</span></li>
                                )
                            })
                        ) : (
                            done_en.map((doneEnItem,idx) => {
                                return (
                                    <li key={idx} ><span>{doneEnItem}</span></li>
                                )
                            })
                        )}
                        {/* <li><span>{t('releasenotespage:ReleaseNoteItem.example-done1')}</span></li>
                        <li><span>{t('releasenotespage:ReleaseNoteItem.example-done2')}</span></li>
                        <li><span>{t('releasenotespage:ReleaseNoteItem.example-done3')}</span></li> */}
                    </ul>
                    <h4>{t('releasenotespage:ReleaseNoteItem.to-do-title')}</h4>
                    <ul>
                        {i18n.language === "fr" ? (
                            to_do_fr.map((toDoFrItem,idx) => {
                                return (
                                    <li key={idx} ><span>{toDoFrItem}</span></li>
                                )
                            })
                        ) : (
                            to_do_en.map((toDoEnItem,idx) => {
                                return (
                                    <li key={idx} ><span>{toDoEnItem}</span></li>
                                )
                            })
                        )}
                        {/* <li><span>{t('releasenotespage:ReleaseNoteItem.example-todo1')}</span></li>
                        <li><span>{t('releasenotespage:ReleaseNoteItem.example-todo2')}</span></li>
                        <li><span>{t('releasenotespage:ReleaseNoteItem.example-todo3')}</span></li> */}
                    </ul>
                </div>
                <div className={classes.colorBorder}></div>
            </div>
        </ReactWOW>
    )
};

export default ReleaseNoteItem;