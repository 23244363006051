import axios from '../axios';
import { userConstants } from '../_constants/userConstants';

export const systemRuleService = {
    getParams,
    getUnactiveSystemRules,
    updateRule,
};

// function getParams(values) {
function getParams(ruleId,moduleId) {
    console.log("params url getParams");
    console.log(ruleId);
    console.log(moduleId);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    // return axios.get('/system-rule-params?rule_id=' + values.ruleId + '?module_id=' + values.moduleId,config)
    return axios.get('/system-rule-params?rule_id=' + ruleId + '&module_id=' + moduleId,config)
        .then(response => {
            console.log("Response getParams system rule by module");
            console.log(response);
            if (response.data) {
                console.log("List of protocols successfully retrieved !");

                let params = response.data.params;

                return params;
            }
        });
}

function getUnactiveSystemRules(ruleId,moduleId) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    // return axios.get('/system-rule-params?rule_id=' + values.ruleId + '?module_id=' + values.moduleId,config)
    return axios.get('/system-rule-unactive?module_id=' + moduleId,config)
        .then(response => {
            console.log("Response getUnactiveSystemRules by module");
            console.log(response);
            if (response.data) {
                console.log("List of unactive System Rules successfully retrieved !");

                let params = response.data.params;

                return params;
            }
        });
}

function updateRule(values) {
    console.log("values system rules service after submit");
    console.log(values);

    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/update-system-rule',values,config)
        // return axios.post('/api/register-module',{values:values,coords:window.coords},config)
        .then(response => {
            console.log("Response registerModule");
            console.log(response);

            return response;
        });
}