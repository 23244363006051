import React,{ useEffect } from 'react';

import IntroMonitoring from '../_components/MonitoringPage/IntroMonitoring';
import ControlConsumption from '../_components/MonitoringPage/ControlConsumption';
import MonitoringData from '../_components/MonitoringPage/MonitoringData';

import Aux from '../_hoc/Auxil/Auxil';

import '../_assets/css/bootstrap.min.css';
import '../_assets/css/animate.css';
// import '../_assets/css/icofont.css';
// import '../_assets/css/owl.carousel.min.css';
import '../_assets/css/owl.theme.default.css';
import '../_assets/css/magnific-popup.css';
import '../_assets/css/style.css';
import '../_assets/css/responsive.css';
import '../_assets/css/color/color-default.css';

import classes from './MonitoringPage.module.scss';

const NMonitoringPage = (props) => {
    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <IntroMonitoring />
            <MonitoringData />
        </div>
    );
}

export default NMonitoringPage;