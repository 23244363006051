import React,{ Component } from 'react';

import ClientChoice from '../Admin/ClientChoice';
import DeviceChoice from '../Dashboard/DeviceChoice';
import InstallationChoice from '../Layout/InstallationChoice';
import Auxil from '../../_hoc/Auxil/Auxil';

import { userService } from '../../_services/userService';
import { addressService } from '../../_services/addressService';
import { moduleService } from '../../_services/moduleService';
import { sermatecService } from '../../_services/sermatecService';

class ConfigInstallations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // selectedClient: JSON.parse(localStorage.getItem('appState')).user.id,
            // selectedDevice: 0,
            // selectedDeviceType: "module",
            selectedClient: this.props.selectedClient,
            selectedInstallation: this.props.selectedInstallation,
            // selectedDevice: this.props.selectedDevice,
            // selectedDeviceType: this.props.selectedDeviceType,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleInstallationChoice = this.handleInstallationChoice.bind(this);
        // this.handleDeviceChoice = this.handleDeviceChoice.bind(this);
    }

    handleSelectChange(event) {
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        userService.getById(event.target.value)
            .then(response => {
                this.setState({ selectedClient: response });
                console.log('selected in handle')
                if (response.addresses.length > 0) {
                    this.setState({ selectedInstallation: response.addresses[0] });
                } else {
                    this.setState({ selectedInstallation: JSON.parse(localStorage.getItem('demo')) });
                }
                /*if (response.modules.length > 0) {
                    this.setState({ selectedDevice: response.modules[0] });
                    this.setState({ selectedDeviceType: "module" });
                } else if (response.sermatecs.length > 0) {
                    this.setState({ selectedDevice: response.sermatecs[0] });
                    this.setState({ selectedDeviceType: "sermatec" });
                } else {
                    this.setState({ selectedDevice: JSON.parse(localStorage.getItem('demo')) });
                    console.log("selected device changed => should rerender chart container !!!");
                    console.log(JSON.parse(localStorage.getItem('demo')));
                    this.setState({ selectedDeviceType: "module" });
                }*/
            });
        // this.setState({ selectedClient: event.target.value });
        // this.setState({ selectedDevice: 0 });
        // this.setState({ selectedDeviceType: "module" });
        // console.log("select new state");
        // console.log(this.state);
        // console.log("select new value");
        // console.log(this.state.selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(this.state.selectedClient));
    }

    handleInstallationChoice(event) {
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        addressService.getAddressById(event.target.value)
            .then(response => {
                this.setState({ selectedInstallation: response });
            });
    }

    /*handleDeviceChoice(event) {
        console.log("event.target");
        console.log(event.target.dataset.type);
        console.log(event.target.dataset.index);
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        if (event.target.dataset.type === "module") {
            moduleService.getById(event.target.dataset.index)
                .then(response => {
                    console.log("dashboard response module by id");
                    console.log(response);
                    this.setState({ selectedDevice: response });
                })
        }
        if (event.target.dataset.type === "sermatec") {
            sermatecService.getById(event.target.dataset.index)
                .then(response => {
                    this.setState({ selectedDevice: response });
                })
        }
        // this.setState({ selectedDevice: event.target.dataset.index });
        this.setState({ selectedDeviceType: event.target.dataset.type });
        console.log("select new state");
        console.log(this.state);
        // console.log("select new value");
        // console.log(this.state.selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(this.state.selectedClient));
    }*/

    render() {
        console.log("BUPage new !! state beginning of render function => selectedUser");
        console.log(this.state);
        const user = JSON.parse(localStorage.getItem('appState')).user;
        const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
        let clientChoice = "";
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
                // clientChoice = (<div className={`col-md-6 ${classes.clientChoice}`}>
                clientChoice = (<div className={`col-md-6`}>
                    {/* <ClientChoice handleSelectChange={this.handleSelectChange} changeClient={this.handleClient} /> */}
                    <ClientChoice selectedClient={this.state.selectedClient} changed={this.handleSelectChange} />
                </div>);
            }
        }
        return (
            <Auxil>
                {clientChoice}
                {/* <div className={`col-md-6 ${classes.clientChoice}`}>
                    <DeviceChoice selectedClient={this.state.selectedClient} selectedDevice={this.state.selectedDevice} changed={this.handleDeviceChoice} />
                </div> */}
                {/* <div className={`col-md-6 ${classes.installationChoice}`}> */}
                <div className={`col-md-6`}>
                    <InstallationChoice selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} changed={this.handleInstallationChoice} />
                </div>
            </Auxil>
        );
    }
}

export default ConfigInstallations;