import React from 'react';

import GenericPage from '../_components/Layout/GenericPage';

const Page404 = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    console.log("user in dashboard");
    console.log(user);

    return (
        <GenericPage>
            <img src={require("../_assets/img/404-IMG-site-battery-UP.png")} alt="Erreur 404 - Page introuvable" style={{ width: '70%',margin: '50px auto' }} />
        </GenericPage>
    );
}

export default Page404;