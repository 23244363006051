import React,{ useState,useEffect } from 'react';

import { Trans,useTranslation } from 'react-i18next';

import { dataService } from '../../_services/dataService';
import { moduleService } from '../../_services/moduleService';

import Aux from '../../_hoc/Auxil/Auxil';

import BasicGauge from '../UI/Charts/BasicGauge';
import DualGauge from '../UI/Charts/DualGauge';

import classes from './AcrPGauge.module.scss';

const AcrPGauge = (props) => {
    const { t } = useTranslation(['common']);
    const mac_address = props.mac_address;
    const [average,setAverage] = useState(0);
    const [appPower,setAppPower] = useState(0);
    const [actPower,setActPower] = useState(0);
    const [reactPower,setReactPower] = useState(0);
    const [selectedPower,setSelectedPower] = useState("act");
    const [max,setmax] = useState(5);
    const [device,setDevice] = useState("Acrel");
    // const [diffSoc,setDiffSoc] = useState(0);
    // const [valueGradient,setValueGradient] = useState("100%");
    const [display,setDisplay] = useState("none");

    useEffect(() => {
        moduleService.getNameByMacAddress(mac_address)
            .then(response => {
                // console.log("response getNameByMacAddress");
                // console.log(response);
                setDevice(response);
            });
        dataService.acrPGauge(mac_address)
            .then(response => {
                // setAverage(Math.abs(response.avg));
                setAverage(response.avg);
                setAppPower(response.app_power);
                setActPower(response.act_power);
                setReactPower(response.react_power);
                // setSelectedPower("app");
                // setAverage(3.54);
                // if (response.avg >= 0) {
                //     setmax(response.max);
                // } else {
                //     setmax(Math.abs(response.min));
                // }
                if (Math.abs(response.max) > Math.abs(response.min)) {
                    setmax(response.max);
                } else {
                    setmax(Math.abs(response.min));
                }
            })
            .then(response => {
                setDisplay("block");
            });
        const interval = setInterval(() => {
            dataService.acrPGauge(mac_address)
                .then(response => {
                    // setAverage(Math.abs(response.avg));
                    setAverage(response.avg);
                    setAppPower(response.app_power);
                    setActPower(response.act_power);
                    setReactPower(response.react_power);
                    // setAverage(0.51);
                    // if (response.avg >= 0) {
                    //     setmax(response.max);
                    // } else {
                    //     setmax(Math.abs(response.min));
                    // }
                    if (response.max > Math.abs(response.min)) {
                        setmax(response.max);
                    } else {
                        setmax(Math.abs(response.min));
                    }
                });
        },30000);
        return () => {
            console.log(`clearing interval`);
            clearInterval(interval);
        };
    },[mac_address]);

    function powerSelectChange(e) {
        const { name,value } = e.target;
        // console.log("selectedPower in power select");
        // console.log(selectedPower);
        console.log("value in power select");
        console.log(value);
        // console.log("appPower in power select");
        // console.log(appPower);
        // console.log("actPower in power select");
        // console.log(actPower);
        // console.log("reactPower in power select");
        // console.log(reactPower);
        setSelectedPower(value);
        // userService.getById(value)
        //     .then(response => {
        //         setTarget(response);
        //         if (response.addresses.length > 0) {
        //             setTargetHasAddress(true);
        //         } else {
        //             setTargetHasAddress(false);
        //         }
        //     });
    }

    return (
        <div className={classes.AcrPGauge}>
            <h3 className={classes.AcrPGaugeTitle}>{device}</h3>
            <div className={classes.powerSelect}>
                <label for="powerSelect">{t('common:datas.power')} : </label>
                <select id="powerSelect" name="powerSelect" onChange={powerSelectChange} value={selectedPower}>
                    <option value="app">{t('common:datas.energy-meter.app-power')}</option>
                    <option value="act">{t('common:datas.energy-meter.act-power')}</option>
                    <option value="react">{t('common:datas.energy-meter.react-power')}</option>
                </select>
            </div>
            <div className={classes.AcrPGaugeContent}>
                {/* <h4><img className={classes.icon} src={require("../../_assets/img/icon_energy_production.png")} alt="Créez des scénarios grâce à  IFTTT" /><span>Production actuelle: {average} kW</span></h4> */}
                {selectedPower === 'app' ? (
                    <h4><img className={classes.icon} src={require("../../_assets/img/icons/data/power.png")} alt={t('common:datas.power')} /><span>{t('common:datas.energy-meter.app-power')}: {appPower} kW</span></h4>
                ) : selectedPower === 'act' ? (
                    average >= 0 ? (
                        // <h4><img className={classes.icon} src={require("../../_assets/img/icons/data/consumption.png")} alt={t('common:datas.imported-energy')} /><span>{t('common:datas.imported-energy')}: {average} kW</span></h4>
                        <h4><img className={classes.icon} src={require("../../_assets/img/icons/data/consumption.png")} alt={t('common:datas.imported-energy')} /><span>{t('common:datas.imported-energy')}: {selectedPower === 'app' ? appPower : selectedPower === "act" ? actPower : reactPower} kW</span></h4>
                    ) : (
                        // <h4><img className={classes.icon} src={require("../../_assets/img/icons/data/production.png")} alt={t('common:datas.exported-energy')} /><span>{t('common:datas.exported-energy')}: {average} kW</span></h4>
                        <h4><img className={classes.icon} src={require("../../_assets/img/icons/data/production.png")} alt={t('common:datas.exported-energy')} /><span>{t('common:datas.exported-energy')}: {selectedPower === 'app' ? appPower : selectedPower === "act" ? actPower : reactPower} kW</span></h4>
                    )
                ) : (
                    <h4><img className={classes.icon} src={require("../../_assets/img/icons/data/power.png")} alt={t('common:datas.power')} /><span>{t('common:datas.energy-meter.react-power')}: {reactPower} kW</span></h4>
                )}
                {/* <img className={classes.icon} src={require("../../_assets/img/icon_energy_meter.png")} alt="Créez des scénarios grâce à  IFTTT" /> */}
                {/* <BasicGauge average={-1 * average} max={max} /> */}
                {selectedPower === 'app' ? (
                    // <DualGauge power={selectedPower} average={-1 * appPower} max={max} />
                    <BasicGauge power={selectedPower} average={appPower} max={max} />
                ) : selectedPower === 'act' ? (
                    <DualGauge power={selectedPower} average={actPower} max={max} />
                ) : (
                    <BasicGauge power={selectedPower} average={reactPower} max={max} />
                )}
                {/* <BasicGauge power={selectedPower} average={-1 * selectedPower === 'app' ? appPower : selectedPower === "act" ? actPower : reactPower} max={max} /> */}
                {/* <img className={classes.upOrDown} src={require("../../_assets/img/IMG_test_acrel_visual.png")} alt="Créez des scénarios grâce à  IFTTT" /> */}
                {/* <img className={classes.upOrDown} src={require("../../_assets/img/icon_energy_meter.png")} alt="Créez des scénarios grâce à  IFTTT" /> */}
            </div>
            <div className={classes.colorBorder}></div>
        </div>
    )
};

export default AcrPGauge;