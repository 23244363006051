import axios from '../axios';

export const contactService = {
    contact,
};

function contact(data) {
    console.log('contact in service');
    const config = {
        headers: {
            'Accept': 'application/json',
            // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    };
    return axios.post('/api/contact',data,config)
        .then(response => {
            console.log(response);
        });
}