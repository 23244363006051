import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../../_services/dataService';
import { moduleService } from '../../../_services/moduleService';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';

import AlarmCard from '../Cards/AlarmCard';
import SmallDataCard from '../Cards/SmallDataCard';

import classes from './PylontechData.module.scss';

const PylontechData = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [pylontech,setPylontech] = useState([]);
    const [errorRequest,setErrorRequest] = useState(false);
    const [emptyReturn,setEmptyReturn] = useState(false);
    const macAddress = [props.mac_address];
    const [pylontechName,setPylontechName] = useState("Pylontech");

    // console.log("pylontechIds");
    // console.log(macAddress);

    useEffect(() => {
        if (macAddress.length > 0) {
            moduleService.getNameByMacAddress(macAddress)
                .then(response => {
                    // if (response !== 'empty') {
                    // console.log("response getNameByMacAddress");
                    // console.log(response);
                    setPylontechName(response);
                    // }
                });
            dataService.pylontechLastData(macAddress)
            // dataService.pylontechLastDataOld(macAddress)
                .then(response => {
                    // console.log("response pylontechLastData");
                    // console.log(response);
                    if (response !== 'empty' && response !== 'error') {
                        setPylontech(Object.values(response));
                    } else if (response === 'error') {
                        setPylontech([]);
                        setErrorRequest(true);
                    } else {
                        setPylontech([]);
                        setEmptyReturn(true);
                    }
                });
        } else {
            setPylontech([]);
        }
        // },[macAddress]);
    },[props.mac_address]);

    // console.log("pylontech");
    // console.log(pylontech);

    return (
        <Aux>
            {/* <div className={`col-md-12 ${classes.AdditionalBatteriesData__list}`}> */}
            {pylontech.length > 0 ? (
                pylontech.map((battery,idx) =>
                    <div className={classes.dataWrapper} key={idx}>
                        {/* <h4>{t('common:datas.battery.battery')} - {pylontech.mac_address}</h4> */}
                        <h4 className={classes.title}>Les autres données batterie - {pylontechName}</h4>
                        {/* <p className={classes.date}>{t('common:datas.last-record')}: {pylontech.date}</p> */}
                        <div className={`${classes.data}`}>
                            <SmallDataCard name={t('common:datas.battery.mod_cnt')} value={battery.mod_cnt} />
                            <SmallDataCard name={t('common:datas.battery.chg_i_limit')} value={battery.chg_i_limit} />
                            <SmallDataCard name={t('common:datas.battery.dischg_i_limit')} value={battery.dischg_i_limit} />
                            <SmallDataCard name={t('common:datas.battery.batt_chg_v')} value={battery.batt_chg_v} />
                            <SmallDataCard name={t('common:datas.battery.soh')} value={battery.soh} />
                            <SmallDataCard name={t('common:datas.battery.avg_cell_t')} value={battery.avg_cell_t} />
                            <SmallDataCard name={t('common:datas.battery.v')} value={battery.v} />
                            <SmallDataCard name={t('common:datas.battery.itot')} value={battery.itot} />
                        </div>
                    </div>
                )) : !errorRequest && !emptyReturn && macAddress.length > 0 ? (
                    <Spinner />
                ) : ""
            }
        </Aux>
    )
};

export default PylontechData;