import React from 'react';
import { Link } from 'react-router-dom';

import classes from './TemporaryPopup.module.scss';

const TemporaryPopup = (props) => {
    return (
        <div className={classes.TemporaryPopup}>
            <div className={classes.popup}>
                <i class="icofont-check-circled"></i>
                <p className={classes.confirmation}>{props.children}</p>
                <Link to={props.redirect}>
                    <button className="btn btn-primary">
                        {props.button}
                    </button>
                </Link>
            </div>
        </div>
    )
};

export default TemporaryPopup;