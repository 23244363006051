import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './SmallDataCard.module.scss';

const SmallDataCard = (props) => {
    const { t } = useTranslation(['common','dashboard']);

    const title = props.name;
    const value = props.value;
    const type = props.type;

    return (
        <div className={classes.SmallDataCard}>
            <p className={classes.title}>{title}</p>
            <p className={type === "energy_meter" ? `${classes.value} ${classes.energyMeter}` : classes.value}>{(typeof (value) != "undefined" && value !== "null" && value !== null) ? value : t('common:datas.not-communicated')}</p>
        </div>
    )
};

export default SmallDataCard;