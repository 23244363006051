import React from 'react';

function N404() {
    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <img src={require("../_assets/img/404-IMG-site-battery-UP.png")} alt="Erreur 404 - Page introuvable" style={{ width: '70%',margin: '50px auto' }} />
        </div>
    );
}

export default N404;
