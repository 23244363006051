import React,{ Component,useState,useEffect } from 'react';

// import Aux from '../../_hoc/Auxil/Auxil';
// import Data from '../../_components/Data/Data';
import Soc from '../Data/Soc';
import BatteryTemperatures from '../Data/BatteryTemperatures';
import WeatherWithForecast from '../Data/WeatherWithForecast';
import classes from './Dashboard.module.scss';
// import { dataService } from '../../_services/dataService';
// import WeatherForecast from '../../_components/UI/Charts/WeatherForecast';
// import data from '../../_components/Data/Data';
// import Spinner from '../../_components/UI/Spinner/Spinner';
// import ActivityChart from '../../_components/Data/ActivityChart';
import ChartContainer from '../Data/ChartContainer';
// import AmChart from '../../_components/Data/AmChart';
// import BatteryUp from '../../_layouts/BatteryUp';
import ClientChoice from '../Admin/ClientChoice';
import DeviceChoice from '../Dashboard/DeviceChoice';

import { userService } from '../../_services/userService';
import { moduleService } from '../../_services/moduleService';
import { sermatecService } from '../../_services/sermatecService';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // selectedClient: JSON.parse(localStorage.getItem('appState')).user.id,
            // selectedDevice: 0,
            // selectedDeviceType: "module",
            selectedClient: props.selectedClient,
            selectedDevice: props.selectedDevice,
            selectedDeviceType: props.selectedDeviceType,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDeviceChoice = this.handleDeviceChoice.bind(this);
    }
    // state = { selectedClient: '5600' }

    // handleClient = (client) => {
    //     this.setState({selectedClient: client});
    // }

    handleSelectChange(event) {
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        userService.getById(event.target.value)
            .then(response => {
                this.setState({ selectedClient: response });
                if (response.modules.length > 0) {
                    this.setState({ selectedDevice: response.modules[0] });
                    this.setState({ selectedDeviceType: "module" });
                } else if (response.sermatecs.length > 0) {
                    this.setState({ selectedDevice: response.sermatecs[0] });
                    this.setState({ selectedDeviceType: "sermatec" });
                } else {
                    this.setState({ selectedDevice: JSON.parse(localStorage.getItem('demo')) });
                    console.log("selected device changed => should rerender chart container !!!");
                    console.log(JSON.parse(localStorage.getItem('demo')));
                    this.setState({ selectedDeviceType: "module" });
                }
            });
        // this.setState({ selectedClient: event.target.value });
        // this.setState({ selectedDevice: 0 });
        // this.setState({ selectedDeviceType: "module" });
        // console.log("select new state");
        // console.log(this.state);
        // console.log("select new value");
        // console.log(this.state.selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(this.state.selectedClient));
    }

    handleDeviceChoice(event) {
        console.log("event.target");
        console.log(event.target.dataset.type);
        console.log(event.target.dataset.index);
        // console.log("select old state");
        // console.log(this.state);
        // console.log("select old value");
        // console.log(this.state.selectedClient);
        if (event.target.dataset.type === "module") {
            moduleService.getById(event.target.dataset.index)
                .then(response => {
                    console.log("dashboard response module by id");
                    console.log(response);
                    this.setState({ selectedDevice: response });
                })
        }
        if (event.target.dataset.type === "sermatec") {
            sermatecService.getById(event.target.dataset.index)
                .then(response => {
                    this.setState({ selectedDevice: response });
                })
        }
        // this.setState({ selectedDevice: event.target.dataset.index });
        this.setState({ selectedDeviceType: event.target.dataset.type });
        console.log("select new state");
        console.log(this.state);
        // console.log("select new value");
        // console.log(this.state.selectedClient);
        // localStorage.setItem("selectedClient",JSON.stringify(this.state.selectedClient));
    }

    render() {
        console.log("Dashboard state beginning of render function => selectedUser");
        console.log(this.state);
        const user = JSON.parse(localStorage.getItem('appState')).user;
        let clientChoice = "";
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
                clientChoice = (<div className="row">
                    <div className={`col-md-8 offset-md-2 col-xl-4 offset-xl-4 ${classes.clientChoice}`}>
                        {/* <ClientChoice handleSelectChange={this.handleSelectChange} changeClient={this.handleClient} /> */}
                        <ClientChoice selectedClient={this.state.selectedClient} changed={this.handleSelectChange} />
                    </div>
                </div>);
            }
        }
        return (
            <div className={classes.Dashboard}>
                <div className="container-fluid">
                    {clientChoice}
                    <div className="row">
                        <div className={`col-12 ${classes.clientChoice}`}>
                            <DeviceChoice selectedClient={this.state.selectedClient} selectedDevice={this.state.selectedDevice} changed={this.handleDeviceChoice} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 ${classes.mainPanel}`}>
                            <ChartContainer key={this.state.selectedDevice} selectedClient={this.state.selectedClient} selectedDevice={this.state.selectedDevice} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-md-12 col-xl-6`}>
                            <WeatherWithForecast selectedDevice={this.state.selectedDevice} selectedDeviceType={this.state.selectedDeviceType} />
                        </div>
                        <div className={`col-md-6 col-xl-3 ${classes.secondaryData}`}>
                            <Soc className={classes.panel} selectedClient={this.state.selectedDevice} />
                        </div>
                        <div className={`col-md-6 col-xl-3 ${classes.secondaryData}`}>
                            <BatteryTemperatures className={classes.panel} selectedClient={this.state.selectedDevice} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
/*
const Dashboard = (props) => {
    const [selectedClient,setselectedClient] = useState([]);

    const user = JSON.parse(localStorage.getItem('appState')).user;
    let clientChoice = "";
    if(typeof user.roles !== 'undefined') {
        if(user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
            clientChoice = (<div className="row">
                <div className={`col-md-8 offset-md-2 col-xl-4 offset-xl-4 ${classes.clientChoice}`}>
                    <ClientChoice />
                </div>
            </div>);
        }
    }
    return (
        <BatteryUp>
            <div className={classes.Dashboard}>
                <div className="container-fluid">
                    {clientChoice}
                    <div className="row">
                        <div className={`col-12 ${classes.mainPanel}`}>
                            <ChartContainer />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-md-12 col-xl-6`}>
                            <WeatherWithForecast />
                        </div>
                        <div className={`col-md-6 col-xl-3 ${classes.secondaryData}`}>
                            <Soc className={classes.panel} />
                        </div>
                        <div className={`col-md-6 col-xl-3 ${classes.secondaryData}`}>
                            <BatteryTemperatures className={classes.panel} />
                        </div>
                    </div>
                </div>
                
            </div>
        </BatteryUp>
    );
}
*/
export default Dashboard;