import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import classes from './AmChart.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';
import { dataService } from '../../_services/dataService';

am4core.useTheme(am4themes_animated);

const AmChart = (props) => {
    console.log(props);
    /*const test = [
        {
            battery_current: "-16.04",
            battery_voltage: "49.77",
            energrid: 0,
            enerinv: 487.92,
            id_energy_mter: 2,
            log_date: 20200803,
            log_time: 0,
            power: -798,
            powerExport: 0,
            powerImport: 456,
            soc: 80,
            solar_prod: 0,
        },
        {
            battery_current: "-10.73",
            battery_voltage: "49.80",
            energrid: 0,
            enerinv: 231.12,
            id_energy_mter: 2,
            log_date: 20200803,
            log_time: 5500,
            power: -534,
            powerExport: 0,
            powerImport: 216,
            soc: 77,
            solar_prod: 0,
        },
        {
            battery_current: "-1.72",
            battery_voltage: "49.39",
            energrid: 0,
            enerinv: 346.68,
            id_energy_mter: 2,
            log_date: 20200803,
            log_time: 73000,
            power: -84,
            powerExport: 0,
            powerImport: 324,
            soc: 58,
            solar_prod: 262.68,
        }
    ]*/
    const chartRef = useRef(null);

    // const [powerLogs,setPowerLogs] = useState([]);
    // const [powerLogs,setPowerLogs] = useState(props.powerLogs);
    // const [startDate,setStartDate] = useState(new Date());
    // const [endDate,setEndDate] = useState(new Date());

    /*useEffect(() => {
        // dataService.socInPeriod()
        //     .then(response => {
        //         setSoc(response.data.soc);
        //     });
        let from = startDate.getFullYear().toString() + ('0' + (startDate.getMonth() + 1)).slice(-2) + ('0' + startDate.getDate()).slice(-2);
        let to = endDate.getFullYear().toString() + ('0' + (endDate.getMonth() + 1)).slice(-2) + ('0' + endDate.getDate()).slice(-2);
        dataService.powerLogs(from,to)
            .then(response => {
                console.log("response");
                console.log(response);
                setPowerLogs(response.data.power);
                // renderChart(powerLogs);
            });
    },[]);*/

    function getMinMax(target,data) {
        console.log("Start minMax function");
        // console.log("target");
        // console.log(target);
        // console.log("data");
        // console.log(data);
        let maxDefault = 0;
        let maxItem = null;
        let minDefault = 0;
        let minItem = null;
        let result = [];
        result['min'] = 0;
        result['max'] = 0;
        console.log("result min max");
        console.log(result);
        for (let i = 0; i < target.length; i++) {
            let item = target[i];
            // console.log("item.data");
            // console.log(item[data]);
            if (item[data] < minDefault) {
                minDefault = item[data];
                result['min'] = item[data];
            }
            if (item[data] > maxDefault) {
                maxDefault = item[data];
                result['max'] = item[data];
            }
        }
        console.log("End minMax function");
        return result;
    }

    /*const dayPickerHandler = date => {
        setStartDate(date);
        setEndDate(date);
    };

    const datePickerHandler = dates => {
        const [start,end] = dates;
        setStartDate(start);
        setEndDate(end);
        // if (endDate === null) {
        //     setEndDate(start);
        // }
    };

    const dateChangeHandler = () => {
        console.log("go");
        // console.log("endDate");
        // console.log(endDate);
        let from = startDate.getFullYear().toString() + ('0' + (startDate.getMonth() + 1)).slice(-2) + ('0' + startDate.getDate()).slice(-2);
        let to = endDate.getFullYear().toString() + ('0' + (endDate.getMonth() + 1)).slice(-2) + ('0' + endDate.getDate()).slice(-2);
        console.log(from.toString());
        console.log(to.toString());
        dataService.powerLogs(from,to)
            .then(response => {
                setPowerLogs(response.data.power);
            });
    }*/

    // console.log("Power Logs");
    // console.log(powerLogs);

    // function renderChart(data) {
    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv",am4charts.XYChart);

        chart.paddingRight = 1;

        /*x.paddingRight = 20;

        let data = [];
        let visits = 10;

        for (let i = 1; i < 366; i++) {
            visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
            data.push({ date: new Date(2018,0,i),name: "name" + i,value: visits });
        }

        x.data = data;

        let dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = x.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.tooltipText = "{valueY.value}";
        x.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        x.scrollbarX = scrollbarX;

        chart.current = x;*/

        chart.dateFormatter.inputDateFormat = "dd-MM-yyyy H:m";
        chart.dateFormatter.dateFormat = "d-M H:m";

        chart.data = props.powerLogs;
        // console.log("chart.data data");
        // console.log(chart.data);
        // console.log("chart.data powerLogs");
        // console.log(data);

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.baseInterval = {
            "timeUnit": "minutes",
            "count": 5
        }

        let loop = 1;
        dateAxis.renderer.labels.template.adapter.add("text",(label,target,key) => {
            let totalLabel = dateAxis.renderer.labels._values.length;
            if (target.dataItem._text === "00:00") {
                let newDate = new Date(target.dataItem.value);
                let day = newDate.getDate();
                let month = newDate.getMonth() + 1;
                if (month.length === 1) month = "0" + month;
                let year = newDate.getFullYear();
                return day + "/" + month;
            } else {
                return label;
            }
            loop++;
        })

        if (chart.data.length > 289) dateAxis.groupData = true;

        let color = [];
        color[0] = "#008000";
        color['greenlight'] = "#7bca7b";
        color[1] = "#ff0000";
        color[2] = "#ffeca0";
        color['orange'] = "#d76000";

        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.background.fillOpacity = 0;
        chart.legend.width = "100%";
        var marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12,12,12,12);
        marker.stroke = "#333";
        marker.strokeDasharray = "1";
        marker.strokeWidth = 2;
        marker.strokeOpacity = 1;
        marker.fillOpacity = .3;
        marker.adapter.add("strokeDasharray",function (strokeDasharray,target) {
            if (typeof target.dataItem.dataContext.fill !== 'undefined') {
                if (target.dataItem.dataContext.fill === color[3]) {
                    return "3";
                }
            }
        });
        marker.adapter.add("stroke",function (strokeColor,target) {
            if (!target.dataItem) {
                return strokeColor;
            } else if (typeof target.dataItem._marker.fill !== 'undefined') {
                if (target.dataItem._marker.fill.hex === color[0]) { return color[0]; }
                if (target.dataItem._marker.fill.hex === color[1]) { return color[1]; }
                if (target.dataItem._marker.fill.hex === color[2]) { return color[2]; }
            } else {
                return strokeColor;
            }

        });

        /**
         * Define min and max values to divide chart in 2 parts of same heights
         */
        let chartMinMax = getMinMax(props.powerLogs,'power');
        let solarMinMax = getMinMax(props.powerLogs,'solar_prod');
        let gridMinMax = getMinMax(props.powerLogs,'powerImport');
        // console.log("chartMinMax");
        // console.log(chartMinMax);
        // console.log("solarMinMax");
        // console.log(solarMinMax);
        // console.log("gridMinMax");
        // console.log(gridMinMax);

        let minMaxArray = [Math.abs(chartMinMax['min']),Math.abs(chartMinMax['max']),Math.abs(solarMinMax['min']),Math.abs(solarMinMax['max']),Math.abs(gridMinMax['min']),Math.abs(gridMinMax['max'])];
        minMaxArray.sort(function (a,b) { return b - a });
        console.log("minMaxArray[0]");
        console.log(minMaxArray[0]);
        // let minItem = 0;
        // let maxItem = 0;
        let minItem = minMaxArray[0] * -1;
        let maxItem = minMaxArray[0];
        /*if (solarMinMax["max"] > chartMinMax["max"] || solarMinMax["max"] > Math.abs(chartMinMax["min"])) {
            chartMinMax["max"] = solarMinMax["max"];
            chartMinMax["min"] = solarMinMax["min"];
        }
        if (Math.abs(chartMinMax["min"]) < Math.abs(gridMinMax["max"]) || Math.abs(solarMinMax["max"]) < Math.abs(gridMinMax["min"])) {
            chartMinMax["max"] = gridMinMax["max"];
            chartMinMax["min"] = gridMinMax["min"];
        }
        if (chartMinMax["max"] < 50 && chartMinMax["min"] > -100) {
            minItem = -100;
            maxItem = 100;
        } else {
            if (Math.abs(chartMinMax["min"]) > Math.abs(chartMinMax["max"]) && chartMinMax["min"] <= 0 && chartMinMax["max"] >= 0) {
                minItem = Math.abs(chartMinMax["min"]) * -1;
                maxItem = Math.abs(chartMinMax["min"]);
            } else if (Math.abs(chartMinMax["min"]) < Math.abs(chartMinMax["max"]) && chartMinMax["min"] <= 0 && chartMinMax["max"] > 0) {
                minItem = Math.abs(chartMinMax["max"]) * -1;
                maxItem = chartMinMax["max"];
            } else if (Math.abs(chartMinMax["min"]) > Math.abs(chartMinMax["max"]) && chartMinMax["min"] <= 0 && chartMinMax["max"] < 0) {
                minItem = Math.abs(chartMinMax["min"]) * -1;
                maxItem = Math.abs(chartMinMax["min"]);
            } else if (Math.abs(chartMinMax["min"]) > Math.abs(chartMinMax["max"]) && chartMinMax["min"] >= 0 && chartMinMax["max"] > 0) {
                minItem = Math.abs(chartMinMax["max"]) * -1;
                maxItem = Math.abs(chartMinMax["max"]);
            }
        }
        console.log("minItem");
        console.log(minItem);
        console.log("maxItem");
        console.log(maxItem);*/

        /**
         * Creation of left y axis
         */
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.baseValue = 0;
        valueAxis.min = minItem;
        valueAxis.max = maxItem;
        valueAxis.strictMinMax = false;
        valueAxis.numberFormatter = new am4core.NumberFormatter();
        valueAxis.minWidth = 80;
        valueAxis.numberFormatter.numberFormat = "#' W'";

        /**
         * Creation of series power
         */
        let series = chart.series.push(new am4charts.LineSeries());
        series.name = "[bold]Load/Unload[bold]";
        series.dataFields.valueY = "power";
        series.dataFields.dateX = "datetime";
        series.strokeWidth = 2;
        series.fillOpacity = 1;
        series.color = color[1];
        series.zIndex = 10;

        /**
         * Creation of series solar production
         */
        let seriesSolarProd = chart.series.push(new am4charts.LineSeries());
        seriesSolarProd.name = "[bold]Solar production[bold]";
        seriesSolarProd.dataFields.valueY = "solar_prod";
        seriesSolarProd.dataFields.dateX = "datetime";
        seriesSolarProd.strokeWidth = 2;
        let gradientSolar = new am4core.LinearGradient();
        gradientSolar.addColor(color['orange'],1);
        gradientSolar.addColor(color['orange'],0);
        gradientSolar.rotation = 90;
        seriesSolarProd.fill = gradientSolar;
        seriesSolarProd.fillOpacity = 1;
        seriesSolarProd.stroke = color['orange'];
        if (chart.data.length < 350) { seriesSolarProd.tensionX = 0.75; }
        seriesSolarProd.zIndex = 1;

        /**
         * Define gradient positive or negative
         */
        let gradientPos = new am4core.LinearGradient();
        gradientPos.addColor(color['greenlight'],1);
        gradientPos.addColor(color['greenlight'],0);
        gradientPos.rotation = 90;

        let gradientNeg = new am4core.LinearGradient();
        gradientNeg.addColor(color[1],0.3);
        gradientNeg.addColor(color[1],0);
        gradientNeg.rotation = -90;

        // Red color for negative values
        let rangeRed = valueAxis.createSeriesRange(series);
        rangeRed.value = 0;
        rangeRed.endValue = -100000;
        rangeRed.contents.stroke = color[1];
        rangeRed.contents.fill = gradientNeg;
        rangeRed.contents.strokeOpacity = 0.7;
        rangeRed.contents.fillOpacity = .8;

        // Green color for positive values
        let rangeGreen = valueAxis.createSeriesRange(series);
        rangeGreen.value = 0;
        rangeGreen.endValue = 100000;
        rangeGreen.contents.stroke = color[0];
        rangeGreen.contents.fill = gradientPos;
        rangeGreen.contents.strokeOpacity = 0.7;
        rangeGreen.contents.fillOpacity = .8;

        if (chart.data.length < 350) { seriesSolarProd.tensionX = 0.95; seriesSolarProd.tensionY = 0.95; }
        series.stroke = am4core.color('#333');
        series.zIndex = 10;

        /**
         * Creation of soc y axis
         */
        let socValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        socValueAxis.renderer.opposite = true;
        socValueAxis.baseValue = 0;
        socValueAxis.min = -100;
        socValueAxis.max = 100;
        socValueAxis.strictMinMax = false;
        socValueAxis.numberFormatter = new am4core.NumberFormatter();
        socValueAxis.minWidth = 60;
        socValueAxis.numberFormatter.numberFormat = "#' %'";

        /**
         * Hide negative values of soc y axis
         */
        socValueAxis.renderer.labels.template.fill = "#f1c705";
        socValueAxis.renderer.labels.template.fontWeight = 600;
        socValueAxis.renderer.labels.template.adapter.add("text",(label,target,key) => {
            if (target.dataItem && target.dataItem.value < 0) {
                return "";
            } else {
                return label;
            }
        });

        socValueAxis.cursorTooltipEnabled = false;

        /**
         * Creation of series soc
         */
        let socSeries = chart.series.push(new am4charts.LineSeries());
        socSeries.name = "[bold]Battery State Of Charge[bold]";
        socSeries.dataFields.valueY = "soc";
        if (chart.data.length < 289) { socSeries.tensionX = 0.75; socSeries.tensionY = 0.75; }
        socSeries.dataFields.dateX = "datetime";
        socSeries.yAxis = socValueAxis;

        let gradientSoc = new am4core.LinearGradient();
        gradientSoc.addColor(color[2],0.85);
        gradientSoc.addColor(color[2],0.25);
        gradientSoc.rotation = 90;
        socSeries.fill = gradientSoc;
        socSeries.strokeWidth = 2;
        socSeries.color = "#ffca2a";
        socSeries.stroke = "#ffca2a";
        socSeries.strokeOpacity = 1;
        socSeries.fillOpacity = 1;
        let gradientStroke = new am4core.LinearGradient();
        gradientStroke.addColor(color[2],1,.8);
        gradientStroke.rotation = 0;
        gradientStroke.strokeDasharray = "0";
        gradientStroke.zIndex = 8;
        gradientStroke.fillOpacity = 1;

        /**
         * Creation of series energy grid
         */
        let energyGridSeries = chart.series.push(new am4charts.LineSeries());
        energyGridSeries.name = "[bold]Network[bold]";
        energyGridSeries.dataFields.valueY = "energrid";
        energyGridSeries.dataFields.dateX = "datetime";
        energyGridSeries.strokeOpacity = 0;
        energyGridSeries.strokeWidth = 1;
        energyGridSeries.fillOpacity = .81;
        energyGridSeries.stroke = color[1];
        energyGridSeries.zIndex = 21;
        energyGridSeries.fill = color[1];

        /**
         * Creation of series energy inv
         */
        let energyInvSeries = chart.series.push(new am4charts.LineSeries());
        energyInvSeries.name = "[bold]Consumption[bold]";
        energyInvSeries.dataFields.valueY = "enerinv";
        energyInvSeries.dataFields.dateX = "datetime";
        energyInvSeries.strokeWidth = 2;
        energyInvSeries.fillOpacity = .85;
        energyInvSeries.stroke = color[1];
        energyInvSeries.zIndex = 20;
        energyInvSeries.fill = am4core.color('#fff');
        // energyInvSeries.rotation = -90;
        let gradientConsumption = new am4core.LinearGradient();
        gradientConsumption.addColor(color[1],0.3);
        gradientConsumption.addColor(color[1],0);

        /**
         * Grid and Consumption diplayed/hidden at the same time
         */
        energyGridSeries.events.on("hidden",function () {
            energyInvSeries.hide();
        });
        energyGridSeries.events.on("shown",function () {
            energyInvSeries.show();
        });
        energyInvSeries.events.on("hidden",function () {
            energyGridSeries.hide();
        });
        energyInvSeries.events.on("shown",function () {
            energyGridSeries.show();
        });
        energyGridSeries.hidden = true;
        energyInvSeries.hidden = true;

        socSeries.tooltipText = "{datetime} \n SOC : {soc.formatNumber('#')}% \n Solar : {solar_prod} Wh \n Battery : {power} W \n Consumption : {enerinv} W[/]";
        socSeries.tooltip.getFillFromObject = false;
        socSeries.tooltip.background.fill = am4core.color("#000");

        chart.cursor = new am4charts.XYCursor();

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    },[props.powerLogs]);
    // }

    // When the paddingRight prop changes it will update the chart
    // useLayoutEffect(() => {
    //     chartRef.current.paddingRight = props.paddingRight;
    // },[props.paddingRight]);

    return (
        // <Aux>
        //     <div>
        //         Pick a date
        //         <DatePicker
        //             className={classes.DatePicker}
        //             selected={startDate}
        //             onChange={dayPickerHandler} />
        //     </div>
        //     <div>
        //         Pick a period
        //         <DatePicker
        //             className={classes.DatePicker}
        //             selected={startDate}
        //             onChange={datePickerHandler}
        //             startDate={startDate}
        //             endDate={endDate}
        //             selectsRange
        //             shouldCloseOnSelect={false}
        //         // showDisabledMonthNavigation
        //         />
        //     </div>

        <div id="chartdiv" style={{ width: "100%",height: "600px" }}></div>
        // </Aux>
    );
}

export default AmChart;