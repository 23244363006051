import React,{ useEffect,useState } from 'react';
// import { Link } from 'react-router-dom';
import { Formik,Field,Form,ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { systemRuleService } from '../../_services/systemRuleService';
import { Trans,useTranslation } from 'react-i18next';

import Aux from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';

import classes from './WebhookForm.module.scss';

import TemporaryPopup from '../UI/ValidationMessage/TemporaryPopup';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import btnRegister from '../../_assets/img/bouton-enregistrer-battery-up.png';
import { webhookService } from '../../_services/webhookService';
// import classes from '*.module.css';

const WebhookForm = (props) => {
    const { t } = useTranslation(['common','registermodulepage']);
    console.log("props Alert form");
    console.log(props);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const ruleId = props.ruleId;
    const moduleId = props.moduleId;
    const isActive = props.active;
    const [modified,setModified] = useState(false);
    const [existingWebhook,setExistingWebhook] = useState(false);
    // const [module,setModule] = useState([]);
    const [initWebhook,setInitWebhook] = useState({
        active: isActive,
        url: "",
        user_id: user.id,
        module_id: moduleId,
    });
    // console.log("module Alert form");
    // console.log(module);

    useEffect(() => {
        webhookService.getByModuleId(moduleId)
            .then(response => {
                console.log('response getWebhook');
                console.log(response);
                if (response) {
                    setInitWebhook({
                        active: response.active === 1 ? true : false,
                        url: response.url,
                        user_id: user.id,
                        module_id: moduleId,
                    });
                    setExistingWebhook(true);
                } else {
                    setInitWebhook({
                        active: false,
                        url: "",
                        user_id: user.id,
                        module_id: moduleId,
                    });
                }
            });
    },[moduleId]);

    return (
        <div id="webhookForm" className={classes.WebhookForm}>
            {/* {validation} */}
            <Formik
                enableReinitialize
                initialValues={initWebhook}
                validationSchema={Yup.object({
                    url: Yup.string()
                        .trim()
                        .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                    user_id: Yup.number(),
                    module_id: Yup.number(),
                })}
                onSubmit={(values,{ setSubmitting }) => {
                    setTimeout(() => {
                        // values["user_id"] = 
                        console.log(values);

                        if (existingWebhook) {
                            webhookService.updateWebhook(values)
                                .then(() => {
                                    setModified(true);
                                });
                        } else {
                            webhookService.addWebhook(values)
                                .then(() => {
                                    // setModified(true);
                                    console.log("done");
                                });
                        }

                        // addressService.editAddress(values)
                        //     .then(() => {
                        //         setModified(true);
                        //         setTimeout(() => {
                        //             history.push('/my-profile');
                        //         },2000);
                        //     });
                    },400);
                }}
            >
                <Form style={{ textAlign: 'left' }}>
                    <Field type="hidden" name="user_id" value={user.id} />
                    <Field type="hidden" name="module_id" value={moduleId} />
                    {/* {module.protocol ? (
                        <Aux> */}
                    <label>
                        <Field type="checkbox" name="active" />
                        {/* {`${values.toggle}`} */}
                    </label>
                    <div className="form-group">
                        {/* <label htmlFor="param1">{t('registermodulepage:RegisterForm.installationname-label')}</label> */}
                        <label htmlFor="url">IFTTT Webhook URL</label>
                        <Field name="url" type="text" className="form-control" placeholder="https://maker.ifttt.com/..." />
                        <ErrorMessage name="url" />
                    </div>
                    {/* </Aux>
                    ) : <Spinner />} */}

                    <button className="btn btn-primary" type="submit" style={{ background: `url(${btnRegister}) center center no-repeat`,backgroundSize: 'contain',width: "180px",height: '70px',border: 'none',padding: '0',display: 'block' }}></button>
                </Form>
            </Formik>
        </div>
    );
}

export default WebhookForm;