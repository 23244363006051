import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './Features.module.scss';
import fondImgTexte2 from '../../_assets/img/IMG-fond-integrer-batteries-smartphone.png';


class Features extends Component {
    render() {
        const { t } = this.props;

        return (

            <section id="features" className={`awsome-features angle-gray-bg ${classes.Features}`}>
                <div className={`col-lg-12 ${classes.featuresWrapper}`}>
                    <div className={`container ${classes.container}`}>
                        <div className="row">
                            <div className="col-lg-12">
                                <ReactWOW delay='0.3s' animation='fadeInUp'>
                                    <div className={`section-title ${classes.sectionTitleWrapper}`}>
                                        <h2 className={classes.sectionTitle}>{t('homepage:Features.integrate-batteries-smarthome')}</h2>
                                        <p className={classes.sectionIntro}><strong>{t('homepage:Features.create-intercations')}</strong></p>
                                    </div>
                                </ReactWOW>
                            </div>
                        </div>

                        <div className={`row ${classes.FeaturesContent}`}>
                            <div className={`col-xs-12 col-md-6 col-lg-4 ${classes.singleFeatureWrapper}`}>
                                <ReactWOW delay='0.4s' animation='fadeInUp'>
                                    <div className={`single-feature ${classes.singleFeature}`}>
                                        <img className={classes.singleFeatureImage} src={require("../../_assets/img/IMG4-battery-up-application-smartphone.png")} alt={t('homepage:Features.create-ifttt-scenarios-title')} />
                                        <div style={{ textAlign: "center" }}>
                                            <h4 style={{ marginTop: "0",color: "#3d009e" }}>{t('homepage:Features.create-ifttt-scenarios-title')}</h4>
                                            <p style={{ lineHeight: "20px" }}>{t('homepage:Features.create-ifttt-scenarios-text')}</p>
                                        </div>
                                    </div>
                                </ReactWOW>
                            </div>

                            {/* <div className={`col-xs-12 col-md-6 col-lg-4 ${classes.singleFeatureWrapper}`}>
                                <ReactWOW delay='0.5s' animation='fadeInUp'>
                                    <div className={`single-feature ${classes.singleFeature}`}>
                                        <img className={classes.singleFeatureImage} src={require("../../_assets/img/IMG-battery-up-application-smartphone.png")} alt="Notifications sur votre smartphone" />
                                        <div style={{ textAlign: "center" }}>
                                            <h4 style={{ marginTop: "0",color: "#3d009e" }}>Notifications sur votre smartphone</h4>
                                            <p style={{ lineHeight: "20px" }}>Recevez des notifications directement sur votre smartphone sur l'état de charge de vos batteries.</p>
                                        </div>
                                    </div>
                                </ReactWOW>
                            </div> */}

                            <div className={`col-xs-12 col-md-6 col-lg-4 ${classes.singleFeatureWrapper}`}>
                                <ReactWOW delay='0.6s' animation='fadeInUp'>
                                    <div className={`single-feature ${classes.singleFeature}`}>
                                        <img className={classes.singleFeatureImage} src={require("../../_assets/img/IMG3-battery-up-application-smartphone.png")} alt={t('homepage:Features.battery-status-realtime-title')} />
                                        <div style={{ textAlign: "center" }}>
                                            <h4 style={{ marginTop: "0",color: "#3d009e" }}>{t('homepage:Features.battery-status-realtime-title')}</h4>
                                            <p style={{ lineHeight: "20px" }}>{t('homepage:Features.battery-status-realtime-text')}</p>
                                        </div>
                                    </div>
                                </ReactWOW>
                            </div>

                            <div className={`col-xs-12 col-md-6 col-lg-4 ${classes.singleFeatureWrapper}`}>
                                <ReactWOW delay='0.7s' animation='fadeInUp'>
                                    <div className={`single-feature ${classes.singleFeature}`}>
                                        <img className={classes.singleFeatureImage} src={require("../../_assets/img/IMG2-battery-up-application-smartphone.png")} alt={t('homepage:Features.light-soc-title')} />
                                        <div style={{ textAlign: "center" }}>
                                            <h4 style={{ marginTop: "0",color: "#3d009e" }}>{t('homepage:Features.light-soc-title')}</h4>
                                            <p style={{ lineHeight: "20px" }}>{t('homepage:Features.light-soc-text')}</p>
                                        </div>
                                    </div>
                                </ReactWOW>
                            </div>

                            {/* <div className={`col-xs-12 col-md-6 col-lg-4 ${classes.singleFeatureWrapper}`}>
                                <ReactWOW delay='0.7s' animation='fadeInUp'>
                                    <div className={`single-feature ${classes.singleFeature}`}>
                                        <img className={classes.singleFeatureImage} src={require("../../_assets/img/IMG2-battery-up-application-smartphone.png")} alt="Statut de votre batterie en temps réel" />
                                        <div style={{ textAlign: "center" }}>
                                            <h4 style={{ marginTop: "0",color: "#3d009e" }}>Compteur d'énergie Modbus RTU</h4>
                                            <p style={{ lineHeight: "20px" }}>Disponibles dans notre shop, ils peuvent aussi être commandés séparément chez un fournisseur de votre choix.</p>
                                        </div>
                                    </div>
                                </ReactWOW>
                            </div> */}

                            {/* <div className={`col-xs-12 col-md-6 col-lg-4 ${classes.singleFeatureWrapper}`}>
                                <ReactWOW delay='0.7s' animation='fadeInUp'>
                                    <div className={`single-feature ${classes.singleFeature}`}>
                                        <img className={classes.singleFeatureImage} src={require("../../_assets/img/IMG2-battery-up-application-smartphone.png")} alt="Statut de votre batterie en temps réel" />
                                        <div style={{ textAlign: "center" }}>
                                            <h4 style={{ marginTop: "0",color: "#3d009e" }}>Flux énergétiques détaillés de votre installation</h4>
                                            <p style={{ lineHeight: "20px" }}>Visualisez l’évolution énergétique de votre installation. Suivez votre consommation et optimisez votre gestion énergétique en toute simplicité.</p>
                                        </div>
                                    </div>
                                </ReactWOW>
                            </div> */}
                            <img src={require("../../_assets/img/IMG-maison-consommateurs-batteries-battery-up.png")} alt={t('homepage:Features.img-house-batteries-alt')} style={{ marginLeft: 'auto',marginRight: 'auto' }} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation(['common','homepage'])(Features);