import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';

import classes from './MainFooter.module.scss';

class MainFooter extends Component {
    render() {
        const { t } = this.props;

        return (
            <footer id="footer" className={classes.MainFooter}>
                <div className={`footer-top ${classes.footerTop}`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-lg-12 ${classes.footerPoweredBy}`}>
                                <ReactWOW delay='0.3s' animation='fadeInUp'>
                                    <img src={require("../../../_assets/img/Logo-Battery-Up-vector.png")} alt={t('common:general.logo-alt')} />
                                    <p>{t('footer:powered-by')}</p>
                                    <img src={require("../../../_assets/img/Logo-solar-tech-vector.png")} alt={t('common:general.logo-wun-alt')} />
                                </ReactWOW>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <ReactWOW delay='0.3s' animation='fadeInUp'>
                                    <p>Wattuneed est active dans le domaine du solaire photovoltaïque connecté au réseau depuis 10 ans.<br/>
                                        Face à la demande grandissante concernant des applications non connectées au réseau, nous avons développé une branche spécialisée dans l'analyse et la mise au point d'installations solaires autonomes.<br/>
                                        Aujourd'hui nous proposons des kits solaires standards assemblés et testés dans nos ateliers : les connectiques sont serties sur les câbles, les coffrets sont montés, la capacité des batteries est vérifiée...<br/>
                                        Les kits solaires sont livrés testés et prêts à l'emploi. Nous proposons de nombreux kits photovoltaiques différents : luminaires, bateaux, camping-cars, maison de vacances, cabane de chasse, hangars isolés, ...
                                    </p>
                                </ReactWOW>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-4 col-lg-4">
                                <div className="single-contact-info wow fadeInUp" data-wow-delay=".4s">
                                    <img src={require("../../../_assets/img/IMG-icone-battery-up-adresse.png")} alt={t('common:icons.address')} />
                                    <h3 style={{ color: "white" }}>{t('common:contact.address')}:</h3>
                                    <p>12 rue Henripré<br />4821 Andrimont<br />{t('common:contact.belgium')}</p>
                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                                <div className="single-contact-info">
                                    <img src={require("../../../_assets/img/IMG-icone-battery-up-email.png")} alt={t('common:icons.email')} />
                                    <h3 style={{ color: "white" }}>{t('common:contact.email')}:</h3>
                                    <p><a style={{ color: "#646464" }} href="mailto:info@wattuneed.com">info@wattuneed.com</a></p>
                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-4 wow fadeInUp" data-wow-delay=".6s">
                                <div className="single-contact-info">
                                    <img src={require("../../../_assets/img/IMG-icone-battery-up-telephone.png")} alt={t('common:icons.phone')} />
                                    <h3 style={{ color: "white" }}>{t('common:contact.phone')}:</h3>
                                    <p>+32 87 45 00 34</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- END FOOTER TOP --> */}

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className={classes.socialMedia}>
                                    <li>
                                        <a href="#">
                                            <img src={require("../../../_assets/img/icone-facebook-battery-up-IMG.png")} alt="Logo Facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={require("../../../_assets/img/icone-instagram-battery-up-IMG.png")} alt="Logo Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={require("../../../_assets/img/icone-shop-battery-up-IMG.png")} alt={t('common:icons.shop')} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <p>SITEMAP</p> */}
                                <ul className={classes.sitemap}>
                                    <li className="nav-item">
                                        <Link to="/">
                                            <span className="nav-link">{t('common:nav.home')}</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/monitoring">
                                            <span className="nav-link">{t('common:nav.concept')}</span>
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/application">
                                            <span className="nav-link">{t('common:nav.app')}</span>
                                        </Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link to="/release-notes">
                                            <span className="nav-link">{t('common:nav.release-notes')}</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/glossary">
                                            <span className="nav-link">{t('common:nav.glossary')}</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact">
                                            <span className="nav-link">{t('common:nav.contact')}</span>
                                        </Link>
                                    </li>
                                </ul>
                                {/* <ul className={classes.sitemap}>
                                        <li className="nav-item">
                                            <Link to="/terms-of-use">
                                                <span className="nav-link">Terms of use</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/cookies-policy">
                                                <span className="nav-link">Cookies policy</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/privacy-policy">
                                                <span className="nav-link">Privacy policy</span>
                                            </Link>
                                        </li>
                                    </ul> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ul className={classes.sitemap}>
                                    <li className="nav-item">
                                        <Link to="/downloads">
                                            <span className="nav-link">{t('common:nav.downloads')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <p className="copyright">Copyright 2021 © {t('common:general.all-rights')}</p>
                            </div>
                        </div>
                        {/* <!-- END ROW --> */}
                    </div>
                    {/* <!-- END CONTAINER --> */}
                </div>
                {/* <!-- END FOOTER BOTTOM --> */}
            </footer>
        );
    }
}

export default withTranslation(['common','footer'])(MainFooter);