import React,{ useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import RecursiveContainer from './RecursiveContainer';
import classes from './AddScenarioContainer.module.scss';

function AddScenarioContainer() {
    const [data,setData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: ""
    });
    const [currentStep,setCurrentStep] = useState(0);

    const makeRequest = (formData) => {
        console.log("Form Submitted",formData);
    };

    const handleNextStep = (newData,final = false) => {
        setData((prev) => ({ ...prev,...newData }));

        if (final) {
            makeRequest(newData);
            return;
        }

        setCurrentStep((prev) => prev + 1);
    };

    const handlePrevStep = (newData) => {
        setData((prev) => ({ ...prev,...newData }));
        setCurrentStep((prev) => prev - 1);
    };

    const steps = [
        <StepOne next={handleNextStep} data={data} />,
        <StepTwo next={handleNextStep} prev={handlePrevStep} data={data} />
    ];

    console.log("data",data);

    return <div className="App">{steps[currentStep]}</div>;
}

const stepOneValidationSchema = Yup.object({
    // first_name: Yup.string().required().label("First name"),
    // last_name: Yup.string().required().label("Last name")
    test: Yup.string().required(<p className="errorMessage">test validation text</p>)
});

const StepOne = (props) => {
    const handleSubmit = (values) => {
        console.log(values);
        props.next(values);
    };

    const formik = useFormik({
        initialValues: {
        },
        // onSubmit: { handleSubmit },
        onSubmit: values => {
            console.log(values);
            props.next(values);
        },
        // onSubmit: values => {
        //     alert(JSON.stringify(values,null,2));
        // },
        validationSchema: stepOneValidationSchema
    });
    // console.log(formik,metricsFields)
    return (
        // <Formik
        //     validationSchema={stepOneValidationSchema}
        //     initialValues={props.data}
        //     onSubmit={handleSubmit}
        // >
        //     {() => (
        //         <Form>
        //             <p>First Name</p>
        //             <Field name="first_name" />
        //             <ErrorMessage name="first_name" />

        //             <p>Last Name</p>
        //             <Field name="last_name" />
        //             <ErrorMessage name="last_name" />

        //             <button type="submit">Next</button>
        //         </Form>
        //     )}
        // </Formik>
        <form className={classes.testForm} onSubmit={formik.handleSubmit}>
            <RecursiveContainer validationSchema={stepOneValidationSchema} config={metricsFields} formik={formik} />
            <button type="submit">Next</button>
            {/* <button type="submit">Submit</button> */}
        </form>

    );
};

const stepTwoValidationSchema = Yup.object({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().label("Password")
});

const StepTwo = (props) => {
    const handleSubmit = (values) => {
        props.next(values,true);
    };

    return (
        <Formik
            validationSchema={stepTwoValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <p>Email</p>
                    <Field name="email" />
                    <ErrorMessage name="email" />

                    <p>Password</p>
                    <Field name="password" />
                    <ErrorMessage name="password" />

                    <button type="button" onClick={() => props.prev(values)}>
                        Back
                    </button>
                    <button type="submit">Submit</button>
                </Form>
            )}
        </Formik>
    );
};
export default AddScenarioContainer;