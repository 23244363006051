import axios from '../axios';

export const weatherService = {
    getFullWeather,
    getFullWeatherMongo,
    // getFullWeather,
};

function getFullWeather(zone,offset) {
    // console.log(zone);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/weather?zone=' + zone + "&offset=" + offset,config)
        .then(response => {
            // console.log("Response getFullWeather");
            // console.log(response);
            if (response.data) {
                // console.log("Webhook successfully created !");
                // console.log(response);

                let weather = response.data;

                return weather;
            }
        });
}

function getFullWeatherMongo(zone,offset) {
    // console.log(zone);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/weather-mongo?zone=' + zone + "&offset=" + offset,config)
        .then(response => {
            // console.log("Response getFullWeather");
            // console.log(response);
            if (response.data) {
                // console.log("Webhook successfully created !");
                // console.log(response);

                let weather = response.data;

                return weather;
            }
        });
}
