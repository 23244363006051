import axios from '../axios';

export const webhookService = {
    addWebhook,
    updateWebhook,
    getByModuleId,
};

function addWebhook(values) {
    console.log(values);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/add-webhook',values,config)
        .then(response => {
            console.log("Response addWebhook");
            console.log(response);
            if (response.data) {
                console.log("Webhook successfully created !");
                console.log(response);

                let webhook = response.data.webhook;

                return webhook;
            }
        });
}

function updateWebhook(values) {
    console.log(values);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-webhook',values,config)
        .then(response => {
            console.log("Response addWebhook");
            console.log(response);
            if (response.data) {
                console.log("Webhook successfully edited !");
                console.log(response);

                let webhook = response.data.webhook;

                return webhook;
            }
        });
}

function getByModuleId(moduleId) {
    console.log('getAll in protocol service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/module-webhook?id=' + moduleId,config)
        .then(response => {
            console.log("Response getAll protocols");
            console.log(response);
            if (response.data) {
                console.log("Webhook successfully retrieved !");

                let webhook = response.data.webhook;

                return webhook;
            }
        });
}
