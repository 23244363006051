import axios from '../axios';
import axiosreal from 'axios';

export const addressService = {
    addAddress,
    editAddress,
    getAddressById,
    delete: _delete,
    // deleteAddress,
    getLatLng,
};

function addAddress(data) {
    console.log("addAddress in service");
    console.log(data);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/address',data,config)
        .then(response => {
            console.log("Response addAddress");
            console.log(response);
            if (response.data) {
                console.log("Address successfully created !");
                console.log(response);

                let address = response.data.address;

                return address;
            }
        });
}

function editAddress(data) {
    console.log("editAddress in service");
    console.log(data);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-address',data,config)
        .then(response => {
            console.log("Response addAddress");
            console.log(response);
            if (response.data) {
                console.log("Address successfully created !");
                console.log(response);

                let address = response.data.address;

                return address;
            }
        });
}

function getAddressById(id) {
    console.log('getAddressById in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/address?id=' + id,config)
        .then(response => {
            console.log(response);
            if (response.data) {
                console.log("Address successfully retrieved !");
                console.log(response.data.address);

                let address = response.data.address;

                return address;
            }
        });
}

function _delete(targetId) {
    console.log('delete in address service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/delete-address?id=' + targetId,config)
        .then(response => {
            console.log("Response delete");
            console.log(response);
            if (response.data) {
                console.log("Address successfully deleted !");
                console.log(response);

                let message = "Address successfully deleted !";

                return message;
            }
        });
}

// function deleteAddress(targetId) {
//     console.log('delete in address service');
//     const user = JSON.parse(localStorage.getItem('appState')).user;
//     const token = user.auth_token;
//     if (typeof token === 'undefined') { return }
//     const config = {
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${token}`,
//         }
//     };

//     return axios.get('/api/abababab/?id=' + targetId,config)
//         .then(response => {
//             console.log("Response delete");
//             console.log(response);
//             if (response.data) {
//                 console.log("Address successfully deleted !");
//                 console.log(response);

//                 let message = "Address successfully deleted !";

//                 return message;
//             }
//         });
// }

function getLatLng(values) {
    let test = axiosreal.create({});

    // delete headers if existing
    delete test.defaults.headers.common['Accept'];
    delete test.defaults.headers.common['X-Requested-With'];
    delete test.defaults.headers.common['X-CSRF-TOKEN'];

    let address = `${values.line1},${values.street_number} ${values.zipcode} ${values.locality}, ${values.country}`;
    const config = {
        headers: {
            'Accept': 'application/json',
            // 'Authorization': `APIKey wTvLLbdq_q1KYM_CcLVhB_iYDc1THksBWjvN5h8vAdU`,
            // 'Authorization': `APIKey h8ONajOMZEkKvuP_0kG3W7uDJVeMX_PnUPH0YmtHomM`,
        },
        params: {
            'q': address,
            // 'apiKey': 'h8ONajOMZEkKvuP_0kG3W7uDJVeMX_PnUPH0YmtHomM'
            // 'apiKey': '3hvO0YiBJCKJbe1fctUc0aOWsnnbVnF0cbgTtvqM2FE'
            'apiKey': '1XwTdW3CFKypPLVBLmH26hyPJm_Y5iDypDi0kigX4NQ'
        }
    };
    // return axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=wTvLLbdq_q1KYM_CcLVhB_iYDc1THksBWjvN5h8vAdU`,config)
    // fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=wTvLLbdq_q1KYM_CcLVhB_iYDc1THksBWjvN5h8vAdU`)
    // fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=h8ONajOMZEkKvuP_0kG3W7uDJVeMX_PnUPH0YmtHomM`)
    // return axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=h8ONajOMZEkKvuP_0kG3W7uDJVeMX_PnUPH0YmtHomM`,config)
    return test.get(`https://geocode.search.hereapi.com/v1/geocode`,config)
        .then(response => {
            console.log("response recursive getLatLNG");
            console.log(response);
            console.log(values);
            if (response.data.items.length !== 0) {
                let address_coords = response.data.items[0].position;
                address_coords["weather_zone"] = response.data.items[0].address.county;
                console.log("IT SHOULD STOP RIGHT NOW - SUCCESS");
                return address_coords;
            } else {
                let message = "Error address";
                console.log("IT SHOULD STOP RIGHT NOW - ERROR");
                return message;
            }

            // if (values.street_number === "" && values.zipcode === "") {
            //     if (response.data.items.length !== 0) {
            //         let address_coords = response.data.items[0].position;
            //         console.log("IT SHOULD STOP RIGHT NOW - SUCCESS");
            //         return address_coords;
            //     } else {
            //         let message = "Error address";
            //         console.log("IT SHOULD STOP RIGHT NOW - ERROR");
            //         return message;
            //     }
            // } else if (values.street_number === "") {
            //     if (response.data.items.length !== 0) {
            //         let address_coords = response.data.items[0].position;
            //         console.log("IT SHOULD STOP RIGHT NOW - SUCCESS");
            //         return address_coords;
            //     } else {
            //         console.log("IT SHOULD STOP RIGHT NOW - empty line1");
            //         let newValues = {
            //             line1: "",
            //             street_number: "",
            //             zipcode: values.zipcode,
            //             locality: values.locality,
            //             country: values.country,
            //         }
            //         return getLatLng(newValues);
            //     }
            // } else {
            //     if (response.data.items.length !== 0) {
            //         let address_coords = response.data.items[0].position;
            //         console.log("IT SHOULD STOP RIGHT NOW - SUCCESS");
            //         return address_coords;
            //     } else {
            //         console.log("IT SHOULD STOP RIGHT NOW - empty n°");
            //         let newValues = {
            //             line1: values.line1,
            //             street_number: "",
            //             zipcode: values.zipcode,
            //             locality: values.locality,
            //             country: values.country,
            //         }
            //         return getLatLng(newValues);
            //     }
            // }
        });
}

function getLatLngBBBBBB(values) {
    let address = `${values.line1},${values.street_number} ${values.zipcode} ${values.locality}, ${values.country}`;
    // return axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=wTvLLbdq_q1KYM_CcLVhB_iYDc1THksBWjvN5h8vAdU`)
    return axios.get(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=3hvO0YiBJCKJbe1fctUc0aOWsnnbVnF0cbgTtvqM2FE`)
        .then(response => {
            console.log("response getLatLng");
            console.log(response);
            if (response.data.items.length === 0) {
                let message = "Error address";
                return message;
            }
            let address_coords = response.data.items[0].position;
            return address_coords;
            // let country_coords = response.data.items[0].position;
            // values.latitude = country_coords.lat;
            // values.longitude = country_coords.lng;
        });
}