import { userConstants } from '../_constants/userConstants';
import { userService } from '../_services/userService';
import { alertActions } from './alertActions';
import { history } from '../_helpers/history';

export const userActions = {
    login,
    logout,
    forgotPassword,
    register,
    getAll,
    getClientsModules,
    getModules,
    delete: _delete
};

function login(email,password) {
    // console.log('is in action');
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email,password)
            .then(
                appSate => {
                    // history.push('/dashboard');
                    console.log("in user actions appState");
                    console.log(appSate);
                    dispatch(success(appSate));
                    // return appSate;
                    history.push('/dashboard');
                },
                error => {
                    console.log("in user actions error");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );

    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST,user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS,user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE,error } }
}

function forgotPassword(email) {
    // console.log('is in action');
    return dispatch => {
        dispatch(request( email ));

        userService.forgotPassword(email)

    };
function request(email) { return { type: userConstants.FORGOT_PASSWORD_REQUEST,email} }

}

function logout() {
    console.log("logout in actions");
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    // const auth = JSON.parse(localStorage.getItem('appState')).user;
    console.log('is in action');
    return dispatch => {
        dispatch(request(user));
        console.log('User in action');
        console.log(user);

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    // if(user.roles.includes('admin') || user.roles.includes('super-admin')) {
                    //     history.push('/register-module?new=true');
                    // }else {

                    // }
                    // history.push('/dashboard');
                    console.log("new user");
                    console.log(user.id);
                    console.log("auth");
                    console.log(JSON.parse(localStorage.getItem('appState')).user);
                    history.push('/register-module?new=true&id='+user.id);
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST,user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS,user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE,error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS,users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE,error } }
}

function getClientsModules() {
    return dispatch => {
        dispatch(request());

        userService.getClientsModules()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS,users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE,error } }
}

function getModules() {
    return dispatch => {
        dispatch(request());

        userService.getModules()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS,users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE,error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id,error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST,id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS,id } }
    function failure(id,error) { return { type: userConstants.DELETE_FAILURE,id,error } }
}
