// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.AdminBlock_AdminBlock__1eb6S {\n  background-color: #fff;\n  margin: 15px 0 30px;\n  flex: 1 1;\n  text-align: left; }\n  .AdminBlock_AdminBlock__1eb6S h5 {\n    text-align: left;\n    font-size: 18px;\n    font-weight: 700; }\n    .AdminBlock_AdminBlock__1eb6S h5.AdminBlock_AdminBlockTitle__28IcG {\n      font-size: 22px;\n      margin-bottom: 20px; }\n    .AdminBlock_AdminBlock__1eb6S h5 .AdminBlock_AdminBlockIcon__3NPNS {\n      height: 40px;\n      margin-right: 15px; }\n    .AdminBlock_AdminBlock__1eb6S h5 svg {\n      margin-right: 10px; }\n", ""]);
// Exports
exports.locals = {
	"AdminBlock": "AdminBlock_AdminBlock__1eb6S",
	"AdminBlockTitle": "AdminBlock_AdminBlockTitle__28IcG",
	"AdminBlockIcon": "AdminBlock_AdminBlockIcon__3NPNS"
};
module.exports = exports;
