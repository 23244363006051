// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginPage_container__10SYf {\n  max-width: 1500px !important; }\n", ""]);
// Exports
exports.locals = {
	"container": "LoginPage_container__10SYf"
};
module.exports = exports;
