import React,{ useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { useDispatch,useSelector } from 'react-redux';

// import { userActions } from '../_actions';
// import BatteryUp from '../_layouts/BatteryUp';
import { history } from '../_helpers/history';

// import useScript from '../_hooks/useScript';
// import ScriptTag from 'react-script-tag';
// import { Helmet } from "react-helmet";

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';
import Navigation from '../_components/Layout/Navigation/Navigation';
import Slider from '../_components/UI/Slider/Slider';
import SolutionHome from '../_components/Homepage/SolutionHome';
import AppUsers from '../_components/Homepage/AppUsers';
import ControlBatteries from '../_components/Homepage/ControlBatteries';
import HowItWorks from '../_components/Homepage/HowItWorks';
import Features from '../_components/Homepage/Features';
import Needs from '../_components/Homepage/Needs';
import SmartHome from '../_components/Homepage/SmartHome';
import GreatAdvantages from '../_components/Homepage/GreatAdvantages';
import PoweredBy from '../_components/Homepage/PoweredBy';
import Counter from '../_components/Homepage/Counter';
import MainFooter from '../_components/Layout/Footer/MainFooter';
import GoTop from '../_components/UI/GoTop/GoTop';

import '../_assets/css/bootstrap.min.css';
import '../_assets/css/animate.css';
// import '../_assets/css/icofont.css';
// import '../_assets/css/owl.carousel.min.css';
import '../_assets/css/owl.theme.default.css';
import '../_assets/css/magnific-popup.css';
import '../_assets/css/style.css';
import '../_assets/css/responsive.css';
import '../_assets/css/color/color-default.css';

import classes from './HomePage.module.scss';

/*function HomePage() {
    console.log("In homepage");
    const users = useSelector(state => state.users);
    // const user = useSelector(state => state.authentication.user.user);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    // console.log("appState");
    // console.log(JSON.parse(localStorage.getItem('appState')).user);
    console.log("user homepage");
    console.log(user.firstName + ' ' + user.surname);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(userActions.getAll());
    // }, []);

    function handleDeleteUser(id) {
        dispatch(userActions.delete(id));
    }

    return (
        <div>
        <BatteryUp>
            <h1>Hi {user.firstName}!</h1>
            <p>You're logged in with React Hooks!!</p>
            <h3>All registered users:</h3>
            {users.loading && <em>Loading users...</em>}
            {users.error && <span classNameName="text-danger">ERROR: {users.error}</span>}
            {users.items &&
                <ul>
                    {users.items.map((user,index) =>
                        <li key={user.id}>
                            {user.firstName + ' ' + user.lastName}
                            {
                                user.deleting ? <em> - Deleting...</em>
                                    : user.deleteError ? <span classNameName="text-danger"> - ERROR: {user.deleteError}</span>
                                        : <span> - <a onClick={() => handleDeleteUser(user.id)} classNameName="text-primary">Delete</a></span>
                            }
                        </li>
                    )}
                </ul>
            }
            <p>
                <Link to="/login">Logout</Link>
            </p>
        </BatteryUp>
        </div>
    );
}*/
function HomePage() {
    if (window.location.pathname === "/") {
        history.push('/home');
    }
    console.log("appstate in homepage");
    console.log(JSON.parse(localStorage.getItem('appState')));
    return (
        // <BatteryUpPage sidebar={false}>
        <GenericPage>
            <Slider />
            <AppUsers />
            <SolutionHome />
            <HowItWorks />
            {/* <ControlBatteries /> */}
            <Features />
            {/* <Needs /> */}
            {/* <SmartHome /> */}
            <GreatAdvantages />
            <PoweredBy />

            {/* <!-- START COUNTER STATS SECTION --> */}
            {/* <Counter /> */}
            {/* <!-- END COUNTER STATS SECTION --> */}

            {/* <!-- START APP SCREENSHOTS SECTION --> */}
            {/* <section id="screenshots" className="app-screenshots-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>App Screenshots</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="screenshot-carousel owl-carousel owl-theme wow fadeInUp" data-wow-delay=".6s">
                                <div className="screenshot-item">
                                    <a href="#screenshot_details" className="screenshot-image" data-toggle="modal" data-target="#screenshot_details">
                                        <img src={require("../_assets/img/screenshot/1.png")} alt="Screenshot Image" />
                                    </a>
                                </div>

                                <div className="screenshot-item">
                                    <a href="#screenshot_details" className="screenshot-image" data-toggle="modal" data-target="#screenshot_details">
                                        <img src={require("../_assets/img/screenshot/2.png")} alt="Screenshot Image" />
                                    </a>
                                </div>

                                <div className="screenshot-item">
                                    <a href="#screenshot_details" className="screenshot-image" data-toggle="modal" data-target="#screenshot_details">
                                        <img src={require("../_assets/img/screenshot/3.png")} alt="Screenshot Image" />
                                    </a>
                                </div>

                                <div className="screenshot-item">
                                    <a href="#screenshot_details" className="screenshot-image" data-toggle="modal" data-target="#screenshot_details">
                                        <img src={require("../_assets/img/screenshot/4.png")} alt="Screenshot Image" />
                                    </a>
                                </div>

                                <div className="screenshot-item">
                                    <a href="#screenshot_details" className="screenshot-image" data-toggle="modal" data-target="#screenshot_details">
                                        <img src={require("../_assets/img/screenshot/5.png")} alt="Screenshot Image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal right screenshots-modal fade" id="screenshot_details">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="screenshot-details-container">
                                    <div className="row">
                                        <div className="col-md-5 col-lg-5">
                                            <img src={require("../_assets/img/screenshot/1.png")} alt="Screenshot Image" />
                                        </div>

                                        <div className="col-md-7 col-lg-7">
                                            <div className="screenshot-details-text">
                                                <h3 className="title">Screenshots Title</h3>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                <ul>
                                                    <li>
                                                        <i className="icofont icofont-check"></i>
                                                            High Resolution
                                                        </li>
                                                    <li>
                                                        <i className="icofont icofont-check"></i>
                                                            Unique Design
                                                        </li>
                                                    <li>
                                                        <i className="icofont icofont-check"></i>
                                                            Full Responsive
                                                        </li>
                                                    <li>
                                                        <i className="icofont icofont-check"></i>
                                                            Clean Codes
                                                        </li>
                                                </ul>

                                                <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                                <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END APP SCREENSHOTS SECTION --> */}

            {/* <!-- START TESTIMONIAL SECTION --> */}
            {/* <section id="testimonial" className="testimonial-section angle-testimonial-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-carousel owl-carousel owl-theme">
                                <div className="testimonial-item">
                                    <i className="icofont-quote-left"></i>
                                    <p className="description">
                                        <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam justo neque, aliquet sit amet elementum vel, vehicula eget eros.</q>
                                    </p>
                                    <div className="testimonial-content">
                                        <div className="pic">
                                            <img src={require("../_assets/img/client/1.png")} alt="client Image" />
                                        </div>
                                        <h3 className="title">Tareq</h3>
                                        <span className="post">UI / UX Designer</span>
                                    </div>
                                </div>

                                <div className="testimonial-item">
                                    <i className="icofont-quote-left"></i>
                                    <p className="description">
                                        <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam justo neque, aliquet sit amet elementum vel, vehicula eget eros.</q>
                                    </p>
                                    <div className="testimonial-content">
                                        <div className="pic">
                                            <img src={require("../_assets/img/client/2.png")} alt="Client Image" />
                                        </div>
                                        <h3 className="title">Muttakin</h3>
                                        <span className="post">Web Developer</span>
                                    </div>
                                </div>

                                <div className="testimonial-item">
                                    <i className="icofont-quote-left"></i>
                                    <p className="description">
                                        <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam justo neque, aliquet sit amet elementum vel, vehicula eget eros.</q>
                                    </p>
                                    <div className="testimonial-content">
                                        <div className="pic">
                                            <img src={require("../_assets/img/client/3.png")} alt="Client Image" />
                                        </div>
                                        <h3 className="title">Miranda Joy</h3>
                                        <span className="post">Web Developer</span>
                                    </div>
                                </div>

                                <div className="testimonial-item">
                                    <i className="icofont-quote-left"></i>
                                    <p className="description">
                                        <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam justo neque, aliquet sit amet elementum vel, vehicula eget eros.</q>
                                    </p>
                                    <div className="testimonial-content">
                                        <div className="pic">
                                            <img src={require("../_assets/img/client/4.png")} alt="Client Image" />
                                        </div>
                                        <h3 className="title">Kristina</h3>
                                        <span className="post">Web Developer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END TESTIMONIAL SECTION --> */}

            {/* <!-- START PRICING TABLE SECTION --> */}
            {/* <section id="pricing" className="pricing-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>Pricing Table</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-4">
                            <div className="single-price-package wow fadeInUp" data-wow-delay=".3s">
                                <div className="price-header">
                                    <h3>Standard</h3>
                                    <h4 className="price-value">
                                        <sup className="text-bottom">$</sup>
                                        <span className="text-large">33</span>
                                        <span className="duration">/ Per Month</span>
                                    </h4>
                                </div>

                                <ul className="price-list">
                                    <li>UI / UX Design</li>
                                    <li>Web Design</li>
                                    <li>App Design</li>
                                    <li className="disabled">Web Developement</li>
                                    <li className="disabled">Wordpress Theme</li>
                                    <li className="disabled">24 / 7 Support</li>
                                </ul>

                                <div className="price-footer">
                                    <a href="#" className="purchase-btn">Purchase</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4">
                            <div className="single-price-package personal-price-package wow fadeInUp" data-wow-delay=".4s">
                                <div className="price-header">
                                    <h3>Personal</h3>
                                    <h4 className="price-value">
                                        <sup className="text-bottom">$</sup>
                                        <span className="text-large">69</span>
                                        <span className="duration">/Per Month</span>
                                    </h4>
                                </div>

                                <ul className="price-list">
                                    <li>UI / UX Design</li>
                                    <li>Web Design</li>
                                    <li>App Design</li>
                                    <li>Web Developement</li>
                                    <li className="disabled">Wordpress Theme</li>
                                    <li className="disabled">24 / 7 Support</li>
                                </ul>

                                <div className="price-footer">
                                    <a href="#" className="purchase-btn">Purchase</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4">
                            <div className="single-price-package wow fadeInUp" data-wow-delay=".5s">
                                <div className="price-header">
                                    <h3>Business</h3>
                                    <h4 className="price-value">
                                        <sup>$</sup>
                                        <span className="text-large">99</span>
                                        <span className="duration">/Per Month</span>
                                    </h4>
                                </div>

                                <ul className="price-list">
                                    <li>UI / UX Design</li>
                                    <li>Web Design</li>
                                    <li>App Design</li>
                                    <li>Web Developement</li>
                                    <li>Wordpress Theme</li>
                                    <li>24 / 7 Support</li>
                                </ul>

                                <div className="price-footer">
                                    <a href="#" className="purchase-btn">Purchase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END PRICING TABLE --> */}

            {/* <!-- START FAQS SECTION --> */}
            {/* <section id="faqs" className="faqs-section angle-gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>Frequently Asked Questions</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-5">
                            <div className="text-center wow fadeInUp" data-wow-delay=".4s">
                                <img src={require("../_assets/img/faq-img.png")} alt="Faq Image" />
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className="accordion js-accordion wow fadeInUp" data-wow-delay=".6s">
                                <div className="accordion__item js-accordion-item active">
                                    <div className="accordion-header js-accordion-header">Question One</div>
                                    <div className="accordion-body js-accordion-body">
                                        <div className="accordion-body__contents">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in?</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion__item js-accordion-item">
                                    <div className="accordion-header js-accordion-header">Question Two</div>
                                    <div className="accordion-body js-accordion-body">
                                        <div className="accordion-body__contents">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion__item js-accordion-item">
                                    <div className="accordion-header js-accordion-header">Question Three</div>
                                    <div className="accordion-body js-accordion-body">
                                        <div className="accordion-body__contents">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion__item js-accordion-item">
                                    <div className="accordion-header js-accordion-header">Question Four</div>
                                    <div className="accordion-body js-accordion-body">
                                        <div className="accordion-body__contents">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion__item js-accordion-item">
                                    <div className="accordion-header js-accordion-header">Question Six</div>
                                    <div className="accordion-body js-accordion-body">
                                        <div className="accordion-body__contents">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse. Nostrum voluptatum et repudiandae vel sed, explicabo in.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END FAQS SECTION --> */}

            {/* <!-- START TEAM SECTION --> */}
            {/* <section id="team" className="team-section angle-sp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>Our Awesome Team</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-member wow fadeInUp" data-wow-delay=".3s">
                                <div className="member-img">
                                    <img src={require("../_assets/img/team/1.jpg")} alt="Team Member Image" />
                                    <div className="overlay">
                                        <div className="member-info">
                                            <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                            <div className="social-links">
                                                <a href="#">
                                                    <i className="icofont-facebook"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-twitter"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="member-name">Steve Roberts</h4>
                                <p className="designation">CEO & Fouder</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-member wow fadeInUp" data-wow-delay=".4s">
                                <div className="member-img">
                                    <img src={require("../_assets/img/team/2.jpg")} alt="Team Member Image" />
                                    <div className="overlay">
                                        <div className="member-info">
                                            <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                            <div className="social-links">
                                                <a href="#">
                                                    <i className="icofont-facebook"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-twitter"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="member-name">John Marshall</h4>
                                <p className="designation">UI/UX Designer</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-member wow fadeInUp" data-wow-delay=".5s">
                                <div className="member-img">
                                    <img src={require("../_assets/img/team/3.jpg")} alt="Team Member Image" />
                                    <div className="overlay">
                                        <div className="member-info">
                                            <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                            <div className="social-links">
                                                <a href="#">
                                                    <i className="icofont-facebook"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-twitter"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="member-name">Michael Cartney</h4>
                                <p className="designation">Front-End Developer</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-member wow fadeInUp" data-wow-delay=".6s">
                                <div className="member-img">
                                    <img src={require("../_assets/img/team/4.jpg")} alt="Team Member Image" />
                                    <div className="overlay">
                                        <div className="member-info">
                                            <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                            <div className="social-links">
                                                <a href="#">
                                                    <i className="icofont-facebook"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-twitter"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="icofont-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="member-name">John Marshall</h4>
                                <p className="designation">UI/UX Designer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END START TEAM SECTION --> */}

            {/* <!-- START APP DOWNLOAD SECTION --> */}
            {/* <section id="app-download" className="app-download-section angle-download-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>NOW AVAILABLE</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-download-content wow fadeInUp" data-wow-delay=".6s">
                                <a href="#" className="download-btn">
                                    <i className="icofont-brand-apple"></i>
                                    <span>
                                        available on
                                            <span className="large-text">App Store</span>
                                    </span>
                                </a>

                                <a href="#" className="download-btn">
                                    <i className="icofont-brand-android-robot"></i>
                                    <span>
                                        available on
                                            <span className="large-text">Play Store</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END APP DOWNLOAD SECTION --> */}

            {/* <!-- START BLOG SECTION --> */}
            {/* <section id="blog" className="blog-section angle-sp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>Latest Blog Post</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-4">
                            <div className="single-blog-post sm-one-col wow fadeInUp" data-wow-delay=".4s">
                                <div className="blog-img">
                                    <a href="blog-details-right-sidebar.html">
                                        <img src={require("../_assets/img/blog/1.jpg")} alt="Blog Post Image" />
                                    </a>
                                    <div className="blog-date">20 Sep, 2017</div>
                                </div>

                                <div className="blog-info">
                                    <h6 className="blog-title">
                                        <a href="blog-details-right-sidebar.html">Awesome app landing page</a>
                                    </h6>
                                    <div className="post-admin">
                                        By <a href="#">admin</a> /
                                            <a href="#">12</a> Comments
                                        </div>
                                    <p>Aliquet id lacinia ultricies odio, neque ipsum facilisis parturient.
                                        Erat tristique nonummy etiam, fusce est porttitor.</p>
                                    <a className="read-more-btn" href="blog-details-right-sidebar.html">
                                        Read More
                                            <i className="icofont-swoosh-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4">
                            <div className="single-blog-post sm-one-col wow fadeInUp" data-wow-delay=".5s">
                                <div className="blog-img">
                                    <a href="blog-details-right-sidebar.html">
                                        <img src={require("../_assets/img/blog/2.jpg")} alt="Blog Post Image" />
                                    </a>
                                    <div className="blog-date">20 Sep, 2017</div>
                                </div>

                                <div className="blog-info">
                                    <h6 className="blog-title">
                                        <a href="blog-details-right-sidebar.html">Awesome app</a>
                                    </h6>
                                    <div className="post-admin">
                                        By <a href="#">admin</a> /
                                            <a href="#">12</a> Comments
                                        </div>
                                    <p>Aliquet id lacinia ultricies odio, neque ipsum facilisis parturient.
                                        Erat tristique nonummy etiam, fusce est porttitor.</p>
                                    <a className="read-more-btn" href="blog-details-right-sidebar.html">
                                        Read More
                                            <i className="icofont-swoosh-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4">
                            <div className="single-blog-post sm-one-col wow fadeInUp" data-wow-delay=".6s">
                                <div className="blog-img">
                                    <a href="blog-details-right-sidebar.html">
                                        <img src={require("../_assets/img/blog/3.jpg")} alt="Blog Post Image" />
                                    </a>
                                    <div className="blog-date">20 Sep, 2017</div>
                                </div>

                                <div className="blog-info">
                                    <h6 className="blog-title">
                                        <a href="blog-details-right-sidebar.html">Awesome app landing page</a>
                                    </h6>
                                    <div className="post-admin">
                                        By <a href="#">admin</a> /
                                            <a href="#">12</a> Comments
                                        </div>
                                    <p>Aliquet id lacinia ultricies odio, neque ipsum facilisis parturient.
                                        Erat tristique nonummy etiam, fusce est porttitor.</p>
                                    <a className="read-more-btn" href="blog-details-right-sidebar.html">
                                        Read More
                                            <i className="icofont-swoosh-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END BLOG SECTION --> */}

            {/* <!-- START CONTACT SECTION --> */}
            {/* <section id="contact" className="contact-section gray-bg angle-contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title wow fadeInUp" data-wow-delay=".3s">
                                <h2>Get in Touch</h2>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-lg-4">
                            <div className="single-contact-info wow fadeInUp" data-wow-delay=".4s">
                                <i className="icofont-location-pin"></i>
                                <h3>Office Address:</h3>
                                <p>2750 Quadra Street Victoria, Canada.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                            <div className="single-contact-info">
                                <i className="icofont-envelope"></i>
                                <h3>E-mail Address:</h3>
                                <p>heroapp@gmail.com</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 wow fadeInUp" data-wow-delay=".6s">
                            <div className="single-contact-info">
                                <i className="icofont icofont-phone"></i>
                                <h3>Phone Number:</h3>
                                <p>+324-9442-515</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="contact-form wow fadeInUp" data-wow-delay=".9s">
                                <form id="contactForm">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input type="text" className="form-control" required data-error="Please enter your name" placeholder="Your Name" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>E-mail</label>
                                                <input type="email" className="form-control" required data-error="Please enter your email" placeholder="Email Address" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" className="form-control" required data-error="Please enter your subject" placeholder="Your subject" />
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea className="form-control" id="message" rows="6" required data-error="Write your message" placeholder="Enter your message here..."></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>

                                    <div className="text-center">
                                        <button type="submit" className="default-button">
                                            Send Message
                                                <i className="icofont-arrow-right"></i>
                                        </button>
                                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END CONTACT SECTION--> */}

            {/* <!-- START FOOTER AREA --> */}
            {/* <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ReactWOW delay='0.3s' animation='fadeInUp'>
                                    <div className="section-title">
                                        <h2>Powered By</h2>
                                        <p>LOGO WUN</p>
                                        <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit amet, consectetur adipisicing incididunt.</p>
                                    </div>
                                </ReactWOW>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="single-contact-info wow fadeInUp" data-wow-delay=".4s">
                                    <i className="icofont-location-pin"></i>
                                    <h3>Adresse:</h3>
                                    <p>12 rue Henripré, 4821 Andrimont, Belgique</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                                <div className="single-contact-info">
                                    <i className="icofont-envelope"></i>
                                    <h3>Email:</h3>
                                    <p>info@wattuneed.com</p>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 wow fadeInUp" data-wow-delay=".6s">
                                <div className="single-contact-info">
                                    <i className="icofont icofont-phone"></i>
                                    <h3>Téléphone:</h3>
                                    <p>+32 87 45 00 34</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6">
                                <p className="copyright">Copyright 2019 © all rights by EnvyTheme</p>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <ul className="social-links">
                                    <li>Follow us :</li>
                                    <li>
                                        <a href="#">
                                            <i className="icofont-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icofont-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icofont-google-plus"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icofont-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icofont-pinterest"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
            {/* <!-- END FOOTER AREA --> */}
            {/* <GoTop scrollStepInPx="50" delayInMs="16.66" /> */}

            {/* </BatteryUpPage> */}
        </GenericPage>
    );
}

export { HomePage };
