// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../_assets/img/IMG-fond-texte-s-enregister.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.RegisterModuleForm_formBackground__pfHjs {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: contain;\n  padding: 10px;\n  margin: 0 10px; }\n  @media only screen and (min-width: 640px) {\n    .RegisterModuleForm_formBackground__pfHjs {\n      padding: 20px;\n      margin: 0 20px; } }\n  @media only screen and (min-width: 1024px) {\n    .RegisterModuleForm_formBackground__pfHjs {\n      padding: 100px;\n      margin: 0 150px; } }\n  .RegisterModuleForm_formBackground__pfHjs .RegisterModuleForm_formWrapper__2oCF8 {\n    background: #ffffff none repeat scroll 0 0;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);\n    padding: 60px 30px; }\n\n.RegisterModuleForm_fieldHelp__2Uc2Y {\n  color: gray;\n  font-size: 13px;\n  line-height: 16px; }\n  .RegisterModuleForm_fieldHelp__2Uc2Y.RegisterModuleForm_fieldExample__3Drno {\n    font-style: italic;\n    margin-bottom: 28px; }\n", ""]);
// Exports
exports.locals = {
	"formBackground": "RegisterModuleForm_formBackground__pfHjs",
	"formWrapper": "RegisterModuleForm_formWrapper__2oCF8",
	"fieldHelp": "RegisterModuleForm_fieldHelp__2Uc2Y",
	"fieldExample": "RegisterModuleForm_fieldExample__3Drno"
};
module.exports = exports;
