import React,{ useEffect } from 'react';
import './App.scss';

import { Router,Route,Switch,Redirect } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUsers,faCogs,faTachometerAlt,faUser,faIndent,faOutdent,faBolt,faUserCog,faThermometerHalf,faThermometerEmpty,faThermometerFull,faCloudSun,faChartLine,faTrashAlt,faEdit,faVolumeUp,faScroll } from '@fortawesome/free-solid-svg-icons';
import { faIdBadge } from '@fortawesome/free-regular-svg-icons';

import './_assets/css/icofont.css';

import { history } from './_helpers/history';
import { alertActions } from './_actions/alertActions';
import { PrivateRoute } from './_components/Routes/PrivateRoute';
import { RoleRoute } from './_components/Routes/RoleRoute';
import { HomePage } from './_pages/HomePage';
import MonitoringPage from './_pages/MonitoringPage';
import AppPage from './_pages/AppPage';
import ReleaseNotesPage from './_pages/ReleaseNotesPage';
import GlossaryPage from './_pages/GlossaryPage';
import ContactPage from './_pages/ContactPage';
import DownloadPage from './_pages/DownloadPage';
import LoginPage from './_pages/LoginPage';
import LegalTextsPages from './_pages/LegalTextsPages';
import RegisterPage from './_pages/RegisterPage';
import AlreadyRegisteredPage from './_pages/AlreadyRegisteredPage';
import ForgotPasswordPage from './_pages/ForgotPasswordPage';
import CookiePage from './_components/LegalTexts/Cookie';
import PrivacyPolicyPage from './_components/LegalTexts/PrivacyPolicy';
import TermsPage from './_components/LegalTexts/Terms';
import { UsersPage } from './_pages/UsersPage';
import { UserDetailsPage } from './_pages/UserDetailsPage';
import { EditUserPage } from './_pages/EditUserPage';
import { MyProfilePage } from './_pages/MyProfilePage';
import { ChangePasswordPage } from './_pages/ChangePasswordPage';
import DashboardPage from './_pages/DashboardPage';
import DevDashboardPage from './_pages/DevDashboardPage';
import RegisterModulePage from './_pages/RegisterModulePage';
import RegisterSermatecPage from './_pages/RegisterSermatecPage';
import AddAddressPage from './_pages/AddAddressPage';
import AlertsPage from './_pages/AlertsPage';
import TriggersPage from './_pages/TriggersPage';
import ReleaseNotesAdminPage from './_pages/ReleaseNotesAdminPage';
import AddReleaseNotePage from './_pages/AddReleaseNotePage';
import AddScenarioPage from './_pages/AddScenarioPage';
import PredictionsPage from './_pages/PredictionsPage';
import Page404 from './_pages/404';
import Page401 from './_pages/401';
import HardReset from './_hoc/HardReset/HardReset';

import NGeneralLayout from './_components/Layout/NGeneralLayout';

library.add(faUsers,faCogs,faTachometerAlt,faUser,faIndent,faOutdent,faBolt,faUserCog,faThermometerHalf,faThermometerEmpty,faThermometerFull,faCloudSun,faChartLine,faTrashAlt,faEdit,faVolumeUp,faIdBadge,faScroll);
function App() {
    // const isLoggedIn = useSelector(state => state.isLoggedIn);
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const allRoles = ['client','installer','admin','super-admin'];
    // const isLoggedIn = JSON.parse(localStorage.getItem('appState')).isLoggedIn;
    useEffect(() => {
        history.listen((location,action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    },[]);

    return (
        <Router history={history}>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/admin" component={HomePage} />
                    {/* <Route exact path="/monitoring" component={HomePage} /> */}
                    {/* <Route exact path="/" component={HomePage} /> */}
                    <Route exact path="/home" component={HomePage} />
                    <Route exact path="/404" component={Page404} />
                    <Route exact path="/401" component={Page401} />
                    <Route exact path="/monitoring" component={MonitoringPage} />
                    <Route exact path="/application" component={AppPage} />
                    <Route exact path="/release-notes" component={ReleaseNotesPage} />
                    <Route exact path="/glossary" component={GlossaryPage} />
                    <Route exact path="/contact" component={ContactPage} />
                    <Route exact path="/downloads" component={DownloadPage} />
                    {/* <Route exact path="/cookies-policy" component={CookiePage} /> */}
                    <Route exact path="/cookies-policy" component={LegalTextsPages} />
                    {/* <Route exact path="/terms-of-use" component={TermsPage} /> */}
                    <Route exact path="/terms-of-use" component={LegalTextsPages} />
                    {/* <Route exact path="/privacy-policy" component={PrivacyPolicyPage} /> */}
                    <Route exact path="/privacy-policy" component={LegalTextsPages} />
                    <RoleRoute role={['installer','admin','super-admin']} exact path="/users" component={UsersPage} />
                    <RoleRoute role={['installer','admin','super-admin']} exact path="/user-details/:id" component={UserDetailsPage} />
                    {/* <RoleRoute role={['installer','admin','super-admin']} exact path="/edit-user/:id" component={EditUserPage} /> */}
                    <PrivateRoute exact path="/edit-user/:id" component={EditUserPage} />
                    <PrivateRoute exact path="/my-profile" component={MyProfilePage} />
                    <PrivateRoute exact path="/change-password" component={ChangePasswordPage} />
                    <PrivateRoute exact path="/dashboard" component={DashboardPage} />
                    {/* <PrivateRoute exact path="/testd" component={DashboardPage} /> */}
                    <PrivateRoute exact path="/register-module" component={RegisterModulePage} />
                    <PrivateRoute exact path="/edit-module/:id" component={RegisterModulePage} />
                    <PrivateRoute exact path="/register-sermatec" component={RegisterSermatecPage} />
                    <PrivateRoute path="/add-address" component={AddAddressPage} />
                    <PrivateRoute path="/edit-address/:id" component={AddAddressPage} />
                    <PrivateRoute path="/my-alerts" component={AlertsPage} />
                    <PrivateRoute path="/triggers" component={TriggersPage} />
                    <PrivateRoute exact path="/register-sermatec" component={RegisterSermatecPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/register" component={RegisterPage} />
                    <PrivateRoute path="/already-registered" component={AlreadyRegisteredPage} />
                    <RoleRoute role={['admin','super-admin']} exact path="/release-notes-all" component={ReleaseNotesAdminPage} />
                    <RoleRoute role={['admin','super-admin']} exact path="/add-release-note" component={AddReleaseNotePage} />
                    <RoleRoute role={['admin','super-admin']} exact path="/edit-release-note/:id" component={AddReleaseNotePage} />
                    <RoleRoute role={['admin','super-admin']} exact path="/rgqdssvksefqsc" component={AddScenarioPage} />
                    <RoleRoute role={['admin','super-admin']} exact path="/aoiuaiuaoi" component={PredictionsPage} />

                    {/* Dev route to dashboard */}
                    <RoleRoute role={['admin','super-admin']} exact path="/rokvmwsevkmmoejvwse" component={DevDashboardPage} />

                    <Route path="/forgotPassword" component={ForgotPasswordPage} />
                    <Route path="/hardreset" component={HardReset} />
                    <Redirect from="*" to="/404" />
                </Switch>

            </div>
        </Router>
        // <Router history={history}>
        //     <div className="App">
        //         <NGeneralLayout />
        //     </div>
        // </Router>
    )
}

export default App;
