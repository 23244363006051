import axios from '../axios';

export const releaseNotesService = {
    getReleaseNotes,
    getReleaseNoteById,
    addReleaseNote,
    editReleaseNote,
};

function getReleaseNotes() {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    // if (typeof token === 'undefined') { return }
    if (typeof token === 'undefined') {
        return axios.get('/api/release-notes')
            .then(response => {
                if (response.data) {
                    let releaseNotes = response.data.release_notes;

                    return releaseNotes;
                }
            });
    }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/release-notes',config)
        .then(response => {
            console.log("Response getReleaseNotes");
            console.log(response);
            if (response.data) {
                console.log("List of release notes successfully retrieved !");

                let releaseNotes = response.data.release_notes;

                return releaseNotes;
            }
        });
}

function getReleaseNoteById(id) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/release-note?id=' + id,config)
        .then(response => {
            console.log("Response getReleaseNoteById");
            console.log(response);
            if (response.data) {
                console.log("Release note successfully retrieved !");

                // let newDate = response.data.release_note.created_at.toDate().toLocaleDateString('fr-BE');
                let releaseNote = response.data.release_note;

                return releaseNote;
            }
        });
}

function addReleaseNote(data) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/add-release-note',data,config)
        .then(response => {
            console.log("Response editReleaseNote");
            console.log(response);
            if (response.data) {
                console.log("Release note successfully created !");

                let releaseNote = response.data.release_note;

                return releaseNote;
            }
        });
}

function editReleaseNote(data) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-release-note',data,config)
        .then(response => {
            console.log("Response editReleaseNote");
            console.log(response);
            if (response.data) {
                console.log("Release note successfully modified !");

                let releaseNote = response.data.release_note;

                return releaseNote;
            }
        });
}