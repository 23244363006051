import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';
import Navigation from '../_components/Layout/Navigation/Navigation';
import IntroMonitoring from '../_components/MonitoringPage/IntroMonitoring';
import ControlConsumption from '../_components/MonitoringPage/ControlConsumption';
import MonitoringData from '../_components/MonitoringPage/MonitoringData';
import MainFooter from '../_components/Layout/Footer/MainFooter';
import GoTop from '../_components/UI/GoTop/GoTop';

import '../_assets/css/bootstrap.min.css';
import '../_assets/css/animate.css';
// import '../_assets/css/icofont.css';
// import '../_assets/css/owl.carousel.min.css';
import '../_assets/css/owl.theme.default.css';
import '../_assets/css/magnific-popup.css';
import '../_assets/css/style.css';
import '../_assets/css/responsive.css';
import '../_assets/css/color/color-default.css';

import classes from './MonitoringPage.module.scss';

const MonitoringPage = (props) => {
    return (
        // <BatteryUpPage sidebar={false}>
        <GenericPage>
            <IntroMonitoring />
            {/* <ControlConsumption /> */}
            <MonitoringData />
            {/* </BatteryUpPage> */}
        </GenericPage>
    );
}

export default MonitoringPage;