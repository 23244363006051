import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import { userService } from '../../_services/userService';
import classes from './UsersList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../../_helpers/history';

// import ReactWOW from 'react-wow';
import Aux from '../../_hoc/Auxil/Auxil';
import Modal from '../UI/Modal/Modal';
import Data from '../Data/Data';

const UsersList = (props) => {
    const authUser = JSON.parse(localStorage.getItem('appState')).user;
    const authRoles = authUser.roles;
    const mainInfo = JSON.parse(localStorage.getItem('mainInfo'));
    console.log("mainInfo users list");
    console.log(mainInfo);
    const [isAdmin,setIsAdmin] = useState(false);
    const [users,setUsers] = useState([]);
    const [deletingStatus,setDeletingStatus] = useState(false);
    const [deletingTarget,setDeletingTarget] = useState(0);
    const [deletingTargetId,setDeletingTargetId] = useState(0);
    const [deletingDone,setDeletingDone] = useState(false);

    useEffect(() => {
        if (authRoles.includes('admin') || authRoles.includes('super-admin')) {
            userService.getAll()
                .then(response => {
                    setUsers(response);
                });
        } else if (authRoles.includes('installer')) {
            userService.getClients()
                .then(response => {
                    setUsers(response);
                });
        }
    },[]);

    useEffect(() => {
        if (authRoles.includes('admin') || authRoles.includes('super-admin')) {
            setIsAdmin(true);
        }
    },[]);

    console.log("Users list");
    console.log(users);

    function deletionModalHandler(e) {
        let target = {
            id: e.target.getAttribute('target-id'),
            name: e.target.getAttribute('target')
        };
        setDeletingTarget(target.name);
        setDeletingTargetId(target.id);
        setDeletingStatus(true);
    }

    function deletionCancelHandler() {
        setDeletingStatus(false);
    }

    function deletionHandler() {
        userService.delete(deletingTargetId)
            .then(response => {
                setDeletingStatus(false);
            })
            .then(response => {
                userService.getAll()
                    .then(response => {
                        setUsers(response);
                        setDeletingDone(true);
                    })
            });
    }

    function deletedCloseHandler() {
        setDeletingDone(false);
    }
    console.log("history.location");
    console.log(history.location.pathname);

    return (
        <section id="usersList" className={classes.usersList}>
            <Modal show={deletingStatus} modalClosed={deletionCancelHandler}>
                {/* <p>{deletingTargetId}</p> */}
                <i className="icofont-warning"></i>
                <p>You are about to delete {deletingTarget}</p>
                <p>Please confirm</p>
                <span onClick={deletionCancelHandler}><i className="icofont-close"></i>cancel</span>
                <button onClick={deletionHandler} className="btn btn-primary">
                    {/* {modifying && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                    Delete
                </button>
            </Modal>
            <Modal show={deletingDone} modalClosed={deletedCloseHandler}>
                <i className="icofont-verification-check"></i>
                <p>The user has been successfully deleted !</p>
                <button onClick={deletedCloseHandler} className="btn btn-primary">
                    Done
                </button>
            </Modal>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <Data
                            icon="icone-battery-UP-IMG-Users"
                            title="Users"
                            type="userslist"
                        >
                            <div className={`row ${classes.titles}`}>
                                <div className="col-lg-1">ID</div>
                                {/* <div className="col-lg-3">Name</div> */}
                                <div className={`${isAdmin ? "col-lg-5" : "col-lg-8"} ${classes.userName}`}>Name</div>
                                <div className={`col-lg-3 ${isAdmin ? "" : classes.isHidden}`}>Role(s)</div>
                                <div className="col-lg-1">Module(s)</div>
                                {/* <div className="col-lg-2">Actions</div> */}
                            </div>
                            {users.map((user,idx) => {
                                return (
                                    <div className={`row ${classes.userItem} ${isAdmin ? classes.isAdmin : ""} ${user.id === authUser.id ? classes.itsYou : ""}`} key={idx} style={{ display: "flex" }}>
                                        <div className={`col-lg-1 ${classes.userId}`}>{user.id}</div>
                                        <div className={`${isAdmin ? "col-lg-5" : "col-lg-8"} ${classes.userName}`}><Link to={`/user-details/${user.id}`}>{user.firstname} {user.surname}</Link></div>
                                        {isAdmin && (
                                            <div className={`col-lg-3 ${classes.userRoles}`}>
                                                {user.roles.map((role,i) => {
                                                    return (<span key={i}>{role.name}{(i + 1) < user.roles.length ? ", " : ""}</span>)
                                                })}
                                            </div>
                                        )}
                                        <div className={`col-lg-1 ${classes.userModules}`}>
                                            {user.modules ? user.modules.length : "0"}
                                            {/* {user.modules.map((module,id) => {
                                                return (<span key={id}>{module.mac_address}</span>)
                                            })} */}
                                        </div>
                                        {/* <div className={`col-lg-2 ${classes.userActions}`}>
                                            <Link to={`/user-details/${user.id}`}><i class="icofont-info-circle"></i></Link>
                                            <Link to={{ pathname: `/edit-user/${user.id}`,state: { prevPath: history.location.pathname } }}><i className="icofont-ui-edit"></i></Link>
                                            <i target-id={user.id} target={user.firstname + ' ' + user.surname} onClick={deletionModalHandler} className="icofont-ui-delete"></i>
                                        </div> */}
                                    </div>
                                )
                            })}
                            {/* {{users}} */}
                            <div className="row mt-5">
                                <div className="col-lg-2 offset-lg-10">
                                    <Link to="register">
                                        <button className="btn btn-primary">
                                            <i class="icofont-ui-add"></i>
                                            Add user
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </Data>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UsersList;