export const dataApiConstants = {
    SOC_SHORT_URL: '/soc',
    SOC_FULL_URL: 'http://127.0.0.1:8000/soc',
    BATTERY_TEMPS_SHORT_URL: '/battery-temperatures',
    BATTERY_TEMPS_FULL_URL: 'http://127.0.0.1:8000/battery-temperatures',
    SOCINPERIOD_SHORT_URL: '/soc-in-period',
    SOCINPERIOD_FULL_URL: 'http://127.0.0.1:8000/soc-in-period',
    POWERLOGS_SHORT_URL: '/power-logs',
    POWERLOGS_FULL_URL: 'http://127.0.0.1:8000/power-logs',
    CHARTDATA_SHORT_URL: '/chart-data',
    CHARTDATA_FULL_URL: 'http://127.0.0.1:8000/chart-data',
    LASTBATTERIESVALUES_SHORT_URL: '/last-batteries-values',
    LASTBATTERIESVALUES_FULL_URL: 'http://127.0.0.1:8000/last-batteries-values',
    LASTBATTERIESALLVALUES_SHORT_URL: '/last-batteries-all-values',
    LASTBATTERIESALLVALUES_FULL_URL: 'http://127.0.0.1:8000/last-batteries-all-values',
    LASTACRELSVALUES_SHORT_URL: '/last-acrels-values',
    LASTACRELSVALUES_FULL_URL: 'http://127.0.0.1:8000/last-acrels-values',
    LASTACRELSALLVALUES_SHORT_URL: '/last-acrels-all-values',
    LASTACRELSALLVALUES_FULL_URL: 'http://127.0.0.1:8000/last-acrels-all-values',
    LASTEASTRONSVALUES_SHORT_URL: '/last-eastrons-values',
    LASTEASTRONSVALUES_FULL_URL: 'http://127.0.0.1:8000/last-eastrons-values',
    LASTEASTRONSALLVALUES_SHORT_URL: '/last-eastrons-all-values',
    LASTEASTRONSALLVALUES_FULL_URL: 'http://127.0.0.1:8000/last-eastrons-all-values',
    SOCLASTHOUR_SHORT_URL: '/soc-last-hour',
    SOCLASTHOUR_FULL_URL: 'http://127.0.0.1:8000/soc-last-hour',
    ACRPGAUGE_SHORT_URL: '/acr-p-gauge-values',
    ACRPGAUGE_FULL_URL: 'http://127.0.0.1:8000/acr-p-gauge-values',
    PYLONTECHALARMS_SHORT_URL: '/pylontech-alarms',
    PYLONTECHALARMS_FULL_URL: 'http://127.0.0.1:8000/pylontech-alarms',
    PYLONTECHLASTDATA_SHORT_URL: '/pylontech-last-data',
    PYLONTECHLASTDATA_FULL_URL: 'http://127.0.0.1:8000/pylontech-last-data',
    ACRABSRELBYHOUR_SHORT_URL: '/acrel-by-hour',
    ACRABSRELBYHOUR_FULL_URL: 'http://127.0.0.1:8000/acrel-by-hour',
    ACRABSRELCHARTSDATA_SHORT_URL: '/acrel-longterm',
    ACRABSRELCHARTSDATA_FULL_URL: 'http://127.0.0.1:8000/acrel-longterm',
    ACRELLATESTDATA_SHORT_URL: '/acrel-latest-data',
    ACRELLATESTDATA_FULL_URL: 'http://127.0.0.1:8000/acrel-latest-data',
    EASTRONLATESTDATA_SHORT_URL: '/eastron-latest-data',
    EASTRONLATESTDATA_FULL_URL: 'http://127.0.0.1:8000/eastron-latest-data',
    // FORECAST_SHORT_URL: '/battery_temperatures',
    // MAINCHARTDATA_SHORT_UL: '/queries/main-chart',
    MAINCHARTDATA_SHORT_UL: '/queries/main-multiple',
    MAINCHARTDATALONGTERM_SHORT_UL: '/queries/main-chart-longterm',
    /* MongoDB URLs */
    /* Cross queries */
    // MAINCHARTDATA_UL: '/queries/main-chart',
    MAINCHARTDATA_UL: '/queries/main-multiple',
    /* Pylontech queries */
    QUERIES_PYLONTECH_URL: 'queries/pylontech',
    /* Acrel queries */
    QUERIES_ACREL_URL: 'queries/acrel',
    /* Eastron queries */
    QUERIES_EASTRON_URL: 'queries/eastron',
};