import React,{ Component } from 'react';

import i18n from "i18next";
import { withTranslation,Trans } from "react-i18next";

import OwlCarousel from 'react-owl-carousel3';
import ReactWOW from 'react-wow';
// import VisibilitySensor from "react-visibility-sensor";
import imgApp from '../../../_assets/img/app-mockup-1.png';
import fondImgSlideHome from '../../../_assets/img/fond-img-slide-home-battery-up.png';
import classes from './Slider.module.scss';

const options = {
    items: 1,
    nav: true,
    dots: false,
    touchDrag: false,
    mouseDrag: false,
    autoplay: false,
    autoplayHoverPause: true,
    loop: true,
    navText: [
        "<i class='icofont-long-arrow-left'></i>",
        "<i class='icofont-long-arrow-right'></i>"
    ]
}

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideData: [
                {
                    // heading: (<span>Contrôlez vos batteries lithium avec<img src={require("../../../_assets/img/Logo-Battery-Up-vector.png")} alt="Battery Up Logo" /></span>),
                    heading: (<span>Contrôlez vos batteries lithium avec<img src={require("../../../_assets/img/Logo-Battery-Up-black.png")} alt="Battery Up Logo" /></span>),
                    // image: "../../../_assets/img/app-mockup-1.png",
                    image: imgApp,
                    buttons: (
                        <div className={`slide-button ${classes.slideButton}`}>
                            < a
                                href="https://www.wattuneed.com/fr/batteries-solaires/25680-monitoring-battery-up-0768563818615.html"
                                target="_blank"
                                className={`slide-btn-bordered mr-10 animated fadeInDown slow opacityOne ${classes.slideButtonBordered}`}
                            >
                                {/* <i className="icofont-info-circle"></i> */}
                                <i className="icofont-cart-alt"></i>
                                <span style={{ marginLeft: '5px' }}>{window.localStorage.i18nextLng === "fr" ? "Acheter un module" : "Buy a device"}</span>
                            </a >
                            {/* < a
                                href="#"
                                className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
                            >
                                <i className="icofont-info-circle"></i>
                                <span style={{ marginLeft: '5px' }}>{t('homepage:Slider.button-buy-bup')}</span>
                            </a > */}
                            <a
                                href="/monitoring"
                                className={`default-button animated fadeInDown slow opacityOne`}
                            >
                                Savoir plus
                            </a>
                        </div>
                    ),
                    // centerLeft: i18n.language === "fr" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />,
                    // centerLeft: window.localStorage.i18nextLng === "fr" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />,
                    centerLeft: "",
                    centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/app-mockup-1.png")} alt="" />,
                    bottom: (
                        <div>
                            <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" />
                        </div>
                    )
                },
                {
                    heading: "Optimisez votre consommation grâce à IFTTT",
                    text: "Créez des scénarios qui vous correspondent et liez vos appareils connectés à vos données de consommation.",
                    // text: <span>Test span</span>,
                    image: imgApp,
                    buttons: (
                        <div className="slide-button">
                            {/* < a
                                href="#"
                                className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
                            > */}
                            {/* <i className="icofont-cloud-download"></i> */}
                            {/* <i className="icofont-iphone"></i>
                                <span style={{ marginLeft: '5px' }}>Download</span>
                            </a > */}
                            {/* <a
                                href="#"
                                className={`slide-btn-bordered animated fadeInDown slow opacityOne`}
                            >
                                Learn More
                            </a> */}
                            <a
                                href="https://www.ifttt.com/"
                                target="_blank"
                                className={`default-button animated fadeInDown slow opacityOne`}
                            >
                                Découvrir IFTTT
                            </a>
                        </div>

                    ),
                    centerLeft: <img className={classes.monitoringImg} src={require("../../../_assets/img/logos/ifttt_alt.png")} alt="" />,
                    centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/IMG-ifttt-connect-alt.png")} alt="" />,
                    // centerRight: "",
                    bottom: ""
                }
            ]
        };
    }

    // componentDidUpdate(prevProps,nextProps) {
    //     console.log("componentDidUpdate");
    //     if (prevProps !== nextProps) {
    //         console.log("this.props.i18n.language");
    //         console.log(this.props.i18n.language);
    //         console.log(window.localStorage.i18nextLng);
    //     }
    // }
    componentDidMount() {
        console.log("componentDidMount");
        console.log(window.localStorage.i18nextLng);
        let NewSlideData = [
            {
                heading: (<span>Contrôlez vos batteries lithium avec<img src={require("../../../_assets/img/Logo-Battery-Up-black.png")} alt="Battery Up Logo" /></span>),
                image: imgApp,
                buttons: (
                    <div className={`slide-button ${classes.slideButton}`}>
                        < a
                            href="https://www.wattuneed.com/fr/batteries-solaires/25680-monitoring-battery-up-0768563818615.html"
                            target="_blank"
                            className={`slide-btn-bordered mr-10 animated fadeInDown slow opacityOne ${classes.slideButtonBordered}`}
                        >
                            {/* <i className="icofont-info-circle"></i> */}
                            <i className="icofont-cart-alt"></i>
                            <span style={{ marginLeft: '5px' }}>{window.localStorage.i18nextLng === "fr" ? "Acheter un module" : "Buy a device"}</span>
                        </a >
                        <a
                            href="/monitoring"
                            className={`default-button animated fadeInDown slow opacityOne`}
                        >
                            Savoir plus
                        </a>
                    </div>
                ),
                centerLeft: i18n.language === "fr" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />,
                centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/app-mockup-1.png")} alt="" />,
                bottom: (
                    <div>
                        <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" />
                    </div>
                )
            },
            {
                heading: "Optimisez votre consommation grâce à IFTTT",
                text: "Créez des scénarios qui vous correspondent et liez vos appareils connectés à vos données de consommation.",
                // text: <span>Test span</span>,
                image: imgApp,
                buttons: (
                    <div className="slide-button">
                        {/* < a
                            href="#"
                            className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
                        > */}
                        {/* <i className="icofont-cloud-download"></i> */}
                        {/* <i className="icofont-iphone"></i>
                            <span style={{ marginLeft: '5px' }}>Download</span>
                        </a > */}
                        {/* <a
                            href="#"
                            className={`slide-btn-bordered animated fadeInDown slow opacityOne`}
                        >
                            Learn More
                        </a> */}
                        <a
                            href="https://www.ifttt.com/"
                            target="_blank"
                            className={`default-button animated fadeInDown slow opacityOne`}
                        >
                            Découvrir IFTTT
                        </a>
                    </div>

                ),
                centerLeft: <img className={classes.monitoringImg} src={require("../../../_assets/img/logos/ifttt_alt.png")} alt="" />,
                centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/IMG-ifttt-connect-alt.png")} alt="" />,
                // centerRight: "",
                bottom: ""
            }
        ]
        if (window.localStorage.i18nextLng === "fr") {
            NewSlideData[0].centerLeft = <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" />;
        } else {
            NewSlideData[0].centerLeft = <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />;
        }
        this.setState({ slideData: NewSlideData })
    }
    // componentDidUpdate(prevProps,prevState) {
    //     console.log("componentDidUpdate");
    //     if (this.props.t !== prevProps.t) {
    //         console.log(this.props.i18n.language);
    //     }
    // }

    render() {
        const { t } = this.props;
        // console.log("i18n & i18n.language");
        // console.log(this.props.i18n.language);
        // console.log(this.props.i18n);
        // console.log(i18n);
        // console.log(i18n["language"]);
        // console.log(window.localStorage.i18nextLng);
        const slideDataTest = [
            {
                // heading: (<span>Contrôlez vos batteries lithium avec<img src={require("../../../_assets/img/Logo-Battery-Up-vector.png")} alt="Battery Up Logo" /></span>),
                heading: (<span>Contrôlez vos batteries lithium avec<img src={require("../../../_assets/img/Logo-Battery-Up-black.png")} alt="Battery Up Logo" /></span>),
                // image: "../../../_assets/img/app-mockup-1.png",
                image: imgApp,
                buttons: (
                    <div className={`slide-button ${classes.slideButton}`}>
                        < a
                            href="https://www.wattuneed.com/fr/batteries-solaires/25680-monitoring-battery-up-0768563818615.html"
                            target="_blank"
                            className={`slide-btn-bordered mr-10 animated fadeInDown slow opacityOne ${classes.slideButtonBordered}`}
                        >
                            {/* <i className="icofont-info-circle"></i> */}
                            <i className="icofont-cart-alt"></i>
                            <span style={{ marginLeft: '5px' }}>{window.localStorage.i18nextLng === "fr" ? "Acheter un module" : "Buy a device"}</span>
                        </a >
                        {/* < a
                            href="#"
                            className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
                        >
                            <i className="icofont-info-circle"></i>
                            <span style={{ marginLeft: '5px' }}>Découvrir</span>
                        </a > */}
                        <a
                            href="/monitoring"
                            className={`default-button animated fadeInDown slow opacityOne`}
                        >
                            Savoir plus
                        </a>
                    </div>
                ),
                centerLeft: i18n.language === "en" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />,
                // centerLeft: window.localStorage.i18nextLng === "fr" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />,
                centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/app-mockup-1.png")} alt="" />,
                bottom: (
                    <div>
                        <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" />
                    </div>
                )
            },
            {
                heading: "Optimisez votre consommation grâce à IFTTT",
                text: "Créez des scénarios qui vous correspondent et liez vos appareils connectés à vos données de consommation.",
                // text: <span>Test span</span>,
                image: imgApp,
                buttons: (
                    <div className="slide-button">
                        {/* < a
                            href="#"
                            className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
                        > */}
                        {/* <i className="icofont-cloud-download"></i> */}
                        {/* <i className="icofont-iphone"></i>
                            <span style={{ marginLeft: '5px' }}>Download</span>
                        </a > */}
                        {/* <a
                            href="#"
                            className={`slide-btn-bordered animated fadeInDown slow opacityOne`}
                        >
                            Learn More
                        </a> */}
                        <a
                            href="https://www.ifttt.com/"
                            target="_blank"
                            className={`default-button animated fadeInDown slow opacityOne`}
                        >
                            Découvrir IFTTT
                        </a>
                    </div>

                ),
                centerLeft: <img className={classes.monitoringImg} src={require("../../../_assets/img/logos/ifttt_alt.png")} alt="" />,
                centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/IMG-ifttt-connect-alt.png")} alt="" />,
                // centerRight: "",
                bottom: ""
            }
        ]
        // const test = window.localStorage.i18nextLng === "fr" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />;

        // slideData = NewSlideData;
        i18n.on('languageChanged',() => {
            let NewSlideData = [
                {
                    heading: (<span>{window.localStorage.i18nextLng === "fr" ? "Contrôlez vos batteries lithium avec" : "Control your lithium batteries with"}<img src={require("../../../_assets/img/Logo-Battery-Up-black.png")} alt="Battery Up Logo" /></span>),
                    image: imgApp,
                    buttons: (
                        <div className={`slide-button ${classes.slideButton}`}>
                            < a
                                href="https://www.wattuneed.com/fr/batteries-solaires/25680-monitoring-battery-up-0768563818615.html"
                                target="_blank"
                                className={`slide-btn-bordered mr-10 animated fadeInDown slow opacityOne ${classes.slideButtonBordered}`}
                            >
                                {/* <i className="icofont-info-circle"></i> */}
                                <i className="icofont-cart-alt"></i>
                                <span style={{ marginLeft: '5px' }}>{window.localStorage.i18nextLng === "fr" ? "Acheter un module" : "Buy a device"}</span>
                            </a >
                            <a
                                href="/monitoring"
                                className={`default-button animated fadeInDown slow opacityOne`}
                            >
                                {window.localStorage.i18nextLng === "fr" ? "Savoir plus" : "Learn more"}
                            </a>
                        </div>
                    ),
                    centerLeft: window.localStorage.i18nextLng === "fr" ? <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" /> : <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />,
                    // centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/app-mockup-1.png")} alt="" />,
                    centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/IMG-battery-pylontech.png")} alt="" />,
                    bottom: (
                        <div>
                            <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" />
                        </div>
                    )
                },
                {
                    // heading: t('homepage:Slider.title2'),
                    // text: t('homepage:Slider.paragraph2'),
                    heading: window.localStorage.i18nextLng === "fr" ? "Optimisez votre consommation grâce à IFTTT" : "Improve your consumption thanks to IFTTT",
                    text: window.localStorage.i18nextLng === "fr" ? "Créez des scénarios qui vous correspondent et liez vos appareils connectés à vos données de consommation." : "Create your scenarios and link your devices to your consumption data.",
                    // text: <span>Test span</span>,
                    image: imgApp,
                    buttons: (
                        <div className={`slide-button ${classes.slideButton}`}>
                            {/* < a
                                href="#"
                                className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
                            > */}
                            {/* <i className="icofont-cloud-download"></i> */}
                            {/* <i className="icofont-iphone"></i>
                                <span style={{ marginLeft: '5px' }}>Download</span>
                            </a > */}
                            {/* <a
                                href="#"
                                className={`slide-btn-bordered animated fadeInDown slow opacityOne`}
                            >
                                Learn More
                            </a> */}
                            <a
                                href="https://www.ifttt.com/"
                                target="_blank"
                                className={`default-button animated fadeInDown slow opacityOne`}
                            >
                                {/* {t('homepage:Slider.button-ifttt')} */}
                                {window.localStorage.i18nextLng === "fr" ? "Découvrir IFTTT" : "Discover IFTTT"}
                            </a>
                        </div>

                    ),
                    // centerLeft: <img className={classes.monitoringImg} src={require("../../../_assets/img/logos/ifttt_alt.png")} alt="" />,
                    // centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/logos/ifttt_alt.png")} alt="" />,
                    centerLeft: <img className={classes.monitoringImg} src={require("../../../_assets/img/IMG-ifttt-soc.png")} alt="" />,
                    centerRight: <img className={classes.monitoringImg} src={require("../../../_assets/img/IMG-ifttt-connect-alt.png")} alt="" />,
                    // centerRight: "",
                    bottom: ""
                }
            ]
            if (window.localStorage.i18nextLng === "fr") {
                NewSlideData[0].centerLeft = <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG.png")} alt="" />;
            } else {
                NewSlideData[0].centerLeft = <img className={classes.monitoringImg} src={require("../../../_assets/img/Phone-illustration-battery-up-IMG_en.png")} alt="" />;
            }
            // console.log("test");
            // console.log(window.localStorage.i18nextLng);
            this.setState({ slideData: NewSlideData })
        })

        return (
            <div id="home" className={`hompage-slides-wrapper .visible-xs-block hidden-xs hidden-sm ${classes.SliderWrapper}`}>
                <div className={classes.Slider}>
                    <OwlCarousel
                        className={`homepage-slides owl-carousel owl-theme ${classes.owlCarousel}`}
                        {...options}
                    >
                        {/* {this.state.slideData.map((data,idx) => ( */}
                        {this.state.slideData.map((data,idx) => (
                            <div className={`single-slider-item ${classes.sliderItem}`} key={idx}>
                                <div className={`slide-item-table ${classes.sliderItemTable}`}>
                                    <div className={`slide-item-tablecell ${classes.tablecell}`} style={{ display: "block" }}>
                                        <div className={`container ${classes.container}`}>
                                            <div className={classes.sliderDesign}>
                                                <div className={`slider-text ${classes.sliderDesignTitle}`}>
                                                    <h1 className={`animated fadeInUp slow opacityOne ${classes.sliderHeading}`}>
                                                        {data.heading}
                                                    </h1>
                                                    {data.text ? <p className={`animated fadeInUp slow opacityOne ${classes.sliderParagraph}`}>{data.text}</p> : ""}
                                                    {/* <p className={`animated fadeInUp slow opacityOne ${classes.sliderParagraph}`}>{data.text}</p> */}
                                                </div>
                                                <div className={classes.sliderDesignCenter}>
                                                    <div className={classes.sliderDesignCenterLeft}>
                                                        {data.centerLeft}
                                                    </div>
                                                    <div className={classes.sliderDesignCenterRight}>
                                                        {data.centerRight}
                                                    </div>
                                                </div>
                                                <div className={classes.sliderDesignButtons}>
                                                    {data.buttons}
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-7"> */}
                                            {/* <VisibilitySensor> */}
                                            {/* {({ isVisible }) => ( */}
                                            {/* <div className={`slider-text ${classes.sliderText}`} style={{ textAlign: "left" }}>
                                                        <h1
                                                            className={`animated fadeInUp slow opacityOne ${classes.sliderHeading}`}
                                                            style={{
                                                                // margin: '60px 0 20px',
                                                                // lineHeight: '0px'
                                                            }}
                                                        >
                                                            {data.heading}
                                                        </h1>
                                                        <p
                                                            className="animated fadeInUp slow opacityOne"
                                                        >
                                                            {data.text}
                                                        </p>
                                                        {data.bottom}
                                                        {data.buttons}

                                                    </div> */}
                                            {/* )} */}
                                            {/* </VisibilitySensor> */}
                                            {/* </div>

                                                <div className="col-5">
                                                    <div className={`welcome-phone ${classes.welcomePhone}`}>
                                                        <img src={data.image} alt="App Mockup image" />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </OwlCarousel>
                </div>
                {/* <ReactWOW delay='0.3s' animation='fadeInUp'>
                    <div className="container" style={{ position: "relative",top: "-150px" }}>
                        <div className="row">
                            <div className="col-lg-2 offset-lg-2 col-xl-2 offset-xl-2">
                                <div className="single-user">
                                    <i className="icofont-wifi-alt"></i>
                                    <h3>WIFI</h3>
                                </div>
                            </div>

                            <div className="col-lg-2 col-xl-2">
                                <div className="single-user">
                                    <i className="icofont-brand-apple"></i>
                                    <h3>ios</h3>
                                </div>
                            </div>

                            <div className="col-lg-2 col-xl-2">
                                <div className="single-user">
                                    <i className="icofont-brand-android-robot"></i>
                                    <h3>Android</h3>
                                </div>
                            </div>

                            <div className="col-lg-2 col-xl-2">
                                <div className="single-user">
                                    <i className="icofont-cloud-download"></i>
                                    <h3>IFTTT</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactWOW> */}
            </div>
        )
    }
}

// Slider.defaultProps = {
//     slideData: [
//         {
//             heading: "Contrôlez vos batteries",
//             text: "Découvrez notre système de monitoring afin de connaître l'état de vos batteries, votre production solaire et d'autres données liées à votre production et votre consommation.",
//             image: "../../images/aaa-battery-up.png",
//             buttons: (
//                 <div className="slide-button">
//                     < a
//                         href="#"
//                         className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
//                     >
//                         <i className="icofont-info-circle"></i>
//                         <span style={{ marginLeft: '5px' }}>I'm interested !</span>
//                     </a >
//                     <a
//                         href="#"
//                         className={`slide-btn-bordered animated fadeInDown slow opacityOne`}
//                     >
//                         Learn More
//             </a>
//                 </div>

//             ),
//         },
//         {
//             heading: "Découvrez notre App",
//             text: "Gardez le contrôle sur votre production où que vous soyez grâce à notre application.",
//             // text: <span>Test span</span>,
//             image: "../../images/app-mockup-1.png",
//             buttons: (
//                 <div className="slide-button">
//                     < a
//                         href="#"
//                         className={`slide-btn-white mr-10 animated fadeInDown slow opacityOne`}
//                     >
//                         {/* <i className="icofont-cloud-download"></i> */}
//                         <i className="icofont-iphone"></i>
//                         <span style={{ marginLeft: '5px' }}>Download</span>
//                     </a >
//                     <a
//                         href="#"
//                         className={`slide-btn-bordered animated fadeInDown slow opacityOne`}
//                     >
//                         Learn More
//             </a>
//                 </div>

//             ),
//         }
//     ]
// }

export default Slider;