import React,{ useState,useEffect } from 'react';

import { Trans,useTranslation } from 'react-i18next';

import { dataService } from '../../_services/dataService';
import { moduleService } from '../../_services/moduleService';
import { predictionService } from '../../_services/predictionService';

import Aux from '../../_hoc/Auxil/Auxil';

import classes from './SocVisual.module.scss';

const SocVisual = (props) => {
    const { t } = useTranslation(['common']);
    const mac_address = props.mac_address;
    const [currentSoc,setCurrentSoc] = useState(0);
    const [previousSoc,setPreviousSoc] = useState(0);
    const [diffSoc,setDiffSoc] = useState(0);
    const [valueGradient,setValueGradient] = useState("100%");
    const [display,setDisplay] = useState("none");
    const [device,setDevice] = useState("Pylontech");
    const [testMac,setTestMac] = useState("");
    const [socAdvice,setSocAdvice] = useState([]);
    // const socAdvice = [
    //     _id => "eee",
    //     value => 3,
    //     recommendation => true
    // ];

    useEffect(() => {
        // console.log("mac_address soc visual");
        // console.log(mac_address);
        moduleService.getNameByMacAddress(mac_address)
            .then(response => {
                setDevice(response);
            });
        dataService.socPastHour(mac_address)
            .then(response => {
                // console.log("mac_address soc visual");
                // console.log(mac_address);
                // console.log("response soc visual");
                // console.log(response);
                if (response.latest) {
                    setCurrentSoc(parseInt(response.latest.soc));
                } else {
                    setCurrentSoc("empty");
                }
                if (response.oldest) {
                    setPreviousSoc(parseInt(response.oldest.soc));
                    setDiffSoc(parseInt(response.latest.soc) - parseInt(response.oldest.soc));
                } else {
                    setPreviousSoc("empty");
                    setDiffSoc(0);
                }
                if (response.latest) {
                    setValueGradient("" + 100 - parseInt(response.latest.soc) + "%");
                } else {
                    setValueGradient("" + 100 + "%");
                }
                // setValueGradient("" + 100 - parseInt(response.latest.soc) + "%");
                // setCurrentSoc(80);
                // setDiffSoc(10);
                // setValueGradient("" + 100 - 80 + "%");
            })
            .then(response => {
                setDisplay("block");
            });
        // const interval = setInterval(() => {
        //     dataService.socPastHour(mac_address)
        //         .then(response => {
        //             console.log("mac_address soc visual");
        //             console.log(mac_address);
        //             setCurrentSoc(parseInt(response.current_soc));
        //             setPreviousSoc(parseInt(response.previous_soc));
        //             setDiffSoc(parseInt(response.current_soc) - parseInt(response.previous_soc));
        //             setValueGradient("" + 100 - parseInt(response.current_soc) + "%");
        //         });
        // },15000);
        // predictionService.socAdvice(mac_address)
        //     .then(response => {
        //         let test = [];
        //         for (const [key,value] of Object.entries(response)) {
        //             test[key] = value;
        //         }
        //         setSocAdvice(test);
        //     });
        let testArray = [];
        testArray['_id'] = "ee";
        testArray['value'] = 23;
        testArray['recommendation'] = true;
        setSocAdvice(testArray);
    },[props.mac_address]);

    // console.log("socAdvice");
    // console.log(socAdvice["recommendation"]);
    // console.log(socAdvice["recommendation"]);

    return (
        <div className={classes.SocVisual}>
            <h3 className={classes.SocVisualTitle}>{device}</h3>
            <div className={classes.SocVisualContent}>
                <div className={classes.socValues}>
                    <div className={classes.socData}>
                        <h4>{t('common:datas.battery.soc')}</h4>
                        {/* {currentSoc !== "empty" ? <p>{currentSoc} %</p> : <p>{t('common:datas.not-communicated')}</p>} */}
                        {currentSoc !== "empty" ? <p>{currentSoc} %</p> : <p>{t('common:datas.no-data')}</p>}
                        {/* <p>{currentSoc} %</p> */}
                    </div>
                    <div className={classes.socData}>
                        <h4>{t('common:datas.progression-last-hour')}</h4>

                        {diffSoc ? (
                            <p>{diffSoc > 0 ? (
                                <Aux>
                                    <img className={classes.upOrDown} src={require("../../_assets/img/icon_up.png")} alt="Créez des scénarios grâce à  IFTTT" /><span>+</span>
                                </Aux>
                            ) : (diffSoc < 0 ? (
                                <Aux>
                                    <img className={classes.upOrDown} src={require("../../_assets/img/icon_down.png")} alt="Créez des scénarios grâce à  IFTTT" /><span></span>
                                </Aux>
                            ) : (
                                // <span className={classes.equals}>=</span>
                                <span className={classes.noProgression}>{t('dashboard:SocVisual.no-progression')}</span>
                            ))}{diffSoc}%</p>
                            // ) : <p>"N/A"</p>}
                        ) : <span className={classes.noProgression}>{t('dashboard:SocVisual.no-progression')}</span>}
                        {/* <p>{diffSoc > 0 ? (
                            <Aux>
                                <img className={classes.upOrDown} src={require("../../_assets/img/icon_up.png")} alt="Créez des scénarios grâce à  IFTTT" /><span>+</span>
                            </Aux>
                        ) : (diffSoc < 0 ? (
                            <Aux>
                                <img className={classes.upOrDown} src={require("../../_assets/img/icon_down.png")} alt="Créez des scénarios grâce à  IFTTT" /><span>-</span>
                            </Aux>
                        ) : (
                            <span className={classes.equals}>+</span>
                        ))}{diffSoc}%</p> */}
                    </div>
                    {/* {(socAdvice["recommendation"] && currentSoc !== "empty") ? ( */}
                    {/* {((socAdvice["value"] || socAdvice["value"] === 0) && currentSoc !== "empty") ? ( */}
                    {(socAdvice["_id"] && currentSoc !== "empty") ? (
                        <div className={`${classes.socData} ${classes.socData__predictions}`}>
                            <h4>Conseil Battery UP</h4>
                            <div>
                                {(socAdvice["recommendation"] && socAdvice["value"] > 5 && currentSoc < 80) ? (
                                    // {(socAdvice["recommendation"] && socAdvice["value"] > 5) ? (
                                    <Aux>
                                        <img src={require("../../_assets/img/icons/predictions/consumption_advice_yes_green.png")} alt="Yes" />
                                        <p>YES</p>
                                    </Aux>
                                ) : (socAdvice["recommendation"] && currentSoc < 80) ? (
                                    // ) : (socAdvice["recommendation"]) ? (
                                    <Aux>
                                        <img src={require("../../_assets/img/icons/predictions/consumption_advice_yes_purple.png")} alt="" />
                                        <p>yes</p>
                                    </Aux>
                                ) : (
                                    <Aux>
                                        <img src={require("../../_assets/img/icons/predictions/consumption_advice_no.png")} alt="No" />
                                        <p>no</p>
                                    </Aux>
                                )}
                            </div>
                        </div>
                    ) : ""}
                </div>
                {/* <div className={classes.imageBattery}>
                    <div className={classes.progressBarContainer}>
                        <div class={`${classes.progressBarChild} ${classes.progress}`}></div>
                        <div class={`${classes.progressBarChild} ${classes.shrinker} ${classes.timelapse}`} style={{ "--to-width": valueGradient }}></div>
                    </div>
                </div> */}
                <div className={classes.imageBatteryWrapper}>
                    <div className={classes.imageBatteryAlt} style={{ "display": display }}>
                        <div className={classes.progressBarContainerAlt}>
                            <div class={`${classes.progressBarChildAlt} ${classes.progressAlt}`}></div>
                            <div class={`${classes.progressBarChildAlt} ${classes.shrinkerAlt} ${classes.timelapseAlt}`} style={{ "--to-height": valueGradient }}></div>
                            <p className={classes.testSoc}>80%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.colorBorder}></div>
        </div>
    )
};

export default SocVisual;