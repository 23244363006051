import { authHeader } from '../_helpers/auth-header';
import axios from '../axios';
import { userConstants } from '../_constants/userConstants';

export const protocolService = {
    getAll,
    // getById,
    // update,
    // delete: _delete,
};

function getAll() {
    console.log('getAll in protocol service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') {return}
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/protocols',config)
        .then(response => {
            console.log("Response getAll protocols");
            console.log(response);
            if (response.data) {
                console.log("List of protocols successfully retrieved !");

                let protocols = response.data.protocols;

                return protocols;
            }
        });
}

function getById(targetId) {
    console.log('getAll in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') {return}
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    console.log("target");
    console.log(targetId);

    // return axios.get('/api/users',config)
    return axios.get('/api/user?id=' + targetId,config)
        .then(response => {
            console.log("Response getAll");
            console.log(response);
            if (response.data) {
                console.log("User successfully retrieved !");
                console.log(response);

                let user = response.data.user;

                return user;
            }
        });
}

function update(userData) {
    console.log('update in service');
    console.log('userData');
    console.log(userData);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') {return}
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-user/',userData,config)
        .then(response => {
            console.log("Response update");
            console.log(response);
            if (response.data) {
                console.log("User successfully updated !");
                console.log(response);

                let user = response.data.user;

                return user;
            }
        });
}

function _delete(targetId) {
    console.log('delete in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') {return}
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/delete-user/?id=' + targetId,config)
        .then(response => {
            console.log("Response delete");
            console.log(response);
            if (response.data) {
                console.log("User successfully deleted !");
                console.log(response);

                let message = "User successfully deleted !";

                return message;
            }
        });
}