import React,{ useState,useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

// import AddressForm from '../_components/Form/AddressForm';

import { releaseNotesService } from '../_services/releaseNotesService';

const AddAddressPage = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const { id } = props.match.params;
    const isAddMode = !id;
    const [initReleaseNote,setInitReleaseNoteForm] = useState({
        id: !id ? 0 : id,
        title_fr: '',
        title_en: '',
        done_fr: '',
        done_en: '',
        to_do_fr: '',
        to_do_en: '',
    });

    console.log("isAddMode init addAddress page");
    console.log(isAddMode);

    useEffect(() => {
        if (id) {
            console.log("even in add mode ?");
            releaseNotesService.getReleaseNoteById(id)
                .then(response => {
                    console.log('response getReleaseNoteById');
                    console.log(response);
                    // const fields = ['line1','line2','street_number','zipcode','locality','country'];
                    // fields.forEach(field => setFieldValue(field, response[field], false));
                    setInitReleaseNoteForm(response);
                });
        }
    },[]);

    return (
        <BatteryUpPage isAddMode={isAddMode} initReleaseNote={initReleaseNote} >
            {/* <AddressForm isAddMode={isAddMode} address={initAddress} /> */}
        </BatteryUpPage>
    );
}

export default AddAddressPage;