import React,{ useState } from 'react';

import { history } from '../_helpers/history';
import BatteryUpPage from '../_components/Layout/BatteryUpPage';

import UserForm from '../_components/Form/UserForm';

// function EditUserPage() {
const EditUserPage = (props) => {
    // const { match: { params } } = props;
    const [params,setParams] = useState(props.match.params);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    if (parseInt(props.match.params.id) !== user.id) {
        history.push('/401');
    }
    console.log("edit page props");
    console.log(props.match.params);
    console.log("edit page props match");
    console.log(params);
    return (
        <BatteryUpPage params={params}>
            {/* <UserForm params={params} prevPath={props.location.state.prevPath} /> */}
        </BatteryUpPage>
    );
}

export { EditUserPage };
