// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../_assets/img/IMG-fond-integrer-batteries-smartphone.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.ReleaseNoteForm_ReleaseNoteForm__2eaIc {\n  width: 100%;\n  margin-bottom: 50px;\n  padding: 0; }\n  .ReleaseNoteForm_ReleaseNoteForm__background__23oiq {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n    background-size: contain;\n    margin: 0 15px; }\n    @media only screen and (min-width: 640px) {\n      .ReleaseNoteForm_ReleaseNoteForm__background__23oiq {\n        margin: 0 100px; } }\n    @media only screen and (min-width: 1024px) {\n      .ReleaseNoteForm_ReleaseNoteForm__background__23oiq {\n        margin: 0 200px; } }\n  .ReleaseNoteForm_ReleaseNoteForm__wrapper__2_ZuP {\n    background-color: #ffffff;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);\n    padding: 15px;\n    text-align: left; }\n    @media only screen and (min-width: 640px) {\n      .ReleaseNoteForm_ReleaseNoteForm__wrapper__2_ZuP {\n        padding: 50px 50px 20px; } }\n    .ReleaseNoteForm_ReleaseNoteForm__wrapper__2_ZuP h2 {\n      text-align: center; }\n    .ReleaseNoteForm_ReleaseNoteForm__wrapper__2_ZuP textarea {\n      height: 120px !important; }\n    .ReleaseNoteForm_ReleaseNoteForm__wrapper__2_ZuP label {\n      margin-top: 15px; }\n  .ReleaseNoteForm_ReleaseNoteForm__2eaIc .ReleaseNoteForm_colorBorder__3VBYv {\n    height: 2px;\n    background: #3d009e;\n    background: linear-gradient(to right, #3d009e, #ff063c); }\n", ""]);
// Exports
exports.locals = {
	"ReleaseNoteForm": "ReleaseNoteForm_ReleaseNoteForm__2eaIc",
	"ReleaseNoteForm__background": "ReleaseNoteForm_ReleaseNoteForm__background__23oiq",
	"ReleaseNoteForm__wrapper": "ReleaseNoteForm_ReleaseNoteForm__wrapper__2_ZuP",
	"colorBorder": "ReleaseNoteForm_colorBorder__3VBYv"
};
module.exports = exports;
