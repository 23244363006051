import React,{ useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

import UsersList from '../_components/UsersPage/UsersList';

// import '../_assets/css/bootstrap.min.css';
// import '../_assets/css/animate.css';
// // import '../_assets/css/icofont.css';
// // import '../_assets/css/owl.carousel.min.css';
// import '../_assets/css/owl.theme.default.css';
// import '../_assets/css/magnific-popup.css';
// import '../_assets/css/style.css';
// import '../_assets/css/responsive.css';
// import '../_assets/css/color/color-default.css';

function UsersPage() {
    return (
        <BatteryUpPage sidebar={true}>
            {/* <UsersList /> */}
        </BatteryUpPage>
    );
}

export { UsersPage };
