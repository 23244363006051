import React from 'react';

import { userService } from '../../../_services/userService';
import { history } from '../../../_helpers/history';

const LogoutButton = (props) => {
    function logoutHandler() {
        userService.logout()
            .then(response => {
                history.push('/');
            });
    }

    return(
        <span onClick={logoutHandler}>Logout</span>
    )
};

export default LogoutButton;