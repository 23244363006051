import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { moduleService } from '../../_services/moduleService';

import classes from './UserAlerts.module.scss';

import Data from '../Data/Data';
import Auxil from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';
import AlertForm from '../Alerts/AlertForm';
import AlertsList from '../Alerts/AlertsList';
import WebhookForm from '../Alerts/WebhookForm';

const UserAlerts = (props) => {
    const { t } = useTranslation(['common','alertspage']);
    const auth = JSON.parse(localStorage.getItem('appState')).user;
    const hasModules = auth.modules.length > 0 ? true : false;
    const [modules,setModules] = useState([]);
    const [systemRules,setSystemRules] = useState([]);

    useEffect(() => {
        // moduleService.getByUserIdWithAlerts(auth.id)
        moduleService.getByUserId(auth.id)
            .then(response => {
                console.log("response modules by user id with alerts");
                console.log(response);
                setModules(response);
            });
        moduleService.getAllSystemRules()
            .then(response => {
                console.log("response all system rules");
                console.log(response);
                setSystemRules(response);
            });
    },[]);

    return (
        <div className={classes.UserAlerts}>
            <div className="container-fluid">
                <div className="row">
                    <div className={`col-lg-12`}>
                        <Data
                            icon={"icon_alerts"}
                            title={t('alertspage:UserAlerts.title')}
                            type={t('alertspage:UserAlerts.title')}
                        >
                            <div className="row mt-4">
                                <div className={`col-lg-12 ${classes.comingSoon}`}>
                                    <p className={classes.comingSoonText}>{t('alertspage:UserAlerts.coming-soon-p1')}</p>
                                    <p className={classes.comingSoonTitle}>{t('alertspage:UserAlerts.coming-soon-t2')}</p>
                                    {/* <p className={classes.comingSoonText}>Les règles ci-dessous sont liées a IFTTT et vous permettront de déclencher des évènements. Ce service sera bientôt disponible mais vous pouvez déjà modifier vos paramètres.</p> */}
                                    <p className={classes.comingSoonText}>{t('alertspage:UserAlerts.coming-soon-p2')}</p>
                                    {i18n.language === "fr" ? (
                                        <Link to="/files/battery-up_webhooks_guide_fr.pdf" target="_blank" download><p className={classes.downloadLink}>{t('alertspage:UserAlerts.download-guide')}</p></Link>
                                    ) : (
                                        <Link to="/files/battery-up_webhooks_guide_en.pdf" target="_blank" download><p className={classes.downloadLink}>{t('alertspage:UserAlerts.download-guide')}</p></Link>
                                    )}
                                    <p className={classes.comingSoonTitle}>{t('alertspage:UserAlerts.coming-soon-t1')}</p>
                                    <p className={classes.comingSoonText}>{t('alertspage:UserAlerts.coming-soon-p3')}</p>
                                    <p className={classes.comingSoonText}>{t('alertspage:UserAlerts.coming-soon-p4')}</p>
                                    {/* <p className={classes.comingSoonText}>Les règles ci-dessous vous permettront de déclencher des évènements. Ce service sera bientôt disponible mais vous pouvez déjà modifier vos paramètres.</p> */}
                                </div>
                                <div className={`col-lg-12 text-left ${classes.modulesList}`}>
                                    {/* <div className="row">
                                    <div className="col-lg-12">
                                            <img className={classes.profileSection__icon} src={require("../../_assets/img/icone-battery-UP-IMG-module.png")} alt={t('common:icons.device')} />
                                            <h4 className={classes.profileSection__title}>{t('common:devices.device_plural')}</h4>
                                            <Link to="/register-module"><i class="icofont-ui-add"></i></Link>
                                        </div>
                                        <div className={`col-lg-12 ${classes.modulesList}`}>
                                            <div className="row"> */}
                                    {auth.modules.length > 0 ? (
                                        modules.map((module,idx) => {
                                            return (
                                                // <Auxil>
                                                // {module.protocol.id === 1 ? (
                                                <div className={`${classes.moduleItem}`} key={idx}>
                                                    <h4>
                                                        <img className={classes.deviceIcon} src={require("../../_assets/img/icon_devices_battery.png")} alt={t('common:icons.battery')} />
                                                        {module.module_name}
                                                    </h4>
                                                    <div className="row">
                                                        <div className={`col-12`}>
                                                            <AlertsList module={module.id} protocol={module.protocol.id} />
                                                            {/* {module.length > 0 ? (
                                                                        module.system_rules.map((rule,ri) => {
                                                                            return (
                                                                                systemRules.length > 0 ? (
                                                                                    systemRules.map((sysRule,sri) => {
                                                                                        return (
                                                                                            <Auxil>
                                                                                                <h5>{sysRule.rule_name}</h5>
                                                                                                <div className={classes.formWrapper}>
                                                                                                    <input type="checkbox" name="active" id="active" />
                                                                                                    <AlertForm key={sri} ruleId={sysRule.id} moduleId={module.id} />
                                                                                                </div>
                                                                                            </Auxil>
                                                                                        )
                                                                                    })
                                                                                ) : ""
                                                                            )
                                                                        })
                                                                    ) : ""} */}


                                                            {/* {module.system_rules.length > 0 ? (
                                                                        module.system_rules.map((rule,ri) => {
                                                                            return (
                                                                                <Auxil>
                                                                                    <h5>{rule.rule_name}</h5>
                                                                                    <div className={classes.formWrapper}>
                                                                                        <input type="checkbox" name="active" id="active" />
                                                                                        <AlertForm key={ri} ruleId={rule.id} moduleId={module.id} />
                                                                                    </div>
                                                                                </Auxil>
                                                                            )
                                                                        })) : ""}
                                                                    {systemRules.length > 0 ? (
                                                                        systemRules.map((sysRule,sri) => {
                                                                            return (
                                                                                <Auxil>
                                                                                    <h5>{sysRule.rule_name}</h5>
                                                                                    <div className={classes.formWrapper}>
                                                                                        <input type="checkbox" name="active" id="active" />
                                                                                        <AlertForm key={sri} ruleId={sysRule.id} moduleId={sysRule.id} />
                                                                                    </div>
                                                                                </Auxil>
                                                                            )
                                                                        })
                                                                    ) : ""} */}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className={`col-12`}>
                                                            <WebhookForm moduleId={module.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                                // ) : ""}
                                                // </Auxil>
                                            )
                                        })) : hasModules ? <Spinner /> : (
                                            <div className="col-lg-12">
                                                <p>
                                                    <Trans i18nKey="alertspage:UserAlerts.no-registered-device">
                                                        You do not have any registered module.
                                                    </Trans>
                                                </p>
                                                <Link to="/register-module">{t('myprofilepage:UserProfile.register-device')}</Link>
                                            </div>
                                        )
                                    }
                                    {/* </div> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </Data>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UserAlerts;