import React,{ useState } from "react";
import * as Yup from "yup";

export default [
    {
        "soc": {
            "linked": "pylontech",
            "type": "number",
            "label": "State of charge",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": true,
        },
        "soh": {
            "linked": "pylontech",
            "type": "number",
            "label": "State of health",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "batt_chg_v": {
            "linked": "pylontech",
            "type": "number",
            "label": "batt_chg_v",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "chg_i_limit": {
            "linked": "pylontech",
            "type": "number",
            "label": "chg_i_limit",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "dischg_i_limit": {
            "linked": "pylontech",
            "type": "number",
            "label": "dischg_i_limit",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "v": {
            "linked": "pylontech",
            "type": "number",
            "label": "v",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": true,
        },
        "itot": {
            "linked": "pylontech",
            "type": "number",
            "label": "itot",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": true,
        },
        "avg_cell_t": {
            "linked": "pylontech",
            "type": "number",
            "label": "avg_cell_t",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "req_chg_enable": {
            "linked": "pylontech",
            "type": "number",
            "label": "req_chg_enable",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "req_dischg_enable": {
            "linked": "pylontech",
            "type": "number",
            "label": "req_dischg_enable",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
            "favourite": false,
        },
        "acr_i": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_i",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "acr_u": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_u",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "acr_p": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_p",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "acr_q": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_q",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_s": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_s",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_pf": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_pf",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_f": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_f",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_abs_act_en": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_abs_act_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_rel_act_en": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_rel_act_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_react_en": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_react_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "acr_capa_en": {
            "linked": "acrel",
            "type": "number",
            "label": "acr_capa_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "current": {
            "linked": "eastron",
            "type": "number",
            "label": "Current",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "voltage": {
            "linked": "eastron",
            "type": "number",
            "label": "voltage",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "act_pow": {
            "linked": "eastron",
            "type": "number",
            "label": "act_pow",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "app_pow": {
            "linked": "eastron",
            "type": "number",
            "label": "app_pow",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "react_pow": {
            "linked": "eastron",
            "type": "number",
            "label": "react_pow",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": true,
        },
        "pow_fact": {
            "linked": "eastron",
            "type": "number",
            "label": "pow_fact",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "freq": {
            "linked": "eastron",
            "type": "number",
            "label": "freq",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "imp_act_en": {
            "linked": "eastron",
            "type": "number",
            "label": "imp_act_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "exp_act_en": {
            "linked": "eastron",
            "type": "number",
            "label": "exp_act_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "imp_react_en": {
            "linked": "eastron",
            "type": "number",
            "label": "imp_react_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "exp_reac_en": {
            "linked": "eastron",
            "type": "number",
            "label": "exp_reac_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "tot_sys_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "tot_sys_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "max_tot_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "max_tot_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "imp_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "imp_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "max_imp_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "max_imp_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "exp_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "exp_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "max_exp_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "max_exp_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "curr_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "curr_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "max_curr_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "max_curr_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "tot_act_en": {
            "linked": "eastron",
            "type": "number",
            "label": "tot_act_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "tot_react_en": {
            "linked": "eastron",
            "type": "number",
            "label": "tot_react_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "curr_sys_pos_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "curr_sys_pos_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "max_sys_pos_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "max_sys_pos_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "curr_sys_rev_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "curr_sys_rev_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "max_sys_rev_pow_dem": {
            "linked": "eastron",
            "type": "number",
            "label": "max_sys_rev_pow_dem",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "tot_app_en": {
            "linked": "eastron",
            "type": "number",
            "label": "tot_app_en",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        // "tot_act_en": {
        //     "linked": "eastron",
        //     "type": "number",
        //     "label": "Current",
        //     "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
        //     // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
        //     "favourite": true,
        // },
        // "tot_react_en": {
        //     "linked": "eastron",
        //     "type": "number",
        //     "label": "Current",
        //     "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
        //     // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
        //     "favourite": true,
        // },
        "tot_current": {
            "linked": "eastron",
            "type": "number",
            "label": "tot_current",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        "tot_voltage": {
            "linked": "eastron",
            "type": "number",
            "label": "tot_voltage",
            "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>).min(2,<p className="errorMessage">MIN</p>).max(6,<p className="errorMessage">MAX</p>),
            // "validation": Yup.number().required(<p className="errorMessage">Current validation text</p>),
            "favourite": false,
        },
        // "third": {
        //     "linked": "eastron",
        //     "type": "text",
        //     "label": "Third metric",
        //     "favourite": false,
        // },
        // "fourth": {
        //     "linked": "pylontech",
        //     "type": "text",
        //     "label": "Fourth metric",
        //     "favourite": false,
        // },
        // "fifth": {
        //     "linked": "eastron",
        //     "type": "text",
        //     "label": "Fifth metric",
        //     "favourite": true,
        // },
        // "sixth": {
        //     "linked": "eastron",
        //     "type": "text",
        //     "label": "Sixth metric",
        //     "favourite": false,
        // }
    }
]