import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import { Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../_assets/css/color/color-default.scss';
import classes from './MonitoringData.module.scss';

class MonitoringData extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="monitoringData" className={`faqs-section angle-gray-bg ${classes.monitoringData}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className={`col-md-6 ${classes.leftColumn}`}>
                            <div className="text-center wow fadeInUp" data-wow-delay=".4s" >
                                <img className={classes.monitoringDataImg} src={require("../../_assets/img/IMG-smartphone-representation-graphique-detailles.png")} alt={t('monitoringpage:MonitoringData.img-main-alt')} />
                            </div>
                        </div>
                        <div className={`col-md-6 ${classes.rightColumn}`}>
                            <div className={classes.chartsText}>
                                <h3 className={`smallLine-title ${classes.sectionSubtitle}`}>{t('monitoringpage:MonitoringData.visual-chart')}</h3>
                                <p className={classes.introCharts}> <strong> {t('monitoringpage:MonitoringData.visualise-devlopment-energies')}</strong> </p>
                                <ul className={classes.customUl}>
                                    <li>{t('monitoringpage:MonitoringData.energy-stored-battery')}</li>
                                    <li>{t('monitoringpage:MonitoringData.energy-taken-battery')}</li>
                                    <li>{t('monitoringpage:MonitoringData.solar-panels-prod')}</li>
                                    <li>{t('monitoringpage:MonitoringData.energy-taken-grid')}</li>
                                    <li>{t('monitoringpage:MonitoringData.energy-injected-grid')}</li>
                                    {/* <li>Consommation totale de l'habitation</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','monitoringpage'])(MonitoringData);