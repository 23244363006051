import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './Legend.module.scss';

const Legend = (props) => {
    const { t } = useTranslation(['common','dashboard']);

    // console.log("props Legend => to display");
    // console.log(props);
    return (
        <div className={props.style === "alt" ? `col-12 ${classes.Legend} ${classes.LegendAlt}` : `col-12 ${classes.Legend}`}>
            {/* <h2><img src={require("../../_assets/img/icone-battery-UP-IMG-infos.png")} alt="" />Legend</h2> */}
            <h2>{t('dashboard:Legend.title')}</h2>
            <div className={classes.devices}>
                {props.arrBatteries.length > 0 ? (
                    <p className={classes.battery}><span></span>{t('dashboard:Legend.data-pylontech')}</p>
                ) : ""}
                {props.arrAcrel.length > 0 ? (
                    <p className={classes.energyMeter}><span></span>{t('dashboard:Legend.data-acrel')}</p>
                ) : ""}
                {props.arrEastron.length > 0 ? (
                    <p className={classes.energyMeter}><span></span>{t('dashboard:Legend.data-eastron')}</p>
                ) : ""}
                {/* <p><span className={classes.energyMeter}></span>Acrel</p> */}
            </div>
            {/* <div className={classes.icons}>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Puissance</p>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Courant</p>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Etat de charge</p>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Production/Energie exportée</p>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Consommation/Energie importée</p>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Alerte</p>
                <p><img src={require("../../_assets/img/icons/data/power_black.png")} alt="" />Date et heure</p>
            </div> */}
        </div>
    )
};

export default Legend;