import React from 'react';

import classes from './ScenarioSummary.module.scss';

const ScenarioSummary = (props) => {
    return (
        <p className={classes.ScenarioSummary}>ScenarioSummary</p>
    )
}

export default ScenarioSummary;