import React,{ useEffect,useState } from 'react';
// import { Link } from 'react-router-dom';
import { Formik,Field,Form,ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { Trans,useTranslation } from 'react-i18next';

import { addressService } from '../../_services/addressService';
import { countryService } from '../../_services/countryService';
import { history } from '../../_helpers/history';

import TemporaryPopup from '../UI/ValidationMessage/TemporaryPopup';
import imgfondlogin from '../../_assets/img/IMG-fond-texte-s-enregister.png';
import btnRegister from '../../_assets/img/bouton-enregistrer-battery-up.png';

const AddressForm = (props) => {
    const { t } = useTranslation(['common','registermodulepage']);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const id = props.id;
    const [countries,setCountries] = useState([]);
    const [modified,setModified] = useState(false);
    // const [initAddress,setInitAddress] = useState({
    //     line1: '',
    //     line2: '',
    //     street_number: '',
    //     zipcode: '',
    //     locality: '',
    //     country: '',
    // });

    useEffect(() => {
        countryService.getAll()
            .then(response => {
                console.log('response getAll');
                setCountries(response);
            });
    },[]);
    // console.log("initAddress");
    // console.log(initAddress);
    console.log("props passed to Address form");
    console.log(props);
    console.log("props.address");
    console.log(props.address);
    console.log("user id hidden filed");
    console.log(user.id);

    const validationMessage = (
        <TemporaryPopup redirect="/my-profile" button="Mon profil">
            Votre adresse a bien été changée !
        </TemporaryPopup>
    )
    const validation = modified ? validationMessage : "";

    return (
        <div id="addressForm" className="app-about-section angle-sp" style={{ marginTop: "0",paddingTop: '0',width: '100%' }}>
            {validation}
            <div className="container">
                <div className="row" style={{ background: `url(${imgfondlogin}) center center no-repeat`,backgroundSize: 'contain',padding: "100px",margin: '0 150px' }}>
                    {/* <div className="col-md-12 col-lg-12">
                        <div className="wow fadeInUp" data-wow-delay=".9s"> */}
                    <div className="col-12" style={{ background: `#ffffff none repeat scroll 0 0`,boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',padding: "60px 30px" }}>
                        {/* <h2 className="underlinedTitle" style={{ textAlign: "left",color: "#ff063c",fontWeight: "300",fontSize: "40px" }}>{!props.isAddMode ? "Edit" : "Add"} address</h2> */}
                        <h2>{!props.isAddMode ? "Edit" : "Add"} address</h2>
                        <Formik
                            enableReinitialize
                            initialValues={props.address}
                            validationSchema={Yup.object({
                                installation_name: Yup.string()
                                    .trim()
                                    .matches(/^[A-Za-z0-9À-ÖØ-öø-ÿ- ]+$/,{ message: <p className="errorMessage">{t('registermodulepage:RegisterForm.yup-installationname-match')}</p> })
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                line1: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                line2: Yup.string()
                                    .trim()
                                    .nullable(),
                                street_number: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                zipcode: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                locality: Yup.string()
                                    .trim()
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                country_id: Yup.number()
                                    .min(1,<p className="errorMessage">{t('registermodulepage:RegisterForm.yup-must-select-country')}</p>)
                                    .required(<p className="errorMessage">{t('common:forms.required')}</p>),
                                user_id: Yup.number(),
                            })}
                            onSubmit={(values,{ setSubmitting }) => {
                                setTimeout(() => {
                                    // {!props.isAddMode ? "Edit" : "Add"}
                                    /* Determine Latitude Longitude */
                                    let countryIndex = countries.findIndex(item => item.id === parseInt(values.country_id));
                                    let country = countries[countryIndex].name_en;
                                    values.country = country;
                                    console.log('addAddress in form');
                                    console.log(values);
                                    addressService.getLatLng(values)
                                        .then(response => {
                                            console.log(response);
                                            values.latitude = response.lat;
                                            values.longitude = response.lng;
                                            values.weather_zone = response.weather_zone;
                                        })
                                        .then(() => {
                                            // if (values.user === 0) {
                                            //     values.user = user.id;
                                            // }
                                            if (props.isAddMode) {
                                                // console.log("values submitted add address");
                                                // console.log(values);
                                                addressService.addAddress(values)
                                                    .then(() => {
                                                        history.push('/my-profile');
                                                    });
                                            } else {
                                                addressService.editAddress(values)
                                                    .then(() => {
                                                        setModified(true);
                                                        setTimeout(() => {
                                                            history.push('/my-profile');
                                                        },2000);
                                                    });
                                            }
                                        });
                                },400);
                            }}
                        >
                            <Form style={{ textAlign: 'left' }}>
                                <Field type="hidden" name="user_id" value={user.id} />
                                <div className="form-group" style={{ margin: '30px 10px 20px' }}>
                                    <label htmlFor="installation_name">{t('registermodulepage:RegisterForm.installationname-label')}</label>
                                    <Field name="installation_name" type="text" className="form-control" placeholder={t('registermodulepage:RegisterForm.installationname-placeholder')} />
                                    <ErrorMessage name="installation_name" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="line1">{t('registermodulepage:RegisterForm.address-line1-label')}</label>
                                    <Field name="line1" type="text" className="form-control" />
                                    <ErrorMessage name="line1" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="line2">{t('registermodulepage:RegisterForm.address-line2-label')}</label>
                                    <Field name="line2" type="text" className="form-control" />
                                    <ErrorMessage name="line2" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="street_number">{t('registermodulepage:RegisterForm.address-number-label')}</label>
                                    <Field name="street_number" type="street_number" className="form-control" />
                                    <ErrorMessage name="street_number" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="zipcode">{t('registermodulepage:RegisterForm.address-zipcode-label')}</label>
                                    <Field name="zipcode" type="text" className="form-control" />
                                    <ErrorMessage name="zipcode" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="locality">{t('registermodulepage:RegisterForm.address-city-label')}</label>
                                    <Field name="locality" type="text" className="form-control" />
                                    <ErrorMessage name="locality" />
                                </div>
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor="country_id">{t('registermodulepage:RegisterForm.address-country-label')}</label>
                                    <Field as="select" name="country_id" className="form-control">
                                        <option value="0">-</option>
                                        {countries.map((country,idx) => {
                                            // console.log("props.address.country_id")
                                            // console.log(props.address.country_id)
                                            // console.log("country.id")
                                            // console.log(country.id)
                                            if (props.address.country_id === country.id) {
                                                console.log("country id's match")
                                                return (<option
                                                    selected
                                                    value={parseInt(country.id)}
                                                    key={idx}
                                                >
                                                    {country.name_en}
                                                </option>
                                                )
                                            } else {
                                                return (<option
                                                    value={country.id}
                                                    key={idx}
                                                >
                                                    {country.name_en}
                                                </option>
                                                )
                                            }
                                        })}
                                    </Field>
                                    <ErrorMessage name="country_id" />
                                </div>

                                <button className="btn btn-primary" type="submit" style={{ background: `url(${btnRegister}) center center no-repeat`,backgroundSize: 'contain',width: "180px",height: '70px',border: 'none',padding: '0',margin: '0 auto',display: 'block' }}></button>
                            </Form>
                        </Formik>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddressForm;