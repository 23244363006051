import React,{ useState,useEffect } from 'react';

import { Trans,useTranslation } from 'react-i18next';

import ReactWOW from 'react-wow';
import ReleaseNoteItem from './ReleaseNoteItem';

import { releaseNotesService } from '../../_services/releaseNotesService';
import Spinner from '../UI/Spinner/Spinner';

import classes from './ReleaseNotes.module.scss';

const ReleaseNotes = (props) => {
    const { t } = useTranslation(['common','releasenotespage']);
    const [releaseNotes,setReleaseNotes] = useState([]);

    useEffect(() => {
        releaseNotesService.getReleaseNotes()
            .then(response => {
                setReleaseNotes(response);
            });
    },[]);

    return (
        <section id="solutionHome" className={`app-about-section angle-sp ${classes.solutionHome}`}>
            <div className={`container ${classes.container}`}>
                <div className="row">
                    <div className="col-lg-12">
                        <ReactWOW delay='0.3s' animation='fadeInUp'>
                            <div className={`section-title angle-section-title ${classes.sectionTitleWrapper}`}>
                                {/* <h2 className={classes.sectionTitle}>Monitorez et gérez vos batteries lithium</h2> */}
                                <h2 className={classes.sectionTitle}>{t('releasenotespage:ReleaseNotes.title')}</h2>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {releaseNotes.length > 0 ? (
                            releaseNotes.map((releaseNote,idx) => {
                                return (
                                    // <ReactWOW delay='0.3s' animation='fadeInUp'>
                                    <ReleaseNoteItem key={idx} releaseNote={releaseNote} />
                                    // </ReactWOW>
                                )
                            })
                        ) : <Spinner />}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReleaseNotes;