import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore,applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import allReducers from './_reducers';
import { Provider } from 'react-redux';
// import { I18nextProvider } from "react-i18next";
// import i18next from "i18next";
import './i18nextConf';
const loggerMiddleware = createLogger();

const store = createStore(
    allReducers,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);

// i18next.init({
//     interpolation: { escapeValue: false },  // React already does escaping
// });

if (localStorage.getItem("appState") === null) {
    let appState = {
        isLoggedIn: false,
        user: {}
    }
    localStorage.setItem("appState",JSON.stringify(appState))
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <I18nextProvider i18n={i18next}> */}
            <Suspense fallback={null}>
                <App />
            </Suspense>
            {/* </I18nextProvider> */}
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
