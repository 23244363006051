// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DataContainer_newVisuals__3kLED {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  flex-wrap: wrap; }\n", ""]);
// Exports
exports.locals = {
	"newVisuals": "DataContainer_newVisuals__3kLED"
};
module.exports = exports;
