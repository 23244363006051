import { authHeader } from '../_helpers/auth-header';
import axios from '../axios';
import { userConstants } from '../_constants/userConstants';

export const userService = {
    login,
    logout,
    forgotPassword,
    register,
    getAll,
    getClients,
    // getClientModules,
    getClientsModules,
    getModules,
    getAllInstallers,
    attachInstaller,
    getById,
    update,
    // delete,
    delete: _delete,
    updatePassword,
};

// function login(email,password) {
function login(email,password) {
    console.log("test login");
    const credentials = {
        email,password
    }
    return axios.post('/api/login',credentials)
        // return axios.post('/presta/login',credentials)
        .then(response => {
            if (response.data.success) {
                // console.log("Login Successful!");
                // console.log(response.data.user);

                let userRoles = [];
                for (let i = 0; i < response.data.user.roles.length; i++) {
                    userRoles.push(response.data.user.roles[i].name);
                }
                // console.log("roles after login");
                // console.log(userRoles);

                let userModules = [];
                for (let i = 0; i < response.data.user.modules.length; i++) {
                    userModules.push(response.data.user.modules[i].mac_address);
                }

                let userData = {
                    firstname: response.data.user.firstname,
                    surname: response.data.user.surname,
                    id: response.data.user.id,
                    email: response.data.user.email,
                    auth_token: response.data.access_token,
                    // roles: userRoles,
                    // modules: userModules
                    // roles: response.data.user.roles,
                    roles: userRoles,
                    rolesObj: response.data.user.roles,
                    modules: response.data.user.modules,
                    sermatecs: response.data.user.sermatecs,
                    addresses: response.data.user.addresses,
                    clients: response.data.user.clients,
                    installer: response.data.user.installer
                    // timestamp: new Date().toString()
                };

                let mainInfo = {
                    firstname: response.data.user.firstname,
                    id: response.data.user.id,
                    mainRole: userRoles.includes('super-admin') ? 'super-admin' : userRoles.includes('admin') ? 'admin' : userRoles.includes('installer') ? 'installer' : 'client',
                };

                // dispatch(login());
                let appState = {
                    isLoggedIn: true,
                    user: userData,
                    // mainInfo: mainInfo,
                };

                // save app state with user date in local storage
                // localStorage.setItem("appState",JSON.stringify(appState));
                // let selectedClient = "5600";
                // let selectedClient = userRoles.includes('client') ? userModules[0] : "5600";
                // localStorage.setItem("selectedClient",JSON.stringify(selectedClient));
                localStorage.setItem("appState",JSON.stringify(appState));
                localStorage.setItem("mainInfo",JSON.stringify(mainInfo));
                return appState;
            }
            // .catch(error => {
            //     console.log(error);
            // });

            // const requestOptions = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ email,password })
            // };

            //     return fetch(`${config.apiUrl}/users/authenticate`,requestOptions)
            //         .then(handleResponse)
            //         .then(user => {
            //             // store user details and jwt token in local storage to keep user logged in between page refreshes
            //             localStorage.setItem('user',JSON.stringify(user));

            //             return user;
            //         });
        });
}

function forgotPassword(email) {

    return axios.post('/api/forgotPassword',{ email: email })
        .then(response => {
            // console.log("email Successful!");
            window.location = "http://localhost:3000/login";
        }).catch(e => console.log(e));
}

function logout() {
    // console.log("logout in services");
    // remove user from local storage to log user out
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    // const userId = { 'id': JSON.parse(localStorage.getItem('appState')).user.id }
    const token = user.auth_token;
    // console.log("logout token");
    // console.log(token);
    if (typeof token === 'undefined') { return false; }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
            // 'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, DELETE'
        }
    };
    // return axios.post('/api/logout',userId,config)
    return axios.post('/api/logout',user,config)
        .then(response => {
            // console.log("response logout in service");
            // console.log(response);
            if (response.data.success) {
                // console.log("Logout Successful!");
                let appState = {
                    isLoggedIn: false,
                    user: {}
                }
                localStorage.setItem("appState",JSON.stringify(appState));
                localStorage.removeItem('mainInfo');
                return appState;
            }
        });
}

function register(userData) {
    const authUser = JSON.parse(localStorage.getItem('appState')).user;
    // console.log("authUser in register service 1");
    // console.log(authUser);
    // const user = JSON.parse(userData);
    // console.log('is in service');
    // console.log('userData');
    // console.log(typeof user);
    // console.log(userData);
    // const config = {
    //     headers: {
    //         'Accept': 'application/json',
    //     }
    // };
    return axios.post('/api/register',userData)
        .then(response => {
            if (response.data.success) {
                // console.log("Regsiter Successful!");

                let userRoles = [];
                for (let i = 0; i < response.data.user.roles.length; i++) {
                    userRoles.push(response.data.user.roles[i].name);
                }

                let userData = {
                    /* OLD */
                    // firstname: response.data.user.firstname,
                    // surname: response.data.user.surname,
                    // id: response.data.user.id,
                    // email: response.data.user.email,
                    // auth_token: response.data.access_token,
                    // roles: userRoles,
                    /* END OLD */
                    firstname: response.data.user.firstname,
                    surname: response.data.user.surname,
                    id: response.data.user.id,
                    email: response.data.user.email,
                    auth_token: response.data.access_token,
                    // roles: userRoles,
                    // modules: userModules
                    // roles: response.data.user.roles,
                    roles: userRoles,
                    rolesObj: response.data.user.roles,
                    modules: response.data.user.modules,
                    sermatecs: response.data.user.sermatecs,
                    addresses: response.data.user.addresses,
                    clients: response.data.user.clients
                    // timestamp: new Date().toString()
                };

                let mainInfo = {
                    firstname: response.data.user.firstname,
                    id: response.data.user.id,
                    mainRole: userRoles.includes('super-admin') ? 'super-admin' : userRoles.includes('admin') ? 'admin' : 'client',
                };

                let appState = {
                    isLoggedIn: true,
                    user: userData
                };

                // const authUser = JSON.parse(localStorage.getItem('appState')).user;
                // console.log("authUser in register service 2");
                // console.log(authUser);
                if (typeof authUser.roles !== 'undefined') {
                    if (!authUser.roles.includes('installer') && !authUser.roles.includes('admin') && !authUser.roles.includes('super-admin')) {
                        // console.log("in condition in register service");
                        localStorage.setItem("appState",JSON.stringify(appState));
                        localStorage.setItem("mainInfo",JSON.stringify(mainInfo));
                    }
                } else {
                    localStorage.setItem("mainInfo",JSON.stringify(mainInfo));
                    localStorage.setItem("appState",JSON.stringify(appState));
                }
                return userData;
            }
        });
}

function getAll() {
    // console.log('getAll in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/users',config)
        .then(response => {
            // console.log("Response getAll");
            // console.log(response);
            if (response.data) {
                // console.log("List of users successfully retrieved !");

                // let userRoles = [];
                // for(let i = 0; i<response.data.user.roles.length; i++) {
                //     userRoles.push(response.data.user.roles[i].name);
                // }

                // let userData = {
                //     firstname: response.data.user.firstname,
                //     surname: response.data.user.surname,
                //     id: response.data.user.id,
                //     email: response.data.user.email,
                //     auth_token: response.data.access_token,
                //     roles: userRoles,
                // };

                let users = response.data.users;
                // let users = [];
                // for(let i = 0; i<response.data; i++) {
                //     users.push(response.data[i]);
                // }

                // let test = "OK user";

                return users;
            }
        });
}

function getClients() {
    // console.log('getClients in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    return axios.get('/api/clients?installer=' + userId,config)
        .then(response => {
            if (response.data) {
                // console.log("List of clients successfully retrieved !");
                // console.log(response.data.clients);

                // let modules = [];
                // for(let i = 0; i<response.data.modules.length; i++) {
                //     modules.push(response.data.modules[i]);
                // }
                let clients = response.data.clients;

                return clients;
            }
        });
}

// function getClientModules(clientId) {
//     console.log('getClientModules in service');
//     const user = JSON.parse(localStorage.getItem('appState')).user;
//     const userId = JSON.parse(localStorage.getItem('appState')).user.id;
//     const token = user.auth_token;
//     if (typeof token === 'undefined') {return}
//     const config = {
//         headers: {
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${token}`,
//         }
//     };
//     return axios.get('/api/client-modules?client=' + clientId,config)
//         .then(response => {
//             if (response.data) {
//                 console.log("List of client's modules successfully retrieved !");

//                 let modules = [];
//                 for(let i = 0; i<response.data.modules.length; i++) {
//                     modules.push(response.data.modules[i]);
//                 }

//                 return modules;
//             }
//         });
// }

function getClientsModules() {
    // console.log('getClientsModules in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    return axios.get('/api/clients-modules?installer=' + userId,config)
        .then(response => {
            if (response.data) {
                // console.log("List of clients' modules successfully retrieved !");

                let modules = [];
                for (let i = 0; i < response.data.modules.length; i++) {
                    modules.push(response.data.modules[i]);
                }

                return modules;
            }
        });
}

function getModules() {
    // console.log('getModules in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/modules',config)
        .then(response => {
            // console.log("Response getModules");
            // console.log(response);
            if (response.data.modules) {
                // console.log("List of modules successfully retrieved !");

                let modules = [];
                for (let i = 0; i < response.data.modules.length; i++) {
                    modules.push(response.data.modules[i]);
                }

                // let userData = {
                //     firstname: response.data.user.firstname,
                //     surname: response.data.user.surname,
                //     id: response.data.user.id,
                //     email: response.data.user.email,
                //     auth_token: response.data.access_token,
                //     roles: userRoles,
                // };

                // let test = "OK";

                return modules;
            }
        });
}

function getAllInstallers() {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/installers',config)
        .then(response => {
            // console.log("Response getAll");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully retrieved getById userService !");
                // console.log(response);

                let installers = response.data.installers;
                return installers;
            }
        });
}

function attachInstaller(target) {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/attach-installers?user=' + user.id + "&installer=" + target,config)
        .then(response => {
            // console.log("Response getAll");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully retrieved getById userService !");
                // console.log(response);

                // let userRoles = [];
                // for (let i = 0; i < response.data.user.roles.length; i++) {
                //     userRoles.push(response.data.user.roles[i].name);
                // }

                // let userData = {
                //     firstname: response.data.user.firstname,
                //     surname: response.data.user.surname,
                //     id: response.data.user.id,
                //     email: response.data.user.email,
                //     auth_token: response.data.access_token,
                //     roles: userRoles,
                //     rolesObj: response.data.user.roles,
                //     modules: response.data.user.modules,
                //     sermatecs: response.data.user.sermatecs,
                //     addresses: response.data.user.addresses,
                //     clients: response.data.user.clients,
                //     installer: response.data.user.installer
                // };

                // let appState = {
                //     isLoggedIn: true,
                //     user: userData
                // };

                // if (typeof user.roles !== 'undefined') {
                //     if (!user.roles.includes('installer') && !user.roles.includes('admin') && !user.roles.includes('super-admin')) {
                //         console.log("in condition in register service");
                //         localStorage.setItem("appState",JSON.stringify(appState));
                //     }
                // } else {
                //     localStorage.setItem("appState",JSON.stringify(appState));
                // }

                return response;
            }
        });
}

function getById(targetId) {
    // console.log('getAll in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
    // console.log("target");
    // console.log(targetId);

    // return axios.get('/api/users',config)
    return axios.get('/api/user?id=' + targetId,config)
        .then(response => {
            // console.log("Response getAll");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully retrieved getById userService !");
                // console.log(response);

                let user = response.data.user;
                // let modules = [];
                // response.data.user.modules.forEach(function(r) {
                //     modules.push(r);
                // })
                // console.log("modules in service");
                // user.modules = modules;

                return user;
            }
        });
}

function update(userData) {
    // console.log('update in service');
    // console.log('userData');
    // console.log(userData);
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const userId = JSON.parse(localStorage.getItem('appState')).user.id;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/edit-user/',userData,config)
        .then(response => {
            // console.log("Response update");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully updated !");
                // console.log(response);

                let user = response.data.user;

                return user;
            }
        });
}

function _delete(targetId) {
    // console.log('delete in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.get('/api/delete-user/?id=' + targetId,config)
        .then(response => {
            // console.log("Response delete");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully deleted !");
                // console.log(response);

                let message = "User successfully deleted !";

                return message;
            }
        });
}

function updatePassword(target) {
    // const data = {
    //     id: user.id,
    //     password: user.password,
    //     password_confirmation: user.password_confirmation
    // }
    // console.log('update password in service');
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const token = user.auth_token;
    if (typeof token === 'undefined') { return }
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };

    return axios.post('/api/update-password',target,config)
        .then(response => {
            // console.log("Response delete");
            // console.log(response);
            if (response.data) {
                // console.log("User successfully deleted !");
                // console.log(response);

                let message = "User successfully deleted !";

                return message;
            }
        });
}
