import React,{ useEffect } from 'react';

import { history } from '../_helpers/history';

import GenericPage from '../_components/Layout/GenericPage';
import ReleaseNotes from '../_components/ReleaseNotes/ReleaseNotes';

import '../_assets/css/bootstrap.min.css';
import '../_assets/css/animate.css';
// import '../_assets/css/icofont.css';
// import '../_assets/css/owl.carousel.min.css';
import '../_assets/css/owl.theme.default.css';
import '../_assets/css/magnific-popup.css';
import '../_assets/css/style.css';
import '../_assets/css/responsive.css';
import '../_assets/css/color/color-default.css';

import classes from './ReleaseNotesPage.module.scss';

function ReleaseNotesPage() {
    return (
        <GenericPage>
            <ReleaseNotes />
        </GenericPage>
    );
}

export default ReleaseNotesPage;

