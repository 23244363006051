import React,{ useEffect,useState } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import BUPTest from '../_components/Layout/BUPTest';
import Dashboard from '../_components/Dashboard/Dashboard';
import Soc from '../_components/Data/Soc';

import { moduleService } from '../_services/moduleService';

const DashboardPage = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    // console.log("user in dashboard");
    // console.log(user);
    // const selectedClient = user.id;
    // const selectedDevice = user.modules.length > 0 ? user.modules[0].mac_address : user.sermatecs.length > 0 ? user.sermatecs[0].serial_number : 5600;
    const selectedClient = user;
    const selectedDevice = user.modules.length > 0 ? user.modules[0] : user.sermatecs.length > 0 ? user.sermatecs[0] : JSON.parse(localStorage.getItem('demo'));
    // const selectedDeviceType = (user.modules.length === 0 && user.sermatecs.length > 0) ? "module" : "sermatec";
    const selectedDeviceType = user.modules.length > 0 ? "module" : user.sermatecs.length > 0 ? "sermatec" : "module";

    useEffect(() => {
        if (localStorage.getItem("demo") === null) {
            moduleService.getById(5600)
                .then(response => {
                    localStorage.setItem("demo",JSON.stringify(response));
                });
        }
    },[]);

    return (
        <BatteryUpPage>
        </BatteryUpPage>
        // <BUPTest />
    );
}

export default DashboardPage;