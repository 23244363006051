import React,{ useState,useEffect } from 'react';

import { dataService } from '../../_services/dataService';
import { moduleService } from '../../_services/moduleService';
import { sermatecService } from '../../_services/sermatecService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './WeatherWithForecast.module.scss';

import Data from './Data';

const WeatherWithForecast = (props) => {
    // const selectedDevice = parseInt(props.selectedDevice);
    const selectedDevice = props.selectedDevice;
    const selectedDeviceType = props.selectedDeviceType;
    // console.log("selectedDevice WeatherForecast");
    // console.log(selectedDevice);
    console.log("selectedDevice WeatherWithForecast props");
    console.log(selectedDevice);

    let initWeather = {
        name: "City",
        sys: {
            // sunrise: Date.parse(new Date().setHours(6)),
            sunrise: new Date().setHours(6,0) / 1000,
            sunset: new Date().setHours(20,0) / 1000,
        },
        main: {
            temp: 15,
            temp_min: 10,
            temp_max: 20
        },
        weather: [{
            icon: "02d"
        }]
    }
    let initUv = 6.0;
    // console.log("sunrise");
    // console.log(initWeather.sys.sunrise);

    const [currentWeather,setCurrentWeather] = useState(initWeather);
    const [weatherForecast,setWeatherForecast] = useState([]);
    const [currentUv,setCurrentUv] = useState(initUv);
    const [uvForecast,setUvForecast] = useState([]);
    // const [deviceCoords,setDeviceCoords] = useState([]);
    const deviceCoords = {
        // latitude: selectedDevice.address.latitude,
        // longitude: selectedDevice.address.longitude,
        latitude: selectedDevice.latitude,
        longitude: selectedDevice.longitude,
    }

    // const sunriseFull = currentWeather.sys.sunrise * 1000;
    // const sunsetFull = currentWeather.sys.sunset * 1000;
    // const sunrise = sunriseFull.getHours() + ":" + sunriseFull.getMinutes();
    // const sunset = sunsetFull.getHours() + ":" + sunsetFull.getMinutes();
    const sunrise = ("0" + new Date(currentWeather.sys.sunrise * 1000).getHours()).slice(-2) + ':' + ("0" + new Date(currentWeather.sys.sunrise * 1000).getMinutes()).slice(-2);
    const sunset = ("0" + new Date(currentWeather.sys.sunset * 1000).getHours()).slice(-2) + ':' + ("0" + new Date(currentWeather.sys.sunset * 1000).getMinutes()).slice(-2);

    useEffect(() => {
        /*if (selectedDevice !== 0) {
            console.log("weather in here 1");
            console.log(selectedDevice);
            // if(typeof selectedDevice.address !== 'undefined' && typeof selectedDevice.address.latitude !== 'undefined' && typeof selectedDevice.address.longitude !== 'undefined')
            setDeviceCoords({ latitude: selectedDevice.address.latitude,longitude: selectedDevice.address.longitude })
            if (selectedDeviceType === "module") {
                console.log("weather in here 2");
                moduleService.getById(selectedDevice.mac_address)
                    .then(response => {
                        console.log("response getbyid find coords");
                        console.log(response);
                        setDeviceCoords({ latitude: response.address.latitude,longitude: response.address.longitude });
                    });
            } else if (selectedDeviceType === "sermatec") {
                console.log("weather in here 3");
                sermatecService.getById(selectedDevice.serial_number)
                    .then(response => {
                        setDeviceCoords({ latitude: response.address.latitude,longitude: response.address.longitude });
                    });
            }
        }*/
        console.log("deviceCoords in weather");
        console.log(deviceCoords);

        // dataService.testNatacha()
        //     .then(response => {
        //         console.log("response");
        //         console.log(response);
        //     });

        // dataService.currentWeather(deviceCoords)
        dataService.currentWeatherByCity(selectedDevice.locality,selectedDevice.country.alpha2_code)
            .then(response => {
                setCurrentWeather(response.data);
            });
        // dataService.weatherForecast(deviceCoords)
        dataService.weatherForecastByCity(selectedDevice.locality,selectedDevice.country.alpha2_code)
            .then(response => {
                console.log("weatherForecastByCity response");
                console.log(response);
                setWeatherForecast(response.data.list.slice(1));
            });
        dataService.uvValue(deviceCoords)
            .then(response => {
                setCurrentUv(response.data.value);
            });
        dataService.uvForecast(deviceCoords)
            .then(response => {
                console.log("response uv forecast");
                console.log(response);
                setUvForecast(response.data);
            });
    },[selectedDevice]);

    // console.log("currentWeather in WeatherWithForecast");
    // console.log(currentWeather);
    // console.log("weatherForecast in WeatherWithForecast");
    // console.log(weatherForecast);
    // console.log("currentUv in WeatherWithForecast");
    // console.log(currentUv);
    console.log("uvForecast in WeatherWithForecast");
    console.log(uvForecast);

    return (
        <Data
            icon="icone-battery-UP-IMG-meteo"
            title="Weather"
            type="weatherForecast"
        >
            <div className={classes.WeatherWithForecast}>
                <h4>{currentWeather.name}</h4>
                <p>Today</p>
                <div className={classes.weatherToday}>
                    {/* <div className={classes.temperaturesToday}> */}
                    <div>
                        <p className={classes.currentTemperatures}><img src={`http://openweathermap.org/img/wn/${currentWeather.weather[0].icon}@2x.png`} alt="" />{currentWeather.main.temp} °C</p>
                    </div>
                    <div className={classes.min}>
                        <h5>Min</h5>
                        <p><FontAwesomeIcon icon="thermometer-empty" />{currentWeather.main.temp_min} °C</p>
                    </div>
                    <div className={classes.max}>
                        <h5>Max</h5>
                        <p><FontAwesomeIcon icon="thermometer-full" />{currentWeather.main.temp_max} °C</p>
                    </div>
                    {/* </div> */}
                    {/* <div className={classes.dataToday}> */}
                    <div>
                        <h5>Current UV</h5>
                        <p>{currentUv}</p>
                    </div>
                    <div>
                        <h5>Sunrise</h5>
                        <p>{sunrise}</p>
                    </div>
                    <div>
                        <h5>Sunset</h5>
                        <p>{sunset}</p>
                    </div>
                    {/* </div> */}
                </div>
                {/* <p>Icon : {currentWeather.weather[0].icon}</p> */}
                <div>
                    <p>Next 7 days</p>
                    <div className={classes.forecast}>
                        {/* <div>
                            <p>Date</p>
                            <p style={{ height: "100px" }}>Météo</p>
                            <p>°</p>
                            <p>UV</p>
                        </div> */}
                        {weatherForecast.map((day,i) => (
                            <div key={day.dt}>
                                {/* <span>{(new Date()).toLocaleDateString()}</span> */}
                                {/* <span>{(new Date(day.sunrise * 1000)).toLocaleDateString()}</span> */}
                                <p>{("0" + (new Date(day.sunrise * 1000)).getDate()).slice(-2) + "/" + ("0" + (new Date(day.sunrise * 1000)).getMonth() + 1).slice(-2)}</p>
                                {/* <span>{("0" + (new Date(day.sunrise * 1000)).getDate()).slice(-2)}</span> */}
                                {/* <span>{("0" + (new Date(day.sunrise * 1000)).getMonth()).slice(-2)}</span> */}
                                {/* <span>{("0" + (new Date(day.sunrise * 1000).getDate()).slice(-2))}</span> */}
                                {/* <span>{(new Date().setDate(new Date().getDate() + 1))}</span> */}
                                {/* <p>{i === 0 ? 'Tomorrow' : (new Date(day.dt))}</p> */}
                                <img src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`} alt="" />
                                <p>{Math.floor(day.temp.day)} °C</p>
                                {uvForecast.length > 0 ? <p className={classes.forecast__uv}>UV: {uvForecast[i].value}</p> : ""}
                                {/* <p>{i}</p> */}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Data>
    );
};

export default WeatherWithForecast;