import React,{ Component } from 'react';

// import classes from './Dropdown.module.scss';
import Aux from '../../../_hoc/Auxil/Auxil';
// import Backdrop from '../Backdrop/Backdrop';

// class DropdownHeader extends Component {
//     render() {
//         return (
//             <a>
//                 {this.props.children}
//             </a>
//         );
//     }
// }
const WrapperHeader = (props) => props.children;

export default WrapperHeader;
