import React from 'react';

const NMonitoringLayout = (props) => {
    console.log("props.selectedInstallation Monitoring Layout");
    console.log(props.selectedInstallation);
    return (
        <div>Test</div>
    )
};

export default NMonitoringLayout;