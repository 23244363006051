import React,{ useState,useEffect } from 'react';

import { dataService } from '../../_services/dataService';

import Data from './Data';
import CircularProgress from '../UI/Charts/CircularProgress';

const Soc = (props) => {
    const [soc,setSoc] = useState(0);
    const selectedId = props.selectedClient;
    // const selectedId = props.selectedClient.hasOwnProperty("mac_address") ? props.selectedClient.mac_address : props.selectedClient.serial_number;

    console.log("selectedId SOC");
    console.log(selectedId);

    useEffect(() => {
        console.log("test useeffect SOC");
    },[]);

    useEffect(() => {
        console.log("selectedId SOC in useeffect");
        console.log(selectedId);
        dataService.socValue(selectedId)
            .then(response => {
                console.log("response SOC");
                console.log(response);
                setSoc(response);
            });
    },[selectedId]);

    // console.log("SOC in soc");
    // console.log(soc);
    // console.log("selectedId in soc");
    // console.log(selectedId);

    return (
        <Data
            icon="icone-battery-UP-IMG-soc"
            title="SOC"
            type="circleProgress"
        >
            {soc === "empty" ? <p>No data</p> : <CircularProgress data={soc} />}
        </Data>
    );
};

export default Soc;