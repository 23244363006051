import axios from 'axios';

const instance = axios.create({
    // baseURL: 'http://battery_up_api.test'
    // baseURL: 'https://happy.wattuneed.com'
    baseURL: 'https://devapi.wattuneed.com'
});

// const httpsAgent = new https.Agent({ rejectUnauthorized: false })
instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
// instance.defaults.httpsAgent = httpsAgent;

export default instance;
