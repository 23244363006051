import React,{ Component,useState } from 'react';
import WrapperHeader from './WrapperHeader';
import WrapperContent from './WrapperContent';
// import classes from './Dropdown.module.scss';
import Aux from '../../../_hoc/Auxil/Auxil';
// import Backdrop from '../Backdrop/Backdrop';


const WrapperDropdown = (props) => {
    // let { content,header } = props;
    // console.log("props Dropdown");
    // console.log(props);
    const [toggle,setToggle] = useState(false);
    return (
        <div>

            <div onClick={() => { toggle == true ? setToggle(false) : setToggle(true) }}>{props.header} </div>

            <div style={toggle == true ? { display: "block" } : { display: "none" }}>
                {props.content}
            </div>
        </div>);
};

export default WrapperDropdown;
