// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeviceChoice_devicesList__38COm {\n  display: flex; }\n  .DeviceChoice_devicesList__38COm .DeviceChoice_deviceItem__pYR5K {\n    width: 150px;\n    height: 150px;\n    cursor: pointer;\n    border: 1px solid black;\n    margin: 0 10px; }\n    .DeviceChoice_devicesList__38COm .DeviceChoice_deviceItem__pYR5K.DeviceChoice_moduleItem__3h019 {\n      background-color: lightblue; }\n    .DeviceChoice_devicesList__38COm .DeviceChoice_deviceItem__pYR5K.DeviceChoice_sermatecItem__122g8 {\n      background-color: lightgreen; }\n", ""]);
// Exports
exports.locals = {
	"devicesList": "DeviceChoice_devicesList__38COm",
	"deviceItem": "DeviceChoice_deviceItem__pYR5K",
	"moduleItem": "DeviceChoice_moduleItem__3h019",
	"sermatecItem": "DeviceChoice_sermatecItem__122g8"
};
module.exports = exports;
