import React,{ useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { userService } from '../../_services/userService';

import Data from '../Data/Data';
import Auxil from '../../_hoc/Auxil/Auxil';
import AdminBlock from '../Layout/Elements/AdminBlock';
// import classes from './InstallationChoice.module.scss';

const InstallationChoice = (props) => {
    const { t } = useTranslation(['common','admin']);
    // const user = JSON.parse(localStorage.getItem('appState')).user;
    const selectedClient = props.selectedClient;
    // const modules = selectedClient.modules;
    // const sermatecs = selectedClient.sermatecs;
    const installations = selectedClient.addresses;
    const selectedInstallation = props.selectedInstallation

    // console.log("selectedClient install choice no usestate");
    // console.log(selectedClient);

    // console.log("selectedInstallation install choice no usestate");
    // console.log(selectedInstallation);

    // useEffect(() => {
    //     console.log('test first useEffect');
    // },[]);

    // console.log("Modules in client choice");
    // console.log(modules);
    // console.log("sermatecs in client choice");
    // console.log(sermatecs);

    return (
        // <Data key={props.selectedClient}
        //     icon="icone-battery-UP-IMG-CLIENT"
        //     title="Devices"
        //     type="select"
        // >
        // <Auxil>
        <AdminBlock
            icon="IMG-icone-battery-up-adresse"
            title={t('admin:install-choice')}
        // type="select"
        >
            <select value={props.selectedInstallation.id} onChange={props.changed}>
                {installations.map((installation,idx) => {
                    if (parseInt(selectedInstallation) === parseInt(installation.id)) {
                        return (
                            <option
                                selected
                                value={installation.id}
                                key={idx}
                            >
                                {installation.line1} {installation.street_number}, {installation.zipcode} {installation.locality}
                            </option>
                        )
                    } else {
                        return (
                            <option
                                value={installation.id}
                                key={idx}
                            >
                                {installation.line1} {installation.street_number}, {installation.zipcode} {installation.locality}
                            </option>
                        )
                    }
                })}
            </select>
        </AdminBlock>
        // </Auxil>
        // </Data>
    );
};

export default InstallationChoice;