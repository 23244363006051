// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.AcrPGauge_AcrPGauge__kyF7F {\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 20px; }\n  .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_AcrPGaugeTitle__2Q1xY {\n    font-size: 18px;\n    color: white;\n    padding: 15px 0;\n    margin: 0;\n    width: 100%;\n    background-color: #3d009e; }\n  .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_powerSelect__3LPvo {\n    display: flex;\n    align-items: center;\n    margin: 5px 0; }\n    .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_powerSelect__3LPvo label {\n      margin: 0; }\n    .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_powerSelect__3LPvo select {\n      padding: 5px;\n      margin: 5px 2px 2px 5px;\n      border: 1px solid #3d009e;\n      border-radius: 5px; }\n  .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_AcrPGaugeContent__1k68d {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    align-items: center; }\n    .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_AcrPGaugeContent__1k68d h4 {\n      margin: 0 0 -30px;\n      display: flex;\n      align-items: center; }\n    .AcrPGauge_AcrPGauge__kyF7F .AcrPGauge_AcrPGaugeContent__1k68d .AcrPGauge_icon__sN-G7 {\n      height: 50px; }\n\n.AcrPGauge_colorBorder__3Y1wP {\n  height: 2px;\n  width: 100%;\n  background: #3d009e;\n  background: linear-gradient(to right, #3d009e, #ff063c); }\n", ""]);
// Exports
exports.locals = {
	"AcrPGauge": "AcrPGauge_AcrPGauge__kyF7F",
	"AcrPGaugeTitle": "AcrPGauge_AcrPGaugeTitle__2Q1xY",
	"powerSelect": "AcrPGauge_powerSelect__3LPvo",
	"AcrPGaugeContent": "AcrPGauge_AcrPGaugeContent__1k68d",
	"icon": "AcrPGauge_icon__sN-G7",
	"colorBorder": "AcrPGauge_colorBorder__3Y1wP"
};
module.exports = exports;
