// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TakeControl_container__1fTuC {\n  max-width: 1500px !important; }\n  .TakeControl_container__1fTuC h2 {\n    color: #ff063c;\n    font-weight: 300;\n    font-size: 35px; }\n  .TakeControl_container__1fTuC p {\n    font-size: 15px; }\n", ""]);
// Exports
exports.locals = {
	"container": "TakeControl_container__1fTuC"
};
module.exports = exports;
