import React,{ useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import classes from './StepOne.module.scss';

const StepOne = (props) => {
    // const fields = props.fields;
    const [fields,setFields] = useState([]);
    const [cannotBeSubmitted,setcannotBeSubmitted] = useState(true);
    const handleSubmit = (values) => {
        props.next(values);
    };
    // console.log("metricsFields");
    // console.log(metricsFields[0]);
    // fields.map((field,idx) => {

    // })}
    // const validYupSoc = fields.includes("soc") ? Yup.string().required(<p className="errorMessage">soc validation text</p>) : "";
    const validYupSoc = fields.includes("soc") ? metricsFields[0]["soc"]["validation"] : "";
    const validYupCurrent = fields.includes("current") ? metricsFields[0]["current"]["validation"] : "";

    const handleActivateChange = () => {
        // const { name,value } = e.target;
        // console.log('The checkbox was toggled');
        // console.log('e');
    }
    const handleChange = (e) => {
        // console.log("fields");
        // console.log(fields);
        // console.log(e.target.name);
        // console.log(e.target.checked);
        // console.log(e.target.name.split("_").pop());
        let changedMetric = e.target.name.split("_").pop();
        let targetLabel = "label_" + changedMetric;
        let myElement = document.getElementById(targetLabel);
        if (e.target.checked) {
            setcannotBeSubmitted(false);
            setFields(fields => [...fields,changedMetric]);
            // let myElement = document.getElementById(targetLabel);
            // myElement.classList.add("on");
            // myElement.classList.remove('off');
            myElement.innerHTML = "ON";
            myElement.style.textAlign = "left";
            myElement.style.paddingLeft = "7px";
            myElement.style.paddingRight = "0";
        } else {
            if (fields.length < 2) {
                setcannotBeSubmitted(true);
            }
            setFields(fields.filter(item => item !== changedMetric));
            // myElement.classList.add("off");
            // myElement.classList.remove('on');
            myElement.innerHTML = "OFF";
            myElement.style.textAlign = "right";
            myElement.style.paddingLeft = "0";
            myElement.style.paddingRight = "4px";
        }
    }

    return (
        <div className={classes.scenarioStep1}>
            <div className={classes.scenarioStep1__list}>
                {/* <input type="checkbox" onChange={handleActivateChange} id="activate_soc" name="activate_soc" /><label className={`${classes.switchLabel} ${classes.off}`} for="activate_soc">OFF</label> */}
                <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_soc" name="activate_soc" /><label className={`${classes.switchLabel}`} id="label_soc" for="activate_soc">OFF</label><span>State of charge</span></div>
                <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_current" name="activate_current" /><label className={`${classes.switchLabel}`} id="label_current" for="activate_current">OFF</label><span>Current</span></div>
                <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_third" name="activate_third" /><label className={`${classes.switchLabel}`} id="label_third" for="activate_third">OFF</label><span>Third metric</span></div>
            </div>
            <Formik
                // validationSchema={stepOneValidationSchema}
                validationSchema={Yup.object({
                    // soc: Yup.string().required(<p className="errorMessage">soc validation text</p>)
                    soc: validYupSoc,
                    current: validYupCurrent
                })}
                initialValues={props.data}
                onSubmit={handleSubmit}
            // onSubmit={(values) => {
            //     console.log(values);
            // }}
            >
                {({ values }) => (
                    <Form>
                        {fields.map((field,idx) => {
                            return (
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor={field}>{metricsFields[0][field]["label"]}</label>
                                    <Field name={`${field}comp`} as="select">
                                        <option value="lt">&lt;</option>
                                        <option value="equal">=</option>
                                        <option value="gt">&gt;</option>
                                    </Field>
                                    <Field name={field} type={metricsFields[0][field]["type"]} className="form-control" />
                                    <ErrorMessage name={field} />
                                </div>
                            )
                        })}
                        {/* <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                        <label htmlFor="soc">State of charge</label>
                        <Field name="soc" type="text" className="form-control" />
                        <ErrorMessage name="soc" />
                    </div> */}

                        <button type="button" onClick={() => props.prev(values)}>
                            Back
                        </button>
                        <button type="submit" disabled={cannotBeSubmitted}>Submit</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default StepOne;