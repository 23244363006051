import React,{ useEffect } from 'react';
import { Route } from 'react-router-dom';

import GenericPage from '../_components/Layout/GenericPage';
import Cookie from '../_components/LegalTexts/Cookie';
import PrivacyPolicy from '../_components/LegalTexts/PrivacyPolicy';
import Terms from '../_components/LegalTexts/Terms';

// import classes from './LoginPage.module.scss';

const LegalTextsPages = (props) => {
    return (
        <GenericPage sidebar={false}>
            <Route path='/cookies-policy' component={Cookie} />
            <Route path='/terms-of-use' component={Terms} />
            <Route path='/privacy-policy' component={PrivacyPolicy} />
        </GenericPage>
    );
}

export default LegalTextsPages;