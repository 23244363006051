import React,{ useState,useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import GenericPage from '../_components/Layout/GenericPage';
import RegisterForm from '../_components/Form/RegisterForm';

import { history } from '../_helpers/history';
import classes from './RegisterPage.module.scss';

const RegisterPage = (props) => {
    const user = localStorage.getItem("appState") === null ? '' : JSON.parse(localStorage.getItem('appState')).user;
    const [isAdmin,setIsAdmin] = useState(false);

    useEffect(() => {
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('installer') || user.roles.includes('admin') || user.roles.includes('super-admin')) {
                setIsAdmin(true);
            }
            if (user.roles.includes('client') && !user.roles.includes('installer') && !user.roles.includes('admin') && !user.roles.includes('super-admin')) {
                setIsAdmin(false);
                history.push('/already-registered');
            }
        }
    },[]);

    return (
        <GenericPage>
            <RegisterForm />
        </GenericPage>
    );
}

export default RegisterPage;