// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AcrelData_dataWrapper__33v1A {\n  margin: 15px 0; }\n  .AcrelData_dataWrapper__33v1A .AcrelData_title__2s_zz {\n    text-align: left;\n    font-size: 20px;\n    color: #ff063c; }\n  .AcrelData_dataWrapper__33v1A .AcrelData_data__1nbCO {\n    display: flex;\n    flex-wrap: wrap; }\n", ""]);
// Exports
exports.locals = {
	"dataWrapper": "AcrelData_dataWrapper__33v1A",
	"title": "AcrelData_title__2s_zz",
	"data": "AcrelData_data__1nbCO"
};
module.exports = exports;
