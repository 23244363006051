import React,{ useState,useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { userService } from '../../_services/userService';

const ForgotPasswordForm = (props) => {
    const [inputs,setInputs] = useState({
        email: '',
    });
    const [submitted,setSubmitted] = useState(false);
    const { email} = inputs;
  //  const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();

    // reset login status
    /*useEffect(() => {
        dispatch(userActions.logout());
    },[]);
    */
    function handleChange(e) {
        const { name,value } = e.target;
        setInputs(inputs => ({ ...inputs,[name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);

        // if (email) {
        //     dispatch(userActions.forgotPassword(email));
        // }


    }

    return (
        <section id="forgotPasswordForm" className="app-about-section angle-sp" style={{marginTop:"100px", paddingTop: '0'}}>
            <div className="container">
                <div className="row" style={{zIndex:"1"}}>
                    <div className="col-lg-8 offset-lg-2">
                        <h2>Forgot password ?</h2>
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .trim()
                                    .email(<p className="errorMessage">Invalid email address</p>)
                                    .required(<p className="errorMessage">Required</p>),
                            })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                userService.forgotPassword(values);
                                // setSubmitting(false);
                            }, 400);
                        }}
                        >
                            {(props) => 
                                <Form>
                                    <div className="form-group" style={{ margin: '10px' }}>
                                        <label htmlFor="email">Email Address</label>
                                        <Field name="email" type="email" className="form-control" />
                                        <ErrorMessage name="email" />
                                    </div>
                                    <p><Link to="/login" className="btn btn-link">Cancel</Link></p>
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                    {/* <p>You don't have an account ? <Link to="/register" className="btn btn-link">Register</Link></p> */}
                                </Form>
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForgotPasswordForm;
