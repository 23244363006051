// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AlertsList_AlertItem__3rba0 {\n  padding: 10px;\n  margin: 20px 0;\n  border-bottom: 1px solid #736ED8; }\n", ""]);
// Exports
exports.locals = {
	"AlertItem": "AlertsList_AlertItem__3rba0"
};
module.exports = exports;
