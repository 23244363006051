import React from 'react';
import CircularProgress from '../UI/Charts/CircularProgress';
import BatteryTemps from '../UI/Charts/BatteryTemps';
import WeatherForecast from '../UI/Charts/WeatherForecast';
import ActivityChart from './ActivityChart';

import classes from './Data.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const data = (props) => {
    // let visual = <p>No defined type</p>
    /*console.log("props data");
    console.log(props.icon);
    // if (props.type === "circleProgress") {
    //     visual = <CircularProgress data={props.data} />
    // }
    switch (props.type) {
        case "circleProgress":
            // visual = <CircularProgress data={props.data} />
            visual = "";
            break;
        case "batteryTemps":
            // visual = <BatteryTemps data={props.data} />
            visual = "";
            break;
        case "weatherForecast":
            // visual = <WeatherForecast data={props.data} />
            visual = "";
            break;
        case "activityChart":
            // visual = <ActivityChart />
            visual = "";
            break;
        case "select":
            visual = "";
            break;
        case "userslist":
            visual = "";
            break;
        case "userInfo":
            visual = "";
            break;
        default:
            visual = <p>No defined type</p>
            break;
    }*/
    return (
        <div className={classes.Data}>
            <h5 className={classes.dataTitle}>
                {/* <FontAwesomeIcon icon={props.icon} /> */}
                {/* <img src={require(props.icon)} /> */}
                <img className={classes.dataIcon} src={require('../../_assets/img/' + props.icon + '.png')} alt='' />
                {props.title}
                {/* <PieChartContainer data={props.data} /> */}
            </h5>
            {/* {visual} */}
            {props.children}
        </div>
    );
};

export default data;