import React,{ useEffect,useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import classes from './StepTiming.module.scss';

const StepTiming = (props) => {
    console.log("props.data in step TIMING");
    console.log(props.data);
    return (
        <div className={classes.scenarioStepWeather}>
            <p>Step timing</p>
        </div>
    );
};

export default StepTiming;