import React,{ Component } from 'react';
import i18n from "i18next";
import { withTranslation,Trans } from "react-i18next";
import { withRouter } from "react-router";
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import { NavLink as Link } from 'react-router-dom';
// import "./navigation.scss";
// import Dropdown from '../Dropdown/Dropdown';
// import DropdownHeader from '../Dropdown/DropdownHeader';
// import DropdownContent from '../Dropdown/DropdownContent';
import LogoutButton from '../../UI/LogoutButton/LogoutButton';
import WrapperHeader from '../../UI/Wrapper/WrapperHeader';
import WrapperContent from '../../UI/Wrapper/WrapperContent';
import WrapperDropdown from '../../UI/Wrapper/WrapperDropdown';
import classes from './Navigation.module.scss';
class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            // listItem: [{ title: "Mon profil",path: "/my-profile" },{ title: "Dashboard",path: "/dashboard" },{ title: "se déconnecter",path: "/dashboard" }]
            listItem: [{ title: "Mon profil",path: "/my-profile" },{ title: "Dashboard",path: "/dashboard" },{ title: "Mes alertes",path: "/my-alerts" }]
        };

    }
    _isMounted = false;

    // state = {
    //     collapsed: true,
    //     listItem: [{ title: "Mon profil",path: "/my-profile" },{ title: "Dashboard",path: "/dashboard" },{ title: "se déconnecter",path: "/dashboard" }]
    // };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        // console.log("nav path");
        // console.log(window.location.pathname);
        // console.log("props navigation");
        // console.log(this.props);
        if (window.location.pathname === "/") {
            console.log("nav home should be disabled");
        }
        this._isMounted = true;
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll",() => {
            if (window.scrollY > 170) {
                elementId.classList.add("menu-shrink");
            } else {
                elementId.classList.remove("menu-shrink");
            }
        });
        window.scrollTo(0,0);

        // if (!(window.location.pathname === '/details-one' || window.location.pathname === '/details-two' || window.location.pathname === '/blog-one' || window.location.pathname === '/blog-two' || window.location.pathname === '/blog-three')) {
        //     let mainNavLinks = document.querySelectorAll("nav ul li a");

        //     window.addEventListener("scroll",() => {
        //         let fromTop = window.scrollY;

        //         mainNavLinks.forEach(link => {
        //             let section = document.querySelector(link.hash);

        //             if (
        //                 section.offsetTop <= fromTop &&
        //                 section.offsetTop + section.offsetHeight > fromTop
        //             ) {
        //                 link.classList.add("active");
        //             } else {
        //                 link.classList.remove("active");
        //             }
        //         });
        //     });
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderMenus = () => {
        const { t } = this.props;

        // For external pages like blog & blog details
        if (window.location.pathname === '/details-one' ||
            window.location.pathname === '/details-two' ||
            window.location.pathname === '/blog-one' ||
            window.location.pathname === '/blog-two' ||
            window.location.pathname === '/blog-three') {
            return (
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link href="/">
                            <a
                                onClick={this.toggleNavbar}
                                className="nav-link"

                            >
                                Home
                            </a>
                        </Link>
                    </li>
                    <li className="nav-item">

                        <a
                            onClick={this.toggleNavbar}
                            className="nav-link"

                        >
                            Monitoring
                            </a>

                    </li>
                    <li className="nav-item">
                        <Link href="/">
                            <a
                                onClick={this.toggleNavbar}
                                className="nav-link"
                            >
                                Application
                            </a>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link href="/">
                            <a
                                onClick={this.toggleNavbar}
                                className="nav-link"
                            >
                                Support
                            </a>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link href="/" >
                            <a
                                onClick={this.toggleNavbar}
                                className="nav-link"
                            >
                                Contact
                            </a>
                        </Link>
                    </li>
                </ul>
            );
        }

        return (
            <ul className={`navbar-nav ml-auto ${classes.navUl}`}>
                <li className="nav-item">
                    <Link to="/home" className={window.location.pathname === "/home" ? "disabled active-link" : ""}>
                        <span className="nav-link">{t('common:nav.home')}</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/monitoring" className={window.location.pathname === "/monitoring" ? "disabled active-link" : ""}>
                        <span className="nav-link">{t('common:nav.concept')}</span>
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to="/application" className={window.location.pathname === "/application" ? "disabled active-link" : ""}>
                        <span
                            className="nav-link"
                        >
                            {t('common:nav.app')}
                        </span>
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="/release-notes" className={window.location.pathname === "/release-notes" ? "disabled active-link" : ""}>
                        <span className="nav-link">{t('common:nav.release-notes')}</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/glossary" className={window.location.pathname === "/glossary" ? "disabled active-link" : ""}>
                        <span className="nav-link">{t('common:nav.glossary')}</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/contact" className={window.location.pathname === "/contact" ? "disabled active-link" : ""}>
                        <span className="nav-link">{t('common:nav.contact')}</span>
                    </Link>
                </li>
            </ul>
        );
    }

    render() {
        const { t } = this.props;

        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const items = Array.from(this.state.listItem);
        const header =
            (<WrapperHeader>
                <a className={classes.userBtn}>
                    <img
                        src={require("../../../_assets/img/icone-battery-UP-IMG-personne.png")}
                        alt="User icon"
                        style={{
                            height: "50px",
                        }} />
                    <span>{this.props.user !== null ? this.props.user.firstname : ""}</span>
                </a>
            </WrapperHeader>);
        const content =
            (<WrapperContent>
                <div className={classes.userSubmenu}>
                    <ul style={{ paddingLeft: '0' }}>
                        {items.map((el,i) => {
                            return (
                                <li key={i}>
                                    <Link className={
                                        `
                            ${window.location.pathname == el.path && i != 2 ? "active-link lineHeight" : ""}
                            ${window.location.pathname == "/401" && i == 0 ? "active-link lineHeight" : ""}
                            ${window.location.pathname == "/login" && i == 1 ? "active-link lineHeight" : ""}
                            `
                                    } to={el.path}>{el.title}</Link>
                                </li>
                            )
                        })}
                        <li><LogoutButton /></li>
                    </ul>
                </div>
            </WrapperContent>);

        return (
            <nav id="navbar" className={`navbar fixed-top navbar-expand-md navbar-light top-menu ${classes.navbar}`}>

                <div className="container-fluid">
                    <Link to="/">
                        <img src={require("../../../_assets/img/Logo-Battery-Up-vector.png")} alt="Battery Up Logo" style={{ width: '200px',display: 'inline-block' }} />
                    </Link>

                    <button
                        onClick={this.toggleNavbar}
                        className={classTwo}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={classOne} id="navbarSupportedContent">
                        {this.renderMenus()}
                        <div className={classes.buttonsMobile}>
                            {this.props.isLoggedIn !== null &&
                                (this.props.location.pathname === "/home" ||
                                    this.props.location.pathname === "/monitoring" ||
                                    this.props.location.pathname === "/application" ||
                                    this.props.location.pathname === "/glossary" ||
                                    this.props.location.pathname === "/contact") && (
                                    <Link className={classes.accessOnlyMobile} to="/dashboard">
                                        {this.props.i18n.language === "fr" ? (
                                            <img src={require("../../../_assets/img/Bouton-acces-battery-up-header.png")} alt="Accès Battery Up !" className={classes.buButton} />
                                        ) : (
                                                <img src={require("../../../_assets/img/Bouton-acces-battery-up-header-EN.png")} alt="Access Battery Up !" className={classes.buButton} />
                                            )}
                                    </Link>
                                )
                            }
                            {this.props.isLoggedIn !== null && this.props.isLoggedIn !== false &&
                                <div className={classes.dropdown}>
                                    <WrapperDropdown header={header} content={content} />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {this.props.isLoggedIn !== null &&
                    (this.props.location.pathname === "/home" ||
                        this.props.location.pathname === "/monitoring" ||
                        this.props.location.pathname === "/application" ||
                        this.props.location.pathname === "/glossary" ||
                        this.props.location.pathname === "/contact") && (
                        <Link className={classes.onlyDesktop} to="/dashboard">
                            {this.props.i18n.language === "fr" ? (
                                <img src={require("../../../_assets/img/Bouton-acces-battery-up-header.png")} alt="Accès Battery Up !" className={classes.buButton} />
                            ) : (
                                    <img src={require("../../../_assets/img/Bouton-acces-battery-up-header-EN.png")} alt="Access Battery Up !" className={classes.buButton} />
                                )}
                        </Link>
                    )
                }
                {this.props.isLoggedIn !== null && this.props.isLoggedIn !== false &&
                    <div className={classes.dropdown}>
                        <WrapperDropdown header={header} content={content} />
                    </div>
                }

                {/* {(this.props.location.pathname === "/application" ||
                    this.props.location.pathname === "/" ||
                    this.props.location.pathname === "/contact" ||
                    this.props.location.pathname === "/monitoring") && (
                        <Link className={classes.onlyDesktop} to="/dashboard">
                            <img src={require("../../../_assets/img/Bouton-acces-battery-up-header.png")} alt="Access Battery Up !" className={classes.buButton} />
                        </Link>
                    )} */}
                {/* <div className={classes.dropdown}>
                    <WrapperDropdown header={header} content={content} />
                </div> */}
                {this.props.i18n.language === "fr" ? (
                    <button className={classes.lngBtn} onClick={() => i18n.changeLanguage('en')}><img src={require("../../../_assets/img/flag_en.jpeg")} />en</button>
                ) : (
                        <button className={classes.lngBtn} onClick={() => i18n.changeLanguage('fr')}><img src={require("../../../_assets/img/flag_fr.jpg")} />fr</button>
                    )}
            </nav>
        );
    }
}

export default withRouter(withTranslation(['common'])(Navigation));
