import React,{ useEffect,useState } from 'react';

import { predictionService } from '../../_services/predictionService';

import classes from './Predictions.module.scss';

const Predictions = (props) => {
    const [predictions,setPredictions] = useState([]);
    const installation = props.selectedInstallation;

    useEffect(() => {
        console.log("installation for predictions");
        console.log(installation);
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = `0${dd}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        today = `${dd}-${mm}-${yyyy}`;
        today = "17-03-2022"
        // console.log('PREDICTIONS installation user ID');
        // console.log(installation);
        let mac_address = '';
        installation.modules.forEach((device) => {
            // console.log("device.brand");
            // console.log(device.brand);
            if (device.protocol_id = 1) {
                mac_address = device.mac_address;
            }
        },[props.selectedInstallation]);

        predictionService.socPredictionsByDeviceInfoDate(installation.user_id.toString(),mac_address,today)
            // predictionService.predictionsByDeviceInfoDate(installation.user_id.toString(),'BU-50-02-91-F7-74-E3','09-03-2022')
            .then(response => {
                console.log('response get prediction by device info & date');
                setPredictions(response);
            });
    },[props.selectedInstallation]);

    return (
        <div className={classes.Predictions}>
            <p>Predictions</p>
            {predictions.length > 0 ? (
                <div className={classes.Header}>
                    <p>Date</p>
                    <p>Time</p>
                    <p>SOC</p>
                    <p>Increase 1h</p>
                    <p>Increase 9 AM</p>
                    <p>Value</p>
                    <p>Recommendation</p>
                </div>
            ) : ""
            }
            {predictions.length > 0 ? (
                predictions.map((prediction,i) => {
                    return (
                        <div className={classes.Prediction}>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__date}`}>
                                {prediction.date}
                            </div>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__time}`}>
                                {prediction.time}
                            </div>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__soc}`}>
                                {prediction.soc}
                            </div>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__varHour}`}>
                                {prediction.soc_var_hour}
                            </div>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__var9am}`}>
                                {prediction.soc_var_9am}
                            </div>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__value}`}>
                                {prediction.value}
                            </div>
                            <div className={`${classes.Prediction__property} ${classes.Prediction__recommendation}`}>
                                {prediction.recommendation ? prediction.value >= 15 ? <span className={classes.recommendYesGreen}>YES</span> : <span className={classes.recommendYesBlue}>YES</span> : <span className={classes.recommendNo}>NO</span>}
                            </div>
                        </div>
                    )
                })
            ) : ""
            }
        </div>
    )
}

export default Predictions;