// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.BatteryUpPage_BatteryUpPage__R0FyB.BatteryUpPage_greyBg__2ur1V {\n  background-color: #e4e8ec; }\n\n.BatteryUpPage_BatteryUpPage__R0FyB .BatteryUpPage_BatteryUpContent__1DB4k {\n  margin-top: 53px; }\n  @media only screen and (min-width: 768px) {\n    .BatteryUpPage_BatteryUpPage__R0FyB .BatteryUpPage_BatteryUpContent__1DB4k {\n      margin-top: 100px; } }\n  .BatteryUpPage_BatteryUpPage__R0FyB .BatteryUpPage_BatteryUpContent__1DB4k .BatteryUpPage_centralPanel__18K-g {\n    padding-top: 15px; }\n", ""]);
// Exports
exports.locals = {
	"BatteryUpPage": "BatteryUpPage_BatteryUpPage__R0FyB",
	"greyBg": "BatteryUpPage_greyBg__2ur1V",
	"BatteryUpContent": "BatteryUpPage_BatteryUpContent__1DB4k",
	"centralPanel": "BatteryUpPage_centralPanel__18K-g"
};
module.exports = exports;
