import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../../_services/dataService';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';

import FlipCard from './FlipCard';
import SimpleCard from './SimpleCard';

import classes from './DataCards.module.scss';

const DataCards = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [batteries,setBatteries] = useState([]);
    const [acrels,setAcrels] = useState([]);
    const [eastrons,setEastrons] = useState([]);
    const batteriesId = props.arrBatteries;
    const acrelsId = props.arrAcrel;
    const eastronsId = props.arrEastron;
    // console.log("batteries for map");
    // console.log(batteries);
    // console.log("acrels for map");
    // console.log(acrels);
    // console.log("eastrons for map");
    // console.log(eastrons);

    useEffect(() => {
        // console.log("batteriesId in useeffect");
        // console.log(batteriesId);
        // console.log("acrelsId in useeffect");
        // console.log(acrelsId);
        // console.log("eastronsId in useeffect");
        // console.log(eastronsId);
        if (batteriesId.length > 0) {
            // dataService.lastBatteriesValuesOld(batteriesId)
            dataService.lastBatteriesValues(batteriesId)
                .then(response => {
                    // console.log('non formatted response');
                    // console.log(response);
                    // console.log("response lastBatteriesValues done");
                    // console.log(Object.values(response));
                    if (response !== 'empty' && typeof response !== 'undefined') {
                        setBatteries(Object.values(response));
                    }
                });
        } else {
            setBatteries([]);
        }
        if (acrelsId.length > 0) {
            dataService.lastAcrelsValues(acrelsId)
                .then(response => {
                    // console.log("response lastAcrelsValues done");
                    // console.log(response);
                    if (response === 'empty') {
                        setAcrels([]);
                    } else if (typeof response === 'undefined') {
                        setAcrels([]);
                    } else {
                        setAcrels(Object.values(response));
                    }
                });
        } else {
            setAcrels([]);
        }
        if (eastronsId.length > 0) {
            dataService.lastEastronsValues(eastronsId)
                .then(response => {
                    // console.log("response lastEastronsValues done");
                    // console.log(response);
                    if (response === 'empty') {
                        setEastrons([]);
                    } else {
                        setEastrons(Object.values(response));
                    }
                });
        } else {
            setEastrons([]);
        }
    },[batteriesId,acrelsId,eastronsId]);

    return (
        // <div className={`col-lg-12 ${classes.TopData}`}>
        <div className={` ${classes.DataCards}`}>
            {batteries.length > 0 ? (
                batteries.map((battery,idx) => {
                    return (
                        <Aux>
                            {battery.date ? (
                                <Aux>
                                    {/* <FlipCard title={t('common:datas.last-record')} icon="clock" type="battery" value={battery.date.substr(0,10)} value2={battery.date.substr(11,5)} unit="" tooltip={t('common:datas.tooltips.last-record')} />
                                    <FlipCard title={battery.itot < 0 ? t('common:datas.battery.itot-charge') : t('common:datas.battery.itot-discharge')} icon="current" type="battery" value={battery.itot + ' A'} unit="" tooltip={battery.itot < 0 ? t('common:datas.tooltips.battery.itot-charge') : t('common:datas.tooltips.battery.itot-discharge')} />
                                    <FlipCard title={t('common:datas.battery.max-power')} icon="power" type="battery" value={battery.itotMax + " kW"} unit="" tooltip={t('common:datas.tooltips.battery.max-power')} />
                                    <FlipCard title={t('common:datas.battery.max-current-week')} icon="current" type="battery" value={battery.week_itot_min + ' A'} unit="" tooltip={t('common:datas.tooltips.battery.itot-charge-max')} />
                                    <FlipCard title={t('common:datas.battery.min-current-week')} icon="current" type="battery" value={battery.week_itot_max + ' A'} unit="" tooltip={t('common:datas.tooltips.battery.itot-discharge-max')} />
                                    <FlipCard title={t('common:datas.battery.average-current-hour')} icon="current" type="battery" value={battery.hour_avg_itot + ' A '} value2={battery.hour_avg_itot_v + ' W/h'} unit="" tooltip={battery.hour_avg_itot < 0 ? t('common:datas.tooltips.battery.itot-average-hour-charge') : t('common:datas.tooltips.battery.itot-average-hour-discharge')} />
                                    <FlipCard title={t('common:datas.battery.last-req-force-chg')} icon="alert" type="battery" value={battery.LastForcedCharge ? battery.LastForcedCharge.substr(0,10) : t('common:terms.no')} value2={battery.LastForcedCharge ? battery.LastForcedCharge.substr(11,5) : ""} unit="" tooltip={t('common:datas.tooltips.battery.last-req-force-chg')} /> */}
                                    <SimpleCard title={t('common:datas.last-record')} icon="clock" type="battery" value={battery.date_install.substr(0,10)} value2={battery.date_install.substr(11,5)} unit="" tooltip={t('common:datas.tooltips.last-record')} />
                                    <SimpleCard title={battery.itot < 0 ? t('common:datas.battery.itot-charge') : t('common:datas.battery.itot-discharge')} icon="current" type="battery" value={battery.itot} unit="A" tooltip={battery.itot < 0 ? t('common:datas.tooltips.battery.itot-charge') : t('common:datas.tooltips.battery.itot-discharge')} />
                                    <SimpleCard title={t('common:datas.battery.max-power')} icon="power" type="battery" value={battery.itotMax} unit="kW" tooltip={t('common:datas.tooltips.battery.max-power')} />
                                    <SimpleCard title={t('common:datas.battery.max-current-week')} icon="current" type="battery" value={battery.week_itot_max} unit="A" tooltip={t('common:datas.tooltips.battery.itot-charge-max')} />
                                    <SimpleCard title={t('common:datas.battery.min-current-week')} icon="current" type="battery" value={battery.week_itot_min} unit="A" tooltip={t('common:datas.tooltips.battery.itot-discharge-max')} />
                                    <SimpleCard title={t('common:datas.battery.average-current-hour')} icon="current" type="battery" value={battery.hour_avg_itot} value2={battery.hour_avg_itot_v} unit="A" unit2="W/h" tooltip={battery.hour_avg_itot < 0 ? t('common:datas.tooltips.battery.itot-average-hour-charge') : t('common:datas.tooltips.battery.itot-average-hour-discharge')} />
                                    {/* <SimpleCard title={t('common:datas.battery.last-req-force-chg')} icon="alert" type="battery" value={battery.LastForcedCharge ? battery.LastForcedCharge.substr(0,10) : t('common:terms.no')} value2={battery.LastForcedCharge ? battery.LastForcedCharge.substr(11,5) : ""} unit="" tooltip={t('common:datas.tooltips.battery.last-req-force-chg')} /> */}
                                    <SimpleCard title={t('common:datas.battery.last-req-force-chg')} icon="soc" type="battery" value={battery.LastForcedCharge ? battery.LastForcedCharge.substr(0,10) : t('common:datas.alarm-not-triggered')} value2={battery.LastForcedCharge ? battery.LastForcedCharge.substr(11,5) : ""} unit="" tooltip={t('common:datas.tooltips.battery.last-req-force-chg')} />
                                </Aux>
                            ) : ""}
                        </Aux>
                    )
                    // })) : acrelsId.length > 0 ? (
                    //     <p>Pas de </p>
                    // ) : ""
                })) : ""
            }
            {acrels.length > 0 ? (
                acrels.map((acrel,idx) => {
                    return (
                        <Aux>
                            {acrel.date ? (
                                <Aux>
                                    {/* <FlipCard title={t('common:datas.last-record')} icon="clock" type="energy_meter" value={acrel.date.substr(0,10)} value2={acrel.date.substr(11,5)} unit="" tooltip={t('common:datas.tooltips.last-record')} />
                                    <FlipCard title={t('common:datas.acrel.acr-p')} icon="power" type="energy_meter" value={acrel.acr_p + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.power')} />
                                    <FlipCard title={t('common:datas.acrel.max-import')} icon="consumption" type="energy_meter" value={acrel.maxConsum + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.import-max')} />
                                    <FlipCard title={t('common:datas.acrel.max-export')} icon="production" type="energy_meter" value={acrel.maxProd + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.export-max')} /> */}
                                    <SimpleCard title={t('common:datas.last-record')} icon="clock" type="energy_meter" value={acrel.date.substr(0,10)} value2={acrel.date.substr(11,5)} unit="" tooltip={t('common:datas.tooltips.last-record')} />
                                    <SimpleCard title={t('common:datas.acrel.acr-p')} icon="power" type="energy_meter" value={acrel.acr_p} unit="W" tooltip={t('common:datas.tooltips.energy-meter.power')} />
                                    <SimpleCard title={t('common:datas.acrel.max-import')} icon="consumption" type="energy_meter" value={acrel.maxConsum} unit="W" tooltip={t('common:datas.tooltips.energy-meter.import-max')} />
                                    <SimpleCard title={t('common:datas.acrel.max-export')} icon="production" type="energy_meter" value={acrel.maxProd} unit="W" tooltip={t('common:datas.tooltips.energy-meter.export-max')} />
                                </Aux>
                            ) : ""}
                            {/* <div className={`${classes.acrel} ${classes.listItem}`} key={idx}>
                                <h4>{acrel.module_name}<span>({acrel.mac_address})</span></h4>
                                <p className={classes.date}>{t('common:datas.last-record')}: {acrel.date}</p>
                                <div className={classes.dataWrapper}>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.acrel.acr-p')}</h5>
                                        {Math.abs(acrel.acr_p) >= 1 ? (
                                            <p>{acrel.acr_p + 'W'} kW</p>
                                        ) : (
                                            <p>{acrel.acr_p * 1000} W</p>
                                        )}
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.acrel.max-consum')}</h5>
                                        <p>{acrel.maxConsum} W</p>
                                    </div>
                                    <div className={classes.data}>
                                        <h5>{t('common:datas.acrel.max-prod')}</h5>
                                        <p>{acrel.maxProd} W</p>
                                    </div>
                                </div>
                            </div> */}
                        </Aux>
                    )
                    // })) : acrelsId.length > 0 ? (
                    //     <p>Pas de </p>
                    // ) : ""
                })) : ""
            }
            {eastrons.length > 0 ? (
                eastrons.map((eastron,idx) => {
                    return (
                        <Aux>
                            {eastron.date ? (
                                <Aux>
                                    {/* <FlipCard title={t('common:datas.last-record')} icon="clock" type="battery" value={eastron.date.substr(0,10)} value2={eastron.date.substr(11,5)} unit="" tooltip={t('common:datas.tooltips.last-record')} />
                                    <FlipCard title={t('common:datas.eastron.power')} icon="power" type="energy_meter" value={eastron.power + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.power')} />
                                    <FlipCard title={t('common:datas.eastron.max-consum')} icon="consumption" type="energy_meter" value={eastron.maxConsum + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.import-max')} />
                                    <FlipCard title={t('common:datas.eastron.max-prod')} icon="production" type="energy_meter" value={eastron.maxProd + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.export-max')} /> */}
                                    <SimpleCard title={t('common:datas.last-record')} icon="clock" type="energy_meter" value={eastron.date.substr(0,10)} value2={eastron.date.substr(11,5)} unit="" tooltip={t('common:datas.tooltips.last-record')} />
                                    <SimpleCard title={t('common:datas.eastron.power')} icon="power" type="energy_meter" value={eastron.power + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.power')} />
                                    <SimpleCard title={t('common:datas.eastron.max-consum')} icon="consumption" type="energy_meter" value={eastron.maxConsum + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.import-max')} />
                                    <SimpleCard title={t('common:datas.eastron.max-prod')} icon="production" type="energy_meter" value={eastron.maxProd + ' W'} unit="" tooltip={t('common:datas.tooltips.energy-meter.export-max')} />
                                    {/* <FlipCard title={t('common:datas.battery.max-power')} icon="power" type="battery" value={battery.itotMax + " kW"} unit="" tooltip={t('common:datas.tooltips.battery.max-power')} />
                                    <FlipCard title={t('common:datas.battery.max-current-week')} icon="current" type="battery" value={battery.week_itot_min + ' A'} unit="" tooltip={t('common:datas.tooltips.battery.itot-charge-max')} />
                                    <FlipCard title={t('common:datas.battery.min-current-week')} icon="current" type="battery" value={battery.week_itot_max + ' A'} unit="" tooltip={t('common:datas.tooltips.battery.itot-discharge-max')} />
                                    <FlipCard title={t('common:datas.battery.average-current-hour')} icon="current" type="battery" value={battery.hour_avg_itot + ' A '} value2={battery.hour_avg_itot_v + ' W/h'} unit="" tooltip={battery.hour_avg_itot < 0 ? t('common:datas.tooltips.battery.itot-average-hour-charge') : t('common:datas.tooltips.battery.itot-average-hour-discharge')} />
                                    <FlipCard title={t('common:datas.battery.last-req-force-chg')} icon="alert" type="battery" value={battery.LastForcedCharge ? battery.LastForcedCharge.substr(0,10) : t('common:terms.no')} value2={battery.LastForcedCharge ? battery.LastForcedCharge.substr(11,5) : ""} unit="" tooltip={t('common:datas.tooltips.battery.last-req-force-chg')} /> */}
                                </Aux>
                            ) : ""}
                        </Aux>
                        // <div className={`col-md-6 ${classes.TopData__list}`}>
                        //     <div className={`${classes.eastron} ${classes.listItem}`} key={idx}>
                        //         <h4>{eastron.module_name}<span>({eastron.mac_address})</span></h4>
                        // <p className={classes.date}>{t('common:datas.last-record')}: {eastron.date}</p>
                        // <div className={classes.dataWrapper}>
                        //     <div className={classes.data}>
                        //     <h5>{t('common:datas.eastron.power')}</h5>
                        //     <p>{eastron.power} W</p>
                        // </div>
                        // <div className={classes.data}>
                        //                 <h5>{t('common:datas.eastron.max-consum')}</h5>
                        //                 <p>{eastron.maxConsum} W</p>
                        //             </div>
                        //             <div className={classes.data}>
                        //                 <h5>{t('common:datas.eastron.max-prod')}</h5>
                        //                 <p>{eastron.maxProd < 0 ? (eastron.maxProd + "W") : t('common:datas.n-a')}</p>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>
                    )
                })) : ""
            }
        </div>
    )
};

export default DataCards;