import React from 'react';

import classes from './AdminBlock.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdminBlock = (props) => {
    // console.log("props admin block");
    // console.log(props);
    return (
        <div className={classes.AdminBlock}>
            <h5 className={classes.AdminBlockTitle}>
                {/* <FontAwesomeIcon icon={props.icon} /> */}
                {/* <img src={require(props.icon)} /> */}
                <img className={classes.AdminBlockIcon} src={require('../../../_assets/img/' + props.icon + '.png')} alt='' />
                {props.title}
                {/* <PieChartContainer data={props.data} /> */}
            </h5>
            {/* {visual} */}
            {props.children}
        </div>
    );
};

export default AdminBlock;