import React from 'react';

import classes from './Notification.module.scss';

const Notification = (props) => {
    const title = props.title;
    const type = props.type;
    const message = props.message;
    const info = props.info;
    const ctrlId = props.ctrlId;

    function closeNotificationHandler() {
        console.log("ctrlId");
        console.log(ctrlId);
        console.log("closeNotificationHandler triggered");
        localStorage.setItem(ctrlId,"false");
    }

    return (
        <div className={type === "danger" ? `${classes.Notification} ${classes.danger}` : type === "warning" ? `${classes.Notification} ${classes.warning}` : type === "green" ? `${classes.Notification} ${classes.green}` : `${classes.Notification} ${classes.info}`}>
            <div className={classes.Notification__wrapper}>
                <div className={classes.Notification__icon}>
                    {type === "danger" ? (
                        <img src={require("../../../_assets/img/icons/notifications/icon_alert.png")} alt="" />
                    ) : type === "warning" ? (
                        <img src={require("../../../_assets/img/icons/notifications/icon_warning.png")} alt="" />
                    ) : type === "green" ? (
                        <img src={require("../../../_assets/img/icons/notifications/icon_green.png")} alt="" />
                    ) : (
                        <img src={require("../../../_assets/img/icons/notifications/icon_info.png")} alt="" />
                    )}
                </div>
                <div className={classes.Notification__content}>
                    <h5 className={classes.Notification__title}>{title}</h5>
                    <p className={classes.Notification__message}>{message}</p>
                    <p className={classes.Notification__info}>{info}</p>
                </div>
                <div className={classes.Notification__actions}>
                    <p className={classes.Notification__closeBtn} onClick={closeNotificationHandler}>X</p>
                </div>
            </div>
            <div className={classes.colorBorder}></div>
        </div>
    )
}

export default Notification;