import React,{ Component } from 'react';
import { Switch,Route,useRouteMatch } from 'react-router-dom';

import { addressService } from '../../_services/addressService';
import { userService } from '../../_services/userService';
import ClientChoice from '../Admin/ClientChoice';
import InstallationChoice from '../Admin/InstallationChoice';

import Aux from '../../_hoc/Auxil/Auxil';
import Sidebar from '../Layout/Sidebar/Sidebar';
import UserProfile from '../MyProfilePage/UserProfile';
import NMonitoringLayout from '../Layout/NMonitoringLayout';

import classes from './BatteryUpPage.module.scss';

class NAdminLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedClient: this.props.selectedClient,
            // selectedClient: JSON.parse(localStorage.getItem('appState')).user,
            // selectedInstallation: this.props.selectedInstallation,
            selectedInstallation: JSON.parse(localStorage.getItem('appState')).user.addresses.length > 0 ? JSON.parse(localStorage.getItem('appState')).user.addresses[0] : "",
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleInstallationChoice = this.handleInstallationChoice.bind(this);
    }

    handleSelectChange(event) {
        userService.getById(event.target.value)
            .then(response => {
                this.setState({ selectedClient: response });
                console.log('selected in handle')
                if (response.addresses.length > 0) {
                    this.setState({ selectedInstallation: response.addresses[0] });
                } else {
                    this.setState({ selectedInstallation: JSON.parse(localStorage.getItem('demo')) });
                }
            });
    }

    handleInstallationChoice(event) {
        addressService.getAddressById(event.target.value)
            .then(response => {
                this.setState({ selectedInstallation: response });
            });
    }

    componentDidMount() {
        // if(typeof this.state.selectedInstallation === "undefined") {
        console.log("it happens now => demo address !");
        console.log("NAdminLayout");
        console.log(this.state.selectedClient);
        if (this.state.selectedClient.addresses.length === 0) {
            console.log("In if");
            addressService.getAddressById(29)
                .then(response => {
                    console.log("response did mount BUPage");
                    console.log(response);
                    this.setState({ selectedInstallation: response });
                });
        }
    }

    render() {
        // const { path } = useRouteMatch();
        const { match } = this.props;
        console.log('match admin Layout');
        console.log(match);

        const user = JSON.parse(localStorage.getItem('appState')).user;
        const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
        let clientChoice = "";
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
                // clientChoice = (<div className={`col-md-6 ${classes.clientChoice}`}>
                clientChoice = (<div className={`col-md-6`}>
                    {/* <ClientChoice handleSelectChange={this.handleSelectChange} changeClient={this.handleClient} /> */}
                    <ClientChoice selectedClient={this.state.selectedClient} changed={this.handleSelectChange} />
                </div>);
            }
        }
        let installChoice = "";
        if (this.state.selectedClient.addresses.length > 0) {
            installChoice = (<div className={`col-md-6`}>
                <InstallationChoice selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} changed={this.handleInstallationChoice} />
            </div>);
        }
        console.log("this.state BU page");
        console.log(this.state);
        return (
            <Aux>
                <div className="col-md-3 col-lg-2 m-0 p-0">
                    <Sidebar />
                </div>
                <div className="col-md-9 col-lg-10 m-0 p-0">
                    <div className={classes.centralPanel}>
                        <div className="container-fluid">
                            <div className="row">
                                {clientChoice}
                                {installChoice}
                            </div>
                            <div className="row">
                                {/* <Route
                                    path='/dashboard'
                                    render={(props) => (
                                        <DataContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                                    )}
                                />
                                <Route
                                    path='/already-registered'
                                    render={(props) => (
                                        <AlreadyRegistered />
                                    )}
                                />
                                <Route
                                    path='/register-module'
                                    render={(props) => (
                                        <RegisterModule {...props} />
                                    )}
                                /> */}
                                <Route
                                    path={`${match}/my-profile`}
                                    render={(props) => (
                                        <UserProfile />
                                    )}
                                />
                                <Route
                                    path="/monitoring"
                                    render={(props) => (
                                        <NMonitoringLayout selectedInstallation={this.state.selectedInstallation} />
                                    )}
                                />
                                {/* <PrivateRoute path="/monitoring" component={NMonitoringLayout} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default NAdminLayout;