import React,{ useState,useEffect,useRef } from 'react';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import Data from '../Data/Data';
import Aux from '../../_hoc/Auxil/Auxil';

import classes from './UserTriggers.module.scss';

import TriggersIndex from './Index';
import TriggerForm from './TriggerForm';

const UserTriggers = (props) => {
    const { t } = useTranslation(['common','alertspage']);
    const [triggerTarget,setTriggerTarget] = useState("");
    const [triggerAdded,setTriggerAdded] = useState("");

    // const modules = props.modules;
    const [modules,setModules] = useState([]);
    // const auth = JSON.parse(localStorage.getItem('appState')).user;

    function handleTriggerChange(newValue) {
        console.log("newValue handleTriggerChange parent");
        console.log(newValue);
        setTriggerTarget(newValue);
    }

    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' });

    function handleTriggerAddition(newValue) {
        // console.log("newValue handleTriggerChange parent");
        // console.log(newValue);
        setTriggerAdded(newValue);
        setTriggerTarget("");
        executeScroll();
        // window.scrollBy(0,-300)
    }

    function handleTriggerDeletion() {
        setTriggerAdded("");
    }

    useEffect(() => {
        console.log("props.modules");
        console.log(props.modules);
        setModules(props.modules);
    },[props.modules]);

    return (
        <Data
            icon={"icon_alerts"}
            title={t('triggerspage:UserTriggers.title')}
            type={t('triggerspage:UserTriggers.title')}
        >
            <Aux>
                <div ref={myRef} style={{ position: 'relative',bottom: '200px' }}></div>
                {/* <div style={{ position: 'relative',bottom: '100px' }}></div> */}
                {triggerAdded && triggerAdded !== "" ? (
                    <div className="row">
                        <div className="col-12">
                            <p className={classes.notificationText}>{triggerAdded === "add" ? t('triggerspage:UserTriggers.trigger-added') : t('triggerspage:UserTriggers.trigger-edited')}</p>
                        </div>
                    </div>
                ) : ""}
                {/* <div className="row">
                <div className={`col-lg-12`}> */}
                <TriggersIndex modules={modules} handleTriggerChange={handleTriggerChange} handleTriggerDeletion={handleTriggerDeletion} triggerAdded={triggerAdded} />
                <div className={classes.formWrapper}>
                    <TriggerForm modules={modules} triggerTarget={triggerTarget} handleTriggerAddition={handleTriggerAddition} />
                </div>
                {/* </div>
            </div> */}
            </Aux>
        </Data>
    )
};

export default UserTriggers;