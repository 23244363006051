// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EastronData_dataWrapper__3m4aV .EastronData_title__2BfEt {\n  text-align: left;\n  font-size: 20px;\n  color: #ff063c; }\n\n.EastronData_dataWrapper__3m4aV .EastronData_data__3DVzq {\n  display: flex;\n  flex-wrap: wrap; }\n", ""]);
// Exports
exports.locals = {
	"dataWrapper": "EastronData_dataWrapper__3m4aV",
	"title": "EastronData_title__2BfEt",
	"data": "EastronData_data__3DVzq"
};
module.exports = exports;
