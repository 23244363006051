import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { dataService } from '../../../_services/dataService';
import { moduleService } from '../../../_services/moduleService';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';

import AlarmCard from '../Cards/AlarmCard';
import SmallDataCard from '../Cards/SmallDataCard';

import classes from './EastronData.module.scss';

const EastronData = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const [eastron,setEastron] = useState([]);
    const macAddress = [props.mac_address];
    const [eastronName,setEastronName] = useState("Eastron");

    console.log("EastronId");
    console.log(macAddress);

    useEffect(() => {
        if (macAddress.length > 0) {
            moduleService.getNameByMacAddress(macAddress)
                .then(response => {
                    // if (response !== 'empty') {
                    console.log("response getNameByMacAddress");
                    console.log(response);
                    setEastronName(response);
                    // }
                });
            dataService.eastronLatestData(macAddress)
                .then(response => {
                    console.log("response eastronLatestData");
                    console.log(response);
                    if (response !== 'empty') {
                        setEastron(Object.values(response));
                    } else {
                        console.log("response");
                        console.log(response);
                        setEastron(response);
                    }
                });
        } else {
            setEastron([]);
        }
        // },[macAddress]);
    },[]);

    console.log("eastron");
    console.log(eastron);

    return (
        <Aux>
            {/* <div className={`col-md-12 ${classes.AdditionalBatteriesData__list}`}> */}
            {eastron.length > 0 ? (
                eastron === "empty" ? "is empty" : (
                    // <p>test</p>
                    eastron.map((energyMeter,idx) =>
                        <div className={classes.dataWrapper} key={idx}>
                            <h4 className={classes.title}>Les autres données Eastron - {eastronName}</h4>
                            <div className={`${classes.data}`}>
                                {/* {(energyMeter.voltage1 !== "0.00" && energyMeter.voltage2 === "0.00" && energyMeter.voltage3 === "0.00") ? ( */}
                                {(energyMeter.voltage1 && !energyMeter.voltage2 && !energyMeter.voltage3) ? (
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage')} value={energyMeter.voltage1 + " V"} />
                                ) : (
                                    <Aux>
                                        {(energyMeter.voltage1 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage1')} value={energyMeter.voltage1 + " V"} /> : "")}
                                        {(energyMeter.voltage2 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage2')} value={energyMeter.voltage2 + " V"} /> : "")}
                                        {(energyMeter.voltage3 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage3')} value={energyMeter.voltage3 + " V"} /> : "")}
                                        {/* <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage1')} value={energyMeter.voltage1 + " V"} />
                                        <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage2')} value={energyMeter.voltage2 + " V"} />
                                        <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage3')} value={energyMeter.voltage3 + " V"} /> */}
                                    </Aux>
                                )}
                                {(energyMeter.current1 && !energyMeter.current2 && !energyMeter.current3) ? (
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current')} value={energyMeter.current1 + " A"} />
                                ) : (
                                    <Aux>
                                        {(energyMeter.current1 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current1')} value={energyMeter.current1 + " A"} /> : "")}
                                        {(energyMeter.current2 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current2')} value={energyMeter.current2 + " A"} /> : "")}
                                        {(energyMeter.current3 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current3')} value={energyMeter.current3 + " A"} /> : "")}
                                    </Aux>
                                )}
                                {(energyMeter.act_pow1 && !energyMeter.act_pow2 && !energyMeter.act_pow3) ? (
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow')} value={energyMeter.act_pow1 + " W"} />
                                ) : (
                                    <Aux>
                                        {(energyMeter.act_pow1 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow1')} value={energyMeter.act_pow1 + " W"} /> : "")}
                                        {(energyMeter.act_pow2 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow2')} value={energyMeter.act_pow2 + " W"} /> : "")}
                                        {(energyMeter.act_pow3 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow3')} value={energyMeter.act_pow3 + " W"} /> : "")}
                                    </Aux>
                                )}
                                {(energyMeter.app_pow1 && !energyMeter.app_pow2 && !energyMeter.app_pow3) ? (
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow')} value={energyMeter.app_pow1 + " W"} />
                                ) : (
                                    <Aux>
                                        {(energyMeter.app_pow1 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow1')} value={energyMeter.app_pow1 + " W"} /> : "")}
                                        {(energyMeter.app_pow2 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow2')} value={energyMeter.app_pow2 + " W"} /> : "")}
                                        {(energyMeter.app_pow3 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow3')} value={energyMeter.app_pow3 + " W"} /> : "")}
                                    </Aux>
                                )}
                                {(energyMeter.react_pow1 && !energyMeter.react_pow2 && !energyMeter.react_pow3) ? (
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow')} value={energyMeter.react_pow1 + " W"} />
                                ) : (
                                    <Aux>
                                        {(energyMeter.react_pow1 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow1')} value={energyMeter.react_pow1 + " W"} /> : "")}
                                        {(energyMeter.react_pow2 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow2')} value={energyMeter.react_pow2 + " W"} /> : "")}
                                        {(energyMeter.react_pow3 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow3')} value={energyMeter.react_pow3 + " W"} /> : "")}
                                    </Aux>
                                )}
                                {(energyMeter.pow_fact1 && !energyMeter.pow_fact2 && !energyMeter.pow_fact3) ? (
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact')} value={energyMeter.pow_fact1} />
                                ) : (
                                    <Aux>
                                        {(energyMeter.pow_fact1 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact1')} value={energyMeter.pow_fact1} /> : "")}
                                        {(energyMeter.pow_fact2 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact2')} value={energyMeter.pow_fact2} /> : "")}
                                        {(energyMeter.pow_fact3 ? <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact3')} value={energyMeter.pow_fact3} /> : "")}
                                    </Aux>
                                )}
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.freq')} value={energyMeter.freq + " Hz"} />
                            </div>
                        </div>
                    )
                )
            ) : ""
            }

            {/* {eastron.length > 0 ? (
                eastron.map((energyMeter,idx) =>
                    <div className={classes.dataWrapper} key={idx}>
                        <h4 className={classes.title}>Les autres données Eastron - {eastronName}</h4>
                        <div className={`${classes.data}`}>
                            {(eastron.voltage1 !== "0.00" && eastron.voltage2 === "0.00" && eastron.voltage3 === "0.00") ? (
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage')} value={eastron.voltage1 + " V"} />
                            ) : (
                                <Aux>
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage1')} value={eastron.voltage1 + " V"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage2')} value={eastron.voltage2 + " V"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.voltage3')} value={eastron.voltage3 + " V"} />
                                </Aux>
                            )}
                            {(eastron.current1 !== "0.00" && eastron.current2 === "0.00" && eastron.current3 === "0.00") ? (
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current')} value={eastron.current1 + " A"} />
                            ) : (
                                <Aux>
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current1')} value={eastron.current1 + " A"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current2')} value={eastron.current2 + " A"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.current3')} value={eastron.current3 + " A"} />
                                </Aux>
                            )}
                            {(eastron.act_pow1 !== "0.00" && eastron.act_pow2 === "0.00" && eastron.act_pow3 === "0.00") ? (
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow')} value={eastron.act_pow1 + " W"} />
                            ) : (
                                <Aux>
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow1')} value={eastron.act_pow1 + " W"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow2')} value={eastron.act_pow2 + " W"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.act_pow3')} value={eastron.act_pow3 + " W"} />
                                </Aux>
                            )}
                            {(eastron.app_pow1 !== "0.00" && eastron.app_pow2 === "0.00" && eastron.app_pow3 === "0.00") ? (
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow')} value={eastron.app_pow1 + " W"} />
                            ) : (
                                <Aux>
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow1')} value={eastron.app_pow1 + " W"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow2')} value={eastron.app_pow2 + " W"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.app_pow3')} value={eastron.app_pow3 + " W"} />
                                </Aux>
                            )}
                            {(eastron.react_pow1 !== "0.00" && eastron.react_pow2 === "0.00" && eastron.react_pow3 === "0.00") ? (
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow')} value={eastron.react_pow1 + " W"} />
                            ) : (
                                <Aux>
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow1')} value={eastron.react_pow1 + " W"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow2')} value={eastron.react_pow2 + " W"} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.react_pow3')} value={eastron.react_pow3 + " W"} />
                                </Aux>
                            )}
                            {(eastron.pow_fact1 !== "0.00" && eastron.pow_fact2 === "0.00" && eastron.pow_fact3 === "0.00") ? (
                                <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact')} value={eastron.pow_fact1} />
                            ) : (
                                <Aux>
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact1')} value={eastron.pow_fact1} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact2')} value={eastron.pow_fact2} />
                                    <SmallDataCard type="energy_meter" name={t('common:datas.eastron.pow_fact3')} value={eastron.pow_fact3} />
                                </Aux>
                            )}
                            <SmallDataCard type="energy_meter" name={t('common:datas.eastron.freq')} value={eastron.freq + " Hz"} />
                        </div>
                    </div>
                )) : macAddress.length > 0 ? (
                    <Spinner />
                ) : ""
            } */}
        </Aux>
    )
};

export default EastronData;