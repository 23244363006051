import React,{ useState,useEffect } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

import UserInfo from '../_components/Profile/UserInfo';

function UserDetailsPage(props) {
    const [params,setParams] = useState(props.match.params);

    return (
        <BatteryUpPage params={params}>
            {/* <UserInfo params={params} /> */}
        </BatteryUpPage>
    );
}

export { UserDetailsPage };