import React,{ Component,useEffect } from 'react';
import { Router,Route,Switch,Redirect } from 'react-router-dom';
import { PrivateRoute } from '../Routes/PrivateRoute';

import i18n from "i18next";
import { withTranslation,Trans } from "react-i18next";

import classes from './BatteryUpPage.module.scss';

import Navigation from './Navigation/Navigation';
import Sidebar from './Sidebar/Sidebar';
import MainFooter from './Footer/MainFooter';
import GoTop from '../UI/GoTop/GoTop';
import Modal from '../UI/Modal/Modal';
import { history } from '../../_helpers/history';

import ClientChoice from '../Admin/ClientChoice';
import DeviceChoice from '../Dashboard/DeviceChoice';
import ConfigInstallations from './ConfigInstallations';
import InstallationChoice from './InstallationChoice';

import DataContainer from '../Dashboard/DataContainer';
import NDataContainer from '../Dashboard/NDataContainer';
import ODataContainer from '../Dashboard/ODataContainer';
import DevDataContainer from '../Dashboard/DevDataContainer';
import AlreadyRegistered from '../Admin/AlreadyRegistered';
import RegisterModule from '../Admin/RegisterModule';
import UserProfile from '../MyProfilePage/UserProfile';
import AddressForm from '../Form/AddressForm';
import UsersList from '../UsersPage/UsersList';
import UserInfo from '../Profile/UserInfo';
import UserForm from '../Form/UserForm';
import UserAlerts from '../Alerts/UserAlerts';
import ReleaseNotesIndex from '../ReleaseNotes/ReleaseNotesIndex';
import ReleaseNoteForm from '../ReleaseNotes/ReleaseNoteForm';
// import UserDispatcher from './UserDispatcher';
import Aux from '../../_hoc/Auxil/Auxil';

import { userService } from '../../_services/userService';
import { addressService } from '../../_services/addressService';
import { moduleService } from '../../_services/moduleService';
import { sermatecService } from '../../_services/sermatecService';
import ReleaseNotes from '../ReleaseNotes/ReleaseNotes';

class UserDispatcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedClient: JSON.parse(localStorage.getItem('appState')).user,
            selectedInstallation: JSON.parse(localStorage.getItem('appState')).user.addresses.length > 0 ? JSON.parse(localStorage.getItem('appState')).user.addresses[0] : "",
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleInstallationChoice = this.handleInstallationChoice.bind(this);
    }

    handleSelectChange(event) {
        userService.getById(event.target.value)
            .then(response => {
                if (response.addresses.length > 0) {
                    this.setState({
                        selectedClient: response,
                        selectedInstallation: response.addresses[0]
                    });
                } else {
                    this.setState({
                        selectedClient: response,
                        selectedInstallation: JSON.parse(localStorage.getItem('demo'))
                    });
                }
            });
    }

    handleInstallationChoice(event) {
        addressService.getAddressById(event.target.value)
            .then(response => {
                this.setState({ selectedInstallation: response });
            });
    }

    resetHandler() {
        localStorage.setItem("mustReset","no");
        history.push('/hardreset');
    }

    componentDidMount() {
        // console.log("culprit");
        console.log("UserDispatcher");
        console.log(this.state.selectedClient);
        if (this.state.selectedClient.addresses.length === 0) {
            addressService.getAddressById(29)
                .then(response => {
                    this.setState({ selectedInstallation: response });
                });
        }
    }

    render() {
        const { t } = this.props;

        const user = JSON.parse(localStorage.getItem('appState')).user;
        const mainInfo = JSON.parse(localStorage.getItem('mainInfo'));
        const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
        let clientChoice = "";
        if (typeof user.roles !== 'undefined') {
            if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
                clientChoice = (<div className={`col-md-6`}>
                    <ClientChoice selectedClient={this.state.selectedClient} changed={this.handleSelectChange} />
                </div>);
            }
        }
        let installChoice = "";
        if (this.state.selectedClient.addresses.length > 0) {
            installChoice = (<div className={`col-md-6`}>
                <InstallationChoice selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} changed={this.handleInstallationChoice} />
            </div>);
        }
        return (
            <Aux>
                <div className="row">
                    {clientChoice}
                    {installChoice}
                </div>
                <div className="row">
                    <Route
                        path='/dashboard'
                        render={(props) => (
                            // <NDataContainer {...props} selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} initBatteries={[]} />
                            <ODataContainer selectedClient={this.state.selectedClient} selectedInstallation={this.state.selectedInstallation} />
                        )}
                    />
                </div>
            </Aux>

        );
    }
}

export default (withTranslation(['common','contactpage'])(UserDispatcher));
