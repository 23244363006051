import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './DataContainer.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import ChartContainer from '../Data/ChartContainer';
// import ChartContainer from '../Data/ChartContainerMongo';
import TopData from '../Dashboard/TopData';
import AdditionalBatteriesData from '../Dashboard/AdditionalBatteriesData';
import WeatherWithForecast from '../Data/WeatherWithForecast';
import FullWeather from '../Weather/FullWeather';
import SocVisual from '../Data/SocVisual';
import PylontechVisualSummary from '../Data/Pylontech/PylontechVisualSummary';
import SocVisualWrapper from '../Data/SocVisualWrapper';
import AcrPGauge from '../Data/AcrPGauge';
import SingleValueCard from '../Data/Cards/SingleValueCard';
import CircleCard from '../Data/Cards/CircleCard';
import DataCards from '../Data/Cards/DataCards';
import Legend from './Legend';
import PylontechData from '../Data/Pylontech/PylontechData';
import PylontechAlarms from '../Data/Pylontech/PylontechAlarms';
import AcrelData from '../Data/Acrel/AcrelData';
import EastronData from '../Data/Eastron/EastronData';
import Data from '../Data/Data';
import DevicesControlWrapper from './DevicesControlWrapper';

const NDataContainer = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const initInstall = props.selectedInstallation;
    // const initBatteries = props.initBatteries;
    const [newTest,setNewTest] = useState([]);
    const [batteries,setBatteries] = useState([]);
    // const [batteries,setBatteries] = useState(initInstall.modules[0].protocol_id === 1 ? [initInstall.modules[0].mac_address] : "");
    const [acrels,setAcrels] = useState([]);
    const [eastrons,setEastrons] = useState([]);
    // console.log("props NDataContainer");
    // console.log(props);
    // console.log("initInstall NDataContainer");
    // console.log(initInstall);
    // console.log("initBatteries NDataContainer");
    // console.log(initBatteries);
    // console.log("newTest NDataContainer init");
    // console.log(newTest);
    // console.log("batteries NDataContainer init");
    // console.log(batteries);

    useEffect(() => {
        // (async () => {
        // setBatteries(initBatteries);
        // console.log("batteries NDataContainer useEffect inside");
        // console.log(batteries);
        let installBatteries = [];
        let installAcrels = [];
        let installEastrons = [];
        if (initInstall && initInstall.modules) {
            installBatteries.length = 0;
            installAcrels.length = 0;
            installEastrons.length = 0;
            for (let i = 0; i < initInstall.modules.length; i++) {
                if (initInstall.modules[i].protocol_id === 1) {
                    installBatteries.push(initInstall.modules[i].mac_address);
                }
                if (initInstall.modules[i].protocol_id === 2 || initInstall.modules[i].protocol_id === 4) {
                    installAcrels.push(initInstall.modules[i].mac_address);
                }
                if (initInstall.modules[i].protocol_id === 3 || initInstall.modules[i].protocol_id === 5) {
                    console.log("should add eastron in array");
                    installEastrons.push(initInstall.modules[i].mac_address);
                }
            }
            // console.log("installBatteries in NDataContainer");
            // console.log(installBatteries);
            // console.log("installAcrels in NDataContainer");
            // console.log(installAcrels);
            // console.log("installEastrons in NDataContainer");
            // console.log(installEastrons);
            if (installBatteries.length > 0) {
                // setBatteries(initBatteries);
                // console.log("Batteries not empty");
                setBatteries(installBatteries);
            } else {
                // console.log("Batteries empty array");
                setBatteries([]);
            }
            if (installAcrels.length > 0) {
                // setAcrels([]);
                // console.log("Acrels not empty");
                setAcrels(installAcrels);
            } else {
                // console.log("Acrels empty array");
                setAcrels([]);
            }
            if (installEastrons.length > 0) {
                // setEastrons([]);
                // console.log("Eastrons not empty");
                setEastrons(installEastrons);
            } else {
                // console.log("Eastrons empty array");
                setEastrons([]);
            }
        }
        else {
            setBatteries([]);
            setAcrels([]);
            setEastrons([]);
        }
        // })()

        // },[props.selectedInstallation,props.initBatteries]);
    },[props.selectedInstallation,props.selectedClient]);
    // },[initInstall,initBatteries]);

    // console.log("batteries NDataContainer useEffect below");
    // console.log(batteries);

    return (
        <Aux>
            {(batteries.length > 0 || acrels.length > 0 || eastrons.length > 0) ? (
                <DevicesControlWrapper batteries={batteries} acrels={acrels} eastrons={eastrons} />
            ) : ""}

            {/* {this.props.i18n.language === "fr" ? (
                <p>Chers clients,<br/>Nous avons actuellement un problème de AAAA avec les batteries Pylontech US 2000 C.<br/></p>
            ): (
                    <img src = { require("../../_assets/img/IMG_SCHEMA_BATT_UP_INTERACTION_COMMUNICATION_ANTICIPATION-EN.png") } alt = "icone" />
            )} */}


            <div className={classes.newVisuals}>
                {/* <div className='col-12'>
                    <p style={{ textAlign: 'left', fontSize: '16px', padding: "30px" }}><b style={{ color: "#3d009e", lineHeight: '40px' }}>Cher(e) client(e),</b><br/>
Nous vous informons aujourd'hui qu'une mise à jour cruciale de votre système de monitoring de batterie "Battery Up" va devoir être faite. Suite à un arrêt unilatéral d'un service Google, sur lequel nous n'avions aucune maîtrise, cette mise à jour s'est avérée nécessaire. En raison de la spécificité de cette mise à jour, un flashage du firmware est requis. Malheureusement, l'interface nécessaire pour cette opération n'est pas accessible à nos clients finaux.<br/>
Afin de garantir la continuité et la qualité de service de votre système "Battery Up", nous vous proposons de prendre en charge intégralement cette mise à jour. Cela implique la récupération de votre module de monitoring, la réalisation de la mise à jour dans nos locaux, puis son renvoi rapide vers vous. Nous estimons que cette opération ne devrait pas excéder 2 à 3 jours.<br/>
Nous tenons à insister sur le fait que tous les frais associés à cette mise à jour, y compris les frais d'envoi en express via UPS, seront intégralement pris en charge par notre société. Vous n'aurez donc aucun frais supplémentaire à assumer.<br/>
Nous sommes conscients des désagréments que cela pourrait vous causer et nous nous excusons sincèrement pour la gêne occasionnée. Nous regrettons profondément cette situation indépendante de notre volonté, et nous mettons tout en œuvre pour que cette opération soit la plus rapide et la plus fluide possible pour vous.<br/>
Nous vous remercions pour votre compréhension et votre confiance.<br/>
<b style={{ color: "#3d009e", lineHeight: '40px' }}>L'équipe Battery UP,</b></p>
                </div> */}
                {/* <SocVisualWrapper batteries={batteries} /> */}
                {(batteries.length > 0 ? (
                    batteries.map((battery,idx) => {
                        return (
                            <Aux>
                                <div key={idx} className={`col-sm-12 col-md-6 col-lg-5`}>
                                    <PylontechVisualSummary mac_address={battery} />
                                </div>
                                {/* <div key={idx} className={`col-sm-12 col-md-6 col-lg-4`}>
                                    <SocVisual mac_address={battery} />
                                </div> */}
                            </Aux>
                        )
                    })) : ""
                )}
                {(acrels.length > 0 ? (
                    acrels.map((acrel,idx) => {
                        return (
                            <div key={idx} className={`col-sm-12 col-md-6 col-lg-5`}>
                                <AcrPGauge mac_address={acrel} />
                            </div>
                        )
                    })) : ""
                )}
            </div>
            {/* <div>
                <SingleValueCard title="Title" icon="power" type="battery" value={3.45} tooltip="Tooltip" />
            </div>
            <div>
                <CircleCard title="Title" icon="power" type="battery" value={3.45} tooltip="Tooltip" />
            </div> */}
            {batteries.length === 0 && acrels.length === 0 && eastrons.length === 0 && (
                <p>{t('dashboard:DataContainer.no-device')}</p>
            )}
            {(batteries.length > 0 || acrels.length > 0 || eastrons.length > 0) && (
                <Aux>
                    {/* <div className={`col-md-12 ${classes.DataContainer}`}>
                        <TopData arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div> */}
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        {/* <ChartContainer selectedBattery={hasBattery ? props.selectedInstallation.modules[indexBattery].mac_address : 0} selectedAcrel={hasAcrel ? props.selectedInstallation.modules[indexAcrel].mac_address : 0} arrBatteries={arrBatteries} arrAcrel={arrAcrel} /> */}
                        <ChartContainer arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div>
                    <div className="col-md-12">
                        {/* <div className={`section-title angle-section-title`} style={{ margin: "10px 0" }}>
                            <h2 className={classes.sectionTitle}>Données de votre installation</h2>
                        </div> */}
                        <Data
                            icon="icone-battery-UP-IMG-infos"
                            title={t('dashboard:DataContainer.data-installation')}
                            type=""
                        >
                            <Legend style="alt" arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                            <div className={`col-12`}>
                                <DataCards arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                            </div>
                        </Data>
                    </div>
                    {/* <div className={`col-md-12`}>
                        <AdditionalBatteriesData arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div>
                    <div className={`col-md-12`}>
                        <FullWeather install={initInstall} />
                    </div> */}
                    {/* <div className={`col-md-12`}>
                        <WeatherWithForecast install={initInstall} selectedDevice={props.selectedInstallation} selectedDeviceType={props.selectedDeviceType} />
                    </div> */}
                    {/* <div className={`col-md-12`}>
                        <MessagesWrapper arrBatteries={batteries} arrAcrel={acrels} />
                    </div> */}
                </Aux>
            )}
            {(batteries.length > 0 ? (
                batteries.map((battery,idxP) => {
                    return (
                        <Aux key={idxP}>
                            <div className={`col-12`}>
                                <PylontechData mac_address={battery} />
                            </div>
                            <div className={`col-12`}>
                                <PylontechAlarms mac_address={battery} />
                            </div>
                        </Aux>
                    )
                })) : ""
            )}
            {(acrels.length > 0 ? (
                acrels.map((acrel,idxA) => {
                    return (
                        <Aux key={idxA}>
                            <div className={`col-12`}>
                                <AcrelData mac_address={acrel} />
                            </div>
                        </Aux>
                    )
                })) : ""
            )}
            {(eastrons.length > 0 ? (
                eastrons.map((eastron,idxE) => {
                    return (
                        <Aux key={idxE}>
                            <div className={`col-12`}>
                                <EastronData mac_address={eastron} />
                            </div>
                        </Aux>
                    )
                })) : ""
            )}
            {(batteries.length > 0 || acrels.length > 0 || eastrons.length > 0) && (
                <Aux>
                    <div className={`col-md-12`}>
                        <FullWeather install={initInstall} />
                    </div>
                </Aux>
            )}
        </Aux>
    );
};

export default NDataContainer;