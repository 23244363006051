import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
// import classes from './MainDataChart.module.scss';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';
import { dataService } from '../../../_services/dataService';

am4core.useTheme(am4themes_animated);

const FourthNewChart = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    console.log("props all devices chart");
    console.log(props);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        var chart = am4core.create('chartdivfourth',am4charts.XYChart)

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;


        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;
        // valueAxis.renderer.labels.template.adapter.add("text",function (text) {
        //     return text == "Absorbed" || text == "Released" ? text : text + " W";
        // })

        // Create series
        function createSeries(field,name) {

            // Set up series
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.name = name;
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "category";
            series.sequencedInterpolation = true;

            // Make it stacked
            series.stacked = true;

            // Configure columns
            series.columns.template.width = am4core.percent(60);
            series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

            // Add label
            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
            // labelBullet.label.text = "{valueY}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;

            return series;
        }

        createSeries("abs",t('common:charts.imported-energy'));
        createSeries("rel",t('common:charts.exported-energy'));

        // Legend
        chart.legend = new am4charts.Legend();

        const test = props.data;
        chart.data = test;
        // chart.data = [
        //     {
        //         category: 'JAN',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'FEB',
        //         abs: 272760,
        //         rel: 232080,
        //     },
        //     {
        //         category: 'MAR',
        //         abs: 160920,
        //         rel: 43200,
        //     },
        //     {
        //         category: 'APR',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: 'MAY',
        //         abs: 126600,
        //         rel: 143280,
        //     },
        //     {
        //         category: 'JUN',
        //         abs: 104880,
        //         rel: 73800,
        //     },
        //     {
        //         category: 'JUL',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'AUG',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'SEP',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'OCT',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'NOV',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: 'DEC',
        //         abs: 50000,
        //         rel: 50000,
        //     }
        // ]

    },[props.data]);
    // }

    return (
        <div id="chartdivfourth" style={{ width: "calc(100% - 40px)",height: "600px" }}></div>
    );
}

export default FourthNewChart;