// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChartApp_container__3tDK4 {\n  max-width: 1500px !important; }\n  .ChartApp_container__3tDK4 h3 {\n    color: #ff063c;\n    font-weight: 300;\n    font-size: 40px;\n    text-transform: none; }\n  .ChartApp_container__3tDK4 li span {\n    color: #3d009e; }\n", ""]);
// Exports
exports.locals = {
	"container": "ChartApp_container__3tDK4"
};
module.exports = exports;
