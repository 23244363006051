import React,{ useEffect,useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import classes from './StepAdditional.module.scss';

const StepAdditional = (props) => {
    return (
        <div className={classes.scenarioStepWeather}>
            <p>Step additional</p>
        </div>
    );
};

export default StepAdditional;