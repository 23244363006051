import React,{ useState } from "react";
import * as Yup from "yup";

export default [
    {
        "temperature": {
            "type": "number",
            "label": "Temperature",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
        },
        "uv": {
            "type": "number",
            "label": "UV",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
        },
        "uv_ratio": {
            "type": "number",
            "label": "UV Ratio",
            "validation": Yup.number().required(<p className="errorMessage">Required field</p>),
        },
    }
]