import React,{ useEffect,useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import metricsFields from "./metrics-fields";
import classes from './StepStopMetrics.module.scss';

const StepStopMetrics = (props) => {
    return (
        <div className={classes.scenarioStepWeather}>
            <p>Step stop</p>
        </div>
    );
};

export default StepStopMetrics;