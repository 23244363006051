import React,{ useEffect } from 'react';

import DiscoverApp from '../_components/AppPage/DiscoverApp';
import IntroApp from '../_components/AppPage/IntroApp';
import ChartApp from '../_components/AppPage/ChartApp';
import TakeControl from '../_components/AppPage/TakeControl';

import Aux from '../_hoc/Auxil/Auxil';

const NAppPage = (props) => {
    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <DiscoverApp />
            <IntroApp />
            <ChartApp />
            <TakeControl />
        </div>
    );
}

export default NAppPage;