import React,{ Component } from 'react';

import i18n from "i18next";
import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './GreatAdvantages.module.scss';
import fondImgTexte1 from '../../_assets/img/fond-texte-solution-batteries-lihtium.png';

class GreatAdvantages extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="GreatAdvantages" className={`app-about-section angle-sp ${classes.GreatAdvantages}`}>
                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className="section-title angle-section-title">
                                    {/* <h2 className={classes.sectionTitle}>Monitorez et gérez vos batteries lithium</h2> */}
                                    <h2 className={classes.sectionSubtitle1}>{t('homepage:GreatAdvantages.title')}</h2>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="row angle-slides-wrapper-bg">
                        <div className="col-md-12">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div >
                                    {this.props.i18n.language === "fr" ? (
                                        <img src={require("../../_assets/img/schema-avantages-considerables-IMG-VECTOR2.png")} alt={t('homepage:GreatAdvantages.img-advantages-alt')} />
                                    ) : (
                                            <img src={require("../../_assets/img/schema-avantages-considerables-IMG-VECTOR2-EN.png")} alt={t('homepage:GreatAdvantages.img-advantages-alt')} />
                                        )}
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-md-12">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className="app-about-text">
                                    <div>
                                    </div>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.easy-set-up">
                                            <strong>• Installation RAPIDE et SIMPLE :</strong> La mise en place de Battery UP ne prend pas plus de 5 minutes et ne nécessite aucune connaissance technique. Un manuel de procédure d’installation simple est disponible en ligne. Et en cas de problèmes, notre support en ligne se fera un plaisir de vous aider.
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.rapid-data">
                                            <strong>• Des données précises et rapides : </strong>Vos valeurs sont affichées en temps réel et enregistrées par intervalle de 15 secondes et accessibles en permanence via le Cloud.
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.optimised-consumption">
                                            <strong>• Consommation optimisée :</strong> Via des scénarios simples en IFTTT, suite aux informations internes (ex : % état de charge de la batterie) et externes (ex : prévisions météo), Battery UP vous conseillera l’utilisation ou non de vos consommateurs. Il pourra également les déclencher à votre place afin de vous faciliter votre vie. (=Automatisation de la gestion des consommateurs)
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.wireless-connected-battery">
                                            <strong>• La batterie connectée en WIFI :</strong> Grâce à son adaptateur WIFI, sachez en temps réel l’état de votre batterie.
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.imagination">
                                            • La multitude de scénarios qui s’offrent à vous n’est <strong>limitée que par votre imagination</strong>
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.interoperability">
                                            <strong>• La porte d'entrée vers le monde des solutions IoT: </strong>Battery UP s'associe avec vos batteries domestiques et les services IoT.
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="homepage:GreatAdvantages.customisation">
                                            <strong>• Personnalisation : </strong>Créez des scénarios qui correspondent à vos besoins.
                                        </Trans>
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','homepage'])(GreatAdvantages);