// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddScenarioContainer_testForm__2XfIF {\n  margin: 50px 100px;\n  padding: 30px;\n  box-shadow: 0 0 20px black; }\n  .AddScenarioContainer_testForm__2XfIF input {\n    background-color: lightgray; }\n", ""]);
// Exports
exports.locals = {
	"testForm": "AddScenarioContainer_testForm__2XfIF"
};
module.exports = exports;
