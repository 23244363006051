import React,{ useEffect } from 'react';

import LoginForm from '../_components/Form/LoginForm';

import classes from './LoginPage.module.scss';

const NLoginPage = (props) => {
    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <LoginForm />
        </div>
    );
}

export default NLoginPage;