import React from 'react';

import GenericPage from '../_components/Layout/GenericPage';
import Glossary from '../_components/GlossaryPage/Glossary';

import '../_assets/css/bootstrap.min.css';
import '../_assets/css/animate.css';
// import '../_assets/css/icofont.css';
// import '../_assets/css/owl.carousel.min.css';
import '../_assets/css/owl.theme.default.css';
import '../_assets/css/magnific-popup.css';
import '../_assets/css/style.css';
import '../_assets/css/responsive.css';
import '../_assets/css/color/color-default.css';

import classes from './MonitoringPage.module.scss';

const GlossaryPage = (props) => {
    return (
        <GenericPage>
            <Glossary />
        </GenericPage>
    );
}

export default GlossaryPage;