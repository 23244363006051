// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.BatteryTemps_BatteryTemps__30USL {\n  display: flex;\n  justify-content: center; }\n  .BatteryTemps_BatteryTemps__30USL .BatteryTemps_current__1Hby3 {\n    color: #fcaf0b; }\n  .BatteryTemps_BatteryTemps__30USL .BatteryTemps_min__1nwEN {\n    color: #8dbee6; }\n  .BatteryTemps_BatteryTemps__30USL .BatteryTemps_max__1L0uC {\n    color: #9b174e; }\n  .BatteryTemps_BatteryTemps__30USL .BatteryTemps_current__1Hby3, .BatteryTemps_BatteryTemps__30USL .BatteryTemps_min__1nwEN, .BatteryTemps_BatteryTemps__30USL .BatteryTemps_max__1L0uC {\n    flex: 1 1; }\n    .BatteryTemps_BatteryTemps__30USL .BatteryTemps_current__1Hby3 h5, .BatteryTemps_BatteryTemps__30USL .BatteryTemps_min__1nwEN h5, .BatteryTemps_BatteryTemps__30USL .BatteryTemps_max__1L0uC h5 {\n      color: black;\n      text-align: center;\n      font-size: 14px;\n      font-weight: 400; }\n    .BatteryTemps_BatteryTemps__30USL .BatteryTemps_current__1Hby3 p svg, .BatteryTemps_BatteryTemps__30USL .BatteryTemps_min__1nwEN p svg, .BatteryTemps_BatteryTemps__30USL .BatteryTemps_max__1L0uC p svg {\n      margin-right: 10px; }\n", ""]);
// Exports
exports.locals = {
	"BatteryTemps": "BatteryTemps_BatteryTemps__30USL",
	"current": "BatteryTemps_current__1Hby3",
	"min": "BatteryTemps_min__1nwEN",
	"max": "BatteryTemps_max__1L0uC"
};
module.exports = exports;
