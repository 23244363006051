// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.PoweredBy_PoweredBy__NP9nh {\n  padding: 50px 0;\n  position: relative;\n  z-index: 1; }\n  .PoweredBy_PoweredBy__NP9nh:before {\n    height: 25%;\n    top: -170px;\n    content: \"\";\n    left: 0;\n    position: absolute;\n    transform: skewY(172deg);\n    -webkit-transform: skewY(172deg);\n    -ms-transform: skewY(172deg);\n    -o-transform: skewY(172deg);\n    width: 100%;\n    z-index: -1; }\n  .PoweredBy_PoweredBy__NP9nh::after {\n    content: \"\";\n    height: 100%;\n    left: 0;\n    opacity: 0.9;\n    position: absolute;\n    right: 0;\n    top: 0;\n    width: 100%;\n    z-index: -1;\n    background: #6a11cb;\n    background: linear-gradient(to right, #6a11cb, #2575fc); }\n  .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 {\n    max-width: 1500px !important; }\n    .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 h1, .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 h2, .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 h3, .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 h4, .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 h5, .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 h6, .PoweredBy_PoweredBy__NP9nh .PoweredBy_container__3_7J8 p {\n      color: #fff; }\n", ""]);
// Exports
exports.locals = {
	"PoweredBy": "PoweredBy_PoweredBy__NP9nh",
	"container": "PoweredBy_container__3_7J8"
};
module.exports = exports;
