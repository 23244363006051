import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import classes from './IntroMonitoring.module.scss';
// import imgIntro from '../../_assets/img/IMG-header-site-battery-UP-22.png';

class IntroMonitoring extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="introMonitoring" className={`app-about-section angle-sp ${classes.introMonitoring}`}>
                <div className="container-fluid" style={{ padding: "0" }}>
                    <div className={`row ${classes.introWrapper}`}>
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                {/* <div className="jumbotron" style={{  backgroundImage: `url(${imgIntro})`,backgroundSize: 'cover' }}> */}
                                {/* <div className="jumbotron" style={{  background: `url(${imgIntro}) no-repeat center center`,height: '100%'  }}> */}
                                <div className="section-title angle-section-title">
                                    <img className={classes.sectionBanner} src={require("../../_assets/img/img-header-site-battery-UP-Monitoring-pc.png")} alt={t('monitoringpage:IntroMonitoring.banner-alt')} />
                                    {/* <h2 className={classes.sectionTitle}>Monitorez et gérez vos batteries lithium</h2> */}
                                    <h2 className={classes.sectionTitle}>{t('monitoringpage:IntroMonitoring.title')}</h2>
                                </div>
                                {/* </div> */}
                            </ReactWOW>
                        </div>
                    </div>
                </div>

                <div className={`container ${classes.container}`}>
                    <div className="row">


                        <div className={`col-md-6 ${classes.leftColumn}`}>
                            <img className={classes.monitoringImg} src={require("../../_assets/img/IMG-application-batterie-UP-monitoring.png")} alt={t('monitoringpage:IntroMonitoring.img-batteries-alt')} />
                        </div>

                        <div className={`col-md-6 ${classes.rightColumn}`}>
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className={`text-center ${classes.wifi}`}>
                                    <h3 className={`smallLine-title ${classes.sectionSubtitle}`}>{t('monitoringpage:IntroMonitoring.wifi-battery-monitoring')}</h3>
                                    <p className={classes.introWifi}><strong>{t('monitoringpage:IntroMonitoring.connect-batteries-web')}</strong></p>
                                    <br></br>
                                    <ul className={classes.customUl}>
                                        <li>{t('monitoringpage:IntroMonitoring.free-of-charge')}</li>
                                        <li>{t('monitoringpage:IntroMonitoring.data-stored')}</li>
                                        <li>{t('monitoringpage:IntroMonitoring.data-frequency')}</li>
                                        {/* <li><span style={{ color: "#3d009e",fontSize: "20px" }}>• Partage de compte familiale</span></li> */}
                                    </ul>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className={`col-md-12 ${classes.perksWrapper}`}>
                            <ReactWOW delay='0.9s' animation='fadeInUp'>
                                <div className={classes.perks}>
                                    <div>
                                        <img src={require("../../_assets/img/IMG-icone-batterie-UP-monitoring2.png")} alt={t('monitoringpage:IntroMonitoring.real-time-data-img-alt')} />
                                        <h3>{t('monitoringpage:IntroMonitoring.real-time-data-title')}</h3>
                                    </div>
                                    <p>{t('monitoringpage:IntroMonitoring.real-time-data-text')}</p>
                                </div>
                                <div className={classes.perks}>
                                    <div>
                                        <img src={require("../../_assets/img/IMG2-icone-batterie-UP-monitoring2.png")} alt={t('monitoringpage:IntroMonitoring.tracking-everywhere-img-alt')} />
                                        <h3>{t('monitoringpage:IntroMonitoring.tracking-everywhere-title')}</h3>
                                    </div>
                                    {/* <p>Accédez en tous lieux à vos données sur le Cloud via votre application Mobile.</p> */}
                                    <p>{t('monitoringpage:IntroMonitoring.tracking-everywhere-text')}</p>
                                </div>
                                <div className={classes.perks}>
                                    <div>
                                        <img src={require("../../_assets/img/IMG3-icone-batterie-UP-monitoring2.png")} alt={t('monitoringpage:IntroMonitoring.mobile-alerts-img-alt')} />
                                        <h3>{t('monitoringpage:IntroMonitoring.mobile-alerts-title')}</h3>
                                    </div>
                                    <p>{t('monitoringpage:IntroMonitoring.mobile-alerts-text')}</p>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','monitoringpage'])(IntroMonitoring);