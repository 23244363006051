import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './DataContainer.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import Soc from '../Data/Soc';
import ChartContainer from '../Data/ChartContainer';
import TopData from '../Dashboard/TopData';
import AdditionalBatteriesData from '../Dashboard/AdditionalBatteriesData';
import WeatherWithForecast from '../Data/WeatherWithForecast';

const DataContainer = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    console.log("props data container");
    console.log(props);
    let testBattery = false;
    let testAcrel = false;
    let testEastron = false;
    let iBattery = 0;
    let iAcrel = 0;
    let iEastron = 0;
    let installBatteries = [];
    let installAcrel = [];
    let installEastron = [];
    if (props.selectedInstallation) {
        for (let i = 0; i < props.selectedInstallation.modules.length; i++) {
            if (props.selectedInstallation.modules[i].protocol_id === 1) {
                testBattery = true;
                iBattery = i;
                installBatteries.push(props.selectedInstallation.modules[i].mac_address);
            }
            if (props.selectedInstallation.modules[i].protocol_id === 2 || props.selectedInstallation.modules[i].protocol_id === 4) {
                testAcrel = true;
                iAcrel = i;
                installAcrel.push(props.selectedInstallation.modules[i].mac_address);
            }
            if (props.selectedInstallation.modules[i].protocol_id === 3 || props.selectedInstallation.modules[i].protocol_id === 5) {
                testEastron = true;
                iEastron = i;
                installEastron.push(props.selectedInstallation.modules[i].mac_address);
            }
        }
    }
    // if(props.selectedInstallation.modules)
    const hasBattery = testBattery;
    const hasAcrel = testAcrel;
    const hasEastron = testEastron;
    const indexBattery = iBattery;
    const indexAcrel = iAcrel;
    const indexEastron = iEastron;
    const arrBatteries = installBatteries;
    const arrAcrel = installAcrel;
    const arrEastron = installEastron;
    console.log("hasBattery");
    console.log(hasBattery);
    console.log("hasAcrel");
    console.log(hasAcrel);
    console.log("hasEastron");
    console.log(hasEastron);
    console.log("indexBattery");
    console.log(indexBattery);
    console.log("indexAcrel");
    console.log(indexAcrel);
    console.log("indexEastron");
    console.log(indexEastron);
    // console.log("selected battery");
    // console.log(props.selectedInstallation.modules[indexBattery].mac_address);
    // console.log("selected Acrel");
    // console.log(props.selectedInstallation.modules[indexAcrel].mac_address);
    console.log("arrBatteries");
    console.log(arrBatteries);
    console.log("arrAcrel");
    console.log(arrAcrel);
    console.log("arrEastron");
    console.log(arrEastron);

    return (
        <Aux>
            {!hasBattery && !hasAcrel && !hasEastron && (
                <p>{t('dashboard:DataContainer.no-device')}</p>
            )}
            {/* {hasBattery && hasAcrel && hasEastron && ( */}
            {(hasBattery || hasAcrel || hasEastron) && (
                <Aux>
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        <TopData arrBatteries={arrBatteries} arrAcrel={arrAcrel} arrEastron={arrEastron} />
                    </div>
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        <ChartContainer selectedBattery={hasBattery ? props.selectedInstallation.modules[indexBattery].mac_address : 0} selectedAcrel={hasAcrel ? props.selectedInstallation.modules[indexAcrel].mac_address : 0} selectedEastron={hasEastron ? props.selectedInstallation.modules[indexEastron].mac_address : 0} arrBatteries={arrBatteries} arrAcrel={arrAcrel} arrEastron={arrEastron} />
                    </div>
                </Aux>
            )}
            {/* {hasBattery && !hasAcrel && (
                <Aux>
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        <TopData arrBatteries={arrBatteries} arrAcrel={arrAcrel} />
                    </div>
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        <ChartContainer selectedBattery={hasBattery ? props.selectedInstallation.modules[indexBattery].mac_address : 0} selectedAcrel={hasAcrel ? props.selectedInstallation.modules[indexAcrel].mac_address : 0} arrBatteries={arrBatteries} arrAcrel={arrAcrel} />
                    </div>
                </Aux>
            )} */}
            {/* {hasBattery && (
                <div className={`col-md-12`}>
                    <AdditionalBatteriesData arrBatteries={arrBatteries} />
                </div>
            )} */}
            {(hasBattery || hasAcrel || hasEastron) && (
                <Aux>
                    <div className={`col-md-12`}>
                        <AdditionalBatteriesData arrBatteries={arrBatteries} arrAcrel={arrAcrel} arrEastron={arrEastron} />
                    </div>
                    <div className={`col-md-12`}>
                        <WeatherWithForecast selectedDevice={props.selectedInstallation} selectedDeviceType={props.selectedDeviceType} />
                    </div>
                </Aux>
            )}
        </Aux>
    );
};

export default DataContainer;