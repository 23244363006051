import React,{ useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './DataContainer.module.scss';

import Aux from '../../_hoc/Auxil/Auxil';
import ChartContainer from '../Data/ChartContainer';
import TopData from '../Dashboard/TopData';
import AdditionalBatteriesData from '../Dashboard/AdditionalBatteriesData';
import WeatherWithForecast from '../Data/WeatherWithForecast';
import FullWeather from '../Weather/FullWeather';
import SocVisual from '../Data/SocVisual';
import SocVisualWrapper from '../Data/SocVisualWrapper';
import AcrPGauge from '../Data/AcrPGauge';
import SingleValueCard from '../Data/Cards/SingleValueCard';
import CircleCard from '../Data/Cards/CircleCard';
import DataCards from '../Data/Cards/DataCards';

const NDataContainer = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    const initInstall = props.selectedInstallation;
    // const initBatteries = props.initBatteries;
    // const [newTest,setNewTest] = useState([]);
    const [batteries,setBatteries] = useState([]);
    // const [batteries,setBatteries] = useState(initInstall.modules[0].protocol_id === 1 ? [initInstall.modules[0].mac_address] : "");
    // const [acrels,setAcrels] = useState([]);
    // const [eastrons,setEastrons] = useState([]);
    // console.log("props NDataContainer");
    // console.log(props);
    console.log("initInstall NDataContainer");
    console.log(initInstall);
    // console.log("initBatteries NDataContainer");
    // console.log(initBatteries);
    // console.log("newTest NDataContainer init");
    // console.log(newTest);
    // console.log("batteries NDataContainer init");
    // console.log(batteries);
    let arrayBatteries = [];
    if (initInstall && initInstall.modules) {
        for (let i = 0; i < initInstall.modules.length; i++) {
            if (initInstall.modules[i].protocol_id === 1) {
                arrayBatteries.push(initInstall.modules[i].mac_address);
            }
        }
    }

    useEffect(() => {
        setBatteries([]);
        // (async () => {
        // setBatteries(initBatteries);
        console.log("arrayBatteries");
        console.log(arrayBatteries);
        if (arrayBatteries.length > 0) {
            console.log("not empty");
            setBatteries(arrayBatteries);
        } else {
            console.log("empty array");
            setBatteries([]);
        }
        // console.log("batteries NDataContainer useEffect inside");
        // console.log(batteries);
        let installBatteries = [];
        let installAcrels = [];
        let installEastrons = [];
        /*if (initInstall && initInstall.modules) {
            installBatteries.length = 0;
            installAcrels.length = 0;
            installEastrons.length = 0;
            for (let i = 0; i < initInstall.modules.length; i++) {
                if (initInstall.modules[i].protocol_id === 1) {
                    installBatteries.push(initInstall.modules[i].mac_address);
                }
                if (initInstall.modules[i].protocol_id === 2) {
                    installAcrels.push(initInstall.modules[i].mac_address);
                }
                if (initInstall.modules[i].protocol_id === 3) {
                    installEastrons.push(initInstall.modules[i].mac_address);
                }
            }
            if (installBatteries.length > 0) {
                // setBatteries(initBatteries);
                console.log("not empty");
                setBatteries(installBatteries);
            } else {
                console.log("empty array");
                setBatteries([]);
            }
            if (installAcrels.length > 0) {
                // setAcrels([]);
                setAcrels(installAcrels);
            } else {
                setAcrels([]);
            }
            if (installEastrons.length > 0) {
                // setEastrons([]);
                setEastrons(installEastrons);
            } else {
                setEastrons([]);
            }
        }
        else {
            setBatteries([]);
            setAcrels([]);
            setEastrons([]);
        }*/
        // })()

        // },[props.selectedInstallation,props.initBatteries]);
    },[initInstall]);
    // },[initInstall,initBatteries]);

    // console.log("batteries NDataContainer useEffect below");
    // console.log(batteries);

    return (
        <Aux>
            <div className={classes.newVisuals}>
                {/* <SocVisualWrapper batteries={batteries} /> */}
                {(batteries.length > 0 ? (
                    batteries.map((battery,idx) => {
                        return (
                            <div key={idx} className={`col-sm-12 col-md-6 col-lg-4`}>
                                <SocVisual mac_address={battery} />
                            </div>
                        )
                    })) : ""
                )}
                {/* <p>test</p> */}
                {/* {(acrels.length > 0 ? (
                    acrels.map((acrel,idx) => {
                        return (
                            <div key={idx} className={`col-sm-12 col-md-6 col-lg-4`}>
                                <AcrPGauge mac_address={acrel} />
                            </div>
                        )
                    })) : ""
                )} */}
            </div>
            {/* {batteries.length === 0 && acrels.length === 0 && eastrons.length === 0 && (
                <p>{t('dashboard:DataContainer.no-device')}</p>
            )}
            {(batteries.length > 0 || acrels.length > 0 || eastrons.length > 0) && (
                <Aux>
                    <div className={`col-md-12 ${classes.DataContainer}`}>
                        <ChartContainer arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div>
                    <div className={`col-12`}>
                        <DataCards arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div>
                    <div className={`col-md-12`}>
                        <AdditionalBatteriesData arrBatteries={batteries} arrAcrel={acrels} arrEastron={eastrons} />
                    </div>
                    <div className={`col-md-12`}>
                        <FullWeather install={initInstall} />
                    </div>
                </Aux>
            )} */}
        </Aux>
    );
};

export default NDataContainer;