import React,{ useEffect,useState } from 'react';
import { Link } from 'react-router-dom';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';
import RegisterModuleForm from '../_components/Form/RegisterModuleForm';

// import classes from './RegisterModulePage.module.scss';

import Modal from '../_components/UI/Modal/Modal';

const RegisterModulePage = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    const [params,setParams] = useState(props.match.params);
    const queryString = require('query-string');
    console.log("props.location.search");
    console.log(props.location.search.length);
    //const newCustomer = JSON.parse(queryString.parse(props.location.search).new);
    //const newCustomerId = JSON.parse(queryString.parse(props.location.search).id);
    const paramNew = props.location.search.length > 0 ? JSON.parse(queryString.parse(props.location.search).new) : null;
    const paramId = props.location.search.length > 0 ? JSON.parse(queryString.parse(props.location.search).id) : null;
    // const paramNew = props.location.search.length > 0 ? JSON.parse(queryString.parse(props.location.search).new) : null;
    // const paramId = props.location.search.length > 0 ? JSON.parse(queryString.parse(props.location.search).id) : null;
    console.log("user in register module page");
    console.log(user);
    console.log("user.roles");
    console.log(user.roles);
    console.log("paramNew");
    console.log(paramNew);
    console.log("paramId");
    console.log(paramId);
    // console.log("typeof((props.location.search).new)");
    // console.log(JSON.parse(queryString.parse(props.location.search).new));
    // const newCustomer = typeof(JSON.parse(queryString.parse(props.location.search).new)) !== 'undefined' ? JSON.parse(queryString.parse(props.location.search).new) : "";
    // const newCustomerId = typeof(JSON.parse(queryString.parse(props.location.search).id)) !== 'undefined' ? JSON.parse(queryString.parse(props.location.search).id) : "";
    const newCustomer = (typeof paramNew !== 'undefined' && paramNew !==  null) ? paramNew : false;
    const newCustomerId = (typeof paramId !== 'undefined' && paramId !==  null) ? paramId : "";
    // const newCustomerAlt = JSON.parse(newCustomer);
    // console.log("url params ?");
    // console.log(JSON.parse(queryString.parse(props.location.search).new));
    // console.log("newCustomer condition ?");
    // console.log(typeof paramNew);
    // console.log("modal ?");
    // console.log(newCustomer);
    const [userIsClient,setUserIsClient] = useState(false);
    const [mustSeeModal,setMustSeeModal] = useState(false);

    useEffect(() => {
        // if((user.roles.includes('installer') || user.roles.includes('super-admin') || user.roles.includes('admin')) && !user.roles.includes('client')) {
        if(user.roles.includes('installer') || user.roles.includes('super-admin') || user.roles.includes('admin')) {
            setUserIsClient(false);
        }
        if(!user.roles.includes('installer') && !user.roles.includes('super-admin') && !user.roles.includes('admin') && user.roles.includes('client')) {
            console.log('WHYYYYYY');
            setUserIsClient(true);
        }
        console.log("newCustomer useEffect");
        console.log(newCustomer);
        if(newCustomer) {
            // setUserIsClient(true);
            setMustSeeModal(true);
        }
        console.log("user in register module");
        console.log(user);
        console.log("userIsClient");
        console.log(userIsClient);
        console.log("newCustomer");
        console.log(newCustomer);
        console.log("newCustomerId");
        console.log(newCustomerId);
        console.log("mustSeeModal");
        console.log(mustSeeModal);
    },[]);

    function enableRegistrationHandler() {
        setMustSeeModal(false);
    }

    return (
        <BatteryUpPage sidebar={true}>
            <Modal show={mustSeeModal} modalClosed={enableRegistrationHandler}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2" style={{marginBottom: "30px"}}>
                            {userIsClient ? (<p className="doubleLine-title" style={{ color: "#ff063c", fontSize:"25px"}}>Merci pour votre inscription<br/></p>) : (<p>L'utilisateur a bien été enregistré !</p>)}
                            <br></br>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 offset-lg-3">
                            <img onClick={enableRegistrationHandler} style={{cursor:"pointer"}} className="" src={require("../_assets/img/fenetre-enregistrer-merci-pour-inscription-fr.png")} alt="enregistrer un boitier" />
                        </div>
                        <div className="col-lg-3">
                            <Link to="dashboard">
                            <img onClick={enableRegistrationHandler} style={{cursor:"pointer"}} className="" src={require("../_assets/img/fenetre-demo-merci-pour-inscription-fr.png")} alt="Demo" />
                            </Link>
                        </div>
                    </div>
                    {userIsClient ? (
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <br>
                            </br>
                                <p>Vous n'avez pas encore de boitier ? Rendez-vous sur notre site.</p>
                                <img onClick={enableRegistrationHandler} style={{cursor:"pointer", maxWidth:"20%"}} className="" src={require("../_assets/img/fenetre-je-veux-un-merci-pour-inscription-fr.png")} alt="J'en veux un" />
                            </div>
                        </div>) : "" }
                </div>
            </Modal>
            <RegisterModuleForm idSelect={typeof newCustomerId !== 'undefined' ? newCustomerId : user.id} />
        </BatteryUpPage>
    );
}

export default RegisterModulePage;
