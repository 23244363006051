import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

// import Link from 'next/link';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';
import classes from './HowItWorks.module.scss';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

class HowItWorks extends Component {
    state = {
        isOpen: false
    };

    openModal = () => {
        this.setState({ isOpen: true })
    };

    render() {
        const { t } = this.props;

        return (
            <section id="how-it-works" className={`how-it-works angle-sp ${classes.HowItWorks}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactWOW delay='0.3s' animation='fadeInUp'>
                                <div className={`section-title ${classes.sectionTitleWrapper}`}>
                                    <h2 className={classes.sectionTitle}>{t('homepage:HowItWorks.how-it-works')}</h2>
                                    {/* <p className={classes.introVideo}>Voici une vidéo reprennant le fonctionnement globale du boitier Battery up sur une installation solaire.</p> */}
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                    <div style={{ marginBottom: "50px",marginTop: "20px" }}>
                        {this.props.i18n.language === "fr" ? (
                            <img src={require("../../_assets/img/IMG_SCHEMA_BATT_UP_INTERACTION_COMMUNICATION_ANTICIPATION.png")} alt="icone" />
                        ) : (
                                <img src={require("../../_assets/img/IMG_SCHEMA_BATT_UP_INTERACTION_COMMUNICATION_ANTICIPATION-EN.png")} alt="icone" />
                            )}
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className={`hiw-feature-content`}>
                                <ReactWOW delay='0.3s' animation='fadeInUp'>
                                    <div className={`single-hiw-feature ${classes.HowItWorksItem}`}>
                                        <div className={classes.HowItWorksSubtitle}>
                                            <img src={require("../../_assets/img/icone-battery-UP-IMG-comm-interaction.png")} alt={t('homepage:HowItWorks.img-communication-alt')} />
                                            <h4>{t('homepage:HowItWorks.communication-title')}</h4>
                                        </div>
                                        <p style={{ textAlign: "left" }}>{t('homepage:HowItWorks.communication-p1')}</p>
                                        <p style={{ textAlign: "left" }}>{t('homepage:HowItWorks.communication-p2')}</p>
                                    </div>
                                </ReactWOW>
                            </div>
                        </div>

                        {/* <ReactWOW delay='0.4s' animation='fadeInUp'>
                                    <div className={`single-hiw-feature ${classes.HowItWorksItem}`}>
                                        <div className={classes.HowItWorksSubtitle}>
                                            <img src={require("../../_assets/img/icone-battery-UP-IMG-notification.png")} alt="icone" />
                                            <h4>Recevoir des notifications</h4>
                                        </div>
                                        <p style={{ textAlign: "left" }}>Des notifications vont vous êtes transmises via votre smartphone pour vous avertir à propos des divers informations sur : les puissances, le courant, imports et export d'énergie, le courant, la température des batteries, l'état de vie des batteries, l'état de charge des batteries, les consommations/productions, météo, ... </p>
                                    </div>
                                </ReactWOW> */}
                        <div className="col-sm-12 col-md-4">
                            <div className={`hiw-feature-content`}>
                                <ReactWOW delay='0.5s' animation='fadeInUp'>
                                    <div className={`single-hiw-feature ${classes.HowItWorksItem}`}>
                                        <div className={classes.HowItWorksSubtitle}>
                                            <img src={require("../../_assets/img/icone-battery-UP-IMG-interaction.png")} alt={t('homepage:HowItWorks.img-interaction-alt')} />
                                            <h4>{t('homepage:HowItWorks.interaction-title')}</h4>
                                        </div>
                                        <p style={{ textAlign: "left" }}>{t('homepage:HowItWorks.interaction-p1')}</p>
                                        <p style={{ textAlign: "left" }}>{t('homepage:HowItWorks.interaction-p2')}</p>
                                    </div>
                                </ReactWOW>


                            </div>
                        </div>


                        <div className="col-sm-12 col-md-4">
                            <div className={`hiw-feature-content`}>
                                <ReactWOW delay='0.5s' animation='fadeInUp'>
                                    <div className={`single-hiw-feature ${classes.HowItWorksItem}`}>
                                        <div className={classes.HowItWorksSubtitle}>
                                            <img src={require("../../_assets/img/icone-battery-UP-IMG-anticiper-actions.png")} alt={t('homepage:HowItWorks.img-anticipate-actions-alt')} />
                                            <h4>{t('homepage:HowItWorks.anticipate-actions-title')}</h4>
                                        </div>
                                        <p style={{ textAlign: "left" }}>{t('homepage:HowItWorks.anticipate-actions-p1')}</p>
                                    </div>
                                </ReactWOW>


                            </div>
                        </div>

                        {/* <div className="col-md-5 offset-md-1">
                            <ReactWOW delay='0.6s' animation='fadeInUp'>
                                <div className="video-demo-content">
                                    <img src={require("../../_assets/img/video-demo.jpg")} alt="Video Demo Image" className="img-circle" />
                                    <div className="play-video-icon">

                                        <a
                                            onClick={e => { e.preventDefault(); this.openModal() }}
                                            className="popup-youtube"
                                        >
                                            <i className="icofont-play"></i>
                                            <div className="sonar-wrapper">
                                                <div className="sonar-emitter">
                                                    <div className="sonar-wave"></div>
                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                </div>
                            </ReactWOW>
                        </div>
                        <ModalVideo
                            channel='youtube'
                            isOpen={this.state.isOpen}
                            videoId='szuchBiLrEM'
                            onClose={() => this.setState({ isOpen: false })}
                        /> */}


                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation(['common','homepage'])(HowItWorks);