import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import i18n from "i18next";
import { Trans,useTranslation } from 'react-i18next';

import { moduleService } from '../../_services/moduleService';

// import classes from './Triggers.module.scss';

import TriggersInfo from './TriggersInfo';
import UserTriggers from './UserTriggers';

const Triggers = (props) => {
    const { t } = useTranslation(['common','alertspage']);
    const auth = JSON.parse(localStorage.getItem('appState')).user;
    const [modules,setModules] = useState([]);

    useEffect(() => {
        console.log("props.selectedClient");
        console.log(props.selectedClient);
        // moduleService.getByUserId(auth.id)
        moduleService.getByUserId(props.selectedClient.id)
            .then(response => {
                console.log("response modules by user id in triggers");
                console.log(response);
                setModules(response);
            });
    },[props.selectedClient]);

    return (
        <div className="">
            <div className="container-fluid">
                <div className="row">
                    <div className={`col-12`}>
                        <TriggersInfo />
                        {/* <p>TriggersInfo</p> */}
                    </div>
                </div>
                <div className="row">
                    <div className={`col-12`}>
                        <UserTriggers modules={modules} />
                        {/* <p>UserTriggers</p> */}
                    </div>
                </div>
            </div>
        </div>
        // <p>test Triggers JS</p>
    )
};

export default Triggers;