// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ScenarioSummary_ScenarioSummary__1Pb5A {\n  border-bottom: 2px solid purple;\n  padding: 20px; }\n", ""]);
// Exports
exports.locals = {
	"ScenarioSummary": "ScenarioSummary_ScenarioSummary__1Pb5A"
};
module.exports = exports;
