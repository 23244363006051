import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
// import classes from './MainDataChart.module.scss';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';
import { dataService } from '../../../_services/dataService';

am4core.useTheme(am4themes_animated);

const HoursChart = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    console.log("props HoursChart");
    console.log(props);
    console.log("props data HoursChart");
    console.log(props.data);
    console.log("Object.assign({},props.data)");
    console.log(Object.assign({},props.data));
    const chartRef = useRef(null);
    // const chartData = [Object.assign({},props.data)];
    const chartData = [];
    props.data.forEach(element => {
        // console.log("each element data");
        // console.log(element);
        chartData.push(element);
    });
    console.log("chartData");
    console.log(chartData);
    const test = props.data;


    useLayoutEffect(() => {
        var chart = am4core.create('chartdiv',am4charts.XYChart)

        // Create category axis
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.opposite = false;

        // Create value axis
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inversed = false;
        valueAxis.title.text = "Energy Absobed / Released (in W)";
        valueAxis.renderer.minLabelPosition = 0.01;

        // Create series
        var series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "abs";
        series1.dataFields.categoryX = "category";
        series1.name = t('common:charts.imported-energy');
        series1.bullets.push(new am4charts.CircleBullet());
        series1.tooltipText = "{name}  {categoryX}: {valueY} W";
        series1.legendSettings.valueText = "{valueY}";
        series1.visible = false;

        var series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "rel";
        series2.dataFields.categoryX = "category";
        series2.name = t('common:charts.exported-energy');
        series2.bullets.push(new am4charts.CircleBullet());
        series2.tooltipText = "{name}  {categoryX}: {valueY} W";
        series2.legendSettings.valueText = "{valueY}";

        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";


        let hs1 = series1.segments.template.states.create("hover")
        hs1.properties.strokeWidth = 5;
        series1.segments.template.strokeWidth = 1;

        let hs2 = series2.segments.template.states.create("hover")
        hs2.properties.strokeWidth = 5;
        series2.segments.template.strokeWidth = 1;

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.itemContainers.template.events.on("over",function (event) {
            var segments = event.target.dataItem.dataContext.segments;
            segments.each(function (segment) {
                segment.isHover = true;
            })
        })

        chart.legend.itemContainers.template.events.on("out",function (event) {
            var segments = event.target.dataItem.dataContext.segments;
            segments.each(function (segment) {
                segment.isHover = false;
            })
        })

        chart.dataAlt = chartData;
        console.log("chart.dataAlt");
        console.log(chart.dataAlt);
        // chart.data = [
        //     {
        //         category: '00h - 01h',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: '01h - 02h',
        //         abs: 272760,
        //         rel: 232080,
        //     },
        //     {
        //         category: '02h - 03h',
        //         abs: 160920,
        //         rel: 43200,
        //     },
        //     {
        //         category: '03h - 04h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '04h - 05h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '05h - 06h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '06h - 07h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '07h - 08h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '08h - 09h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '09h - 10h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '10h - 11h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '11h - 12h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '12h - 13h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '13h - 14h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '14h - 15h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '15h - 16h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '16h - 17h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '17h - 18h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '18h - 19h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '19h - 20h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '20h - 21h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '21h - 22h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '22h - 23h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        //     {
        //         category: '23h - 00h',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        // ]
        chart.data = test;
        console.log("chart.data");
        console.log(chart.data);

        // chart.colors.step = 2;

        // // Add data
        // chart.data = generateChartData();

        // // Create axes
        // var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        // dateAxis.renderer.minGridDistance = 50;

        // // Create series
        // function createAxisAndSeries(field,name,opposite,bullet) {
        //     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //     if (chart.yAxes.indexOf(valueAxis) != 0) {
        //         valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        //     }

        //     var series = chart.series.push(new am4charts.LineSeries());
        //     series.dataFields.valueY = field;
        //     series.dataFields.dateX = "category";
        //     series.strokeWidth = 2;
        //     series.yAxis = valueAxis;
        //     series.name = name;
        //     series.tooltipText = "{name}: [bold]{valueY}[/]";
        //     series.tensionX = 0.8;
        //     series.showOnInit = true;

        //     var interfaceColors = new am4core.InterfaceColorSet();

        //     valueAxis.renderer.line.strokeOpacity = 1;
        //     valueAxis.renderer.line.strokeWidth = 2;
        //     valueAxis.renderer.line.stroke = series.stroke;
        //     valueAxis.renderer.labels.template.fill = series.stroke;
        //     valueAxis.renderer.opposite = opposite;
        // }

        // createAxisAndSeries("abs","Absorbed",false,"circle");
        // createAxisAndSeries("rel","Released",true,"triangle");

        // // Add legend
        // chart.legend = new am4charts.Legend();

        // // Add cursor
        // chart.cursor = new am4charts.XYCursor();

        // // generate some random data, quite different range
        // function generateChartData() {
        //     var chartData = [];
        //     var firstDate = new Date();
        //     firstDate.setDate(firstDate.getDate() - 100);
        //     firstDate.setHours(0,0,0,0);

        //     var visits = 1600;
        //     var hits = 2900;
        //     var views = 8700;

        //     for (var i = 0; i < 15; i++) {
        //         // we create date objects here. In your data, you can have date strings
        //         // and then set format of your dates using chart.dataDateFormat property,
        //         // however when possible, use date objects, as this will speed up chart rendering.
        //         var newDate = new Date(firstDate);
        //         newDate.setDate(newDate.getDate() + i);

        //         visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        //         hits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        //         views += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);

        //         chartData.push({
        //             date: newDate,
        //             visits: visits,
        //             hits: hits,
        //             views: views
        //         });
        //     }
        //     return chartData;
        // }

        // chart.data = [
        //     {
        //         category: '00:00',
        //         abs: 50000,
        //         rel: 50000,
        //     },
        //     {
        //         category: '01:00',
        //         abs: 272760,
        //         rel: 232080,
        //     },
        //     {
        //         category: '02:00',
        //         abs: 160920,
        //         rel: 43200,
        //     },
        //     {
        //         category: '03:00',
        //         abs: 141360,
        //         rel: 6240,
        //     },
        // ]

        // createSeries('abs','Absorbed');
        // createSeries('rel','Released');

    },[props.data]);
    // }

    return (
        <div id="chartdiv" style={{ width: "calc(100% - 40px)",height: "600px" }}></div>
    );
}

export default HoursChart;