import React from 'react';
import { PieChart,Pie,Label } from 'recharts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CircularProgress = (props) => {
    console.log('in chart');
    console.log(props);
    const max = [
        {
            "name": "max",
            "value": 1
        },
    ];
    // const soc = 450 * props.data / 100;
    const soc = 360 * props.data / 100;
    // const data = [{ soc: 80 }];
    const data01 = [
        {
            "name": "SOC",
            "value": 1
        },
        // {
        //     "name": "Empty",
        //     "value": 20
        // },
    ];

    return (
        <PieChart style={{ transform: "rotate(-90deg)" }} width={90} height={90} innerRadius={40} outerRadius={48} data={data01}>
            {/* <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#8884d8" label /> */}
            {/* <Pie dataKey="value" nameKey="name" cx="50%" cy="50%" fill="#82ca9d" label /> */}
            {/* <Pie data={max} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={90} endAngle={450} innerRadius={60} outerRadius={80} fill="#82ca9d" isAnimationActive={false} />
            <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={90} endAngle={soc} innerRadius={60} outerRadius={80} fill="#8ffa9d" /> */}
            <Pie data={max} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={0} endAngle={360} innerRadius={30} outerRadius={40} fill="#e4e8ec" isAnimationActive={false} />
            <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={0} endAngle={soc} innerRadius={30} outerRadius={40} fill="#fcaf0b" label={<CustomLabel value1={props.data} />} labelLine={false} />
            {/* <Label width={10} position="center" value="test" color="#82ca9d" /> */}
            {/* <Label width={30} position="center"
                content={<CustomLabel value1="one" value2="two" />}>
            </Label> */}
        </PieChart>
    );
};

function CustomLabel({ value1 }) {
    const cx = "50%";
    const cy = "150%";
    return (
        <text transform="rotate(90,90,90)" x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            {/* <tspan alignmentBaseline="middle" fontSize="18">{<FontAwesomeIcon icon="user" />}</tspan> */}
            {/* <tspan alignmentBaseline="middle" fontSize="10">{value1}%</tspan> */}
            <tspan alignmentBaseline="middle" fontSize="20">{value1}%</tspan>
        </text>
    )
}

export default CircularProgress;