import React from 'react';

import classes from './BatteryUpPage.module.scss';

import Navigation from './Navigation/Navigation';
import MainFooter from './Footer/MainFooter';

const GenericPage = (props) => {
    const user = localStorage.getItem("mainInfo") !== null ? JSON.parse(localStorage.getItem('mainInfo')) : null;
    const isLoggedIn = localStorage.getItem("mainInfo") !== null ? true : false;
    console.log(" generic page localStorage");
    console.log(localStorage.getItem("mainInfo"));
    console.log(" generic page user");
    console.log(user);
    console.log("generic page isLoggedIn");
    console.log(isLoggedIn);
    return (
        <div className={`${classes.BatteryUpPage}`} data-spy="scroll" data-offset="70">
            <Navigation user={user} isLoggedIn={isLoggedIn} />
            <div className={`container-fluid ${classes.BatteryUpContent}`}>
                <div className="row">
                    <div className={"col-lg-12 m-0 p-0"}>
                        {props.children}
                    </div>
                </div>
            </div>
            <MainFooter />
        </div>
    );
};

export default GenericPage;