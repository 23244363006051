import React,{ useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';

import { userService } from '../../_services/userService';

import Data from '../Data/Data';
import classes from './DeviceChoice.module.scss';

const DeviceChoice = (props) => {
    const user = JSON.parse(localStorage.getItem('appState')).user;
    // const [user,setUser] = JSON.parse(localStorage.getItem('appState')).user;
    // const [modules,setModules] = useState(props.selectedClient.modules);
    // const [sermatecs,setSermatecs] = useState(props.selectedClient.sermatecs);
    console.log("props.selectedClient device choice before setting const");
    console.log(props.selectedClient);
    // const selectedClient = parseInt(props.selectedClient);
    const selectedClient = props.selectedClient;
    const modules = selectedClient.modules;
    const sermatecs = selectedClient.sermatecs;
    // const [selectedClient,setSelectedClient] = useState(props.selectedClient);

    console.log("selectedClient device choice no usestate");
    console.log(selectedClient);

    useEffect(() => {
        console.log('test first useEffect');
    },[]);

    /*useEffect(() => {
        console.log("props.selectedClient device choice in useeffect");
        console.log(props.selectedClient);
        // setSelectedClient(props.selectedClient);
        // if(user.roles.includes('installer')) {
        //     userService.getClientModules(props.selectedClient)
        //         .then(response => {
        //             setModules(response);
        //         });
        // }
        // if(user.roles.includes('super-admin') || user.roles.includes('admin')) {
        //     userService.getModules()
        //         .then(response => {
        //             setModules(response);
        //         });
        // }
        // if(!user.roles.includes('super-admin') && !user.roles.includes('admin') && !user.roles.includes('installer') && user.roles.includes('client')) {
        //     userService.get
        // }
        // setSelectedClient(parseInt(props.selectedClient));
        console.log("Selected client in device choice useEffect");
        console.log(selectedClient);
        if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {
            userService.getById(selectedClient)
                .then(response => {
                    console.log("did I retrieve the user properly ?");
                    console.log(response);
                    setModules(response.modules);
                    setSermatecs(response.sermatecs);
                });
        }
        if (user.roles.includes('super-admin') || user.roles.includes('admin') || user.roles.includes('installer')) {

        }
    },[selectedClient]);*/

    // console.log("Selected client props in device choice");
    // console.log(props.selectedClient);
    // console.log("Selected client useState in device choice");
    // console.log(selectedClient);
    console.log("Modules in client choice");
    console.log(modules);
    console.log("sermatecs in client choice");
    console.log(sermatecs);

    return (
        <Data key={props.selectedClient}
            icon="icone-battery-UP-IMG-CLIENT"
            title="Devices"
            type="select"
        >
            {(modules.length > 0 || sermatecs.length > 0) &&
                <div className={classes.devicesList}>
                    {modules.length > 0 &&
                        modules.map((module,idx) => {
                            return (
                                <div onClick={props.changed} data-type="module" data-index={module.mac_address} key={module.mac_address} className={[classes.deviceItem,classes.moduleItem].join(' ')}>
                                    {module.mac_address}
                                </div>
                            )
                        })
                    }
                    {sermatecs.length > 0 &&
                        sermatecs.map((sermatec,idx) => {
                            return (
                                <div onClick={props.changed} data-type="sermatec" data-index={sermatec.serial_number} key={sermatec.serial_number} className={[classes.deviceItem,classes.sermatecItem].join(' ')}>
                                    {sermatec.serial_number}
                                </div>
                            )
                        })
                    }
                </div>
            }
            {/*
            <select value={selectedClient} onChange={props.changed}>
                <option value="demo">Demo</option>
                {modules.map((module,idx) => {
                    if(user.roles.includes('client')) {
                        if(user.id === module.user_id) {
                            return (
                                <option
                                    selected
                                    value={module.mac_address}
                                    key={idx}
                                >
                                    module - {module.mac_address}
                                </option>
                            )
                        } else {
                            return (
                                <option
                                    value={module.mac_address}
                                    key={idx}
                                >
                                    {module.mac_address}
                                </option>
                            )
                        }
                    } else {
                        return (
                            <option
                                value={module.mac_address}
                                key={idx}
                            >
                                {module.mac_address}
                            </option>
                        )
                    }
                })}
            </select>
            */}
        </Data>
    );
};

export default DeviceChoice;