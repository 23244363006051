// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n\n*   1.2 Mixins\n\n*/\n/*\nTable of Contents - Mixins\n\n1.0 Linear gradient\n2.0 Media queries\n3.0 Clearfix\n4.0 Pseudo\n5.0 Positions\n6.0 Rem units\n7.0 Vendor prefixing\n8.0 Retina Images\n9.0 Keyframes\n10.0 Truncate\n*/\n/* 1.0 Linear gradient */\n/* 2.0 Media queries */\n/* 3.0 Clearfix */\n/* 4.0 Pseudo */\n/* 5.0 Positions */\n/* 6.0 Rem units */\n/* 7.0 Vendor prefixing */\n/* 8.0 Retina Images */\n/* 9.0 Keyframes */\n/* 10.0 Truncate */\n.TriggerForm_TriggerForm__2TuAU {\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n  background-color: white; }\n  @media only screen and (min-width: 1024px) {\n    .TriggerForm_TriggerForm__2TuAU {\n      margin: 0 120px; } }\n  .TriggerForm_TriggerForm__2TuAU h3 {\n    padding: 20px 0; }\n  .TriggerForm_TriggerForm__2TuAU label {\n    font-weight: 600;\n    color: #3d009e; }\n  .TriggerForm_TriggerForm__2TuAU .TriggerForm_timeWindow__2gz0r {\n    display: flex;\n    align-items: flex-end; }\n    .TriggerForm_TriggerForm__2TuAU .TriggerForm_timeWindow__field__1To7d {\n      width: 60px; }\n      .TriggerForm_TriggerForm__2TuAU .TriggerForm_timeWindow__field__1To7d:nth-child(3) {\n        margin-right: 30px; }\n      .TriggerForm_TriggerForm__2TuAU .TriggerForm_timeWindow__field__1To7d input {\n        text-align: right; }\n    .TriggerForm_TriggerForm__2TuAU .TriggerForm_timeWindow__hourSeparator__wLptt {\n      margin: 0 5px 26px;\n      font-weight: 600; }\n  .TriggerForm_TriggerForm__2TuAU .TriggerForm_fieldInfo__1YWdl {\n    font-size: 12px;\n    color: gray;\n    padding-left: 10px;\n    font-style: italic;\n    font-weight: 600;\n    line-height: 16px;\n    margin-top: 5px; }\n  .TriggerForm_TriggerForm__2TuAU .TriggerForm_submitButton__3CP-L {\n    margin: 0 30px 30px; }\n  .TriggerForm_TriggerForm__2TuAU .TriggerForm_formSectionSeparator__text__1VSQw span {\n    display: block;\n    font-style: italic;\n    line-height: 18px; }\n", ""]);
// Exports
exports.locals = {
	"TriggerForm": "TriggerForm_TriggerForm__2TuAU",
	"timeWindow": "TriggerForm_timeWindow__2gz0r",
	"timeWindow__field": "TriggerForm_timeWindow__field__1To7d",
	"timeWindow__hourSeparator": "TriggerForm_timeWindow__hourSeparator__wLptt",
	"fieldInfo": "TriggerForm_fieldInfo__1YWdl",
	"submitButton": "TriggerForm_submitButton__3CP-L",
	"formSectionSeparator__text": "TriggerForm_formSectionSeparator__text__1VSQw"
};
module.exports = exports;
