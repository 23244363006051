import React,{ useEffect,useState } from "react";
import { Formik,Form,Field,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import weatherFields from "./weather-fields";
import classes from './StepWeather.module.scss';

const StepWeather = (props) => {
    const [fields,setFields] = useState([]);
    const onTrigger = (values) => {
        console.log("trigger step weather");
        props.parentCallback(values);
        // event.preventDefault();
    }
    const validYupTemperature = fields.includes("temperature") ? weatherFields[0]["temperature"]["validation"] : "";
    const validYupUv = fields.includes("uv") ? weatherFields[0]["uv"]["validation"] : "";
    const validYupUvRatio = fields.includes("uv_ratio") ? weatherFields[0]["uv_ratio"]["validation"] : "";

    const handleChange = (e) => {
        let changedMetric = e.target.name.split("_").pop();
        let targetLabel = "label_" + changedMetric;
        let myElement = document.getElementById(targetLabel);
        if (e.target.checked) {
            // setcannotBeSubmitted(false);
            setFields(fields => [...fields,changedMetric]);
            myElement.innerHTML = "ON";
            myElement.style.textAlign = "left";
            myElement.style.paddingLeft = "7px";
            myElement.style.paddingRight = "0";
        } else {
            // if (fields.length < 2) {
            //     setcannotBeSubmitted(true);
            // }
            setFields(fields.filter(item => item !== changedMetric));
            myElement.innerHTML = "OFF";
            myElement.style.textAlign = "right";
            myElement.style.paddingLeft = "0";
            myElement.style.paddingRight = "4px";
        }
    }

    return (
        <div className={classes.scenarioStepWeather}>
            <h2>Step weather</h2>
            <div className={classes.scenarioStepWeather__list}>
                <div className={classes.scenarioStepWeather__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_temperature" name="activate_temperature" /><label className={`${classes.switchLabel}`} id="label_temperature" for="activate_temperature">OFF</label><span>Temperature</span></div>
                <div className={classes.scenarioStepWeather__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_uv" name="activate_uv" /><label className={`${classes.switchLabel}`} id="label_uv" for="activate_uv">OFF</label><span>UV</span></div>
                <div className={classes.scenarioStepWeather__item}><input type="checkbox" onChange={e => handleChange(e)} id="activate_uv_ratio" name="activate_uv_ratio" /><label className={`${classes.switchLabel}`} id="label_uv_ratio" for="activate_uv_ratio">OFF</label><span>UV ratio</span></div>
                {/* {installFields.map((installField,idx) => {
                    return (
                        <div className={classes.scenarioStep1__item}><input type="checkbox" onChange={e => handleChange(e)} id={`activate_` + installField} name={`activate_` + installField} /><label className={`${classes.switchLabel}`} id={`label_` + installField} for={`activate_` + installField}>OFF</label><span>test - {metricsFields[0][installField].label}</span></div>
                    )
                })} */}
            </div>
            <Formik
                validationSchema={Yup.object({
                    temperature: validYupTemperature,
                    uv: validYupUv,
                    uv_ratio: validYupUvRatio
                })}
                // initialValues={props.data}
                initialValues={props.data}
                // onSubmit={handleSubmit}
                onSubmit={onTrigger}
            >
                {({ values }) => (
                    <Form>
                        {fields.map((field,idx) => {
                            return (
                                <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                    <label htmlFor={field}>{weatherFields[0][field]["label"]}</label>
                                    <Field name={`${field}_comp`} as="select">
                                        <option value="lt">&lt;</option>
                                        <option value="equal">=</option>
                                        <option value="gt">&gt;</option>
                                    </Field>
                                    <Field name={field} type={weatherFields[0][field]["type"]} className="form-control" />
                                    <ErrorMessage name={field} />
                                </div>
                                // <div className="form-group" style={{ margin: '10px 10px 20px' }}>
                                //     <label htmlFor="weather">Weather</label>
                                //     <Field name="weather" type="text" className="form-control" placeholder="type anything" />
                                //     <ErrorMessage name="weather" />
                                // </div>
                            )
                        })}

                        <button type="button" onClick={() => props.prev(values)}>
                            Back
                        </button>
                        {/* <button type="submit" disabled={cannotBeSubmitted}>Next</button> */}
                        <button type="submit">Next</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default StepWeather;