import React,{ useState,useEffect,useRef,useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
// import classes from './MainDataChart.module.scss';

import Aux from '../../../_hoc/Auxil/Auxil';
import Spinner from '../../UI/Spinner/Spinner';
import { dataService } from '../../../_services/dataService';

am4core.useTheme(am4themes_animated);

const FirstNewChartDays = (props) => {
    const { t } = useTranslation(['common','dashboard']);
    console.log("props all devices chart");
    console.log(props);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        var chart = am4core.create('chartdivDays',am4charts.XYChart)
        chart.colors.step = 2;

        chart.legend = new am4charts.Legend()
        chart.legend.position = 'top'
        chart.legend.paddingBottom = 20
        chart.legend.labels.template.maxWidth = 95

        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'category'
        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;

        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;

        function createSeries(value,name) {
            var series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'category'
            series.name = name

            series.events.on("hidden",arrangeColumns);
            series.events.on("shown",arrangeColumns);

            var bullet = series.bullets.push(new am4charts.LabelBullet())
            bullet.interactionsEnabled = false
            bullet.dy = 30;
            bullet.label.text = '{valueY}'
            bullet.label.fill = am4core.color('#ffffff')

            return series;
        }

        chart.data = [
            {
                category: 'Monday',
                abs: 120,
                rel: 5640,
            },
            {
                category: 'Tuesday',
                abs: 600,
                rel: 4200,
            },
            {
                category: 'Wednesday',
                abs: 2040,
                rel: 960,
            },
            {
                category: 'Thursday',
                abs: 14040,
                rel: 600,
            },
            {
                category: 'Friday',
                abs: 15240,
                rel: 720,
            },
            {
                category: 'Saturday',
                abs: 120,
                rel: 4200,
            },
            {
                category: 'Sunday',
                abs: 4920,
                rel: 5640,
            },
        ]


        createSeries('abs','Absorbed');
        createSeries('rel','Released');

        function arrangeColumns() {

            var series = chart.series.getIndex(0);

            var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                var x0 = xAxis.getX(series.dataItems.getIndex(0),"categoryX");
                var x1 = xAxis.getX(series.dataItems.getIndex(1),"categoryX");
                var delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    var middle = chart.series.length / 2;

                    var newIndex = 0;
                    chart.series.each(function (series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        }
                        else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    })
                    var visibleCount = newIndex;
                    var newMiddle = visibleCount / 2;

                    chart.series.each(function (series) {
                        var trueIndex = chart.series.indexOf(series);
                        var newIndex = series.dummyData;

                        var dx = (newIndex - trueIndex + middle - newMiddle) * delta

                        series.animate({ property: "dx",to: dx },series.interpolationDuration,series.interpolationEasing);
                        series.bulletsContainer.animate({ property: "dx",to: dx },series.interpolationDuration,series.interpolationEasing);
                    })
                }
            }
        }
    },[]);
    // }

    return (
        <div id="chartdivDays" style={{ width: "calc(100% - 40px)",height: "600px" }}></div>
    );
}

export default FirstNewChartDays;