import React,{ useState } from 'react';

import BatteryUpPage from '../_components/Layout/BatteryUpPage';

import ChangePasswordForm from '../_components/Form/ChangePasswordForm';

const ChangePasswordPage = (props) => {
    const [params,setParams] = useState(props.match.params);
    return (
        <BatteryUpPage sidebar={true}>
            <ChangePasswordForm />
        </BatteryUpPage>
    );
}

export { ChangePasswordPage };
