import React from 'react';

import classes from './BatteryTemps.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BatteryTemps = (props) => {
    return (
        <div className={classes.BatteryTemps}>
            <div className={classes.current}>
                <h5>Current</h5>
                <p><FontAwesomeIcon icon="thermometer-half" />{props.data.current}</p>
            </div>
            <div className={classes.min}>
                <h5>Min</h5>
                <p><FontAwesomeIcon icon="thermometer-empty" />{props.data.min}</p>
            </div>
            <div className={classes.max}>
                <h5>Max</h5>
                <p><FontAwesomeIcon icon="thermometer-full" />{props.data.max}</p>
            </div>
        </div>
    )
};

export default BatteryTemps;