import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Trans,useTranslation } from 'react-i18next';

import { moduleService } from '../../_services/moduleService';

import classes from './AlertsList.module.scss';

import Data from '../Data/Data';
import Auxil from '../../_hoc/Auxil/Auxil';
import Spinner from '../UI/Spinner/Spinner';
import AlertForm from '../Alerts/AlertForm';

const AlertsList = (props) => {
    const { t } = useTranslation(['common','alertspage']);
    const auth = JSON.parse(localStorage.getItem('appState')).user;
    const hasModules = auth.modules.length > 0 ? true : false;
    const [rules,setRules] = useState([]);
    const [systemRules,setSystemRules] = useState([]);
    const moduleId = props.module;

    useEffect(() => {
        moduleService.getActiveSystemRules(moduleId)
            .then(response => {
                console.log("response getActiveSystemRules");
                console.log(response);
                setRules(response);
            });
        // moduleService.getByUserIdWithAlerts(auth.id)
        //     .then(response => {
        //         console.log("response modules by user id with alerts");
        //         console.log(response);
        //         setModules(response);
        //     });
        moduleService.getAllSystemRules()
            .then(response => {
                console.log("response all system rules");
                console.log(response);
                setSystemRules(response);
            });
    },[]);

    return (
        systemRules.length > 0 ? (
            systemRules.map((sysRule,sri) => {
                return (
                    (sysRule.id === 2 && props.protocol === 2) ? "" : (
                        <div className={classes.AlertItem}>
                            <h5 className={classes.alertTitle}>{sysRule.rule_name}</h5>
                            <div className={classes.formWrapper}>
                                {/* <p>{sysRule.id}</p>
                            <p>{rules}</p>
                            <p>{rules.includes(sysRule.id) ? "true" : "false"}</p> */}
                                {/* <input type="checkbox" name="active" id="active" /> */}
                                <AlertForm key={sri} ruleId={sysRule.id} moduleId={moduleId} active={rules.includes(sysRule.id) ? true : false} />
                            </div>
                        </div>)
                )
            })
        ) : ""
    )
};

export default AlertsList;