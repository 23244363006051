import React,{ Component } from 'react';

import { withTranslation,Trans } from "react-i18next";

import ReactWOW from 'react-wow';
import { Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../_assets/css/color/color-default.scss';
import classes from './SmartHome.module.scss';
import fondImgTexte from '../../_assets/img/fond-img-utilisez-batt-up-batt.png';

class SmartHome extends Component {
    render() {
        const { t } = this.props;

        return (
            <section id="smarthome" className={`faqs-section angle-gray-bg ${classes.SmartHome}`}>

                <div className={`container ${classes.container}`}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={`section-title wow fadeInUp ${classes.sectionTitleWrapper}`} data-wow-delay=".3s">
                                <h2 className={classes.sectionTitle}>{t('homepage:SmartHome.use-battery-up')}</h2>
                                <p style={{ fontSize: "20px" }}>{t('homepage:SmartHome.create-interactions')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-md-5 ${classes.imgLeftColoumn}`}>
                            <div className="text-center wow fadeInUp" data-wow-delay=".4s">
                                <img src={require("../../_assets/img/IMG-application-battery-up-site.png")} alt={t('homepage:SmartHome.img-faq-alt')} />
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className={`col-lg-12 ${classes.qAndA}`}>
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton className={`accordion__button ${classes.QATitle}`}>{t('homepage:SmartHome.what-is-ifttt-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('homepage:SmartHome.what-is-ifttt-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton className={`accordion__button ${classes.QATitle}`}>{t('homepage:SmartHome.how-it-works-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            <p style={{ textAlign: "left" }}>{t('homepage:SmartHome.how-it-works-text')}</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton className={`accordion__button ${classes.QATitle}`}>{t('homepage:SmartHome.is-it-complicated-title')}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{ backgroundColor: "#fff" }}>
                                            {/* <p style={{ textAlign: "left" }}>Non, un utilisateur sans trop de connaissances techniques peut installer notre solution Battery UP en suivant la procédure d’installation du manuel d’installation disponible en ligne.Si cela vous semble compliqué la FAQ et notre SUPPORT en ligne vous aiderons à atteindre avec peu de difficulté votre installation personnalisée.</p> */}
                                            <p style={{ textAlign: "left" }}>
                                                <Trans i18nKey="homepage:SmartHome.is-it-complicated-text">
                                                    Non, un utilisateur sans trop de connaissances techniques peut installer notre solution Battery UP en suivant la procédure d’installation du manuel d’installation disponible en ligne. Si cela vous semble compliqué, notre <a href="https://www.wattuneed.com" target="_blank" style={{ color: "#3d009e" }}>SUPPORT en ligne</a> peut vous aider à installer et exploiter vos modules.
                                                </Trans>
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                                <img className={classes.QAImage} src={require("../../_assets/img/IMG-utilisez-battery-up-avec-batt-questions.png")} alt={t('homepage:SmartHome.img-questions-alt')} />

                                {/* <div className="accordion js-accordion wow fadeInUp" data-wow-delay=".6s">
                                    <div className="accordion__item js-accordion-item active">
                                        <div className="accordion-header js-accordion-header">Qu'est ce qu'IFTTT ?</div>
                                        <div className="accordion-body js-accordion-body">
                                            <div className="accordion-body__contents">
                                                <p>IFTTT signifie “If this then that” (« si ceci alors cela », en français). Pour faire simple, c’est une manière de connecter et d’automatiser vos appareils intelligents. L’application et la web app IFTTT vous permettent de déclencher des actions selon certains conditions à déterminer dans l'App Battery UP.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion__item js-accordion-item">
                                        <div className="accordion-header js-accordion-header">Comment Battery UP fonctionne-t-il ?</div>
                                        <div className="accordion-body js-accordion-body">
                                            <div className="accordion-body__contents">
                                                <p>Le contrôleur intelligent du boîtier relie en toute simplicité tous les éléments utiles entre eux pour créer une solution innovante de gestion d’énergie.Cette solution permet de contrôler le parc batteries, la production solaire, le réseau ou toutes autres sources d’énergie. Les consommateurs d’énergie ne sont pas en reste grâce à l’ajout de compteur d’énergie supplémentaire.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion__item js-accordion-item">
                                        <div className="accordion-header js-accordion-header">Est-ce compliqué à mettre en oeuvre ?</div>
                                        <div className="accordion-body js-accordion-body">
                                            <div className="accordion-body__contents">
                                                <p>Non, un utilisateur sans trop de connaissances techniques peut installer notre solution PylonWeb en suivant la procédure d’installation du manuel d’installation disponible en ligne.Si cela vous semble compliqué la FAQ et notre SUPPORT en ligne vous aiderons à atteindre avec peu de difficulté votre installation personnalisée.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section >
        );
    }
}

export default withTranslation(['common','homepage'])(SmartHome);