import React from 'react';

function N401() {
    return (
        <div className={"col-lg-12 m-0 p-0"}>
            <img src={require("../_assets/img/401-pas-autorise-site-battery-UP-IMG.png")} alt="Erreur 401 - Pas autorisé" style={{ width: '70%',margin: '50px auto' }} />
        </div>
    );
}

export default N401;
