// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PylontechAlarms_alarmsWrapper__BV3OM {\n  margin: 15px 0; }\n  .PylontechAlarms_alarmsWrapper__BV3OM .PylontechAlarms_title__1FYxM {\n    text-align: left;\n    font-size: 20px;\n    color: royalblue; }\n  .PylontechAlarms_alarmsWrapper__BV3OM .PylontechAlarms_alarms__2sgQV {\n    display: flex;\n    flex-wrap: wrap; }\n", ""]);
// Exports
exports.locals = {
	"alarmsWrapper": "PylontechAlarms_alarmsWrapper__BV3OM",
	"title": "PylontechAlarms_title__1FYxM",
	"alarms": "PylontechAlarms_alarms__2sgQV"
};
module.exports = exports;
